import backend from "backend";
import { notifyNew, notifyClose } from "store/action-creators";

export function newPrediction(optimizationParams, socketSlug, dispatch) {
  dispatch(notifyClose()); // Clear previous error messages when starting a prediction

  return removeAllExistingPredictions(socketSlug)
    .then(() => backend.post(`v3/predictions/${socketSlug}`, optimizationParams))
    .then((prediction) => {
      console.log(`Prediction ${prediction.predictionId} started.`, prediction);

      if (window.location.pathname !== "/predictions")
        dispatch(notifyNew({ message: "Optimisation will be ready in a few minutes" }));
    })
    .catch((error) => {
      console.error("Error running new prediction", error, optimizationParams);
      dispatch(
        notifyNew({
          message: "There was an error running your prediction",
          isError: true,
          isPersistent: true,
        }),
      );
    });
}

function removeAllExistingPredictions(socketSlug) {
  return backend
    .get(`v3/predictions/${socketSlug}`)
    .then((predictions) => {
      const toBeDeleted = predictions.map((d) => d.predictionId);
      console.log("Prior prediction(s) to be deleted", toBeDeleted);
      return toBeDeleted;
    })
    .then((toBeDeleted) => Promise.all(toBeDeleted.map((id) => backend.delete(`v3/predictions/${socketSlug}/${id}`))))
    .then(() => console.log("Prior predictions cleared"))
    .catch((err) => console.error("Failed to delete prior predictions!", err));
}
