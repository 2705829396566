import { Button } from "@mui/material";
import styled from "@emotion/styled";

//Description: Normal text button without icon
// variant and color should be used to get different types of button
/*
Example: a alert red contained button with white text (
      alertRed: { main: "#E10000", contrastText: "#ffffff" }, //setup in gettheme.js
)
<TextButton variant="contained" color="alertRed"> 
  OK
</TextButton>
*/

//Extend in future: a prop like size like small, default and large can be added to have different font-size, height and padding
export const TextButton = styled(Button)`
  padding-left: 10px;
  padding-right: 10px;
  height: 32px;
  min-width: 0;

  font-size: 14px;
  font-weight: 400;
  &.Mui-disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: ${({ theme }) => theme.palette.greyish[20]};
    color: #fff;
  }
`;
