import styled from "@emotion/styled/macro";
import Fade from "@mui/material/Fade";
import Popover from "@mui/material/Popover";
import _ from "lodash";
import React from "react";
import MultiChip from "shared/components/MultiChip";
import filterOutUselessDimensionValues from "shared/helpers/filterOutUselessDimensionValues";
import formattedLabel from "shared/helpers/formattedLabel";
import fromKeyValueListToKeyValuesObject from "shared/helpers/fromKeyValueListToKeyValuesObject";
import SingleChip from "../SingleChip";
import Options from "./Options";
import getToggledFilter from "./getToggledFilter";

export default function DimensionFilter({
  filter,
  color,
  onChange,
  dimensionValues,
  groupingToFilter = {},
  defaultLabel = "All values",
  expandedByDefault = "grouping",
  backDisabled = false, // Use this not to show ← for going back
  clearDisabled = false, // Use this not to show X for clearing filter
  hiddenDimensions = [],
  labelDictionary = {},
  squaredBorder = false,
  preferredDimensionOrder = [],
  multiChip = true,
  dimensionOptions = [],
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);
  const inputEl = React.useRef();
  const [hoveredDimension, setHoveredDimension] = React.useState(null);
  const chipsRef = React.useRef(null);
  // Keep track of filter history so that we can go back
  const [history, setHistory] = React.useState([]);
  const filterStr = JSON.stringify(filter);

  React.useEffect(() => {
    setHistory((history) => [...history, JSON.parse(filterStr)].slice(-20)); // Only keep 20 elements
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStr]);

  function goBack() {
    setHistory(history.slice(0, -2));
    const targetFilter = history[history.length - 2];
    if (targetFilter) onChange(targetFilter, true); //true flag to signal media insights plus to go back on the groupBy for the cards.
  }

  // Discard unique dimensions that are incompatible with this filter
  const valuesGroupedByDim = fromKeyValueListToKeyValuesObject(dimensionValues);
  let reasonableOptions = _.mapValues(valuesGroupedByDim, (values, dimension) =>
    filterOutUselessDimensionValues({ filter, uniqueDimensions: dimensionValues })(values, dimension)
      .slice()
      .filter((d) => d !== null)
      .sort((a, b) =>
        // Sort by label
        (labelDictionary[dimension + "." + a] || a) > (labelDictionary[dimension + "." + b] || String(b)) ? 1 : -1,
      ),
  );

  if (preferredDimensionOrder.length > 0) {
    reasonableOptions = Object.keys(reasonableOptions)
      .sort((a, b) => preferredDimensionOrder.indexOf(a) - preferredDimensionOrder.indexOf(b))
      .reduce((acc, key) => {
        acc[key] = reasonableOptions[key];
        return acc;
      }, {});
  }
  const filteredDimensions = Object.keys(filter);
  // sort the items based on sortOrder array, rest will be at the end
  const sortOrder = ["effect_type", "datatypename"];
  filteredDimensions.sort(function (a, b) {
    const delta = sortOrder.indexOf(a) - sortOrder.indexOf(b);
    if (delta === 0) {
      return a === b ? 0 : a < b ? -1 : 1;
    }
    return delta;
  });
  function toggleOpen(e) {
    setAnchorEl(anchorEl === null ? e.currentTarget : null);
  }

  let parts = [];
  if (!backDisabled && onChange && history.length > 1)
    parts = [{ role: "back", labelTestId: "Back", labels: ["←"], onClick: goBack }];
  const clearPart = {
    labelTestId: "Close",
    labels: ["✕"],
    role: "clear",
    onClick: () => {
      onChange({});
      setAnchorEl(null);
    },
  };
  if (filteredDimensions.length > 0) {
    parts = [
      ...parts,
      ...filteredDimensions.map((dimension) => ({
        title: `${labelDictionary[dimension] || dimension} ${
          filter[dimension]?.length > 1 ? ` (${filter[dimension]?.length})` : ""
        }`,
        labels: filter[dimension].map((value) =>
          // See https://blackwoodseven.slack.com/archives/CJW82FTK6/p1579251863004000
          // See also https://blackwoodseven.slack.com/archives/CJW82FTK6/p1613033636010500?thread_ts=1613028808.008500&cid=CJW82FTK6
          value === "valuemissing" || value === "blank"
            ? "Untagged"
            : formattedLabel(labelDictionary, value, dimension),
        ),
        style: { minWidth: 120, maxWidth: 140 }, // Not to cut labels like "Device"
        onMouseEnter: (e) => {
          setHoveredDimension(dimension);
        },
        onClick: (e) => {
          toggleOpen(e);
          setHoveredDimension(dimension);
        },
      })),
      ...(clearDisabled || !onChange ? [] : [clearPart]),
    ];
  } else
    parts = [
      ...parts,
      {
        title: "Showing",
        labels: [defaultLabel],
        onMouseEnter: (e) => {
          setHoveredDimension(null);
        },
        onClick: toggleOpen,
      },
    ];

  return (
    <div ref={chipsRef}>
      {multiChip ? (
        <MultiChip
          color={color}
          parts={parts}
          disableUpdateAnimation={isOpen}
          dataTestId={"dimensionsMenuLabel"}
          data-test-id="dimensionsMenuButton"
          squaredBorder={squaredBorder}
        />
      ) : (
        <SingleChip
          dataTestId={"dimensionsMenuLabel"}
          data-test-id="dimensionsMenuButton"
          defaultLabel={defaultLabel}
          labelDictionary={labelDictionary}
          filter={filter}
          onClick={(e) => toggleOpen(e)}
          back={{
            enabled: filteredDimensions.length > 0 && !backDisabled && onChange && history.length > 1,
            onClick: goBack,
          }}
          clear={{
            enabled: filteredDimensions.length > 0 && !(clearDisabled || !onChange),
            onClick: () => {
              onChange({});
              setAnchorEl(null);
            },
          }}
        />
      )}

      <Popover
        id={isOpen ? "dimension-picker" : undefined}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{ style: { overflowY: "hidden" } }}
        TransitionComponent={Fade}
      >
        <div style={{ position: "relative" }} data-test-id="dimensionsFilterMenu">
          <OptionsFrame data-test-id="dimensionsFilterMenuOptions">
            <Options
              inputEl={inputEl}
              hoveredDimension={hoveredDimension}
              options={
                !_.isEmpty(filter)
                  ? reasonableOptions
                  : { grouping: Object.keys(groupingToFilter).sort((a, b) => (a > b ? +1 : -1)), ...reasonableOptions }
              }
              nearestDimension={filteredDimensions[filteredDimensions.length - 1]}
              filter={filter}
              onRemoveDimension={(dimension) => {
                if (hoveredDimension === dimension) setHoveredDimension(null);
                if (onChange) {
                  onChange(_.omit(filter, [dimension]));
                  if (Object.keys(filter).length > Object.keys(_.omit(filter, [dimension])).length)
                    setAnchorEl(chipsRef.current);
                }
              }}
              onFilterToggle={({ dimension, value }) => {
                if (!onChange) return;
                if (dimension === "grouping") onChange(groupingToFilter[value]);
                else {
                  onChange(getToggledFilter({ previousFilter: filter, value, inDimension: dimension }));
                  if (
                    Object.keys(filter).length >
                    Object.keys(getToggledFilter({ previousFilter: filter, value, inDimension: dimension })).length
                  )
                    setAnchorEl(chipsRef.current);
                }
              }}
              onClose={() => setAnchorEl(null)}
              disabled={onChange === undefined}
              expandedByDefault={expandedByDefault}
              hiddenDimensions={hiddenDimensions}
              labelDictionary={labelDictionary}
              dimensionOptions={dimensionOptions}
            />
          </OptionsFrame>
        </div>
      </Popover>
    </div>
  );
}

const OptionsFrame = styled.div`
  width: 300px;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;

  /* Scrollbars are tricky to style, for now I'm disabling it */
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;
