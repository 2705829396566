import { getClaimsToken } from "auth";
import _ from "lodash";
import qs from "qs";
import store from "store";
import { notifyNew } from "store/action-creators";

export default async function fetcher(url, opts = {}, token = getClaimsToken(), loggingDisabled = true) {
  const methodToShow = opts?.method?.toUpperCase() || "GET";
  const urlToShow = url.split("?")[0];
  if (process.env.NODE_ENV === "development" && !loggingDisabled) {
    const queryPart = url.split("?")[1];
    console.log(
      `%c ↗ ${methodToShow} ${urlToShow}`,
      "color: rgb(199, 217, 157)",
      queryPart ? qs.parse(queryPart) : opts.body && JSON.parse(opts.body),
    );
  }

  return fetch("/backend-api/" + url, {
    ...opts,
    headers: { ...(opts.headers || {}), Authorization: `Bearer ${token}` },
  }).then((res) => {
    if (!res.ok) {
      return res.text().then((errorResponse) => {
        const errorInfo = `${res.status} ${urlToShow}\n${errorResponse || ""}`;
        console.error(errorInfo);

        if (res.status === 401 || errorInfo.includes("invalid token...")) {
          // store.dispatch(notifyNew({ message: "Your session ended. Please log in again." }));
          // logout();
        } else {
          const queryParams = qs.parse(url.split("?")[1]);
          if (!_.isEmpty(queryParams)) console.error("\nQuery params:", queryParams);
          if (opts.body) console.error("\nRequest body:", JSON.parse(opts.body));
          // This will result in a red snackbar the user will see
          if (!loggingDisabled)
            store.dispatch(notifyNew({ message: "There was a problem retrieving data", isError: true }));
        }
        throw new Error(errorInfo);
      });
    } else {
      // No error, behave differently depending on content type
      const contentType = res.headers.get("content-type");
      return (contentType?.includes("application/json") ? res.json() : res.text())
        .then((data) => {
          if (process.env.NODE_ENV === "development" && !loggingDisabled)
            console.log(`%c ↘ ${methodToShow} ${urlToShow} ${res.status}`, "color: rgb(157, 217, 172)", data);
          return data;
        })
        .catch(() => null); // In case the return is empty, don't propagate error
    }
  });
}
