import { v4 as uuid } from "uuid";
import _ from "lodash";

const updateConstraint = (constraint, field, value, index) => {
  if (field === "dimensions") {
    constraint.dimensions[index] = { ...constraint.dimensions[index], selection: { ...value } };
    if (index < constraint.dimensions.length - 1) {
      const dimensionsToUpdate = _.takeRight(constraint.dimensions, constraint.dimensions.length - 1 - index);
      _.forEach(dimensionsToUpdate, (d) => {
        delete d.selection;
      });
    }
    return {
      ...constraint,
      dimensions: [...constraint.dimensions],
    };
  } else {
    return { ...constraint, [field]: value };
  }
};

export default function addActivityDialogReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ACTIVITY_V2_ADD_EDIT_CONSTRAINT":
      const isAdding = action.index === undefined;
      let constraintObj = {};
      if (!isAdding) {
        constraintObj = { ...state.data.constraints[action.index] };
        constraintObj.dimensions = [];
        _.each(action.headers, (header, i) => {
          const find = _.find(constraintObj.match, (d) => {
            return d.dimension === header;
          });
          if (find) {
            const selectionObj = { index: i, selection: {} };
            selectionObj.selection[find.dimension] = find.value;
            constraintObj.dimensions.push(selectionObj);
          } else {
            constraintObj.dimensions.push({ index: i });
          }
        });
      }
      return {
        ...state,
        inlineEditor: {
          ...state.inlineEditor,
          editing: true,
          index: action.index ?? null,
          constraint: isAdding
            ? {
                type: "fixed-spend",
                startDate: state.data.startDate,
                endDate: state.data.endDate,
                dimensions: [{ index: 0 }],
              }
            : { ...constraintObj },
        },
      };

    case "ACTIVITY_V2_RESET_BEFORE_ADD_NEW_CONSTRAINT":
      return {
        ...state,
        inlineEditor: {
          ...state.inlineEditor,
          constraint: {
            ...INITIAL_STATE.inlineEditor.constraint,
            startDate: state.data.startDate,
            endDate: state.data.endDate,
          },
        },
      };

    case "ACTIVITY_V2_DELETE_CONSTRAINT":
      return {
        ...state,
        data: {
          ...state.data,
          constraints: state.data.constraints.filter((d, i) => i !== action.index),
        },
        inlineEditor: INITIAL_STATE.inlineEditor,
      };

    case "ACTIVITY_V2_UPDATE_FIELD":
    case "ACTIVITY_V2_SWITCH_BRIEF":
      return {
        ...state,
        data: {
          ...state.data,
          [action.label]: action.value,
        },
      };

    case "ACTIVITY_V2_UPDATE_INLINE_EDITOR_FIELD":
      return {
        ...state,
        inlineEditor: {
          ...state.inlineEditor,
          constraint: updateConstraint(state.inlineEditor.constraint, action.field, action.val, action.index),
        },
      };

    case "ACTIVITY_V2_DIALOG_HIDE":
      return {
        ...state,
        isVisible: false,
        inlineEditor: INITIAL_STATE.inlineEditor,
      };

    case "ACTIVITY_V2_DIALOG_SHOW":
      return {
        ...state,
        source: null,
        data: INITIAL_STATE.data,
        isVisible: true,
        isModify: INITIAL_STATE.isModify,
      };

    case "ACTIVITY_V2_DIALOG_SHOW_MODIFY":
      const constraints = [...action.originalRequest.constraints].map((d) => {
        let dimensions = [];
        if (Array.isArray(d.match)) {
          dimensions = d.match.map((t, i) => {
            const obj = { index: i, selection: {} };
            obj.selection[t.dimension] = t.value;
            return obj;
          });
        } else {
          const obj = { index: 0, selection: {} };
          const key = d.match.dimension === "media-group" ? "media-grouping" : d.match.dimension;
          obj.selection[key] = [d.match.value];
          dimensions = [obj];
        }
        return {
          ...d,
          match: Array.isArray(d.match)
            ? d.match
            : [
                {
                  dimension: d.match.dimension === "media-group" ? "media-grouping" : d.match.dimension,
                  value: [d.match.value],
                },
              ],
          dimensions,
        };
      });
      return {
        ...state,
        data: {
          ...state.data,
          startDate: action.originalRequest.startDate,
          endDate: action.originalRequest.endDate,
          constraints,
          budget: action.originalRequest.budget || action.originalRequest.maxBudget,
          currentBudget: action.originalRequest.budget || action.originalRequest.maxBudget,
          target: action.originalRequest.target,
          currentTarget: action.originalRequest.target,
          selectedBrief: action.originalRequest.type,
        },
        isVisible: true,
        isModify: true,
      };

    case "ACTIVITY_V2_CANCEL_EDIT_CONSTRAINT":
      return {
        ...state,
        inlineEditor: INITIAL_STATE.inlineEditor,
      };

    case "ACTIVITY_V2_START_VALIDATING":
      return {
        ...state,
        inlineEditor: {
          ...state.inlineEditor,
          validating: true,
        },
      };

    case "ACTIVITY_V2_VALIDATION_SUCCESS":
      return {
        ...state,
        inlineEditor: {
          ...state.inlineEditor,
          validating: false,
        },
      };

    case "ACTIVITY_V2_VALIDATION_ERROR":
      return {
        ...state,
        inlineEditor: {
          ...state.inlineEditor,
          validating: false,
          errorMessage: action.errorMessage,
        },
      };

    case "ACTIVITY_V2_OVERALL_VALIDATION_ERROR":
      return {
        ...state,
        isPredictionError: action.errorStatus,
      };

    case "ACTIVITY_V2_ADD_INLINE_CONSTRAINT_DIMENSION":
      return {
        ...state,
        inlineEditor: {
          ...state.inlineEditor,
          constraint: {
            ...state.inlineEditor.constraint,
            dimensions: [...state.inlineEditor.constraint.dimensions, { index: action.index }],
          },
        },
      };

    case "ACTIVITY_V2_DELETE_INLINE_CONSTRAINT_DIMENSION":
      const currentDimension = state.inlineEditor.constraint.dimensions;
      currentDimension.splice(action.index - 1, 1);
      return {
        ...state,
        inlineEditor: {
          ...state.inlineEditor,
          constraint: {
            ...state.inlineEditor.constraint,
            dimensions: currentDimension,
          },
        },
      };

    case "ACTIVITY_V2_EMPTY_INLINE_CONSTRAINT_DIMENSION":
      const isLast = state.inlineEditor.constraint.dimensions.length - 1 === action.index;
      const findCurrentDimension = state.inlineEditor.constraint.dimensions;
      _.forEach(findCurrentDimension, (d) => {
        if (isLast && action.index === d.index) {
          delete d["selection"];
        }
        if (action.index === d.index) {
          delete d["selection"];
        }
      });

      return {
        ...state,
        inlineEditor: {
          ...state.inlineEditor,
          constraint: {
            ...state.inlineEditor.constraint,
            dimensions: [...findCurrentDimension],
          },
        },
      };

    case "ACTIVITY_V2_SAVE_CONSTRAINT":
      const updatedData = [...state.data.constraints];
      const newConstraint = {
        id: uuid(),
        ...state.inlineEditor.constraint,
      };
      if (action.index === null) updatedData.push(newConstraint);
      else updatedData[action.index] = newConstraint;

      return {
        ...state,
        data: {
          ...state.data,
          constraints: updatedData,
        },
        inlineEditor: INITIAL_STATE.inlineEditor,
      };
    default:
      return state;
  }
}

export const INITIAL_STATE = {
  isVisible: false,
  isModify: false,
  isPredictionError: false,
  data: {
    selectedBrief: "budgetBased",
    budget: undefined,
    currentBudget: undefined,
    target: undefined,
    currentTarget: undefined,
    startDate: undefined,
    endDate: undefined,
    constraints: [],
  },
  inlineEditor: {
    editing: false,
    errorMessage: undefined,
    index: null,
    validating: false,
    constraint: {
      type: undefined,
      startDate: undefined,
      endDate: undefined,
      match: [],
      dimensions: [],
      value: undefined,
    },
  },
  source: null,
};
