import styled from "@emotion/styled/macro";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { Option } from "admin";
import { useAccount } from "auth";
import ReactDOM from "react-dom";
import BroadcastStationControl from "../BroadcastStation";
import { initialBroadcastEditorState } from "../BroadcastStation/BroadcastEditor";
import { ReactComponent as BroadcastIcon } from "../BroadcastStation/broadcastIcon.svg";
import AvailableOrgs from "./SelectOrg";
import UsersEditor from "./UsersEditor";

export default function ToolEditor({
  organization,
  name,
  changesPending,
  changesPendingCb,
  tab,
  setTab,
  organizationsList,
  broadcastEditorState,
  setBroadcastEditorState,
  getOrgs,
  setDisableScroll,
}) {
  const { isClaim } = useAccount();
  return (
    <>
      {(tab === "organization" || tab === "userManagement") && (
        <AdminHeaderForOrgEditor>
          <Choose>
            <Option
              style={{ fontSize: "14px", padding: "7.5px 10px", height: "fit-content" }}
              active={tab === "organization"}
              onClick={() => {
                setTab("organization");
                setDisableScroll(false);
              }}
              data-test-id="admin-configuration-tab"
            >
              ORGANIZATIONS
            </Option>

            {isClaim("GlobalARM.Read") && (
              <Option
                style={{ fontSize: "14px", padding: "7.5px 10px", height: "fit-content" }}
                active={tab === "userManagement"}
                onClick={() => {
                  if (!changesPending) setTab("userManagement");
                }}
                disabled={changesPending}
                data-test-id="admin-users-tab"
              >
                USER MANAGEMENT
              </Option>
            )}
          </Choose>
        </AdminHeaderForOrgEditor>
      )}
      {tab !== "userManagement" && isClaim("Broadcast.Write") && (
        <BroadcastStationdiv>
          <div style={{ display: "flex", alignItems: "center" }}>
            <BroadcastIcon />
            <BroadcastTitle>Global Broadcast Station</BroadcastTitle>
          </div>
          <div>
            {tab === "broadcaststation" && (
              <Button
                aria-label="Create new broadcast"
                title="Create new broadcast"
                sx={{ textTransform: "none", fontSize: "14px" }}
                size="small"
                data-test-id="create-new-broadcast"
                variant="contained"
                color="primary"
                disabled={broadcastEditorState.isEditorOpen}
                startIcon={<AddIcon />}
                disableElevation
                onClick={() => setBroadcastEditorState({ ...initialBroadcastEditorState, isEditorOpen: true })}
              >
                New Broadcast
              </Button>
            )}
            {tab === "organization" && (
              <Button
                sx={{ textTransform: "none", fontSize: "14px" }}
                size="small"
                data-test-id="open-broadcast"
                variant="text"
                color="primary"
                disableElevation
                onClick={() => setTab("broadcaststation")}
              >
                Open station
              </Button>
            )}
          </div>
        </BroadcastStationdiv>
      )}
      {tab === "organization" && <AvailableOrgs organizationsList={organizationsList} />}

      {tab === "broadcaststation" && (
        <BroadcastStationControl
          setBroadcastEditorState={setBroadcastEditorState}
          broadcastEditorState={broadcastEditorState}
        />
      )}

      {tab === "userManagement" && isClaim("GlobalARM.Read") && (
        <UsersEditor organization={organization} getOrgs={getOrgs} setDisableScroll={setDisableScroll} />
      )}
    </>
  );
}

function AdminHeaderForOrgEditor({ children }) {
  const adminHeaderTitleEl = document.getElementById("admin-header");
  return adminHeaderTitleEl && ReactDOM.createPortal(children, adminHeaderTitleEl);
}

const Choose = styled.div`
  display: flex;

  > * {
    &:first-of-type {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    &:last-of-type {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
`;

const BroadcastStationdiv = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 9px 15px;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.greyish[20]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.greyish[20]};
`;

const BroadcastTitle = styled.span`
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
`;
