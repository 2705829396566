import styled from "@emotion/styled";
import { IconButton, Tooltip } from "@mui/material";
import { useTheme } from "@mui/styles";
import { getClaimsToken } from "auth";
import _ from "lodash";
import React from "react";
import CheckBoxMenu from "shared/components/DropdownMenu/CheckBoxMenu";
import store from "store";
import { notifyNew } from "store/action-creators";
import { mutate } from "swr";
import { useData } from "../useData";
import ConfirmationDialog from "./ConfirmModal";

//Icons
import { ReactComponent as CheckIcon } from "shared/Icons/check.svg";
import { ReactComponent as CheckDisabledIcon } from "shared/Icons/check_disabled.svg";
import { ReactComponent as CrossIcon } from "shared/Icons/cross.svg";
import { ReactComponent as EditIcon } from "shared/Icons/edit-2-black.svg";

export default function BulkAssign({ tab, organization }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const { users = [], globallyAvailableModels, globallyAvailableFeatures, modelsLabels } = useData(organization);

  const usersInTab = users.filter((user) =>
    tab === "internal" ? user.email.endsWith("@kantar.com") : !user.email.endsWith("@kantar.com"),
  );

  const initialData = {
    models: globallyAvailableModels.reduce(
      (acc, model) => ({
        ...acc,
        [model]: usersInTab.every((user) => user.models.includes(model))
          ? true
          : usersInTab.every((user) => !user.models.includes(model))
            ? null
            : false,
      }),
      {},
    ),
    features: globallyAvailableFeatures.reduce(
      (acc, feature) => ({
        ...acc,
        [feature]: usersInTab.every((user) => user.features.includes(feature))
          ? true
          : usersInTab.every((user) => !user.features.includes(feature))
            ? null
            : false,
      }),
      {},
    ),
  };
  const [{ isDisabled, localData }, dispatch] = React.useReducer(localDataReducer, {
    isDisabled: true,
    localData: initialData,
  });

  const noChangesDone = _.isEqual(localData, initialData);

  const actions = {
    bulkRemove: async () => {
      const request = {
        features: [...Object.keys(localData.features).filter((key) => localData.features[key] === null)],
        models: [...Object.keys(localData.models).filter((key) => localData.models[key] === null)],
        affectingScope: `${tab}Users`,
      };
      await fetch(`/backend-api/auth/v2/user/update-all/remove`, {
        method: "PATCH",
        body: JSON.stringify(request),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getClaimsToken()}`,
        },
      })
        .then(async (res) => {
          if (!res.ok) throw new Error(await res.text());
          return res;
        })
        .catch((err) =>
          store.dispatch(
            notifyNew({
              message: `Failed to add Bulk update users.`,
              isError: true,
            }),
          ),
        );
      mutate(`/backend-api/auth/v2/user/getall/${organization}`); // Revalidate
    },
    bulkAdd: async () => {
      const request = {
        features: [...Object.keys(localData.features).filter((key) => localData.features[key])],
        models: [...Object.keys(localData.models).filter((key) => localData.models[key])],
        affectingScope: `${tab}Users`,
      };
      await fetch(`/backend-api/auth/v2/user/update-all/add`, {
        method: "PATCH",
        body: JSON.stringify(request),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getClaimsToken()}`,
        },
      })
        .then(async (res) => {
          if (!res.ok) throw new Error(await res.text());
          return res;
        })
        .catch((err) =>
          store.dispatch(
            notifyNew({
              message: `Failed to add Bulk update users.`,
              isError: true,
            }),
          ),
        );
      mutate(`/backend-api/auth/v2/user/getall/${organization}`); // Revalidate
    },
  };
  return (
    <>
      <ConfirmationDialog
        key={open}
        open={open}
        onClose={() => setOpen(false)}
        onConfirmation={async () => {
          const { features, models } = localData;
          const toBeRemoved = Object.values({ ...features, ...models }).some((value) => value === null);
          const toBeAdded = Object.values({ ...features, ...models }).some((value) => value);

          if (toBeAdded || toBeRemoved) {
            if (toBeRemoved) {
              await actions.bulkRemove();
            }

            if (toBeAdded) {
              await actions.bulkAdd();
            }
            store.dispatch(
              notifyNew({
                message: `Selected models and features have been ${toBeRemoved && toBeAdded ? "granted/revoked" : toBeAdded ? "granted" : "revoked"} access for all ${tab} users.`,
                closeButton: false,
                isPersistent: false,
                extraStyles: { width: "320px", padding: "10px", fontSize: "12px", color: theme.palette.whiteish.main },
              }),
            );
          }
          dispatch({ type: "reset" });
        }}
        tab={tab}
      />

      <Container theme={theme}>
        <Label>Select the models and features to grant/revoke access to for all {tab} users</Label>
        <DropdownsContainer>
          <CheckBoxMenu
            title="Models"
            options={globallyAvailableModels.map((model) => ({
              id: model,
              label: modelsLabels[model],
              checked: localData.models[model],
            }))}
            selected="Models"
            onChange={(item) => dispatch({ type: "change-models", toggleItem: item })}
            onChangeAll={(state) => dispatch({ type: "all-models", toggleTo: state })}
            clickDisabled={isDisabled}
            style={{ buttonText: { color: isDisabled ? theme.palette.greyish[50] : "inherit" } }}
          />
          <CheckBoxMenu
            title="Features"
            options={globallyAvailableFeatures
              .filter((feature) => feature)
              .map((feature) => ({
                id: feature,
                label: feature,
                checked: localData.features[feature],
              }))}
            selected="Features"
            onChange={(item) => dispatch({ type: "change-features", toggleItem: item })}
            clickDisabled={isDisabled}
            style={{ buttonText: { color: isDisabled ? theme.palette.greyish[50] : "inherit" } }}
            onChangeAll={(state) => dispatch({ type: "all-features", toggleTo: state })}
          />
        </DropdownsContainer>
        <Actions>
          {isDisabled ? (
            <ModifiedIconButton>
              <Tooltip title={"Edit"} arrow>
                <EditIcon
                  height={20}
                  width={20}
                  style={{ cursor: "pointer" }}
                  onClick={() => dispatch({ type: "make-editable" })}
                />
              </Tooltip>
            </ModifiedIconButton>
          ) : (
            <>
              <ModifiedIconButton onClick={() => setOpen(true)}>
                {noChangesDone ? (
                  <Tooltip title={"No changes done"} arrow>
                    <CheckDisabledIcon height={20} width={20} style={{ cursor: "not-allowed" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title={"Save current changes"} arrow>
                    <CheckIcon height={20} width={20} style={{ cursor: "pointer" }} />
                  </Tooltip>
                )}
              </ModifiedIconButton>
              <ModifiedIconButton
                onClick={() =>
                  dispatch({
                    type: "discard-changes",
                    originalData: initialData,
                  })
                }
              >
                <Tooltip title={"Discard changes"} arrow>
                  <CrossIcon height={20} width={20} style={{ cursor: "pointer" }} />
                </Tooltip>
              </ModifiedIconButton>
            </>
          )}
        </Actions>
      </Container>
    </>
  );
}

const Container = styled.div`
  padding: 10px 15px 10px 15px;
  display: grid;
  margin-bottom: 7px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.greyish[20]};
  background-color: ${({ theme }) => theme.palette.greyish[10]};
  grid-template-columns: 345px 500px auto;
  align-items: center;
`;

const Label = styled.div`
  font-size: 14px;
  margin-right: 37px;
  color: ${({ theme }) => theme.palette.greyish[80]};
`;

const ModifiedIconButton = styled(IconButton)`
  height: 20px;
  width: 20px;
  padding: 0;
`;

const DropdownsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const Actions = styled.div`
  display: flex;
  gap: 10px;
  margin-left: 22px;
  padding: 0px 8px;
  margin-top: 14px;
  justify-content: flex-end;
`;

function localDataReducer(state, action) {
  switch (action.type) {
    case "make-editable":
      return { ...state, isDisabled: false };
    case "change-models":
      return {
        ...state,
        localData: {
          ...state.localData,
          models: {
            ...state.localData.models,
            [action.toggleItem]:
              state.localData.models[action.toggleItem] === null
                ? false
                : state.localData.models[action.toggleItem] === false
                  ? true
                  : null,
          },
        },
      };

    case "change-features":
      return {
        ...state,
        localData: {
          ...state.localData,
          features: {
            ...state.localData.features,
            [action.toggleItem]:
              state.localData.features[action.toggleItem] === null
                ? false
                : state.localData.features[action.toggleItem] === false
                  ? true
                  : null,
          },
        },
      };

    case "all-models":
      return {
        ...state,
        localData: {
          ...state.localData,
          models: {
            ...Object.keys(state.localData.models).reduce((acc, model) => ({ ...acc, [model]: action.toggleTo }), {}),
          },
        },
      };

    case "all-features":
      return {
        ...state,
        localData: {
          ...state.localData,
          features: {
            ...Object.keys(state.localData.features).reduce(
              (acc, feature) => ({ ...acc, [feature]: action.toggleTo }),
              {},
            ),
          },
        },
      };

    case "reset":
    case "discard-changes":
      return { ...state, isDisabled: true, ...(action.originalData ? { localData: action.originalData } : {}) };
    default:
      return { ...state };
  }
}
