import React from "react";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as DeleteIcon } from "./delete-icon.svg";

export default function DeleteIconButton({ onClick, extraStyles }) {
  return (
    <IconButton onClick={onClick} sx={{ marginTop: "3px", ...extraStyles }}>
      <DeleteIcon height={20} width={20} />
    </IconButton>
  );
}
