import FEATURES from "features";

export default function featureOverridesReducer(state = FEATURES, action) {
  if (action.type === "TOGGLE_FEATURE") {
    const { featureName } = action;
    const currentState = state[featureName]?.state;

    return {
      ...state,
      [featureName]: {
        ...state[featureName],
        state: currentState === undefined ? true : currentState === true ? false : undefined,
      },
    };
  }

  return state;
}
