import styled from "@emotion/styled";
import { Tooltip } from "@mui/material";
import { isValidName, isValidEmail, isValidRole } from "admin/helpers";
import _ from "lodash";
import { ReactComponent as InfoIcon } from "shared/Icons/info.svg";
import { ErrorMessage, roles as orgRoles } from "../OrgEditor/UsersEditor/index";
import { roles as toolRoles } from "../ToolEditor/UsersEditor/index";

export function runOrgArmValidation(toAddNonEmpty, existingEmails, existInToolEmails) {
  return (
    (toAddNonEmpty.some((d) => !isValidEmail(d.email)) && (
      <ErrorMessage>
        Please enter a valid email id{" "}
        <Tooltip
          arrow
          placement="top"
          title={
            <div>
              Email Format Guide:
              <br />
              <Line>Feel free to use up to 64 characters before the '@' and 48 after.</Line>
              <br />
              <Line>
                Before the '@', you can use letters (a-z, A-Z), numbers (0-9), and symbols like '.', '_', '!', '#', '^',
                and '~', but please don't start or end with them.
              </Line>
              <br />
              <Line>
                After the '@', stick to letters, numbers, '-', and '.', ensuring there's at least one '.'. Remember, '-'
                and '.' can't start, end, or follow each other directly.
              </Line>
              <br />
              <Line>
                Lastly, ensure there's a section after the final '.' with 2 to 24 characters, and avoid back-to-back
                periods anywhere
              </Line>
            </div>
          }
        >
          <InfoIcon style={{ paddingLeft: 5, cursor: "pointer" }} />
        </Tooltip>
      </ErrorMessage>
    )) ||
    (toAddNonEmpty.some((d) => existInToolEmails.includes(d.email)) && (
      <ErrorMessage>Entered email id have already access to this organsiation.</ErrorMessage>
    )) ||
    (existingEmails.length !== _.uniq(existingEmails).length && (
      <ErrorMessage>Found email already exists in the tool, please select from existing users dropdown</ErrorMessage>
    )) ||
    (toAddNonEmpty.some((d) => d.firstName.trim().length === 0) && (
      <ErrorMessage>Each user need to have a first name</ErrorMessage>
    )) ||
    (toAddNonEmpty.some((d) => !isValidName(d.firstName)) && (
      <ErrorMessage>
        Each user need a valid first name{" "}
        <Tooltip arrow placement="top" title="Use only letters, _, - and spaces">
          <InfoIcon style={{ paddingLeft: 5, cursor: "pointer" }} />
        </Tooltip>
      </ErrorMessage>
    )) ||
    (toAddNonEmpty.some((d) => d.lastName.trim().length === 0) && (
      <ErrorMessage>Each user need to have a last name</ErrorMessage>
    )) ||
    (toAddNonEmpty.some((d) => !isValidName(d.lastName)) && (
      <ErrorMessage>
        Each user need a valid last name{" "}
        <Tooltip arrow placement="top" title="Use only letters, _, - and spaces">
          <InfoIcon style={{ paddingLeft: 5, cursor: "pointer" }} />
        </Tooltip>
      </ErrorMessage>
    )) ||
    (toAddNonEmpty.some((d) => d.models.length === 0) && (
      <ErrorMessage>Each user need to have access to atleast one model</ErrorMessage>
    )) ||
    (toAddNonEmpty.some((d) => d.features.length === 0) && (
      <ErrorMessage>Each user need to have access to atleast one feature</ErrorMessage>
    )) ||
    (toAddNonEmpty.some((d) => !isValidRole(orgRoles, d.orgRole)) && (
      <ErrorMessage>Each user need to assign a valid role</ErrorMessage>
    )) ||
    null
  );
}

export function runToolArmValidation(toAddNonEmpty, existingEmails) {
  return (
    (toAddNonEmpty.some((d) => !isValidEmail(d.email)) && (
      <ErrorMessage>
        Please enter a valid email id{" "}
        <Tooltip
          arrow
          placement="top"
          title={
            <div>
              Email Format Guide:
              <br />
              <Line>Feel free to use up to 64 characters before the '@' and 48 after.</Line>
              <br />
              <Line>
                Before the '@', you can use letters (a-z, A-Z), numbers (0-9), and symbols like '.', '_', '!', '#', '^',
                and '~', but please don't start or end with them.
              </Line>
              <br />
              <Line>
                After the '@', stick to letters, numbers, '-', and '.', ensuring there's at least one '.'. Remember, '-'
                and '.' can't start, end, or follow each other directly.
              </Line>
              <br />
              <Line>
                Lastly, ensure there's a section after the final '.' with 2 to 24 characters, and avoid back-to-back
                periods anywhere
              </Line>
            </div>
          }
        >
          <InfoIcon style={{ paddingLeft: 5, cursor: "pointer" }} />
        </Tooltip>
      </ErrorMessage>
    )) ||
    (existingEmails.length !== _.uniq(existingEmails).length && (
      <ErrorMessage>Entered email id is already present in the system.</ErrorMessage>
    )) ||
    (toAddNonEmpty.some((d) => d.firstName.trim().length === 0) && (
      <ErrorMessage>Each user need to have a first name</ErrorMessage>
    )) ||
    (toAddNonEmpty.some((d) => !isValidName(d.firstName)) && (
      <ErrorMessage>
        Each user need a valid first name{" "}
        <Tooltip arrow placement="top" title="Use only letters, _, - and spaces">
          <InfoIcon style={{ paddingLeft: 5, cursor: "pointer" }} />
        </Tooltip>
      </ErrorMessage>
    )) ||
    (toAddNonEmpty.some((d) => d.lastName.trim().length === 0) && (
      <ErrorMessage>Each user need to have a last name</ErrorMessage>
    )) ||
    (toAddNonEmpty.some((d) => !isValidName(d.lastName)) && (
      <ErrorMessage>
        Each user need a valid last name{" "}
        <Tooltip arrow placement="top" title="Use only letters, _, - and spaces">
          <InfoIcon style={{ paddingLeft: 5, cursor: "pointer" }} />
        </Tooltip>
      </ErrorMessage>
    )) ||
    (toAddNonEmpty.some((d) => !isValidRole(toolRoles, d.defaultRole)) && (
      <ErrorMessage>Each user need to assign a valid role</ErrorMessage>
    )) ||
    null
  );
}

const Line = styled.span`
  display: inline-block;
  margin: 2.5px 0px;
`;
