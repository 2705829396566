import {
  parse,
  format,
  startOfYear,
  startOfQuarter,
  startOfMonth,
  startOfWeek,
  startOfDay,
  endOfYear,
  endOfQuarter,
  endOfMonth,
  endOfWeek,
  endOfDay,
  isSameYear,
  isSameQuarter,
  isSameMonth,
  isSameWeek,
  isSameDay,
  differenceInCalendarYears,
  differenceInCalendarQuarters,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  differenceInCalendarDays,
  subYears,
  subQuarters,
  subMonths,
  subWeeks,
  subDays,
  addYears,
  addDays,
} from "date-fns";

// Please read:
// - Data is local to the user,
// - data from the server labelled YYYY-MM-DDTHH:mm:ssZ (even with the Z notation)
//   means that data is labelled with that date in local time
// - We don't do timezone conversions
// - Our preferred date management library is date-fns (we plan to migrate others)

// We've had quite a few bugs related with time. For reference:
// - https://blackwoodseven.atlassian.net/browse/BUGS-1144
// - https://blackwoodseven.atlassian.net/browse/BUGS-1137
// These let to the conclusion that a simple system is parsing server
// dates as local to the browser, since we wanted the UI to show the same
// dates no matter where a user was located (see above, assume data to be local)

// We very often use a portable string representation of dates
// (example: "2020-11-17") for our internal data structures
export function dateToString(jsDate) {
  return format(jsDate, "yyyy-MM-dd");
}

// This function centralises data parsing
// We accept JS Dates, YYYY-MM-DD...  (f.e.: YYYY-MM-DDTHH:mm:ssZ)
export function parseDate(dateInUnknownFormat) {
  if (dateInUnknownFormat instanceof Date) return dateInUnknownFormat;
  else if (typeof dateInUnknownFormat === "string")
    return parse(dateInUnknownFormat.substring(0, 10), "yyyy-MM-dd", new Date(0, 0, 0, 0, 0, 0, 0));
  throw new Error(`Unknown date format: ${dateInUnknownFormat}`);
}

export function prettyDate(dateInUnknownFormat, opts = {}) {
  const date = dateInUnknownFormat instanceof Date ? dateInUnknownFormat : parseDate(dateInUnknownFormat);
  const showYear = opts.showYear || !isSameYear(new Date(), date);
  const showMonth = opts.showMonth;
  const leadingZero = opts.leadingZero;

  switch (opts.interval) {
    case "year":
      return format(date, "yyyy");
    case "quarter":
      return format(date, `QQQ${showYear ? " yyyy" : ""}`);
    case "month":
      return format(date, `${opts.longLabels ? "MMMM" : "MMM"}${showYear ? " yyyy" : ""}`);
    case "week":
      return `W${format(date, `II${showMonth ? ", MMM" : ""}${showYear ? " RRRR" : ""}`)}`;
    case "day":
    default:
      return format(
        date,
        `${opts.showWeekday ? "EEEE, " : ""}${leadingZero ? "d" : ""}d MMM${showYear ? " yyyy" : ""}`,
      );
  }
}

// The bounds have been chosen to limit the weight of the model data to retrieve
const PERIOD_BOUNDS = { min: 7, max: 4 * 366 }; // Consider leap years
export function adjustPeriod(period, opts = {}) {
  let [start, end] = period.map(parseDate);
  const dayCount = differenceInCalendarDays(end, start) + 1;

  // Adjust the period within the bounds, taking the start day as reference
  if (dayCount < PERIOD_BOUNDS.min) end = addDays(start, PERIOD_BOUNDS.min - 1);
  else if (dayCount > PERIOD_BOUNDS.max) end = addDays(start, PERIOD_BOUNDS.max - 1);

  // This is normally used when we're dealing with a weekly model
  if (opts.forceWeekly) {
    start = startOfWeek(start, { weekStartsOn: opts.weekStartsOn || 1 });
    end = endOfWeek(end, { weekStartsOn: opts.weekStartsOn || 1 });
  }
  return [start, end].map(dateToString);
}

export const prettyPeriod = (period, opts = {}) => {
  const dates = period.map(parseDate);
  const now = new Date();
  const showYear = opts.showYear || !isSameYear(now, dates[0]) || !isSameYear(now, dates[1]);
  const interval = opts.resolveFullPeriods && getLargestExactInterval(dates);
  // If the period can be written as a single thing, like "Jan, 2019", then do it
  if (interval && isSame(dates[0], dates[1], interval))
    return prettyDate(dates[0], { ...opts, interval, longLabels: true, showYear: true });
  else return dates.map((d) => prettyDate(d, { ...opts, showYear, interval: "day" })).join(" → ");
};

function isSame(date, vsDate, interval) {
  switch (interval) {
    case "year":
      return isSameYear(date, vsDate);
    case "quarter":
      return isSameQuarter(date, vsDate);
    case "month":
      return isSameMonth(date, vsDate);
    case "week":
      return isSameWeek(date, vsDate);
    case "day":
    default:
      return false;
  }
}

function getLargestExactInterval(periodParsed) {
  const [starts, ends] = periodParsed;
  if (isSameDay(starts, startOfYear(starts)) && isSameDay(ends, endOfYear(ends))) return "year";
  else if (isSameDay(starts, startOfQuarter(starts)) && isSameDay(ends, endOfQuarter(ends))) return "quarter";
  else if (isSameDay(starts, startOfMonth(starts)) && isSameDay(ends, endOfMonth(ends))) return "month";
  else return undefined; // Cannot find anything exact like a week or larger
}

// We snap to full periods. That means that if you're looking at October (31 days)
// your previous period will be September (which has 30 days)
// This works under the assumption that people want to compare accross full months (quarters, years...)
function getPreviousPeriodSnappy(periodParsed) {
  const [startDate, endDate] = periodParsed;
  const previousEnds = subDays(startDate, 1); // The vsPeriod end is always one day before the period
  switch (getLargestExactInterval(periodParsed)) {
    case "year":
      return [subYears(startDate, differenceInCalendarYears(endDate, startDate) + 1), previousEnds];
    case "quarter":
      return [subQuarters(startDate, differenceInCalendarQuarters(endDate, startDate) + 1), previousEnds];
    case "month":
      return [subMonths(startDate, differenceInCalendarMonths(endDate, startDate) + 1), previousEnds];
    case "week":
      return [subWeeks(startDate, differenceInCalendarWeeks(endDate, startDate) + 1), previousEnds];
    default:
      return [subDays(startDate, differenceInCalendarDays(endDate, startDate) + 1), previousEnds];
  }
}

export function getDaysInPeriod(period) {
  const periodParsed = period.map(parseDate);
  return differenceInCalendarDays(periodParsed[1], periodParsed[0]) + 1;
}

export function getPreviousPeriod(period, opts = {}) {
  const periodParsed = period.map(parseDate);
  if (opts.snapToFullPeriods) {
    return getPreviousPeriodSnappy(periodParsed).map(dateToString);
  } else {
    const daysInPeriod = getDaysInPeriod(period);
    return periodParsed.map((d) => subDays(d, daysInPeriod)).map(dateToString);
  }
}

// How can I work with dates as if they were ISO.
export function getFiscalYear(countryCode, yearOffset = 0) {
  const refDate = addYears(new Date(), yearOffset);
  // If no deviation found go with natural year
  const [from, to] = (FISCAL_YEARS[countryCode?.toLowerCase()] || ["01/01", "12/31"]).map((mmdd, i) => {
    const dateWithYear = parse(mmdd, "MM/dd", refDate);
    if (i === 0 ? dateWithYear > refDate : dateWithYear < refDate) return addYears(dateWithYear, i === 0 ? -1 : +1);
    else return dateWithYear;
  });
  return { start: startOfDay(from), end: endOfDay(to) };
}
// List of fiscal year deviations from the natural year (Jan 1 > Dec 31)
const FISCAL_YEARS = {
  af: ["12/21", "12/20"],
  as: ["10/01", "09/30"],
  ai: ["04/01", "03/31"],
  ag: ["04/01", "03/31"],
  au: ["07/01", "06/30"],
  bs: ["07/01", "06/30"],
  bd: ["07/01", "06/30"],
  bb: ["04/01", "03/31"],
  bz: ["04/01", "03/31"],
  bm: ["04/01", "03/31"],
  bt: ["07/01", "06/30"],
  bw: ["04/01", "03/31"],
  vg: ["04/01", "03/31"],
  bn: ["04/01", "03/31"],
  mm: ["04/01", "03/31"],
  cm: ["07/01", "06/30"],
  ca: ["04/01", "03/31"],
  ky: ["04/01", "03/31"],
  cx: ["07/01", "06/30"],
  cc: ["07/01", "06/30"],
  ck: ["04/01", "03/31"],
  dm: ["07/01", "06/30"],
  eg: ["07/01", "06/30"],
  sz: ["04/01", "03/31"],
  et: ["07/08", "07/07"],
  gi: ["07/01", "06/30"],
  gu: ["10/01", "09/30"],
  ht: ["10/01", "09/30"],
  hk: ["04/01", "03/31"],
  in: ["04/01", "03/31"],
  ir: ["04/21", "04/20"],
  im: ["04/01", "03/31"],
  jm: ["04/01", "03/31"],
  jp: ["04/01", "03/31"],
  je: ["04/01", "03/31"],
  ke: ["07/01", "06/30"],
  kw: ["04/01", "03/31"],
  la: ["10/01", "09/30"],
  ls: ["04/01", "03/31"],
  mw: ["07/01", "06/30"],
  mh: ["10/01", "09/30"],
  mu: ["07/01", "06/30"],
  fm: ["10/01", "09/30"],
  ms: ["04/01", "03/31"],
  na: ["04/01", "03/31"],
  nr: ["07/01", "06/30"],
  np: ["07/16", "07/15"],
  nz: ["04/01", "03/31"],
  nu: ["04/01", "03/31"],
  nf: ["07/01", "06/30"],
  mp: ["10/01", "09/30"],
  pk: ["07/01", "06/30"],
  pw: ["10/01", "09/30"],
  pn: ["04/01", "03/31"],
  pr: ["07/01", "06/30"],
  qa: ["04/01", "03/31"],
  lc: ["04/01", "03/31"],
  sg: ["04/01", "03/31"],
  za: ["04/01", "03/31"],
  tz: ["07/01", "06/30"],
  th: ["10/01", "09/30"],
  tk: ["04/01", "03/31"],
  to: ["07/01", "06/30"],
  tt: ["10/01", "09/30"],
  ug: ["07/01", "06/30"],
  gb: ["04/06", "04/05"],
  us: ["10/01", "09/30"],
  vi: ["10/01", "09/30"],
};
