import { getSimulationInputStateToSave } from "pages/predictions/Simulator/SimulatorInput/Footer";
import _ from "lodash";

const DB_NAME = "simulatorInputState";
const STORE_NAME = "simulatorInputStateStore";

const dbPromise = new Promise((resolve, reject) => {
  const request = indexedDB.open(DB_NAME, 1);

  request.onupgradeneeded = (event) => {
    const db = event.target.result;
    db.createObjectStore(STORE_NAME, { keyPath: "id" });
  };

  request.onsuccess = (event) => {
    resolve(event.target.result);
  };

  request.onerror = (event) => {
    reject(event.target.error);
  };
});

export const saveInputState = async (object) => {
  const db = await dbPromise;
  try {
    const id = getStoreId();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([STORE_NAME], "readwrite");
      const store = transaction.objectStore(STORE_NAME);
      const dataWithId = { id, ...object };
      const request = store.put(dataWithId);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error("Failed to save data:", error);
  }
};

export const getSavedInputState = async () => {
  const db = await dbPromise;
  try {
    const id = getStoreId();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([STORE_NAME], "readonly");
      const store = transaction.objectStore(STORE_NAME);
      const request = store.get(id);

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error("Failed to get data:", error);
  }
};

export const deleteSavedInputState = async () => {
  const db = await dbPromise;
  try {
    const id = getStoreId();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([STORE_NAME], "readwrite");
      const store = transaction.objectStore(STORE_NAME);
      const request = store.delete(id);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error("Failed to delete data:", error);
  }
};

export function getIndexedDBObjectToSave({
  apiMediaInputData,
  mediaInputData,
  apiNonMediaInputData,
  nonMediaInputData,
  period,
  simAdminConfigItems,
}) {
  //it takes the simulation redux state keys and create the object to save in indexedDB

  const statePayload = getSimulationInputStateToSave({
    apiMediaInputData,
    mediaInputData,
    apiNonMediaInputData,
    nonMediaInputData,
    period,
    simAdminConfigItems,
  });
  //clonedDeep as soem state objects can be Proxy() as they are coming coming from reducer.
  return _.cloneDeep(statePayload);
}

export function getStoreId() {
  try {
    const selectedOrgSlug = JSON.parse(sessionStorage.getItem("selectedOrg"))?.orgSlug;
    const selecteModelSlug = sessionStorage.getItem("selected-model");
    if (!selectedOrgSlug || !selecteModelSlug) {
      throw new Error("Failed to create Id from sessionStorage");
    }
    return `${selectedOrgSlug}/${selecteModelSlug}`;
  } catch (e) {
    throw e;
  }
}
