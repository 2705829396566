import { useTheme } from "@emotion/react";
import { InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import { ReactComponent as SearchIcon } from "shared/Icons/Magnifier.svg";
import { ReactComponent as CrossIcon } from "shared/Icons/crossGrey.svg";

export default function SearchBar({ searchText, activeEmail, setSearchText }) {
  const theme = useTheme();
  return (
    <TextField
      sx={{
        width: "100%",
        padding: "8px 15px",
        paddingTop: 0,
        "& .MuiInputBase-root": {
          height: "32px",
          fontSize: "14px",
          padding: "6px 8px",
          backgroundColor: theme.palette.greyish[10],
        },
        "& .MuiInputBase-root > input": { padding: 0, color: theme.palette.greyish[50] },
        "& .MuiInputBase-root > input::placeholder": { opacity: 1 },
        "& .MuiInputBase-root > input:-webkit-autofill": {
          transition: "background-color 0s 600000s, color 0s 600000s !important",
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {searchText === "" ? (
              <SearchIcon height={20} width={20} />
            ) : (
              activeEmail === "" && (
                <CrossIcon height={20} width={20} onClick={() => setSearchText("")} style={{ cursor: "pointer" }} />
              )
            )}
          </InputAdornment>
        ),
        autoComplete: "off",
      }}
      hiddenLabel
      id="user-search"
      variant="filled"
      placeholder="Search user by email or name"
      value={searchText}
      onChange={(e) => setSearchText(e.target.value.toLowerCase())}
      disabled={activeEmail !== ""}
    />
  );
}
