export default function businessInsightsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "BUSINESS_INSIGHTS_SELECT_PERIOD":
      return { ...state, period: action.period || INITIAL_STATE.period }; // Reset if no period specified

    case "BUSINESS_INSIGHTS_SELECT_VS_PERIOD_TYPE":
      return { ...state, vsPeriodType: action.vsPeriodType };

    case "BUSINESS_INSIGHTS_FILTER_UPDATE":
      return { ...state, filter: action.filter };

    default:
      return state;
  }
}

const INITIAL_STATE = {
  period: undefined,
  vsPeriodType: undefined,
  filter: {}, // or { driver_type: ['paid_media'] }
};
