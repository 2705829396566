import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import backend from "backend";
import _ from "lodash";
import getImpactTypeForModel from "shared/helpers/getImpactTypeForModel";
import { areTwoSimulatorConfigDifferent } from "shared/hooks/useSimulationSupportedStatus";
import store from "store";
import { notifyNew } from "store/action-creators";
import {
  deleteSavedInputState,
  getIndexedDBObjectToSave,
  getSavedInputState,
  saveInputState,
} from "store/reducerUtils/simulatorIndexedDB";
import {
  calculateBaselinePeriod,
  calculateMediaParentPercentageChange,
  calculateNewGroupBaseline,
  groupMediaInputData,
  groupNonMediaInputData,
  insertCustomGroupInChildren,
  isOverlapWithExistingGroups,
  resetMediaVariables,
  resetNonMediaVariables,
  simulatorInputNotifyNew,
} from "store/reducerUtils/simulatorUtils";
import { v4 as uuid } from "uuid";

const INITIAL_STATE = {
  inputPageStep: "SELECT_TIMELINE", //can be "SELECT_TIMELINE" or "INPUT_SCREEN"
  simulationStatus: { statusFast: "NA", statusFull: "NA", status: "not-set" }, //not-set as default because "NA" would be set if nothing is found from reducer in simulator index.js
  availableMediaDimensions: [], //to be set from the model config to hide certain media dimensions from the input table
  driverCustom: [], //to be set from the model config to hide certain business dimensions and group into separate tables
  grouping: "all",
  period: [], //[startDate, endDate]
  baselinePeriod: [], //[startDate, endDate]
  apiMediaInputData: [], //to store the original data for payload creation //to store the original data for payload creation
  apiNonMediaInputData: [],
  mediaInputData: {},
  nonMediaInputData: {},
  mediaInputDataLoading: true,
  nonMediaInputDataLoading: true,
  isSaved: false,
  mediaNewChildGroupFilter: { slug: null, filter: {}, baseline: 0, percentageChange: 0, error: null, editing: false }, //New child groups (multi-filter) {slug: groupingSlug, filter: {dimension1: value1, dimension2: value2}} only single at a time
  interval: "week",
  simulationAvailablePeriod: { startdate: null, enddate: null },
  outputTab: { slug: "SUMMARY", name: "Overall summary", label: "Media and non-media total data table", type: "total" },
  simAdminConfigItems: {
    mediaGroupings: [],
    driverCustom: [],
    mediaInsightsPlus__impact_per_dimension_v1: [],
    modelType: "",
  }, //to save in indexedDb along with state and later validate that state against current modelConfig.
  localDataValidating: false,
  simulatorSeqNum: 0, //to force re-render the simulator input table input fields, when we clear the filed we send 0 as a new value but redux is not triggering a re-render when the earlier value was also 0
  mediaTabDimensionsForFullSimulation: [],
};

export const validateLocalSavedData = createAsyncThunk(
  "indexedDB/validateLocalSavedData",
  async ({ modelSlug, selectedModelType }, thunkAPI) => {
    //modelSlug not needed here but need to rerun this logic when the model changes, this way the consumer is hinted to include it in useEffect dependency array.

    try {
      /*
        here we will just check the validity of the saved data and if it is valid then we will set the period and forward the page step
        to table screen, actual variable data is set in other respective media and non media thunks
      */
      const state = thunkAPI.getState();
      const currentSimAdminConfigItems = state.simulator.simAdminConfigItems;
      const dataFromIndexedDB = await getSavedInputState();
      if (dataFromIndexedDB) {
        const { period, simAdminConfigItems } = dataFromIndexedDB;
        const dataNoLongerValid = areTwoSimulatorConfigDifferent({
          config1: simAdminConfigItems,
          config2: currentSimAdminConfigItems,
          modelType: selectedModelType,
        });
        if (dataNoLongerValid || !period) {
          await deleteSavedInputState();
        } else {
          return { period };
        }
      }
    } catch (error) {
      console.error("Error fetching data from IndexedDB:", error);
      //delete faulty data from indexedDb which is producing errors.
      await deleteSavedInputState();
    }
  },
);

export const deleteLocalSavedData = createAsyncThunk("indexedDB/deleteLocalSavedData", async () => {
  await deleteSavedInputState();
});

export const fetchMediaInputData = createAsyncThunk(
  "v1/simulation/input?variableType=media",
  async ({ modelSlug, startDate, endDate }, thunkAPI) => {
    //first check if local saved state is available, before calling the api
    try {
      const dataFromIndexedDB = await getSavedInputState();
      if (dataFromIndexedDB) {
        const { mediaTableState } = dataFromIndexedDB;
        //notifying here instead of the validate as validate is trigerred early and sometime the notification is falsly shown.
        simulatorInputNotifyNew({ message: "Loaded input data from where you left off." });
        return { mediaTableState, loadedFromLocal: true };
      }
    } catch (error) {
      console.error("Error fetching data from IndexedDB:", error);
    }

    //if no data is available from indexedDB then fetch from the api
    try {
      const data = await backend.get(modelSlug && startDate && endDate && `v1/simulation/input/${modelSlug}`, {
        params: {
          startDate,
          endDate,
          variableType: "media",
        },
      });
      return data;
    } catch (error) {
      thunkAPI.dispatch(notifyNew({ message: `Failed to fetch media input data.`, isError: true }));
    }
  },
);

export const fetchNonMediaInputData = createAsyncThunk(
  "v1/simulation/input?variableType=nonmedia",
  async ({ modelSlug, startDate, endDate }, thunkAPI) => {
    //first check if local saved state is available, before calling the api
    try {
      const dataFromIndexedDB = await getSavedInputState();
      if (dataFromIndexedDB) {
        const { nonMediatableState } = dataFromIndexedDB;
        return { nonMediatableState, loadedFromLocal: true };
      }
    } catch (error) {
      console.error("Error fetching data from IndexedDB:", error);
    }

    //if no data is available from indexedDB then fetch from the api
    try {
      const data = await backend.get(modelSlug && startDate && endDate && `v1/simulation/input/${modelSlug}`, {
        params: {
          startDate,
          endDate,
          variableType: "nonmedia",
        },
      });
      return data;
    } catch (error) {
      thunkAPI.dispatch(notifyNew({ message: `Failed to fetch non-media input data.`, isError: true }));
    }
  },
);

export const fetchSavedData = createAsyncThunk("v1/simulation/output", async ({ modelSlug, simulationId, params }) => {
  const data = await backend.get(`v1/simulation/output/${modelSlug}/${simulationId}`);
  return { ...data, params };
});

export const fetchSimConfig = createAsyncThunk("v1/getSimConfig", async ({ modelSlug }) => {
  return await backend.get(`v1/simulation/getSimConfig/${modelSlug}`);
});

const simulatorSlice = createSlice({
  name: "simulator",
  initialState: INITIAL_STATE,
  reducers: {
    setInputPageStep: (state, action) => {
      state.inputPageStep = action.payload;
    },
    setSimulationPeriod: (state, action) => {
      state.period = action.payload.simulationPeriod;
      state.baselinePeriod = action.payload.baselinePeriod;
      state.mediaNewChildGroupFilter = { ...INITIAL_STATE.mediaNewChildGroupFilter };
    },
    updateMediaInputValue: (state, action) => {
      let { slug, childKey, newPercentageChange } = action.payload;
      state.simulatorSeqNum++;

      if (!slug) {
        return state;
      }
      if (childKey) {
        //update child
        state.mediaInputData[slug].children[childKey].percentageChange = newPercentageChange;
        const { totalBaseline, percentageChange } = calculateMediaParentPercentageChange(
          state.mediaInputData[slug].children,
        );
        state.mediaInputData[slug].baseline = totalBaseline;
        state.mediaInputData[slug].percentageChange = percentageChange;
      } else {
        //parent updated

        //apply the percentage change to all children
        for (const childKey in state.mediaInputData[slug].children) {
          state.mediaInputData[slug].children[childKey].percentageChange = newPercentageChange;
        }
        const { totalBaseline, percentageChange } = calculateMediaParentPercentageChange(
          state.mediaInputData[slug].children,
        );
        state.mediaInputData[slug].baseline = totalBaseline;
        state.mediaInputData[slug].percentageChange = percentageChange;
      }
    },
    updateNonMediaInputValue: (state, action) => {
      const { bucket, driver, newPercentageChange, childKey } = action.payload;
      state.simulatorSeqNum++;
      if (childKey) {
        //changed at child level
        const children = state.nonMediaInputData[bucket][driver].children;
        if (children[childKey]) {
          children[childKey].percentageChange = newPercentageChange;
        }

        //NOTE: I don't think it is right to take average here but anyway if any of the child is different then we are showing hyphen and disabling the group level input in UI and if all are same then the average gives us the exact same value as the child to show on UI.
        const parent = state.nonMediaInputData[bucket][driver];
        const childrenArray = Object.values(children ?? {});
        if (childrenArray.length > 0) {
          parent.percentageChange =
            childrenArray.reduce((acc, curr) => acc + curr.percentageChange, 0) / childrenArray.length;
        }
      } else {
        state.nonMediaInputData[bucket][driver].percentageChange = newPercentageChange;

        //make all child same chage %
        for (const childKey in state.nonMediaInputData[bucket][driver].children) {
          state.nonMediaInputData[bucket][driver].children[childKey].percentageChange = newPercentageChange;
        }
      }
    },
    resetValues: (state, action) => {
      if (state.grouping === "all") {
        resetMediaVariables(state);
        resetNonMediaVariables(state);
      }
      if (state.grouping === "media") {
        resetMediaVariables(state);
      }
      if (state.grouping === "nonmedia") {
        resetNonMediaVariables(state);
      }
      if (action.payload === "all") {
        state.period = INITIAL_STATE.period;
        state.baselinePeriod = INITIAL_STATE.baselinePeriod;
        state.mediaInputData = INITIAL_STATE.mediaInputData;
        state.nonMediaInputData = INITIAL_STATE.nonMediaInputData;
      }
    },
    updateMediaNewChildGroupFilter: (state, action) => {
      const {
        slug,
        dimension,
        dimensionValue,
        updateAction, //start, updateFilter, updateScenario, finish, reset, edit
        editId, //for editing
        customFilter, //for delete purpose
      } = action.payload;

      switch (updateAction) {
        case "new":
          const groupingInsertions = state.apiMediaInputData.filter((entry) => entry["media-grouping"] === slug);
          state.mediaNewChildGroupFilter = {
            ...INITIAL_STATE.mediaNewChildGroupFilter,
            slug: slug,
            baseline: calculateNewGroupBaseline(groupingInsertions, {}), //initital empty filter.
            error: "Please select a filter to apply scenario value to a combination.",
          };
          return;
        case "updateFilter": {
          if (dimensionValue === null) {
            delete state.mediaNewChildGroupFilter.filter[dimension];
          } else {
            state.mediaNewChildGroupFilter.filter[dimension] = dimensionValue;
          }
          const groupingInsertions = state.apiMediaInputData.filter((entry) => entry["media-grouping"] === slug);

          //calculate new baseline with filter
          const filter = state.mediaNewChildGroupFilter.filter;
          const editId = state.mediaNewChildGroupFilter.editId;
          state.mediaNewChildGroupFilter.baseline = calculateNewGroupBaseline(groupingInsertions, filter);

          const impact = getImpactTypeForModel(state.simAdminConfigItems.modelType);
          const availableMediaDimensions = state.simAdminConfigItems.mediaInsightsPlus__impact_per_dimension_v1[impact];
          if (_.isEmpty(filter)) {
            state.mediaNewChildGroupFilter.error = "Please select a filter to apply scenario to a combination.";
          } else if (
            isOverlapWithExistingGroups(
              groupingInsertions,
              availableMediaDimensions,
              filter,
              _.omit(state.mediaInputData[slug].children, editId), //exclude the current editing group from overlap check
            )
          ) {
            //check for overlap with already created groups
            state.mediaNewChildGroupFilter.error = "This filter overlaps with an existing combination.";
          } else {
            state.mediaNewChildGroupFilter.error = null;
          }
          return;
        }
        case "reset":
          state.mediaNewChildGroupFilter = { ...INITIAL_STATE.mediaNewChildGroupFilter };
          return;
        case "updateScenario":
          state.mediaNewChildGroupFilter.percentageChange = action.payload.newPercentageChange;
          return;
        case "finish": {
          const filter = customFilter ?? state.mediaNewChildGroupFilter.filter;
          const slug = state.mediaNewChildGroupFilter.slug;
          const groupingInsertions = state.apiMediaInputData.filter((entry) => entry["media-grouping"] === slug);

          const children = state.mediaInputData[slug].children;
          const impact = getImpactTypeForModel(state.simAdminConfigItems.modelType);
          const availableMediaDimensions = state.simAdminConfigItems.mediaInsightsPlus__impact_per_dimension_v1[impact];
          const percentageChange = state.mediaNewChildGroupFilter.percentageChange;
          const editId = state.mediaNewChildGroupFilter.editId;
          //reGenerate the children based on more filter added or removed
          try {
            state.mediaInputData[slug].children = insertCustomGroupInChildren(
              groupingInsertions,
              availableMediaDimensions,
              filter,
              children,
              percentageChange,
              editId,
            );
            //recalculate the parent percentage change for grouping
            const { totalBaseline, percentageChange: groupingPercentageChange } = calculateMediaParentPercentageChange(
              state.mediaInputData[slug].children,
            );
            state.mediaInputData[slug].baseline = totalBaseline;
            state.mediaInputData[slug].percentageChange = groupingPercentageChange;
            //reset the state
            state.mediaNewChildGroupFilter = { ...INITIAL_STATE.mediaNewChildGroupFilter };
          } catch (e) {
            console.error(e);
          }
          return;
        }
        case "edit": {
          const group = state.mediaInputData[slug].children[editId];
          const impact = getImpactTypeForModel(state.simAdminConfigItems.modelType);
          const availableMediaDimensions = state.simAdminConfigItems.mediaInsightsPlus__impact_per_dimension_v1[impact];
          const filter = _.pick(group, availableMediaDimensions);
          const { baseline, percentageChange } = group;
          state.mediaNewChildGroupFilter = {
            slug,
            editId,
            filter,
            baseline,
            percentageChange,
            editing: true, //to indicate we are editing an already created group.
            beforeEditValues: {
              //to compare if any changes are made during edit
              filter,
              percentageChange,
            },
          };
          return;
        }
        default:
          return state;
      }
    },
    setInterval: (state, action) => {
      state.interval = action.payload;
    },
    setGrouping: (state, action) => {
      state.grouping = action.payload;
    },
    setOutputTab: (state, action) => {
      state.outputTab = action.payload;
    },
    setSimulationStatus: (state, action) => {
      state.simulationStatus = action.payload;
    },
    setModifying: (state, action) => {
      state.modifying = action.payload;
      if (!action.payload) {
        //if we are exiting modifying mode, reset the before modifying data
        state.beforeModifyingData = {};
      }
    },
    setSimAdminConfigItems: (state, action) => {
      state.simAdminConfigItems = action.payload;
    },
    resetSimulationState: (state) => {
      return { ...INITIAL_STATE };
    },
    setIsSaved: (state, action) => {
      state.isSaved = action.payload;
    },
    setMediaTabDimensionsForFullSimulation: (state, action) => {
      state.mediaTabDimensionsForFullSimulation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMediaInputData.pending, (state, action) => {
      if (!action.payload) {
        return state;
      }
      state.mediaInputDataLoading = true;
    });
    builder.addCase(fetchMediaInputData.fulfilled, (state, action) => {
      state.mediaInputDataLoading = false;
      if (!action.payload) {
        return state;
      }
      if (action.payload.loadedFromLocal) {
        const { apiMediaInputData, mediaInputData } = action.payload.mediaTableState;
        state.apiMediaInputData = apiMediaInputData;
        state.mediaInputData = mediaInputData;

        return;
      }

      //if fetched from api then group the data.
      const withIds = action.payload.map((entry) => {
        entry.id = uuid();
        entry.percentageChange = 0;
        return entry;
      });
      state.apiMediaInputData = withIds;
      const impact = getImpactTypeForModel(state.simAdminConfigItems.modelType);
      const availableMediaDimensions = state.simAdminConfigItems.mediaInsightsPlus__impact_per_dimension_v1[impact];
      const groupedMediaInputData = groupMediaInputData(withIds, availableMediaDimensions, {
        sort: "asc",
      });
      state.mediaInputData = groupedMediaInputData;
    });
    builder.addCase(fetchNonMediaInputData.pending, (state, action) => {
      if (!action.payload) {
        return state;
      }
    });
    builder.addCase(fetchNonMediaInputData.fulfilled, (state, action) => {
      state.nonMediaInputDataLoading = false;
      if (!action.payload) {
        return state;
      }
      if (action.payload.loadedFromLocal) {
        const { apiNonMediaInputData, nonMediaInputData } = action.payload.nonMediatableState;
        state.apiNonMediaInputData = apiNonMediaInputData;
        state.nonMediaInputData = nonMediaInputData;
        return;
      }

      //if fetched from api then group the data.
      const withIds = action.payload.map((entry) => {
        entry.id = uuid();
        entry.percentageChange = 0;
        return entry;
      });
      state.apiNonMediaInputData = withIds;
      const driverCustom = state.simAdminConfigItems.driverCustom;
      const groupedNonMediaInputData = groupNonMediaInputData(withIds, driverCustom);
      state.nonMediaInputData = groupedNonMediaInputData;
    });
    builder.addCase(fetchSimConfig.fulfilled, (state, action) => {
      if (!action.payload) {
        store.dispatch(notifyNew({ message: `no data available`, isError: true }));
        return state;
      }
      state.simulationAvailablePeriod = action.payload;
    });
    //this builder case is to fetch the saved data during simulate to render the table in disabled state and for modifying
    builder.addCase(fetchSavedData.fulfilled, (state, action) => {
      state.mediaInputDataLoading = false;
      state.nonMediaInputDataLoading = false;

      if (!action.payload) {
        console.error("No saved data in simulate api");
        return state;
      }
      const {
        mediaTableState: { mediaInputData, apiMediaInputData },
        nonMediatableState: { nonMediaInputData, apiNonMediaInputData },
        period,
        simAdminConfigItems,
      } = action.payload;
      if (mediaInputData && nonMediaInputData && apiMediaInputData && apiNonMediaInputData) {
        state.mediaInputData = mediaInputData;
        state.apiMediaInputData = apiMediaInputData;
        state.nonMediaInputData = nonMediaInputData;
        state.apiNonMediaInputData = apiNonMediaInputData;
      }
      if (period) {
        state.period = period;
        state.baselinePeriod = calculateBaselinePeriod(period);
      }
      if (simAdminConfigItems) {
        state.simAdminConfigItems = simAdminConfigItems;
      }
      state.beforeModifyingData = {
        //data to use for comparison during modifying
        mediaInputData,
        nonMediaInputData,
      };

      if (state.modifying) {
        //save the state once after setting up everything for modifying.
        const {
          apiMediaInputData,
          mediaInputData,
          apiNonMediaInputData,
          nonMediaInputData,
          period,
          simAdminConfigItems,
        } = state;
        const objectToSave = getIndexedDBObjectToSave({
          apiMediaInputData,
          mediaInputData,
          apiNonMediaInputData,
          nonMediaInputData,
          period,
          simAdminConfigItems,
        });
        saveInputState(objectToSave);
      }
    });
    builder.addCase(validateLocalSavedData.pending, (state, action) => {
      state.localDataValidating = true;
    });
    builder.addCase(validateLocalSavedData.fulfilled, (state, action) => {
      state.localDataValidating = false;
      if (action.payload) {
        state.period = action.payload.period;
        state.baselinePeriod = calculateBaselinePeriod(action.payload.period);
        state.inputPageStep = "INPUT_SCREEN";
      }
    });
  },
});

export const saveSimulatorInputMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  //save the state to indexedDB
  const simState = store.getState().simulator;
  const { apiMediaInputData, mediaInputData, apiNonMediaInputData, nonMediaInputData, period, simAdminConfigItems } =
    simState;
  switch (action.type) {
    case "simulator/updateMediaInputValue":
    case "simulator/updateNonMediaInputValue":
    case "simulator/updateMediaNewChildGroupFilter": {
      const objectToSave = getIndexedDBObjectToSave({
        apiMediaInputData,
        mediaInputData,
        apiNonMediaInputData,
        nonMediaInputData,
        period,
        simAdminConfigItems,
      });
      saveInputState(objectToSave).catch((error) => console.error("Failed to save to indexed db"));
      break;
    }
    default:
      break;
  }

  return result;
};
export const {
  setInputPageStep,
  setSimulationPeriod,
  updateMediaInputValue, //included in middleware, remember to update the middleware
  updateNonMediaInputValue, //included in middleware, remember to update the middleware
  resetValues,
  updateMediaNewChildGroupFilter,
  setInterval,
  setGrouping,
  setOutputTab,
  setSimulationStatus,
  setModifying,
  resetSimulationState,
  setIsSaved,
  setSimAdminConfigItems,
  setMediaTabDimensionsForFullSimulation, //this is used for both prediction tab and activity, TODO: look if it can be done for local state in both place
} = simulatorSlice.actions;

export const selectInputPageStep = (state) => state.simulator.inputPageStep;
export const selectSimulationPeriod = (state) => state.simulator.period;
export const selectBaselinePeriod = (state) => state.simulator.baselinePeriod;
export const selectDataAvailablePeriod = (state) => state.simulator.simulationAvailablePeriod;
export const selectMediaInputData = (state) => state.simulator.mediaInputData;
export const selectApiMediaInputData = (state) => state.simulator.apiMediaInputData;
export const selectApiNonMediaInputData = (state) => state.simulator.apiNonMediaInputData;
export const selectNonMediaInputData = (state) => state.simulator.nonMediaInputData;
export const selectMediaInputDataLoading = (state) => state.simulator.mediaInputDataLoading;
export const selectNonMediaInputDataLoading = (state) => state.simulator.nonMediaInputDataLoading;
export const selectMediaNewChildGroupFilter = (state) => state.simulator.mediaNewChildGroupFilter;
export const simulatorOutputInterval = (state) => state.simulator.interval;
export const simulatorInputGrouping = (state) => state.simulator.grouping;
export const simulatorOutputTab = (state) => state.simulator.outputTab;
export const selectSimulationStatus = (state) => state.simulator.simulationStatus;
export const selectModifyingStatus = (state) => state.simulator.modifying;
export const selectBeforeModifyingData = (state) => state.simulator.beforeModifyingData;
export const selectSimulatorSeqNum = (state) => state.simulator.simulatorSeqNum;
export const selectIsSaved = (state) => state.simulator.isSaved;
export const selectLocalDataValidating = (state) => state.simulator.localDataValidating;
export const selectSimAdminConfigItems = (state) => state.simulator.simAdminConfigItems;
export const selectMediaTabDimensionsForFullSimulation = (state) => state.simulator.mediaTabDimensionsForFullSimulation;

export default simulatorSlice.reducer;
