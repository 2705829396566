import React from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { notifyNew, notifyClose } from "store/action-creators";
import styled from "@emotion/styled/macro";

function Notify({
  open,
  message,
  action,
  isError,
  isPersistent,
  onClose,
  onActionFailed,
  horizontalPosition,
  closeButton,
  extraStyles = {},
}) {
  const primaryAction = action && (
    <StyledButton
      key="undo"
      variant="outlined"
      size="small"
      onClick={() =>
        Promise.resolve(action ? action.onClick() : undefined)
          .then(onClose)
          .catch(onActionFailed)
      }
    >
      {action ? action.text : "Dismiss"}
    </StyledButton>
  );
  const closeAction = closeButton && (
    <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
      <CloseIcon />
    </IconButton>
  );

  return (
    <Snackbar
      sx={{ ".MuiPaper-root": { minWidth: "auto", ...extraStyles } }}
      anchorOrigin={{ vertical: "bottom", horizontal: horizontalPosition }}
      open={open}
      key={message}
      autoHideDuration={isPersistent ? null : 6000}
      onClose={(e, reason) => {
        // I don't want click-aways to close this https://material-ui.com/components/snackbars/#simple-snackbars
        if (reason !== "clickaway") onClose();
      }}
      ContentProps={{ "aria-describedby": "message-id", sx: { background: isError ? "#d32f2f" : undefined } }}
      message={
        <span id="message-id" style={{ display: "flex", gap: "15px" }}>
          {message}
        </span>
      }
      action={primaryAction ? [primaryAction, closeAction] : [closeAction]}
    />
  );
}

export default connect(
  (state) => ({
    open: state.notify.open,
    message: state.notify.message, // "Activity has been deactivated"
    action: state.notify.action, // { text: "Undo", onClick: () => activate(...) }
    isError: state.notify.isError,
    isPersistent: state.notify.isPersistent,
    horizontalPosition: state.notify.horizontalPosition, //In some cases we want to show in right, action-creation defaults to "left"
    closeButton: state.notify.closeButton,
    extraStyles: state.notify.extraStyles,
  }),
  (dispatch) => ({
    onClose: () => dispatch(notifyClose()),
    onActionFailed: (error) => {
      console.error("Failed while performing primary action on a notification", error);
      dispatch(notifyNew({ message: "Action failed!", isError: true }));
    },
  }),
)(Notify);

const StyledButton = styled(Button)`
  color: white;
  border-color: white;

  &:hover {
    color: black;
    background-color: white;
    border-color: white;
  }
`;
