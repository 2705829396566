export default function businessInsightsPlusReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "BUSINESS_INSIGHTS_PLUS_SELECT_PERIOD":
      return { ...state, period: action.period || INITIAL_STATE.period }; // Reset if no period specified

    case "BUSINESS_INSIGHTS_PLUS_SELECT_VS_PERIOD_TYPE":
      return { ...state, vsPeriodType: action.vsPeriodType };

    case "BUSINESS_INSIGHTS_PLUS_FILTER_UPDATE":
      const dimensionsInFilter = Object.keys(action.filter);
      const singleDriverCustomInFilter =
        action.filter["driver-custom"]?.length === 1 ? action.filter["driver-custom"][0] : null;
      let parentDimension = singleDriverCustomInFilter
        ? getGroupBy(
            action.driverCustom?.find((d) => d.slug === singleDriverCustomInFilter)?.dimension ?? [],
            dimensionsInFilter,
          )
        : "driver-custom";
      return { ...state, filter: action.filter, groupByDimension: parentDimension ?? state.groupByDimension }; //if parentDimension undefined then return current dimension only, helpful for showing the last block when no drilldown is possible

    case "BUSINESS_INSIGHTS_PLUS_GROUPBY_UPDATE":
      return { ...state, groupByDimension: action.groupByDimension };

    default:
      return state;
  }
}

function getGroupBy(availableDimensions, dimensionsInFilter) {
  //First find the last availableDimension which is also present in filter so that we can the next/last availableDimension

  const lastCommonIdx = availableDimensions.findLastIndex((dim) => dimensionsInFilter.includes(dim));
  if (lastCommonIdx === -1) {
    //not found
    return availableDimensions[0];
  }

  if (lastCommonIdx === availableDimensions.length - 1) {
    //already last
    return availableDimensions.at(-1);
  }

  //otherwise return the next dimension
  return availableDimensions.at(lastCommonIdx + 1);
}

const INITIAL_STATE = {
  period: undefined,
  vsPeriodType: undefined,
  filter: {}, // or { driver_type: ['paid_media'] }
  groupByDimension: "driver-custom",
};
