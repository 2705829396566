import { useTheme } from "@emotion/react";
import styled from "@emotion/styled/macro";
import Tooltip from "@mui/material/Tooltip";
import EditorItem from "admin/EditorItem";
import EditorItemForOrgRoles from "admin/EditorItem/EditorItemForOrgRoles";
import { RowActions } from "admin/CommonComponents/UserRowActions";
import { isValidEmail } from "admin/helpers";
import { useAccount } from "auth";
import _ from "lodash";
import React from "react";
import DropdownMenu from "shared/components/DropdownMenu";
import { getRoleLabel } from "shared/helpers/formatters";
import { roles } from ".";

export function Entry({
  isNew,
  data,
  onChange,
  onDelete,
  touched,
  error,
  globallyAvailableOrgs = {},
  activeEmail = "",
  onActivate,
  lastStandingAdminCheck,
}) {
  const [{ isDisabled, localData }, dispatch] = React.useReducer(localDataReducer, {
    isDisabled: isNew ? false : true, //New item is editable
    localData: data,
  });
  const originalData = _.cloneDeep(data);
  const theme = useTheme();
  const { isClaim } = useAccount();

  if (!isDisabled && !isNew) {
    //data to use localData if we are in editing mode
    data = localData;
  }
  const org_labels = Object.keys(globallyAvailableOrgs).reduce((result, key) => {
    result[key] = globallyAvailableOrgs[key].name;
    return result;
  }, {});

  const isKantarEmail = data.email.toLowerCase().endsWith("@kantar.com");
  const isSuperAdmin = data.defaultRole === "superAdmin";

  return (
    <EntryRow
      disabled={isDisabled}
      theme={theme}
      isNew={isNew}
      active={activeEmail === "" || activeEmail === data.email}
    >
      <Tooltip
        title={
          !isNew && (activeEmail === "" || activeEmail === data.email) ? (
            <>
              To change the email <br /> delete and add again
            </>
          ) : (
            ""
          )
        }
        //used to place the tooltip
        //https://popper.js.org/docs/v2/modifiers/offset/
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -12],
                },
              },
            ],
          },
        }}
        arrow
      >
        <div>
          <EditorItem
            withinTable
            isDisabled={!isNew}
            schema={{ title: "Email ID", type: "string", ui__isSlug: true }}
            data={data.email}
            onChange={(localOnChange) =>
              isNew
                ? onChange({
                    ...data,
                    email: localOnChange(data.email)?.toLowerCase()?.trim(),
                    ...(!localOnChange(data.email).toLowerCase().endsWith("@kantar.com") &&
                    isValidEmail(localOnChange(data.email))
                      ? { defaultRole: "client" }
                      : { defaultRole: data.defaultRole }),
                  })
                : dispatch({ type: "change-email", email: localOnChange(data.email)?.toLowerCase() })
            }
            extraStyles={{
              paddingLeft: isNew && 5,
              fontSize: isNew ? "12px" : "14px",
              textTransform: isNew ? "uppercase" : undefined,
            }}
            placeholderStyle={{ opacity: 1, color: theme.palette.greyish[50] }}
          />
        </div>
      </Tooltip>
      <EditorItem
        withinTable
        schema={{ title: "First Name", type: "string" }}
        data={data.firstName}
        isDisabled={isDisabled}
        onChange={(localOnChange) =>
          isNew
            ? onChange({ ...data, firstName: localOnChange(data.firstName) })
            : dispatch({ type: "change-firstname", firstName: localOnChange(data.firstName) })
        }
        extraStyles={{
          paddingLeft: isNew && 5,
          fontSize: isNew ? "12px" : "14px",
          color: activeEmail === "" && "black",
          textTransform: isNew ? "uppercase" : undefined,
        }}
        placeholderStyle={{ opacity: 1, color: theme.palette.greyish[50] }}
      />
      <EditorItem
        withinTable
        schema={{ title: "Last Name", type: "string" }}
        data={data.lastName}
        isDisabled={isDisabled}
        onChange={(localOnChange) =>
          isNew
            ? onChange({ ...data, lastName: localOnChange(data.lastName) })
            : dispatch({ type: "change-lastname", lastName: localOnChange(data.lastName) })
        }
        extraStyles={{
          paddingLeft: isNew && 5,
          fontSize: isNew ? "12px" : "14px",
          color: activeEmail === "" && "black",
          textTransform: isNew ? "uppercase" : undefined,
        }}
        placeholderStyle={{ opacity: 1, color: theme.palette.greyish[50] }}
      />
      {isNew ? (
        <EditorItem
          withinTable
          schema={{
            title: "Organizations",
            description: "Orgs this user can see", // need proper message from BA
            type: "array",
            items: {
              type: "string",
              ui__isSlug: true,
              enum: Object.keys(globallyAvailableOrgs),
              ui__labels: org_labels,
            },
            default: [],
          }}
          showLabel={false}
          data={data.organizations}
          isDisabled={isDisabled}
          onChange={(localOnChange) =>
            isNew
              ? onChange({ ...data, organizations: localOnChange(data.organizations) })
              : dispatch({ type: "change-orgs", organizations: localOnChange(data.organizations) })
          }
          search={true}
          extraStyles={{
            fontSize: "12px",
            color: theme.palette.greyish[50],
            opacity: "1",
            textTransform: "uppercase",
            height: "100%",
          }}
        />
      ) : (
        <EditorItemForOrgRoles
          withinTable
          schema={{
            title: "Organizations",
            description: "Organizations this user can see",
            type: "array",
            items: {
              type: "string",
              ui__isSlug: true,
              enum: Object.keys(globallyAvailableOrgs),
              ui__labels: org_labels,
            },
            default: [],
          }}
          showLabel={false}
          data={data?.accessibleOrgs}
          isDisabled={isDisabled}
          defaultRole={data.defaultRole}
          onChange={(localOnChange) =>
            isNew
              ? onChange({ ...data, organizations: localOnChange(data.accessibleOrgs) })
              : dispatch({ type: "change-accessibleOrgs", accessibleOrgs: localOnChange(data.accessibleOrgs) })
          }
          extraStyles={{
            color: activeEmail !== "" && activeEmail !== data.email ? "#858574" : "inherit",
            fontSize: isNew ? "12px" : "14px",
          }}
        />
      )}
      <DropdownMenu
        value={data.defaultRole === "" ? "Role" : getRoleLabel(data.defaultRole)}
        selected={data.defaultRole === "" ? "Role" : getRoleLabel(data.defaultRole)}
        onChange={(newRole) =>
          isNew ? onChange({ ...data, defaultRole: newRole }) : dispatch({ type: "change-role", defaultRole: newRole })
        }
        options={roles}
        hideArrowOnDisable={true}
        fixedWidth={180}
        iconSize="small"
        clickDisabled={
          isDisabled ||
          (!isNew && isSuperAdmin && lastStandingAdminCheck(data.email)) ||
          (isValidEmail(data.email) && !isKantarEmail)
        }
        style={{
          container: { height: "100%", fontSize: "inherit", opacity: 1 },
          button: {
            border: "none",
            height: "100%",
            fontSize: isNew ? "12px" : "14px",
            textTransform: isNew ? "uppercase" : undefined,
            padding: "0px 10px",
            color:
              ((!isNew && isSuperAdmin && lastStandingAdminCheck(data.email)) ||
                (isValidEmail(data.email) && !isKantarEmail)) &&
              "#858574",
          },
          buttonText: {
            color: data.defaultRole === "" && "#858574",
          },
        }}
        buttonTooltip={
          isNew
            ? isValidEmail(data.email) && !isKantarEmail
              ? "External user's role is restricted to client"
              : ""
            : lastStandingAdminCheck && lastStandingAdminCheck(data.email)
              ? "You cannot remove the last Super Admin"
              : isValidEmail(data.email) && !isKantarEmail
                ? "External user's role cannot be changed"
                : "Role change will affect across all organizations"
        }
        //used to place the tooltip
        //https://popper.js.org/docs/v2/modifiers/offset/
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -12],
                },
              },
            ],
          },
        }}
      />

      {isClaim("GlobalARM.Write") && (
        <RowActions
          isDisabled={isDisabled}
          isNew={isNew}
          dispatch={dispatch}
          data={data}
          originalData={originalData}
          onDelete={data.email !== sessionStorage.getItem("msalUsername") && onDelete}
          onSave={(data) => {
            onChange(data);
            dispatch({ type: "make-disabled" });
          }}
          armLevel="tool"
          activeEmail={activeEmail}
          onActivate={onActivate}
        />
      )}
    </EntryRow>
  );
}

function localDataReducer(state, action) {
  switch (action.type) {
    case "make-editable":
      return { ...state, isDisabled: false };
    case "make-disabled":
      return { ...state, isDisabled: true, ...(action.originalData ? { localData: action.originalData } : {}) };
    case "change-email":
      return { ...state, localData: { ...state.localData, email: action.email } };
    case "change-firstname":
      return { ...state, localData: { ...state.localData, firstName: action.firstName } };
    case "change-lastname":
      return { ...state, localData: { ...state.localData, lastName: action.lastName } };
    case "change-orgs":
      return { ...state, localData: { ...state.localData, organizations: action.organizations } };
    case "change-role":
      return { ...state, localData: { ...state.localData, defaultRole: action.defaultRole } };
    case "change-accessibleOrgs":
      return { ...state, localData: { ...state.localData, accessibleOrgs: action.accessibleOrgs } };
    default:
      return { ...state };
  }
}

const EntryRow = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: ${({ isNew }) =>
    isNew ? "var(--tool-new-entry-grid-columns)" : "var(--tool-entry-grid-columns)"};
  padding: ${({ isNew }) => !isNew && "1px 0px"};
  font-size: 16px;

  border-bottom: 1px solid lightgray;
  border: ${({ disabled, theme, isNew }) =>
    disabled || isNew ? undefined : `1px solid ${theme.palette.primary.main}`};
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  height: 40px;
`;
