export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "NAVIGATION_SET_CURRENT_PAGE":
      return { ...state, currentPage: action.page ?? INITIAL_STATE.currentPage };
    default:
      return state;
  }
}

const INITIAL_STATE = {
  currentPage: 1,
};
