import _ from "lodash";

export function filterV3Dimensions(dimensionValues = []) {
  /*When we use the dimensions in BI+ filter or Business grouping editor using the 
    `v3/historical/${socketSlug}/decomposition/dimension-values` endpoint there can be two cases:
    1. Direct/Indirect models => we want to show only direct and indirect effect_type as that covers all data, so will filter total effect_type.
    2. in non Direct/Indirect model there will be only total effect_type so that will cover all data, but we want to remove the effect_type key in this case
    */
  const dimensionValuesWithoutTotal = dimensionValues?.filter((d) => d["datatypename"] && d["effect_type"] !== "total");

  //If the model is non Direct/Indirect then dimensionValuesWithoutTotal will be empty so in that case we will filter differently using only total effect_type and we will omit the effect_type key as there will be only one option in this case.
  return dimensionValuesWithoutTotal?.length === 0
    ? dimensionValues
        ?.filter((d) => d["datatypename"] && d["effect_type"] === "total")
        ?.map((d) => _.omit(d, "effect_type"))
    : dimensionValuesWithoutTotal;
}
