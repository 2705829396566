import { getAadClaims, logout, useAccount } from "auth";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sessionStorageSync } from "shared/helpers/sessionStorageSync";
import { filteredSearchParams } from "shared/helpers/urlSearchParams";

// This is a custom implementation of all routing in the app using query params instead of route params.
function AutoRedirect({ orgsLoading = true, orgData, setAutoSelectLoading, setError }) {
  const { conf, selectedModel, onSelectModel } = useAccount();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const urlOrg = searchParams.get("org");
  const urlModel = searchParams.get("model");
  const orgDataStr = JSON.stringify(orgData);
  const selectedOrg = JSON.parse(sessionStorage.getItem("selectedOrg"));

  React.useEffect(() => {
    // This will auto select modal based on access
    const redirectToModel = (response = { conf }) => {
      if (
        urlModel !== null &&
        response?.conf?.models?.[selectedModel]?.id !== urlModel &&
        Object.values(response?.conf?.models)
          .map((obj) => obj.id.toString())
          .includes(urlModel)
      ) {
        const [modelSlug, model] = Object.entries(response?.conf?.models).filter(
          ([key, value]) => value.id.toString() === urlModel,
        )[0];
        onSelectModel(modelSlug);
        setSearchParams((params) => {
          params.set("model", model.id);
          return params;
        });
        setAutoSelectLoading(false);
      } else {
        sessionStorageSync.removeItem("selected-model");
        navigate(`/?org=${urlOrg}`);
        setAutoSelectLoading(false);
      }
    };

    // This will auto select org based on access
    const autoSelectOrg = async () => {
      try {
        if (orgsLoading) return;
        const orgs = JSON.parse(orgDataStr)?.accessibleOrgs || [];
        if (urlOrg !== null && orgs?.map((a) => a.id.toString()).includes(urlOrg)) {
          if (selectedOrg?.id.toString() !== urlOrg) {
            setAutoSelectLoading(true);
            return onOrgSelect(orgs?.find((a) => a.id.toString() === urlOrg)).finally(() =>
              setAutoSelectLoading(false),
            );
          } else {
            redirectToModel();
          }
        } else {
          setAutoSelectLoading(false);
          logout();
          navigate("/");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    autoSelectOrg();
    // lint is disabled in this line to avoid infinite loop redirection and limit api calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgDataStr, urlOrg, urlModel]);
  return null;
}

export default AutoRedirect;

// This will redirect to the first available feature. This will be hit when the user selects a model and user should be redirect to the first available page.
export function navigateToAvailableFeature(isFeatureAvailable, navigate, searchParams, modelId, preferredRoute = "") {
  const orgParam = filteredSearchParams(searchParams, ["org"]);
  const featurePriority = {
    "Media insights": ["media-insights"],
    "Media insights+": ["media-insights-plus"],
    "Business insights": ["business-insights"],
    "Business insights+": ["business-insights-plus"],
    Predict: ["predictions", "activities"],
  };
  const preferredFeature = getKeyByValue(featurePriority, preferredRoute.replaceAll("/", ""));

  // Check if the preferred feature is available and navigate to it first
  if (preferredRoute !== "" && isFeatureAvailable(preferredFeature)) {
    navigate(`${preferredRoute}${orgParam}&model=${modelId}`);
    return;
  }

  // Find the first available feature from the priority list and navigate to it
  const availableFeature = Object.keys(featurePriority).find((feature) => isFeatureAvailable(feature));
  if (availableFeature) navigate(`/${featurePriority[availableFeature][0]}${orgParam}&model=${modelId}`);
  return;
}

// do not export and use it elsewhere as it can cause navigation flow to be decoupled
function onOrgSelect(org) {
  return getAadClaims(org?.orgSlug, "prod").then(() =>
    sessionStorageSync.setItem(
      "selectedOrg",
      JSON.stringify({ orgSlug: org?.orgSlug, orgName: org?.orgName, id: org?.id }),
    ),
  );
}

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key].includes(value));
}
