//this is to log certain errors only in development mode
// use this in the functions you create to indicate to developers if something is wrong, like a number was not provided to a formatter, or a required parameter is missing
export const devErrorLogger = (message) => {
  if (process.env.NODE_ENV === "development") {
    console.error(
      `%cDEV WARNING : %c${message}`,
      "color: red; font-weight: bold;",
      "background-color: black; color: yellow;",
    );
  }
};
