import React from "react";
import { Tooltip, Popover } from "@mui/material";
import styled from "@emotion/styled/macro";
import { useTheme } from "@mui/styles";
import tinycolor from "tinycolor2";
// slug - thi is the value Slug (eg paid-media, paid-social...)
// isDisabled - Boolean
// color - default color
// sortedColorPickerOptions - color array to display in dropdown
// onCHange - collback function - will return selected color on update
export default function ColorPicker({ slug, isDisabled, color, prodColor, sortedColorPickerOptions, onChange }) {
  //We need two state for color picker popover because when we are rendering MUI popover and setting the anchor element the popover which is opened at that anchor position will always be the one for the last list, so we also need id to identify the popover.
  const [openColorPickerId, setOpenColorPickerId] = React.useState(null);
  const [colorPickerAnchorEl, setColorPickerAnchorEl] = React.useState(null);
  const theme = useTheme();
  const handleColorPickerClose = (event) => {
    setColorPickerAnchorEl(null);
    setOpenColorPickerId(null);
  };
  const rgbColorString = tinycolor(color).toRgbString();
  const rgbProdColorString = tinycolor(prodColor).toRgbString();
  return (
    <>
      <Tooltip title={isDisabled ? null : "Change the default color for this grouping."}>
        <ColorPickerWrapper
          color={color}
          onClick={(event) => {
            if (isDisabled) return;
            setColorPickerAnchorEl(event.currentTarget);
            setOpenColorPickerId(`color-picker-${slug}`);
          }}
          isDisabled={isDisabled}
        />
      </Tooltip>
      <Popover
        id={`color-picker-${slug}`}
        open={openColorPickerId === `color-picker-${slug}`}
        anchorEl={colorPickerAnchorEl}
        onClose={handleColorPickerClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        elevation={2}
      >
        {prodColor && rgbColorString !== rgbProdColorString && (
          <div
            style={{
              padding: "8px",
              margin: "0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#fafafa",
            }}
          >
            <span style={{ margin: "0", color: theme.palette.greyish[40] }}>Colour in production:</span>
            <ColorPickerOption
              key={prodColor}
              color={prodColor}
              onClick={() => onChange(prodColor)}
              style={{ width: "32px" }}
            />
          </div>
        )}
        <div>
          <span style={{ margin: "0", marginLeft: "8px", color: theme.palette.greyish[40] }}>Select from these:</span>
          <ColorPickerOptions>
            {sortedColorPickerOptions?.map((color) => (
              <ColorPickerOption
                key={color}
                color={color}
                onClick={() => {
                  onChange(color);
                }}
              />
            ))}
          </ColorPickerOptions>
        </div>
      </Popover>
    </>
  );
}

const ColorPickerWrapper = styled.div`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border-radius: 0px;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  background-color: ${({ color }) => color};
`;

const ColorPickerOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 32px);
  gap: 4px;
  margin: 4px;
  padding: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #858574;
`;
const ColorPickerOption = styled.div`
  background-color: ${({ color }) => color};
  height: 32px;
  transition: scale 50ms linear;
  cursor: pointer;

  &:hover {
    scale: 1.3;
  }
`;
