export const ADD_ACTIVITY_DIALOG_SHOW = "ADD_ACTIVITY_DIALOG_SHOW";
export const ADD_ACTIVITY_DIALOG_SHOW_MODIFY = "ADD_ACTIVITY_DIALOG_SHOW_MODIFY";
export const ADD_ACTIVITY_DIALOG_HIDE = "ADD_ACTIVITY_DIALOG_HIDE";

export const ADD_ACTIVITY_UPDATE_FIELD = "ADD_ACTIVITY_UPDATE_FIELD";

export const ADD_ACTIVITY_SWITCH_BRIEF = "ADD_ACTIVITY_SWITCH_BRIEF";

export const ADD_ACTIVITY_UPDATE_INLINE_EDITOR_FIELD = "ADD_ACTIVITY_UPDATE_INLINE_EDITOR_FIELD";

export const ADD_ACTIVITY_EDIT_CONSTRAINT = "ADD_ACTIVITY_EDIT_CONSTRAINT";

export const RESET_BEFORE_ADD_NEW_CONSTRAINT = "RESET_BEFORE_ADD_NEW_CONSTRAINT";

export const ADD_ACTIVITY_CANCEL_EDIT_CONSTRAINT = "ADD_ACTIVITY_CANCEL_EDIT_CONSTRAINT";

export const ADD_ACTIVITY_SAVE_CONSTRAINT = "ADD_ACTIVITY_SAVE_CONSTRAINT";

export const ADD_ACTIVITY_DELETE_CONSTRAINT = "ADD_ACTIVITY_DELETE_CONSTRAINT";

export const ADD_ACTIVITY_START_VALIDATING = "ADD_ACTIVITY_START_VALIDATING";

export const ADD_ACTIVITY_VALIDATION_SUCCESS = "ADD_ACTIVITY_VALIDATION_SUCCESS";

export const ADD_ACTIVITY_VALIDATION_ERROR = "ADD_ACTIVITY_VALIDATION_ERROR";
