import React from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import styled from "@emotion/styled/macro";
import _ from "lodash";

import { addModifyEditor, resetEditor } from "store/reducers/activityDialog";
import useDimensionValuesV3 from "shared/hooks/useDimensionValues-v3";
import fromKeyValueListToKeyValuesObject from "shared/helpers/fromKeyValueListToKeyValuesObject";

import TableFooter from "./tableFooter";
import ConstraintsEditor from "../ConstraintsEditor";
import DisplaySavedConstraints from "./displaySavedConstraintsTable";
import ValidateError from "./validateError";
import { useAccount } from "auth";
import useDimensionValues from "shared/hooks/useDimensionValues";
import getImpactTypeForModel from "shared/helpers/getImpactTypeForModel";

const ConstraintsTable = ({
  constraints,
  currency,
  disabled,
  inlineEditor,
  addModifyEditor,
  resetEditor,
  modalDispatch,
  validating,
  errorMessage,
}) => {
  const { selectedModel, selectedModelConf: modelConf, isFeatureAvailable } = useAccount();
  const {
    socketSlug,
    mediaInsightsPlus__impact_per_dimension_v1: availableDimensions,
    activeVersion,
    mediaGroupings,
    type,
  } = modelConf;

  const dimensionValuesMedia = useDimensionValuesV3(selectedModel, { onlyMedia: true });
  const dimensionValuesMediaOld = useDimensionValues(socketSlug, activeVersion, { onlyMedia: true });

  const dimensionValuesToUse =
    dimensionValuesMedia.length > 0
      ? dimensionValuesMedia
      : insertMediaGroupings(dimensionValuesMediaOld, mediaGroupings); //dimension values media old with media groupings inserted as they are not present in v4/dimensions API unlike v3/historical/dimension-values

  const valuesGroupedByDim = fromKeyValueListToKeyValuesObject(dimensionValuesToUse);
  const impact = getImpactTypeForModel(type);
  const availableConfigDimension = availableDimensions?.[impact];

  //use all dimensions as fallback if impact per dimension is not configured
  const mediaDimensionsInModel = Object.keys(dimensionValuesToUse?.[0] ?? {});

  const dimensionValuesMediaAllowed = filterAllowedDimensionValues(
    dimensionValuesToUse,
    availableConfigDimension ?? mediaDimensionsInModel,
  );
  const headerLabels = [];
  const headerDimension = inlineEditor.index === null ? inlineEditor.constraint.dimensions : [];
  headerDimension.map(({ selection = { Dimension: null } }) => headerLabels.push(...Object.keys(selection)));
  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <h3>Do you want to add constraints to this {isFeatureAvailable("Simulator") ? "optimisation" : "prediction"}?</h3>
      {constraints.length > 0 && (
        <DisplaySavedConstraints
          constraints={constraints}
          inlineEditor={inlineEditor}
          modalDispatch={modalDispatch}
          valuesGroupedByDim={valuesGroupedByDim}
          dimensionValuesMedia={dimensionValuesMediaAllowed}
          availableConfigDimension={availableConfigDimension ?? mediaDimensionsInModel}
        />
      )}
      {inlineEditor.editing && inlineEditor.index === null ? (
        <>
          <ConstraintWrapper>
            <ConstraintsEditor
              modalDispatch={modalDispatch}
              dimensionValuesMedia={dimensionValuesMediaAllowed}
              valuesGroupedByDim={valuesGroupedByDim}
              availableConfigDimension={availableConfigDimension ?? mediaDimensionsInModel}
            />
          </ConstraintWrapper>
          <ValidateError validating={validating} errorMessage={errorMessage} />
          <TableFooter />
        </>
      ) : null}
      {(!inlineEditor.editing && inlineEditor.index === null) ||
      (inlineEditor.editing && inlineEditor.index !== null) ? (
        <ButtonWrapper id="table__add-constraints-button">
          <Button
            data-ref="new-activity-add-constraint"
            variant="outlined"
            disabled={disabled || inlineEditor.editing || dimensionValuesToUse.length === 0}
            onClick={() => {
              resetEditor();
              addModifyEditor();
              const elementToScroll = document.getElementById("table__add-constraints-button");
              const scrollToElement = () => elementToScroll.scrollIntoView({ behavior: "smooth" });
              setTimeout(scrollToElement, 0);
            }}
            sx={{
              fontSize: 14,
              borderRadius: 0,
              border: 0.5,
              borderColor: "primary.main",
              textTransform: "none",
              padding: "5px",
              width: "163px",
              height: "32px",
            }}
          >
            <AddIcon fontSize="small" />
            &nbsp;&nbsp;ADD CONSTRAINT
          </Button>
        </ButtonWrapper>
      ) : null}
    </div>
  );
};

function insertMediaGroupings(dimensionValuesMediaOld = [], mediaGroupings = []) {
  /*v4/dimensions does not include media-grouping in the entries.
    So to identify which insertions are linked with which media grouping we can use this function to 
      manually insert the linked media-grouping to each insertions.

    Example input = [{
      "channel": "paidsearch",
      "publisher": "bing",
      "campaign_product": "generic",
      "creative_format": "regular",
      "campaign_type": "brand"
    }]

    Example output: [{
      "media-grouping": "paid-search",
      "channel": "paidsearch",
      "publisher": "bing",
      "campaign_product": "generic",
      "creative_format": "regular",
      "campaign_type": "brand"
    }]
    
  */
  const groupingToFilter = mediaGroupings?.reduce((acc, d) => ({ ...acc, [d.slug]: d.filter }), {});
  const dimensionValuesWithMG = dimensionValuesMediaOld.map((entry) => {
    //check if any of the filter in a media grouping have filter common with entry
    const groupingsWithOverlap = [];
    for (const grouping in groupingToFilter) {
      const filter = groupingToFilter[grouping];
      for (const dim in filter) {
        if (filter[dim]?.includes(entry[dim])) {
          if (!groupingsWithOverlap.includes(grouping)) {
            groupingsWithOverlap.push(grouping);
          }
        }
      }
    }

    if (groupingsWithOverlap.length > 1) {
      //if there are multiple groupings with overlap then we need to check which overlaps completely with entry
      const groupingWithCompleteOverlap = groupingsWithOverlap.filter((slug) => {
        const filter = groupingToFilter[slug];

        for (const dim in filter) {
          if (!filter[dim].includes(entry[dim])) {
            //if even a single filter dimension doesn't match then the grouping doesn't completely cover the entry
            return false;
          }
        }
        return true;
      });
      return { "media-grouping": groupingWithCompleteOverlap[0], ...entry };
    } else if (groupingsWithOverlap.length === 1) {
      return { "media-grouping": groupingsWithOverlap[0], ...entry };
    } else {
      return entry;
    }
  });
  return dimensionValuesWithMG;
}

function filterAllowedDimensionValues(dimensionValues, allowedDimensions) {
  const allowedDimensionValues = [];
  if (allowedDimensions === undefined) {
    return allowedDimensionValues;
  }
  dimensionValues?.forEach((object) => {
    allowedDimensionValues.push(_.pick(object, allowedDimensions));
  });

  return allowedDimensionValues;
}

const ConstraintWrapper = styled.div`
  margin-bottom: 10px;
  position: relative;
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

export default connect(
  (state) => ({
    validating: state.activityDialog.inlineEditor.validating,
    errorMessage: state.activityDialog.inlineEditor.errorMessage,
  }),
  {
    addModifyEditor,
    resetEditor,
  },
)(ConstraintsTable);
