import { getClaimsToken } from "auth";
import qs from "qs";

export default async function downloadBinary(url, filename, opts = {}) {
  try {
    if (process.env.NODE_ENV === "development")
      console.log(
        `%c ↗ ${url.split("?")[0]} — Downloading binary...`,
        "color: rgb(199, 217, 157)",
        qs.parse(url.split("?")[1]),
      );

    // This may take a bit long, maybe we want to notify that something is going on
    const res = await fetch("/backend-api/" + url, {
      ...opts,
      headers: { ...(opts.headers || {}), Authorization: `Bearer ${getClaimsToken()}` },
    });

    if (!res.ok) {
      const errorResponse = await res.text();
      const errorInfo = `${res.status} ${url.split("?")[0]}\n${errorResponse || ""}`;
      console.error(errorInfo, "\nQuery params:", qs.parse(url.split("?")[1]));
      // This will result in a red snackbar the user will see
      //store.dispatch(notifyNew({ message: "There was a problem retrieving data", isError: true }));
      throw new Error(errorInfo);
    }

    const blob = await res.blob();
    if (process.env.NODE_ENV === "development")
      console.log(`%c ↘ ${url.split("?")[0]} ${res.status} — Download complete`, "color: rgb(157, 217, 172)");

    // Fake this by creating a temporary anchor and clicking it
    const objectURL = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = objectURL;
    a.download = filename;
    document.body.appendChild(a); // Firefox requires appending the element to the DOM
    a.click(); // Here users will see the file downloading

    // Clean up
    document.body.removeChild(a);
    URL.revokeObjectURL(blob);
  } catch (err) {
    console.error("ERROR", err);
  }
}
