import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import styled from "@emotion/styled/macro";
import { useTheme } from "@mui/material/styles";
import formattedLabel from "shared/helpers/formattedLabel";

export default function OptionsForOneDimension({
  dimension,
  options,
  expandedOverride,
  onSelect,
  labelDictionary = {},
}) {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(expandedOverride); // Only groupings start expanded

  const optionsCount = options.length;
  const checkedCount = options.filter((d) => d.chosen).length;
  const anySelected = options.some((d) => d.selected);

  React.useEffect(() => {
    if (expandedOverride) setExpanded(true);
    else setExpanded(false);
  }, [expandedOverride]);

  React.useLayoutEffect(() => {
    /* 
      Popover does not recaluculate its position when the content changes, just like we have 
      with collapsing and expanding dimension options. There is also a updatePosition() action on MUI popover
      but it didn't seem to work in this case, this is the only way I found to correctly retrigger
      the popover positioning.
      https://github.com/mui/material-ui/issues/10595#issuecomment-403130358

      //DON"T OVERUSE IT
    */
    window.dispatchEvent(new CustomEvent("resize"));
  }, [expanded]);

  return (
    <React.Fragment key={dimension}>
      <ListItem
        key={dimension}
        style={{ cursor: "pointer" }}
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => setExpanded((expanded) => !expanded)}
      >
        <DimensionLabel
          primary={
            <>
              <DimensionSpan
                data-test-id={labelDictionary[dimension] || dimension}
              >{`${formattedLabel(labelDictionary, null, dimension)}`}</DimensionSpan>
              {checkedCount > 0 ? (
                <>
                  <DimensionSpan style={{ marginLeft: 10, color: theme.palette.primary.main, fontWeight: 700 }}>
                    {checkedCount}
                  </DimensionSpan>
                  <DimensionSpan style={{ opacity: 0.3, marginLeft: 6 }}>{`/ ${optionsCount}`}</DimensionSpan>
                </>
              ) : (
                <DimensionSpan style={{ opacity: 0.3, marginLeft: 10 }}>
                  {optionsCount > 0 ? optionsCount : ""}
                </DimensionSpan>
              )}
            </>
          }
          style={{ color: expandedOverride ? "black" : "gray" }}
        />
        {!anySelected && (expanded ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      <Collapse in={anySelected || expanded}>
        {[
          ...options.map((d) => ({
            ...d,
            label:
              d.value === "valuemissing" || d.value === "blank"
                ? "Untagged"
                : formattedLabel(labelDictionary, d.value, dimension),
          })),
        ]
          .sort((a, b) => a.label.localeCompare(b.label))
          .map(({ value, label, selected, chosen }) => (
            <ListItem
              button
              key={value}
              selected={selected}
              onMouseDown={(e) => e.preventDefault()}
              sx={{
                background: chosen && theme.palette.primary.main,
                color: chosen && "#fff",
                "&:hover": { backgroundColor: chosen ? theme.palette.primary.main : "#F2F1EE" },
                "&:focus": {
                  backgroundColor: theme.palette.primary.main,
                  color: "#fff",
                },
              }}
              onClick={(e) => onSelect({ value, dimension })}
              style={{ height: 35 }}
            >
              <ListItemText primary={<>{label}</>} />
            </ListItem>
          ))}
      </Collapse>
    </React.Fragment>
  );
}

const DimensionLabel = styled(ListItemText)`
  margin-top: 4px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const DimensionSpan = styled.span`
  font-size: 12px;
`;
