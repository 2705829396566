import moment from "moment";
import backend from "backend";
import commonInvestmentPlansParams from "shared/helpers/commonInvestmentPlansParams";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  GET_PLANS_BY_ACTIVITY,
  GET_PLANS_BY_ACTIVITY_SUCCESS,
  GET_PLANS_BY_ACTIVITY_ERROR,
  GET_ACTIVITY_SUMMARIES,
  GET_ACTIVITY_SUMMARIES_SUCCESS,
  GET_ACTIVITY_SUMMARIES_ERROR,
  REMOVE_INVESTMENT_PLAN,
  REMOVE_INVESTMENT_PLAN_SUCCESS,
  REMOVE_INVESTMENT_PLAN_ERROR,
} from "./actions";

// SAGAS are sort of `IF THIS ACTION THEN THAT ACTION`
// · They listen to actions (produced by any module, including this one)
// · They compute with data in the action and in the redux state
// · They produce new actions with the outcomes (and also side effects,
//     but try not to do that unless there's a good reason)

function* getPlansByActivity(action) {
  try {
    const plans = yield call(() =>
      backend.get(`v3/investment-plans/${action.socketSlug}`, {
        params: { activityId: action.activityId, ...commonInvestmentPlansParams },
      }),
    );
    yield put({
      type: GET_PLANS_BY_ACTIVITY_SUCCESS,
      plans,
    });
  } catch (err) {
    yield put({
      type: GET_PLANS_BY_ACTIVITY_ERROR,
      error: err,
    });
  }
}

function* getActivitySummaries(action) {
  yield put({
    type: GET_ACTIVITY_SUMMARIES,
    socketSlug: action.socketSlug,
  });
  try {
    const res = yield call(
      // We let the reducer filter out activities without plan
      () => backend.get("v1/activities", { params: { modelSlug: action.socketSlug } }),
    );
    yield put({
      type: GET_ACTIVITY_SUMMARIES_SUCCESS,
      list: res,
    });
  } catch (err) {
    yield put({
      type: GET_ACTIVITY_SUMMARIES_ERROR,
      err,
    });
  }
}

function* getAllActivities(action) {
  // Fetching all activated activities (basic info) for period selection menu
  const aLongTimeAgo = moment().subtract(3, "year").format("YYYY-MM-DD");
  const inADistantFuture = moment().add(18, "month").format("YYYY-MM-DD");
  const addPeriodToActivity = (activity) => {
    const activePlan = activity.investmentPlans[activity.activePlanId];
    return {
      ...activity,
      period: {
        startDate: moment(activePlan.startDate),
        endDate: moment(activePlan.endDate),
      },
    };
  };

  const allActivities = yield call(() =>
    backend
      .get("v1/activities", {
        params: { active: true, startDate: aLongTimeAgo, endDate: inADistantFuture, modelSlug: action.socketSlug },
      })
      .then((activities) => activities.map(addPeriodToActivity)),
  );

  yield put({
    type: "ACTIVITIES_ALL_ACTIVITIES_LOADED",
    allActivities,
  });
}

function* removePlan(action) {
  try {
    yield call(() =>
      backend.delete(`v1/activities/${action.socketSlug}/${action.activity}/investment-plan-ids/${action.planId}`),
    );

    yield put({
      type: REMOVE_INVESTMENT_PLAN_SUCCESS,
      id: action.planId,
      socketSlug: action.socketSlug,
    });
  } catch (err) {
    err.response.status === 409 // deletion failed because activity is not empty server side.
      ? yield put({
          type: REMOVE_INVESTMENT_PLAN_SUCCESS,
          socketSlug: action.socketSlug,
          id: action.planId,
        })
      : yield put({
          type: REMOVE_INVESTMENT_PLAN_ERROR,
          error: err,
        });
  }
}

function* ActivitiesSaga() {
  yield takeLatest("MODEL_INIT", getActivitySummaries);
  yield takeLatest("MODEL_INIT", getAllActivities);
  yield takeLatest("SAVE_INVESTMENT_PLAN_DONE", getActivitySummaries);
  yield takeLatest("DEACTIVATE_PLAN_SUCCESS", getActivitySummaries);
  yield takeLatest("DEACTIVATE_PLAN_SUCCESS", getAllActivities);
  yield takeLatest(REMOVE_INVESTMENT_PLAN_SUCCESS, getActivitySummaries);
  yield takeLatest(GET_PLANS_BY_ACTIVITY, getPlansByActivity);
  yield takeLatest(REMOVE_INVESTMENT_PLAN, removePlan);
}

export default ActivitiesSaga;
