import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import CurrencyDollarIcon from "@mui/icons-material/AttachMoney";
import CurrencyEuroIcon from "@mui/icons-material/Euro";

export default function getMoneyIcon(currency) {
  switch (currency) {
    case "EUR":
    case "EURO":
      return CurrencyEuroIcon;
    case "USD":
      return CurrencyDollarIcon;
    case "GBP":
      return CurrencyPoundIcon;
    default:
      return undefined;
  }
}
