import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const INITIAL_STATE = {
  filter: {},
  groupBy: "media-grouping",
  dimensionOptions: [],
  splitView: false,
  impact: "combined",
  isDrillDownAllowed: false,
  savePlanStatus: {}, //used to show loading spinner when save draft in progress, {status: "inProgress", planId: id}
};

const optimizationSlice = createSlice({
  name: "optimization",
  initialState: INITIAL_STATE,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload.filter;
      if (action.payload.increaseLevelBy === 1) {
        const currentIndex = action.payload.drilldownOptions.findIndex((item) => item === state.groupBy);
        let nextIndex = currentIndex + 1;
        state.groupBy = action.payload.drilldownOptions[nextIndex];
        if (nextIndex + 1 < action.payload.drilldownOptions.length) {
          state.isDrillDownAllowed = true;
        } else {
          state.isDrillDownAllowed = false;
        }
      } else if (action.payload.increaseLevelBy === 0) {
        state.groupBy =
          _.intersection(action.payload.drilldownOptions, Object.keys(action.payload.filter)).at(-1) ??
          state.dimensionOptions.filter((a) => a.selected)?.at(0)?.value;
        const currentIndex = action.payload.drilldownOptions.findIndex((item) => item === state.groupBy);
        let nextIndex = currentIndex + 1;
        if (nextIndex < action.payload.drilldownOptions.length) {
          state.isDrillDownAllowed = true;
        } else {
          state.isDrillDownAllowed = false;
        }
      } else {
        const currentIndex = action.payload.drilldownOptions.findIndex((item) => item === state.groupBy);
        let prevIndex = currentIndex > 0 ? currentIndex - 1 : 0;
        state.groupBy = action.payload.drilldownOptions[prevIndex];
        if (prevIndex < action.payload.drilldownOptions.length) {
          state.isDrillDownAllowed = true;
        } else {
          state.isDrillDownAllowed = false;
        }
      }
    },
    setDimensionOptions: (state, action) => {
      state.dimensionOptions = action.payload.dimensionOptions;
      state.filter = {};
      state.groupBy = action.payload.dimensionOptions.find((a) => a.selected)?.value;
      state.isDrillDownAllowed = action.payload.dimensionOptions.filter((obj) => obj.selected).length > 1;
    },
    toggleSplitView: (state) => {
      state.splitView = !state.splitView;
    },
    updateDrillDown: (state, action) => {
      state.isDrillDownAllowed = action.payload.isDrillDownAllowed;
    },
    setImpact: (state, action) => {
      state.splitView = false;
      state.impact = action.payload.impact;
      state.dimensionOptions = action.payload.dimensionOptions;
      state.filter = {};
      state.groupBy = state.dimensionOptions.find((a) => a.selected)?.value || INITIAL_STATE.groupBy;
      state.isDrillDownAllowed = action.payload.dimensionOptions.filter((obj) => obj.selected).length > 1;
    },
    resetState: (state, action, INITIAL_STATE) => {
      state.dimensionOptions = action.payload.dimensionOptions;
      state.filter = {};
      state.groupBy = action.payload.dimensionOptions.find((a) => a.selected)?.value || INITIAL_STATE.groupBy;
      state.splitView = false;
      state.isDrillDownAllowed = action.payload.dimensionOptions.filter((obj) => obj.selected).length > 1;
      state.impact = "combined";
    },
    setSavePlanStatus: (state, action) => {
      state.savePlanStatus = action.payload;
    },
  },
});

export const {
  setFilter,
  toggleSplitView,
  setImpact,
  setDimensionOptions,
  resetState,
  updateDrillDown,
  setSavePlanStatus,
} = optimizationSlice.actions;
export const selectFilter = (state) => state.optimization.filter;
export const selectImpact = (state) => state.optimization.impact;
export const selectSplitView = (state) => state.optimization.splitView;
export const selectDimensionOptions = (state) => state.optimization.dimensionOptions;
export const selectGroupBy = (state) => state.optimization.groupBy;
export const selectIsDrillDownAllowed = (state) => state.optimization.isDrillDownAllowed;
export const selectSavePlanStatus = (state) => state.optimization.savePlanStatus;

export default optimizationSlice.reducer;
