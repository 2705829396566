export default function getToggledFilter({ previousFilter, value, inDimension }) {
  if (!previousFilter[inDimension]) return { ...previousFilter, [inDimension]: [value] };

  const nextFilter = {};
  Object.keys(previousFilter).forEach((dimension) => {
    if (dimension === inDimension) {
      const oldValues = previousFilter[dimension];
      const newValues = oldValues.includes(value) ? oldValues.filter((d) => d !== value) : [...oldValues, value];
      if (newValues.length > 0) nextFilter[dimension] = newValues;
    } else {
      // Leave it untouched
      nextFilter[dimension] = previousFilter[dimension];
    }
  });

  return nextFilter;
}
