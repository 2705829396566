import React from "react";
import useSWR from "swr";
import { getClaimsToken } from "auth";
import qs from "qs";
import fetcher from "./fetcher";

// For making caching dependant on params,
// keep an eye on https://github.com/vercel/swr/pull/145#discussion_r350064725
export default function useData(apiRoute, params = {}, swrOpts, loggingDisabled) {
  const lastResult = React.useRef(undefined);
  // Sometimes the frontend will change params too quickly due to
  // users clicking buttons very fast. This allows us to apply slower changing params
  const url = useDebounce(apiRoute && apiRoute + paramsSerialiser(params), 1000);
  const token = getClaimsToken();
  //including token as key for cache too, as for same url we are using different token to get prod/draft data
  const { data, mutate, error } = useSWR([url, token], ([url, token]) => fetcher(url, {}, token, loggingDisabled), {
    dedupingInterval: 8 * 1000, // Do not revalidate very often
    ...swrOpts,
  });
  if (data) {
    lastResult.current = data;
    return { data, loading: false, mutate, error };
  } else {
    // If data is stale, return the latest cached data
    // The reason for this is to make possible transitions when changing filters
    // (a transition between the previous and newly fetched data)
    return { data: lastResult.current, loading: true, mutate, error };
  }
}

// For making caching dependant on params,
// keep an eye on https://github.com/vercel/swr/pull/145#discussion_r350064725
export function paramsSerialiser(params) {
  // When params have n values we do ?param=val1&param=val2...
  // We need to do this because we need to encode multi-dimensional multi-value filters,
  // things like { publisher: ['nbc', 'abc'] } → ?filter:publisher=nbc&filter:publisher:abc
  const serialised = qs.stringify(params, { arrayFormat: "repeat" });
  return serialised ? "?" + serialised : "";
}

// This debounces a value, but it applies a leading update so that the whole
// operation feels responsible at the initial click
export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  const isTimerPending = React.useRef(false);

  React.useEffect(() => {
    let handlerId;
    if (!isTimerPending.current) {
      setDebouncedValue(value); // Leading update
      handlerId = setTimeout(() => {
        // We've done already a leading update, so just signal that no timer is pending
        isTimerPending.current = false;
      }, delay);
    } else {
      handlerId = setTimeout(() => {
        setDebouncedValue(value);
        isTimerPending.current = false;
      }, delay);
    }
    isTimerPending.current = true;

    return () => clearTimeout(handlerId);
  }, [value, delay]);

  return debouncedValue;
}
