import _ from "lodash";
import { useAccount } from "auth";

const metrics = {
  net_spend: {
    label: "Media investment",
    isMoney: true,
    isRounded: true,
  },

  effects_aggregated: {
    label: (objectiveUnitName) => `Media generated ${objectiveUnitName}`,
    isRounded: true,
  },

  profit_aggregated: {
    label: "Media generated profit",
    isMoney: true,
    isRounded: true,
  },

  "net_spend/effects_aggregated": {
    label: "Cost per action",
    isMoney: true,
    dailyDisabled: true, // Because the uncertainty for daily is very high
    isRounded: true,
  },

  "profit_aggregated/net_spend": {
    label: "Media profit-ROI",
    dailyDisabled: true, // Because the uncertainty for daily is very high
  },

  effects_observed: {
    label: (objectiveUnitName) => `Total ${objectiveUnitName}`,
    timelineDisabled: true, // Because this is the media insights page
    isRounded: true,
  },
};

// Sometimes labels need to be evaluated when this function is called
// (think: new stuff we depend on on the Redux store). That's why
// computed labels are functions.
export function getMetricLabel(id, objectiveUnitName) {
  const { label } = metrics[id] || { label: id?.replace(/_/g, " ").toLowerCase() };
  return _.isFunction(label) ? label(objectiveUnitName) : label;
}

export function getDefaultMetricLabel(id) {
  const { label } = metrics[id];
  return _.isFunction(label) ? label("{objectiveUnitName}") : label;
}

export function useMetricLabelGetter() {
  const { selectedModel: modelSlug, conf } = useAccount();
  const modelConf = conf.models[modelSlug] || {};
  const { mediaInsights__metrics, mediaInsights__metrics_v2, objectiveUnitName } = modelConf;
  if (mediaInsights__metrics_v2 && mediaInsights__metrics_v2.length > 0)
    return (slug) =>
      (mediaInsights__metrics_v2.find((d) => d.slug === slug)?.label || slug)?.replace(
        "{objectiveUnitName}",
        objectiveUnitName,
      );
  else if (mediaInsights__metrics)
    return (slug) => {
      const { label } = metrics[slug] || { label: slug?.replace(/_/g, " ").toLowerCase() };
      return _.isFunction(label) ? label(objectiveUnitName) : label;
    };
  else return {};
}

// Check for: isMoney, isRounded, dailyDisabled, timelineDisabled...
export function checkMetricFlag(id, flag) {
  return (metrics[id] || {})[flag] || false;
}

export default metrics;
