import _ from "lodash";

/**
 * Checks if two arrays are equal.
 *
 * @param {string[]} a - The first array to compare.
 * @param {string[]} b - The second array to compare.
 * @returns {boolean} - Returns true if the arrays are equal, false otherwise, order is not cheked.
 */
export default function checkArrayEquality(a, b) {
  if (!a || !b) {
    return false;
  }

  return _.isEqual(a.toSorted(), b.toSorted());
}
