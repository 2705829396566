import checkArrayEquality from "./checkArrayEquality";

//checks if two filter objects are equal by performing deep equality
//example structure a: {channel: ['tv', 'radio'], campaign: ['branding']}
export default function checkFilterEquality(a, b) {
  if (!a || !b) {
    return false;
  }

  //check for dimension keys first
  if (!checkArrayEquality(Object.keys(a), Object.keys(b))) {
    return false;
  }

  for (const [dimension, filter] of Object.entries(a)) {
    //check deep equality for filter array
    if (!checkArrayEquality(filter, b?.[dimension])) {
      return false;
    }
  }

  return true;
}
