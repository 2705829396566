export default function fromKeyValueListToKeyValuesObject(array) {
  const result = {};
  array.forEach((object) => {
    Object.keys(object).forEach((key) => {
      const value = object[key];
      if (result[key]) {
        if (!result[key].includes(value)) result[key] = [...result[key], value];
      } else result[key] = [value];
    });
  });
  return result;
}
