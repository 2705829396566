import styled from "@emotion/styled/macro";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { IconButton } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import { ReactComponent as EditGreyIcon } from "shared/Icons/edit-2.svg";

import { useAccount } from "auth";

import { connect } from "react-redux";
import { addModifyEditor } from "store/reducers/activityDialog";

import CustomTooltip from "shared/components/CustomTooltip";
import formattedLabel from "shared/helpers/formattedLabel";
import { useLabels } from "shared/helpers/prettyNames";
import { prettyPeriod } from "shared/helpers/time";
import ConstraintsEditor, {
  TableCellAmount,
  TableCellDimension,
  TableCellIcon,
  TableCellPeriod,
  TableCellType,
  TableRow,
  TableRowLeft,
  TableRowRight,
} from "../ConstraintsEditor";
import getMoneyIcon from "../helpers/getMoneyIcon";
import { getValueTypeLabel } from "../helpers/valueTypes";
import ValidateError from "./validateError";

function DisplaySavedConstraints({
  data,
  constraint,
  constraints,
  inlineEditor,
  modalDispatch,
  dimensionValuesMedia,
  addModifyEditor,
  valuesGroupedByDim,
  availableConfigDimension,
}) {
  const { selectedModelConf: modelConf, isFeatureAvailable } = useAccount();
  const { currency } = modelConf;
  const MoneyIcon = getMoneyIcon(currency);
  const mainDivRef = React.useRef();
  const secondaryDivRef = React.useRef();

  const labelDictionary = useLabels(modelConf);
  const allDimension = _.flatten(constraints.map((d) => _.pick(d, ["match"])).map((d) => d.match));
  const headers = _.remove(
    _.uniq(allDimension.map((d) => d.dimension)).sort(
      (a, b) => availableConfigDimension.indexOf(a) - availableConfigDimension.indexOf(b),
    ),
    (n) => {
      return availableConfigDimension.includes(n);
    },
  );
  const availableKeysInDimension =
    dimensionValuesMedia?.length > 0
      ? dimensionValuesMedia?.map((item) => Object.keys(item)).reduce((a, b) => a.concat(b))
      : [];
  const uniqueKeysInDimension = _.uniq(availableKeysInDimension);

  // the startDate & endDate should be equal to minDate or maxDate, or it should be between minDate and maxDate
  const checkPeriodFilter = (date) => {
    return date === data.startDate || date === data.endDate || moment(date).isBetween(data.startDate, data.endDate);
  };
  const validatePeriodFilter = (startDate, endDate) => {
    return checkPeriodFilter(startDate) && checkPeriodFilter(endDate);
  };

  const periodTooltipContent = (
    <div style={{ padding: "0px 10px", maxWidth: 400 }}>
      <p>
        The applicable period should fall within the {isFeatureAvailable("Simulator") ? "optimisation" : "prediction"}{" "}
        period.
      </p>
    </div>
  );

  const mainDivScroll = (e) => {
    secondaryDivRef.current.scrollLeft = e.target.scrollLeft;
  };
  const secondaryDivScroll = (e) => {
    mainDivRef.current.scrollLeft = e.target.scrollLeft;
  };
  return (
    <>
      <Wrapper ref={mainDivRef} onScroll={mainDivScroll}>
        <TableHeaderRow style={{ opacity: inlineEditor.editing && "0.5" }}>
          <TableHeaderRowLeft>
            <TableCellIcon />
            <TableCellType style={{ paddingLeft: "3px" }}>TYPE</TableCellType>
            <TableCellAmount style={{ paddingLeft: "3px" }}>
              AMOUNT
              {MoneyIcon ? (
                <IconButton sx={{ padding: "0px", lineHeight: "var(--default-line-height)", fontSize: "inherit" }}>
                  (
                  <MoneyIcon
                    sx={{
                      fontSize: "14px",
                      color: "#000",
                      fontWeight: "300",
                      width: "0.8em",
                      height: "0.8em",
                    }}
                  />
                  )
                </IconButton>
              ) : (
                ` (${currency?.toUpperCase()})`
              )}
            </TableCellAmount>
            <TableCellPeriod style={{ paddingLeft: "3px" }}>APPLICABLE PERIOD</TableCellPeriod>
          </TableHeaderRowLeft>
          <TableBodyRowRight
            headerLength={inlineEditor.index !== null ? constraint.dimensions.length : headers.length}
            constraintID={inlineEditor.index !== null && Boolean(inlineEditor.index.toString())}
            hideAddButton={
              inlineEditor.index !== null
                ? constraint.dimensions.length === uniqueKeysInDimension.length
                : headers.length === uniqueKeysInDimension.length
            }
          >
            {headers.map((header) => (
              <TableCellDimension
                key={header}
                title={labelDictionary[header] ? _.upperCase(labelDictionary[header]) : _.upperCase(header)}
                style={{ paddingLeft: "3px" }}
              >
                {formattedLabel(labelDictionary, null, header)}
              </TableCellDimension>
            ))}
          </TableBodyRowRight>
        </TableHeaderRow>
        {constraints.map((item, index) =>
          inlineEditor.index === index ? (
            <div style={{ position: "relative" }} key={`${item.id}`}>
              <ConstraintsEditor
                style={{ height: "72px" }}
                headers={headers}
                modalDispatch={modalDispatch}
                valuesGroupedByDim={valuesGroupedByDim}
                dimensionValuesMedia={dimensionValuesMedia}
                availableConfigDimension={availableConfigDimension}
              />
            </div>
          ) : (
            <TableBodyRow
              style={{
                opacity: inlineEditor.editing && "0.5",
                cursor: inlineEditor.editing ? "not-allowed" : "pointer",
              }}
              key={`${item.id}`}
              onClick={() => {
                if (!inlineEditor.editing) addModifyEditor({ index, headers });
              }}
            >
              <TableBodyRowLeft constraintID={inlineEditor.index !== null && Boolean(inlineEditor.index.toString())}>
                <TableCellIcon>
                  {!validatePeriodFilter(item.startDate, item.endDate) && (
                    <CustomTooltip
                      dark
                      content={!validatePeriodFilter(item.startDate, item.endDate) && periodTooltipContent}
                      placement="bottom"
                      arrow
                    >
                      <ErrorOutlineIcon
                        sx={{
                          color: "red",
                          margin: "5px 2px 2px 5px",
                          fontSize: "20px",
                          fontWeight: "300",
                        }}
                      />
                    </CustomTooltip>
                  )}
                </TableCellIcon>
                <TableCellType style={{ paddingLeft: "3px" }}>{getValueTypeLabel(item.type)}</TableCellType>
                <TableCellAmount style={{ paddingLeft: "3px" }}>{numeral(item.value).format("0,0")}</TableCellAmount>
                <CustomTooltip
                  dark
                  content={!validatePeriodFilter(item.startDate, item.endDate) && periodTooltipContent}
                  placement="bottom"
                  arrow
                >
                  <TableCellPeriod
                    style={{
                      color: !validatePeriodFilter(item.startDate, item.endDate) && "#E10000",
                      paddingLeft: "3px",
                    }}
                  >
                    {item.startDate && item.endDate
                      ? prettyPeriod([item.startDate, item.endDate], { showYear: true })
                      : ""}
                  </TableCellPeriod>
                </CustomTooltip>
              </TableBodyRowLeft>
              <TableBodyRowRight
                headerLength={headers.length}
                constraintID={inlineEditor.index !== null && Boolean(inlineEditor.index.toString())}
                hideAddButton={headers.length === uniqueKeysInDimension.length}
              >
                {headers.map((header) => {
                  const findMetricInMatch = _.find(item.match, (o) => {
                    return o.dimension === header;
                  });
                  const metric =
                    findMetricInMatch?.value[0] === "blank" || findMetricInMatch?.value[0] === "valuemissing"
                      ? "Untagged"
                      : formattedLabel(labelDictionary, findMetricInMatch?.value[0], header.toLowerCase());
                  return findMetricInMatch ? (
                    <CustomTooltip dark content={getTooltipContent(header, metric)} placement="bottom" arrow>
                      <TableCellDimension key={`${item.id}.${header}`} style={{ paddingLeft: "3px" }}>
                        {metric}
                      </TableCellDimension>
                    </CustomTooltip>
                  ) : (
                    <TableCellDimension key={`${item.id}.${header}`} style={{ paddingLeft: "3px" }}>
                      -
                    </TableCellDimension>
                  );
                })}
                {inlineEditor.index !== null && <div style={{ width: "102px" }}></div>}
                <div style={{ display: "none" }} className="show-on-hover">
                  <ModifiedIconButton
                    disabled={true}
                    sx={{ float: "right", paddingRight: inlineEditor.index !== null && "25px" }}
                  >
                    <EditGreyIcon />
                  </ModifiedIconButton>
                </div>
              </TableBodyRowRight>
            </TableBodyRow>
          ),
        )}
      </Wrapper>

      {/* sync scroll table */}
      <div
        className="customScroll"
        style={{ overflow: "auto", marginBottom: "10px" }}
        ref={secondaryDivRef}
        onScroll={secondaryDivScroll}
      >
        <TableHeaderRow style={{ height: "1px", border: "none", backgroundColor: "transparent" }}>
          <TableHeaderRowLeft style={{ backgroundColor: "transparent", border: "none" }}>
            <TableCellIcon />
            <TableCellType />
            <TableCellAmount />
            <TableCellPeriod />
          </TableHeaderRowLeft>
          <TableBodyRowRight
            style={{ backgroundColor: "transparent" }}
            headerLength={inlineEditor.index !== null ? constraint.dimensions.length : headers.length}
            constraintID={inlineEditor.index !== null && Boolean(inlineEditor.index.toString())}
            hideAddButton={
              inlineEditor.index !== null
                ? constraint.dimensions.length === uniqueKeysInDimension.length
                : headers.length === uniqueKeysInDimension.length
            }
          >
            {headers.map((header) => (
              <TableCellDimension key={header} />
            ))}
          </TableBodyRowRight>
        </TableHeaderRow>
      </div>
      {Boolean(inlineEditor.index?.toString()) && (
        <ValidateError validating={inlineEditor.validating} errorMessage={inlineEditor.errorMessage} />
      )}
    </>
  );
}

function getTooltipContent(title, description) {
  return (
    <div style={{ padding: 10, display: "flex", flexDirection: "column", gap: "5px" }}>
      <span style={{ color: "#fafafa", fontSize: "10px", fontWeight: "400", textTransform: "uppercase" }}>
        {title.replace(/[-_]/g, " ")}
      </span>
      <span style={{ color: "#fafafa", fontSize: "16px", fontWeight: "400", lineHeight: "22px" }}>{description}</span>
    </div>
  );
}

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 3px;
  border: 1px solid ${(props) => props.theme.palette.greyish[50]};
  border-bottom: 0px;
  font-size: 14px;
  letter-spacing: var(--default-letter-spacing);
  line-height: var(--default-line-height);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
`;

const TableHeaderRow = styled(TableRow)`
  font-size: 10px;
  background-color: ${(props) => props.theme.palette.greyish[10]};
  height: 40px;
  font-weight: 300;
  border-bottom: 1px solid ${(props) => props.theme.palette.greyish[50]};
  min-width: fit-content;
  text-transform: uppercase;
  position: sticky;
  top: 0;
  z-index: 3;
`;

const TableBodyRow = styled(TableRow)`
  height: 72px;
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.palette.greyish[50]};
  min-width: fit-content;
  &:hover {
    background-color: #f5f5f5;
  }
  &:hover > div:last-of-type > div.show-on-hover {
    display: block !important;
  }
`;

const TableHeaderRowLeft = styled(TableRowLeft)`
  background-color: ${({ theme }) => theme.palette.greyish[10]};
  border: none;
  border-right: 1px solid #858574;
`;

const TableBodyRowLeft = styled(TableRowLeft)`
  border-right: 1px solid ${(props) => props.theme.palette.greyish[50]};
`;

const TableBodyRowRight = styled(TableRowRight)`
  justify-content: space-between;
`;

const ModifiedIconButton = styled(IconButton)`
  height: 24px;
  width: 24px;
  margin-left: auto;
`;

export default connect(
  (state) => ({
    data: state.activityDialog.data,
    constraint: state.activityDialog.inlineEditor.constraint,
  }),
  {
    addModifyEditor,
  },
)(DisplaySavedConstraints);
