import _ from "lodash";

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "MEDIA_INSIGHTS_SELECT_PERIOD":
      return { ...state, period: action.period || INITIAL_STATE.period }; // Reset if no period specified
    case "MEDIA_INSIGHTS_SELECT_INTERVAL":
      return { ...state, interval: action.interval };
    case "MEDIA_INSIGHTS_SELECT_TIMELINE_METRIC":
      return { ...state, timelines: { ...state.timelines, [action.chartId]: action.newKpi } };
    case "MEDIA_INSIGHTS_FOCUS_TOGGLE":
      return { ...state, focusMode: !state.focusMode };
    case "MEDIA_INSIGHTS_FILTER_UPDATE": {
      const changes = { filter: action.filter, filterColor: action.color ?? state.filterColor };

      // Empty filter resets
      if (_.isEmpty(changes.filter)) {
        changes.groupBy = INITIAL_STATE.groupBy;
        changes.focusMode = INITIAL_STATE.focusMode;
      }

      return { ...state, ...changes };
    }
    case "MEDIA_INSIGHTS_FILTER_CLEAR_ALL": {
      const { filter, groupBy, filterColor } = INITIAL_STATE;
      return { ...state, filter, groupBy, filterColor, focusMode: false };
    }
    case "MEDIA_INSIGHTS_EXPANDED_KPIS_TOGGLE":
      return {
        ...state,
        expandedKpis: state.expandedKpis.includes(action.kpi)
          ? state.expandedKpis.filter((d) => d !== action.kpi)
          : [...state.expandedKpis, action.kpi].slice(0, 3), // Max 3 elements
      };
    case "MEDIA_INSIGHTS_EXPANDED_KPIS_SORT_BY":
      return {
        ...state,
        sortBy: action.sortBy,
        ascending: state.sortBy === action.sortBy ? !state.ascending : false, // Toggle ascending/descending
      };
    default:
      return state;
  }
}

const INITIAL_STATE = {
  period: undefined,
  interval: "day",
  focusMode: false,
  filter: {}, // or { publisher: ['nbc'] }
  timelines: { top: "effects_aggregated", bottom: "net_spend" },
  expandedKpis: [],
  sortBy: undefined, // column to use are reference for sorting, could be 'net_spend', 'effects_aggregated', etc
  ascending: false,
  filterColor: undefined, //to set the color of the filter to same of that of the grouping when drilling down
};
