import _ from "lodash";

export function getFilteringFunction(filter) {
  return (insertion) => {
    // Empty filters don't filter out
    if (_.isEmpty(filter)) return true;
    else
      return Object.keys(filter).every(
        (dimension) => insertion[dimension] && filter[dimension].includes(insertion[dimension]),
      );
  };
}

// In order to decide which options to show test drive adding the options to the filter
// This is enough to rule out incompatible options(for instance, competitor XYZ and publisher Facebook)
export default function filterOutUselessDimensionValues({ filter, uniqueDimensions }) {
  return (values, dimension) =>
    values.filter(
      (value) =>
        // I DON'T want that option to show if adding it would result in an empty selection
        uniqueDimensions.filter(
          getFilteringFunction({
            ...filter,
            [dimension]: _.uniq([...(filter[dimension] || []), value]),
          }),
        ).length > 0,
    );
}
