import React from "react";
import styled from "@emotion/styled";
import { produce } from "immer";
import EditorItem from ".";
import { Cell, Title, TableLayout } from "./layoutComponents";
import Button from "@mui/material/Button";
import DeleteIconButton from "shared/components/DeleteIconButton";
import Menu from "@mui/material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Fade from "@mui/material/Fade";
import Checkbox from "@mui/material/Checkbox";
import { InputAdornment, TextField } from "@mui/material";
import { useTheme } from "@mui/styles";

// Icons
import { ReactComponent as SearchIcon } from "shared/Icons/Magnifier.svg";
import { ReactComponent as CrossIcon } from "shared/Icons/crossGrey.svg";

export default function EditorItemForArrays(props) {
  if (props.schema.items?.enum)
    return <DropdownVariant {...props} extraStyles={{ height: 35, fontSize: 14, ...props.extraStyles }} />;
  const { schema, data = [], onChange, onNavigate, isDisabled } = props;
  const headers =
    schema.items?.ui__openSeparatellyWith || data.length === 0
      ? null
      : Object.entries(schema.items?.properties || {}).map(([key, props]) => (
          <Title key={key} extraStylesDiv={{ fontSize: 12 }} {...props} isUppercase={true} />
        ));

  if (!data.map) console.log("DATA .MAP", data, schema);
  return (
    <TableLayout columns={headers?.length || 1} extra={isDisabled ? undefined : 45}>
      {headers && headers.length > 0 && (
        <>
          {headers}
          {!isDisabled && <Cell />}
        </>
      )}

      {data.map((d, i) => (
        <React.Fragment key={i}>
          <EditorItem
            {...props}
            withinTable
            schema={schema.items}
            data={d}
            onNavigate={onNavigate}
            onChange={(localOnChange) =>
              onChange(
                produce((s) => {
                  if (s) s[i] = localOnChange(s[i]);
                }),
              )
            }
          />

          {!isDisabled && (
            <Cell style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              {!schema.ui__readOnly && (
                <DeleteIconButton
                  onClick={() => onChange((prev) => prev.filter((d, j) => i !== j))}
                  extraStyles={{ padding: 0 }}
                />
              )}
            </Cell>
          )}
        </React.Fragment>
      ))}

      {!props.isDisabled && !schema.ui__readOnly && (
        <div style={{ padding: "9px 15px", gridColumn: "1/-1" }}>
          <Button
            variant="outlined"
            disabled={
              schema.items.ui__uniqueKey && schema.items.properties[schema.items.ui__uniqueKey]?.enum.length === 0
            }
            color="primary"
            onClick={() => {
              return onChange((prev) => [...(prev || []), schema.items?.default]);
            }}
            style={{ height: 32 }}
          >
            + Add
          </Button>{" "}
          {data.length === 0 && (
            <span style={{ padding: "10px 12px", opacity: 0.5 }}>{`No ${
              schema?.title?.toLowerCase() || "items"
            }`}</span>
          )}
        </div>
      )}
    </TableLayout>
  );
}

function DropdownVariant({
  schema,
  data = [],
  onChange,
  isDisabled,
  showLabel = true,
  search = false,
  extraStyles = {},
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const options = schema.items.enum || [];
  const dataNotInOptions = data.filter((d) => !options.includes(d));
  const getLabel = (slug) => schema.items.ui__labels?.[slug] || slug;
  const disabled = isDisabled || schema.ui__readOnly || !onChange;

  const itemsToShow = [...options, ...dataNotInOptions]
    .filter((a) => a !== undefined && a !== null)
    .filter((a) => getLabel(a).toLowerCase().includes(searchText));
  const theme = useTheme();
  let label;
  if (data.length > 0) {
    label = `${data.length}/${schema.items?.enum?.length}`;
    if (showLabel) {
      label += ` · ${data.map(getLabel).join(", ")}`;
    }
  } else {
    label = schema.title ? (schema.title === "Excluded models" ? "No excluded models" : `${schema.title}`) : "items";
  }

  return (
    <>
      <Cell
        onClick={(e) => setAnchorEl(e.currentTarget)}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          padding: "10px 14px",
          ...extraStyles,
        }}
      >
        <span
          style={{
            width: "calc(100% - 20px)",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: data.length > 0 ? "#000" : theme.palette.greyish[50],
            fontWeight: anchorEl !== null && 700,
          }}
        >
          {label}
        </span>
      </Cell>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{ style: { marginTop: -6 } }}
        TransitionComponent={Fade}
      >
        <List
          dense
          disablePadding
          style={{ marginTop: -10, outline: "none" }}
          data-test-id={`adminDropdown${schema.title.replace(/\s/g, "")}`}
        >
          {search ? (
            <ListItem
              dense
              key="Search"
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                zIndex: 2,
                padding: 0,
              }}
            >
              <TextField
                key={"search"}
                sx={{ width: "100%" }}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchText === "" ? (
                        <SearchIcon />
                      ) : (
                        <CrossIcon onClick={() => setSearchText("")} style={{ cursor: "pointer" }} />
                      )}
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
                hiddenLabel
                id="user-search"
                variant="filled"
                placeholder="Search by org name"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value.toLowerCase())}
              />
            </ListItem>
          ) : (
            <ListItem
              key="all-items"
              style={{
                cursor: disabled ? "not-allowed" : "pointer",
                borderBottom: "1px solid #cacaca",
                color: "gray",
                background: "#fafafa",
                padding: "0 12px",
              }}
              onClick={
                !isDisabled && onChange
                  ? () => onChange(() => (data?.length > 0 ? [] : [...options, ...dataNotInOptions]))
                  : undefined
              }
            >
              <ListItemIconStyled>
                <CheckBoxStyled
                  edge="start"
                  disabled={disabled}
                  checked={data.length > 0}
                  tabIndex={-1}
                  indeterminate={data.length !== 0 && data.length !== schema.items.enum.length}
                  disableRipple
                  color="primary"
                />
              </ListItemIconStyled>
              <ListItemText primary={`All ${schema?.title?.toLowerCase() || "items"}`} />
            </ListItem>
          )}

          {itemsToShow.length === 0 && (
            <ListItemText primary="There are no options available." style={{ margin: 10 }} />
          )}

          <div style={{ maxHeight: 400, overflow: "auto" }} className="customScroll">
            {itemsToShow
              .sort((a, b) => getLabel(a).localeCompare(getLabel(b), undefined, { sensitivity: "base" }))
              .sort((a, b) => data.includes(b) - data.includes(a))
              .map((option) => (
                <ListItem
                  key={option || "unknown"}
                  style={{
                    cursor: disabled ? "not-allowed" : "pointer",
                    padding: "0 12px",
                  }}
                  disabled={disabled}
                  onClick={
                    !isDisabled && onChange
                      ? () =>
                          onChange(
                            // Keep the same order in the enum
                            () =>
                              [...options, ...dataNotInOptions].filter((d) =>
                                d === option ? !data.includes(d) : data.includes(d),
                              ),
                          )
                      : undefined
                  }
                >
                  <ListItemIconStyled>
                    <CheckBoxStyled
                      edge="start"
                      disabled={disabled}
                      checked={data?.includes(option)}
                      tabIndex={-1}
                      disableRipple
                      color="primary"
                    />
                  </ListItemIconStyled>
                  <ListItemText primary={getLabel(option)} />
                </ListItem>
              ))}
          </div>
        </List>
      </Menu>
    </>
  );
}

const ListItemIconStyled = styled(ListItemIcon)`
  min-width: 32px;
`;

const CheckBoxStyled = styled(Checkbox)`
  margin-left: 0;

  svg {
    flex-shrink: 0; //Icons are shrinking because of some inherited styles
  }
`;
