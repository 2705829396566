import React from "react";

const handlers = {};
function notifyHandlers(keys = Object.keys(handlers)) {
  keys.forEach((key) => {
    (handlers[key] || []).forEach((handler) => handler());
  });
}

// This behaves like window.sessionStorage but notifying handlers
export const sessionStorageSync = {
  getItem(key) {
    return sessionStorage.getItem(key);
  },

  setItem(key, value) {
    sessionStorage.setItem(key, value);
    notifyHandlers([key]);
  },

  removeItem(key) {
    sessionStorage.removeItem(key);
    notifyHandlers([key]);
  },

  clear() {
    Object.entries(sessionStorage).forEach(([key, val]) => {
      if (!exceptionSessionStorageKeys.includes(key)) delete sessionStorage[key];
    });
    notifyHandlers(); // Notify all
  },
};

const exceptionSessionStorageKeys = ["pendo_tabId", "msalToken", "msalUsername"];

// This hook behaves very similarly to useState
// but data is in sessionStorage and will reload if
// changed in a different component or imperativelly
export function useSessionStorageSync(key, initialValue = sessionStorage.getItem(key)) {
  const [proxy, setProxy] = React.useState(initialValue);

  React.useEffect(() => {
    if (initialValue) sessionStorageSync.setItem(key, initialValue);
    const handler = () => setProxy(sessionStorage.getItem(key));
    handlers[key] = [...(handlers[key] || []), handler];

    return () => {
      handlers[key] = handlers[key].filter((d) => d !== handler);
      if (handlers[key].length === 0) delete handlers[key];
    };
  }, [key, initialValue, setProxy]);

  return [proxy, (value) => sessionStorageSync.setItem(key, value)];
}
