import styled from "@emotion/styled/macro";

// A card is just a div but with certain visual props that
// we'd like to display uniformly throughout the app
const Card = styled.div`
  background-color: white;
  border-radius: 0px;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow-y: hidden;
`;

export default Card;
