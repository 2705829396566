function buildOptimizationParams({
  activityV2StateData,
  activityV2StateInlineEditor,
  objective,
  modelslug,
  uiModelInfo,
  useExperimentalOptimizer,
  tolerance,
  isAdding,
  extraConstraint,
  emailNotification,
}) {
  const dateFormatter = (d) => (typeof d === "string" ? d : d.format("YYYY-MM-DD"));
  const getConstraints = () => {
    const existingConstraints = [...activityV2StateData.constraints];

    if (extraConstraint) {
      if (isAdding) {
        const allConstraints = [...existingConstraints, extraConstraint];
        return allConstraints;
      } else {
        const finalConstraints = existingConstraints;
        finalConstraints[activityV2StateInlineEditor.index] = extraConstraint;
        return finalConstraints;
      }
    } else {
      return existingConstraints;
    }
  };

  const commonParams = {
    modelslug,
    objective,
    startDate: dateFormatter(activityV2StateData.startDate),
    endDate: dateFormatter(activityV2StateData.endDate),
    constraints: getConstraints().map((c) => ({
      ...c,
      startDate: dateFormatter(c.startDate),
      endDate: dateFormatter(c.endDate),
    })),
  };

  const baseParams = {
    useExperimentalOptimizer,
    extraData: {
      activityId: activityV2StateData.activityId,
      name: activityV2StateData.activityName,
      ...uiModelInfo,
    },
    emailNotification,
  };

  let optimizationParams = {};
  switch (activityV2StateData.selectedBrief) {
    case "budgetBased":
      optimizationParams = {
        ...baseParams,
        params: {
          ...commonParams,
          type: "budgetBased",
          budget: activityV2StateData.budget,
        },
      };
      break;

    case "targetBased":
      optimizationParams = {
        ...baseParams,
        params: {
          ...commonParams,
          type: "targetBased",
          target: activityV2StateData.target,
          maxBudget: activityV2StateData.budget,
          tolerance: tolerance,
        },
      };
      break;

    default:
      throw new Error("Unknown media brief " + activityV2StateData.selectedBrief);
  }

  return optimizationParams;
}

export default buildOptimizationParams;
