import React from "react";
import store from "store";
import { notifyNew } from "store/action-creators";
import useSWR, { mutate } from "swr";
import _ from "lodash";
import { readableStamp } from "admin/helpers";
import { getClaimsToken } from "auth";
import { produce } from "immer";
import { configurableKeys } from "./schemaModels";

export default function useConfVersions(organization) {
  const fetcher = (url, headers) => {
    return fetch(url, { headers }).then(async (res) =>
      res.ok ? res.json() : Promise.reject(new Error(await res.text())),
    );
  };
  const [conf, setLocalConf] = React.useState();
  const [saveInProgress, setSaveInProgress] = React.useState(false);
  const [takeToProdInProgress, setTakeToProdInProgress] = React.useState(false);
  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const { data } = useSWR(`/backend-api/admin/v1/organization-confs/${organization}`, (url) =>
    fetcher(url, { Authorization: `Bearer ${getClaimsToken()}` }),
  );
  const [prodVersion, draftVersion] = [data?.prod?.conf, data?.validation?.conf];
  const draftAndProdDiffer = isConfDifferent(prodVersion, draftVersion);
  const unsavedChanges = conf !== undefined && isConfDifferent(draftVersion, conf);
  const stopEditing = () => Promise.resolve(setLocalConf(undefined));
  // Allows to get any extra changes made by the server when saving
  const reloadDraft = ({ validation }) => setLocalConf(validation?.conf);
  return {
    name: data?.prod?.organizationName || organization,
    loading: !Boolean(data),
    saveInProgress,
    deleteInProgress,
    takeToProdInProgress,
    conf: conf || prodVersion,
    prodConf: prodVersion,
    onChange: conf && setLocalConf,
    hasPrevDraft: draftAndProdDiffer,
    prodUpdatedStamp: data?.prod?.metadata && readableStamp(data.prod.metadata.lastChanged, data.prod.metadata.author),
    draftUpdatedStamp:
      data?.validation?.metadata &&
      readableStamp(data.validation.metadata.lastChanged, data.validation.metadata.author),
    actions: {
      edit: conf === undefined ? () => Promise.resolve(setLocalConf(draftVersion)) : undefined,
      discardChanges: unsavedChanges ? () => Promise.resolve(setLocalConf(draftVersion)) : undefined,
      saveDraft: unsavedChanges
        ? (isImpactReset, internalSelectedModel) => {
            const saveConfVersion = produce(conf, (draftState) => {
              if (isImpactReset) {
                draftState.models[internalSelectedModel]["mediaInsightsPlus__impact_per_dimension_v1"] =
                  prodVersion.models[internalSelectedModel]["mediaInsightsPlus__impact_per_dimension_v1"];
              }
            });
            setSaveInProgress(true); // Set inProgress to true when saving starts
            return save({ organization, version: "validation", conf: saveConfVersion })
              .then(() => mutate(`/backend-api/admin/v1/organization-confs/${organization}`)) //revalidating the cache stored after post request
              .then(reloadDraft)
              .finally(() => setSaveInProgress(false)); // Set inProgress to false when saving completes
          }
        : undefined,
      deleteDraft: draftAndProdDiffer
        ? () => {
            setDeleteInProgress(true);
            return save({ organization, version: "validation", conf: removeModelKeysNotInProd(prodVersion, conf) })
              .then(() => mutate(`/backend-api/admin/v1/organization-confs/${organization}`)) //revalidating the cache stored after post request
              .then(stopEditing)
              .finally(() => setDeleteInProgress(false));
          }
        : undefined,
      takeToProd:
        !unsavedChanges && draftAndProdDiffer
          ? () => {
              setTakeToProdInProgress(true);
              return save({ organization, version: "prod", conf: draftVersion })
                .then(() => mutate(`/backend-api/admin/v1/organization-confs/${organization}`)) //revalidating the cache stored after post request
                .then(stopEditing)
                .finally(() => setTakeToProdInProgress(false));
            }
          : undefined,
      seeProd: conf !== undefined && !unsavedChanges ? stopEditing : undefined,
    },
  };
}

function save({ organization, version, conf }) {
  console.info(`${organization}: Saving configuration to ${version} version...`);

  return mutate(`/backend-api/admin/v1/organization-confs/${organization}`, ({ prod, validation }) =>
    fetch(`/backend-api/admin/v1/organization-confs/${organization}/${version}`, {
      method: "POST",
      body: JSON.stringify(conf),
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${getClaimsToken()}` },
    })
      .then(async (res) => (res.ok ? res.text() : Promise.reject(new Error(await res.text()))))
      .then(() => {
        mutate("/backend-api/auth/v2/user/get-organizations");
      }) // Will trigger a revalidation of the list
      .then(() => {
        return {
          prod:
            version === "prod"
              ? {
                  ...prod,
                  conf: conf,
                }
              : prod,
          validation:
            version === "validation"
              ? {
                  ...validation,
                  conf: conf,
                }
              : validation,
        };
      })
      .catch((err) => {
        console.error(err);
        store.dispatch(notifyNew({ message: `Failed to save configuration to ${version} version`, isError: true }));
        throw new Error(err);
      }),
  );
}

function isConfDifferent(confA, confB) {
  if (!confA || !confB) return false;
  return !_.isEqual(excludeExternallyUpdatedAttributes(confA), excludeExternallyUpdatedAttributes(confB));
}

function excludeExternallyUpdatedAttributes(conf) {
  const confCopy = JSON.parse(JSON.stringify(conf));

  Object.keys(confCopy.models).forEach((modelSlug) => {
    delete confCopy.models[modelSlug].brand; // Neo
    delete confCopy.models[modelSlug].name; // Neo
    delete confCopy.models[modelSlug].createdAt; // Neo
    delete confCopy.models[modelSlug].updatedAt; // Neo
    delete confCopy.models[modelSlug].market; // Neo
    delete confCopy.models[modelSlug].objectiveUnitName; // Neo
    delete confCopy.models[modelSlug].socketSlug; // Neo
    delete confCopy.models[modelSlug].type; // Neo
    delete confCopy.models[modelSlug].weekly; // Neo

    delete confCopy.models[modelSlug].modelFwModelId; // Modelling Framework
    delete confCopy.models[modelSlug].modelFwVersion_prod; // Modelling Framework
    delete confCopy.models[modelSlug].modelFwVersion_validation; // Modelling Framework

    // Clean up in progress
    delete confCopy.models[modelSlug].slug;
    delete confCopy.models[modelSlug].version_prod;
    delete confCopy.models[modelSlug].version_validation;
  });

  delete confCopy.organization; // Neo
  delete confCopy.modelFwOrgId; // Modelling Framework

  // Clean up in progress
  delete confCopy.users;

  return confCopy;
}
function removeModelKeysNotInProd(prodConf, conf) {
  /*
    * What does it do?
    ** This function will mark keys null for deletion in backend from the draft configuration for each model that are not present in the prod configuration while we are deleting a draft. 
    ** IT DOES NOT ACTUALLY DELETE THE KEYS just marks them null for deletion in backend.

    * Why do we need this only at the time of deletion?
    ** Say a key was not in prod conf for model when we started the draft like mediaInsightsPlus__metrics_v1 but when you have added a value for this key the key is inserted in draft and if you don't take it to production it will never be in prod.
    ** When we delete draft we send the prod conf to make draft and prod conf same but how the logic in backend is written it only replaces the draft values with prod values, but does not delete the keys that are not in prod.
    ** So it result in always having a difference in draft and prod. It will appear as such that draft is not deleting, there will always be an open draft button on UI even after clicking delete.
  */

  const prodConfCopy = structuredClone(prodConf);
  const modelKeysToConsiderForDeletion = configurableKeys;

  Object.keys(prodConfCopy?.models ?? {}).forEach((model) => {
    const keysNotInProd = _.difference(
      Object.keys(conf?.models?.[model] ?? {}),
      Object.keys(prodConfCopy.models[model]),
    );

    const keysMarkedForDeletion = _.intersection(keysNotInProd, modelKeysToConsiderForDeletion) ?? [];
    keysMarkedForDeletion.forEach((key) => (prodConfCopy.models[model][key] = null));
  });
  return prodConfCopy;
}
