import React from "react";
import { useAccount } from "auth";
import useLongPollingPrediction from "shared/hooks/useLongPollingPrediction";
import "./blink.css";

const badgeStyle = {
  common: {
    display: "inline-block",
    margin: "0px 8px",
    width: 10,
    height: 10,
    borderRadius: "50%",
  },
  loading: {
    backgroundColor: "gray",
  },
  notify: {
    backgroundColor: "#28CEC0",
  },
};

export default function NewPredictionBadge({ predictionStep, style = {} }) {
  const { selectedModelConf: modelConf } = useAccount();
  const { socketSlug } = modelConf;
  const { result, extraData } = useLongPollingPrediction(socketSlug);

  if (result?.status === "inProgress")
    return (
      <div
        data-ref="prediction-in-progress"
        className="new-prediction-badge-blink"
        style={{ ...badgeStyle.common, ...badgeStyle.loading, ...style }}
      />
    );
  else if (extraData && !extraData.hasBeenSeen)
    return <div style={{ ...badgeStyle.common, ...badgeStyle.notify, ...style }} />;

  return null;
}
