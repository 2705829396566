/*
    The output generated in simulation is according to the current model configuration from admin panel. 
    It depend on three things
        1. Media Groupings - In input/output the variables are grouped by media groupings.
        2. Non Media Groupings (a.k.a: business groupings stored as `driverCustom` ) - In input/output the variables are grouped 
            by non media groupings and which dimensions to show to user for each grouping.
        3. Visible impact per dimension - Which media dimensions to show to user in input and output.

    A lot of logic depends on this state so we save the current snapshot while running the simulation, and in output we only use that,
     but there can be cases when after running the simulation the configuration can be changed in that case the plan should be marked as 
     no longer supported and functions like modify plan should not be allowed. This should also be done when comparing two plans in 
     activity tab and both ran with different configurations. Modify should not be enabled in any case if the configuration is changed.

    The same happens in optimisation as well see the hook `usev4InvPlanStatus` in `useV4InvPlanData.js`
  */

import { useAccount } from "auth";
import useLongPollingSimulator from "./useLongPollingSimulator";
import checkFilterEquality from "shared/helpers/checkFilterEquality";
import getImpactTypeForModel from "shared/helpers/getImpactTypeForModel";
import checkArrayEquality from "shared/helpers/checkArrayEquality";

export function useSimulationSupportedStatus() {
  //this hook to be used only in the prediction tab output page to disable modify button, for disabling comparison in activity tab use the below utility function which compares the extraData(contains all three keys described above) of two plans.

  const { selectedModel: modelSlug, selectedModelConf, selectedModelType } = useAccount();

  const { data } = useLongPollingSimulator(modelSlug);

  const { extraData } = data;
  if (!extraData) {
    return { noLongerSupported: false };
  }

  const { driverCustom, mediaGroupings, mediaInsightsPlus__impact_per_dimension_v1 } = selectedModelConf;

  return {
    noLongerSupported: areTwoSimulatorConfigDifferent({
      config1: extraData,
      config2: { driverCustom, mediaGroupings, mediaInsightsPlus__impact_per_dimension_v1 },
      modelType: selectedModelType,
    }),
  };
}

export function areTwoSimulatorConfigDifferent({ config1, config2, modelType }) {
  try {
    //compare two media groupings
    if (areTwoMediaGroupingConfigDifferent(config1.mediaGroupings, config2.mediaGroupings)) {
      return true;
    }
    //compare two driverCustom
    if (areTwoDriverCustomConfigDifferent(config1.driverCustom, config2.driverCustom)) {
      return true;
    }
    //compare two impact per dimension
    if (
      areTwoImpactPerDimensionConfigDifferent(
        config1.mediaInsightsPlus__impact_per_dimension_v1,
        config2.mediaInsightsPlus__impact_per_dimension_v1,
        modelType,
      )
    ) {
      return true;
    }
    return false;
  } catch (e) {
    console.error("Failed to compare simulator config", e);
    return true;
  }
}

function areTwoMediaGroupingConfigDifferent(mediaGrouping1, mediaGrouping2) {
  if (!mediaGrouping1 || !mediaGrouping2) {
    return true;
  }
  //compare two media groupings and return true if different
  if (mediaGrouping1.length !== mediaGrouping2.length) {
    return true;
  }
  for (const grouping1 of mediaGrouping1) {
    const grouping2 = mediaGrouping2.find((m) => m.slug === grouping1.slug);

    if (!grouping2) {
      // if the slug not found in mediaGrouping2 then it is changed and they are considered different
      return true;
    }

    if (!checkFilterEquality(grouping1.filter, grouping2.filter)) {
      // if the filter is different then they are considered different
      return true;
    }
  }

  //if every key is same then it is same, return false
  return false;
}

function areTwoDriverCustomConfigDifferent(driverCustom1, driverCustom2) {
  if (!driverCustom1 || !driverCustom2) {
    return true;
  }
  //compare two driverCustom and return true if different
  if (driverCustom1.length !== driverCustom2.length) {
    return true;
  }
  for (const driver1 of driverCustom1) {
    const driver2 = driverCustom2.find((m) => m.slug === driver1.slug);

    if (!driver2) {
      // if the slug not found in driverCustom2 then it is changed and they are considered different
      return true;
    }

    if (!checkFilterEquality(driver1.filter, driver2.filter)) {
      // if the filter is different then they are considered different
      return true;
    }
    if (!checkArrayEquality(driver1.dimension, driver2.dimension)) {
      // if the dimension is different then they are considered different
      return true;
    }
  }

  //if every key is same then it is same, return false
  return false;
}

function areTwoImpactPerDimensionConfigDifferent(impactPerDimension1, impactPerDimension2, modelType) {
  if (!impactPerDimension1 || !impactPerDimension2) {
    return true;
  }
  //compare two impact per dimension config
  const impact = getImpactTypeForModel(modelType);
  const availableDimensions1 = impactPerDimension1?.[impact];
  const availableDimensions2 = impactPerDimension2?.[impact];

  return !checkArrayEquality(availableDimensions1, availableDimensions2);
}
