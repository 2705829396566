import backend from "backend";

export default function useBroadcastMessages() {
  const { data: broadcastItems = [], loading, mutate } = backend.useData("v1/broadcasts");

  const latestBroadcast = broadcastItems.find((item) => item.hasBeenSeen === false);
  const isNewBroadcastAvailable = Boolean(latestBroadcast);

  return { broadcastItems, loading, mutate, isNewBroadcastAvailable, latestBroadcast };
}

export const EXTRA_PADDING_FOR_BROADCAST = 40;
