import { Icon, Popover } from "@mui/material";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import { styled, useTheme } from "@mui/material/styles";
import { logout, useAccount } from "auth";
import { useOrgData } from "auth/useOrgData";
import { navigateToAvailableFeature } from "autoRedirect";
import _ from "lodash";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import HighlightText from "shared/components/HighlightText";
import SearchBar from "shared/components/SearchBar";
import { maxChars } from "shared/helpers/formatters";
import { sessionStorageSync } from "shared/helpers/sessionStorageSync";

import { ReactComponent as ArrowUpBlack } from "../../../shared/Icons/chevron-black.svg";
import { ReactComponent as ArrowDown } from "../../../shared/Icons/chevron-down-white.svg";

const MAX_CHAR_LENGTH = 40;
export default function ModelMenu() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { selectedModel, conf, onSelectModel, isFeatureAvailable } = useAccount();
  const { data: orgData = null } = useOrgData({ token: sessionStorageSync.getItem("msalToken") });
  const availableModels = Object.entries(conf?.models || {}).map(([modelSlug, { name, brand, id }]) => ({
    modelSlug,
    name,
    brand,
    id,
  }));
  const darkHeader = useTheme().extraData?.darkHeader;
  const orgList = orgData?.accessibleOrgs || [];
  const selectedOrg = JSON.parse(sessionStorage.getItem("selectedOrg"));
  const colorToUse = darkHeader ? "white" : "black";

  return (
    <>
      <Button
        aria-haspopup="true"
        sx={{
          textTransform: "none",
          fontWeight: "inherit",
          color: colorToUse,
          padding: 0,
          fontSize: "16px",
          height: "24px",
          display: "flex",
          alignItems: "center",
        }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <Text>
          <EllipsisLabel>
            {`${availableModels.find((d) => d.modelSlug === selectedModel)?.brand} ${availableModels.find((d) => d.modelSlug === selectedModel)?.name}`}
          </EllipsisLabel>
          <StyledIcon>
            {isFeatureAvailable("BW7 theme") ? (
              <ArrowUpBlack
                height={20}
                width={20}
                style={{ transform: Boolean(anchorEl) ? "none" : "rotate(180deg)" }}
              />
            ) : (
              <ArrowDown
                height={20}
                width={20}
                style={{
                  transform: Boolean(anchorEl) ? "rotate(180deg)" : "none",
                  transition: "transform 0.3s ease-in-out",
                }}
              />
            )}
          </StyledIcon>
        </Text>
      </Button>

      <Popover
        id="popover-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        autoFocus={false}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        PaperProps={{
          style: { marginTop: 4, minWidth: 240 },
        }}
        TransitionComponent={Fade}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {selectedOrg && (
          <>
            <OrgLabel disabled={true}>{selectedOrg?.orgName}</OrgLabel>
            {availableModels.length > 8 && (
              <SearchBar value={searchText} onChange={setSearchText} placeholder="Search a model" />
            )}
          </>
        )}

        <div className="customScroll" style={{ maxHeight: 28 * 10 + "px", overflowY: "auto" }}>
          {searchText?.length
            ? _.sortBy(
                availableModels.filter((item) => {
                  const combinedString = (item?.brand + " " + item?.name).toLowerCase();
                  return (
                    combinedString.includes(searchText.toLowerCase()) &&
                    searchText.trimStart().length === searchText.length
                  );
                }),
                ["brand", "name"],
              )?.map(({ modelSlug, brand, name, id: modelId }) => (
                <ModelMenuItem
                  key={modelSlug}
                  onClick={() => {
                    if (selectedModel !== modelSlug) {
                      onSelectModel(modelSlug);
                      navigateToAvailableFeature(
                        (feature) => isFeatureAvailable(feature, modelSlug),
                        navigate,
                        searchParams,
                        modelId,
                        window.location.pathname,
                      );
                    }
                    setAnchorEl(null);
                  }}
                  sx={{
                    color: modelSlug === selectedModel ? "white" : "inherit",
                    backgroundColor: modelSlug === selectedModel ? "primary.main" : "inherit",
                    "&:hover": {
                      backgroundColor: modelSlug !== selectedModel ? theme.palette.greyish[10] : "primary.main",
                    },
                  }}
                >
                  {HighlightText(maxChars(brand + " " + name, MAX_CHAR_LENGTH), searchText)}
                </ModelMenuItem>
              ))
            : _.sortBy(availableModels, ["brand", "name"]).map(({ modelSlug, brand, name, id: modelId }) => (
                <ModelMenuItem
                  key={modelSlug}
                  tabIndex={-1}
                  onClick={() => {
                    if (selectedModel !== modelSlug) {
                      onSelectModel(modelSlug);
                      navigateToAvailableFeature(
                        (feature) => isFeatureAvailable(feature, modelSlug),
                        navigate,
                        searchParams,
                        modelId,
                        window.location.pathname,
                      );
                    }
                    setAnchorEl(null);
                  }}
                  sx={{
                    color: modelSlug === selectedModel ? "white" : "inherit",
                    backgroundColor: modelSlug === selectedModel ? "primary.main" : "inherit",
                    "&:hover": {
                      backgroundColor: modelSlug !== selectedModel ? theme.palette.greyish[10] : "primary.main",
                    },
                  }}
                >
                  {maxChars(brand + " " + name, MAX_CHAR_LENGTH)}
                </ModelMenuItem>
              ))}
        </div>
        {orgList && orgList.length > 1 && (
          <ChangeOrg
            disableRipple={true}
            onClick={() => {
              setAnchorEl(null);
              logout("User action to change org.");
              navigate("/");
            }}
          >
            ← Select a different organization
          </ChangeOrg>
        )}
      </Popover>
    </>
  );
}

const ModelMenuItem = styled(MenuItem)`
  min-width: 240px;
  padding: 8px;
  font-size: 14px;
  height: 34px;
`;
const OrgLabel = styled(MenuItem)`
  && {
    color: ${({ theme }) => theme.palette.greyish[50]};
    font-size: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.greyish[20]};
    background-color: ${({ theme }) => theme.palette.whiteish.main};
    padding: 8px;
    white-space: normal;
    line-height: var(--default-line-height);
    opacity: 1; //override the mui disabled opacity
  }
`;

const ChangeOrg = styled("div")(
  ({ theme }) => `
  color:${theme.palette.primary.main};
  font-size: 12px;
  &:hover {
    text-decoration: underline;
    background-color: white;
  }
  position: sticky;
  bottom: 0;
  background: #fff;
  padding: 8px 10px;
  height: 31px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  font-size: 12px;
`,
);

const Text = styled("div")`
  display: flex;
  width: -webkit-fill-available;
  gap: 5px;
`;

const EllipsisLabel = styled("div")`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledIcon = styled(Icon)`
  display: flex;
  height: 20px;
  width: 20px;
`;
