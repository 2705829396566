import styled from "@emotion/styled/macro";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { readableStamp } from "admin/helpers";
import { produce } from "immer";
import { NewSwitch } from "shared/components/NewSwitch";
import EditorItemForArrays from "./EditorItemForArrays";
import EditorItemForArraysV2 from "./EditorItemForArrays-v2";
import EditorItemForObjects from "./EditorItemForObjects";
import EditorItemForText from "./EditorItemForText";

// Important: We're using curried producers here https://immerjs.github.io/immer/docs/curried-produce

export default function EditorItem(props) {
  const {
    schema,
    data,
    onChange, // Needs to be passed a function where the first argument is previous state
    isDisabled,
  } = props;
  // Custom UI components
  if (schema.ui__component) return schema.ui__component(props);

  switch (schema.type) {
    case "timestamp":
      return <DisabledCell>{readableStamp(data)}</DisabledCell>;
    case "boolean":
      return schema.ui__readOnly ? (
        <DisabledCell>{data ? "Yes" : "No"}</DisabledCell>
      ) : (
        <Centered>
          <NewSwitch
            checked={data || false}
            onChange={(e) => onChange(produce(() => e))}
            disabled={isDisabled || schema.ui__readOnly}
            data-test-id={`adminSwitch${schema.title?.replace(/\s/g, "")}`}
          />
        </Centered>
      );

    case "integer":
    case "string":
      if (schema.enum) {
        return isDisabled || schema.ui__readOnly ? (
          <DisabledCell style={{ height: "35px" }}>{schema.ui__labels?.[data] || data || "None"}</DisabledCell>
        ) : (
          <div>
            <Select
              variant="standard"
              value={data === undefined ? "" : data}
              onChange={(e) => onChange(() => (e.target.value === null ? undefined : e.target.value))}
              style={{
                padding: "10px 0 10px 15px",
                width: "100%",
                height: 34,
                fontSize: "inherit",
                fontFamily: schema.ui__isSlug ? "Inconsolata, monospace" : undefined,
              }}
              disableUnderline
              displayEmpty
              MenuProps={{ className: `adminSelect${schema.title?.replace(/\s/g, "")}` }}
            >
              {data === undefined && schema.required && (
                <MenuItem value="">
                  <em style={{ opacity: 0.5 }}>Select {schema.title || ""}</em>
                </MenuItem>
              )}
              {schema.enum.map((d) => (
                <MenuItem
                  key={d || "none"}
                  value={d}
                  sx={{ fontFamily: schema.ui__isSlug ? "Inconsolata, monospace" : undefined }}
                >
                  {schema.ui__labels?.[d] || d || "None"}
                </MenuItem>
              ))}
            </Select>
          </div>
        );
      } else {
        return <EditorItemForText {...props} />;
      }

    case "array":
      return <EditorItemForArrays {...props} />;
    case "array-v2":
      // This is new version of the editor item dropdown in the user management.
      // Have created a diff version for future use in other instances
      // Previous version remains unaffected
      return <EditorItemForArraysV2 {...props} />;
    case "object":
      return <EditorItemForObjects {...props} />;

    default:
      console.error("Unknown type", schema.type);
  }

  return null;
}

const DisabledCell = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 15px;
  align-items: center;
  cursor: not-allowed;
  font-size: 14px;
`;

const Centered = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
