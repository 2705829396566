import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectMediaInputData, selectNonMediaInputData } from "store/reducers/simulator";
import store from "store";
import { notifyNew } from "store/action-creators";
import { dateToString } from "shared/helpers/time";
import { subYears } from "date-fns";

// TODO: Need to write atleast two unit tests for all this utility functions
export function groupMediaInputData(inputData, availableMediaDimensions, opts = {}) {
  if (!inputData) {
    return {};
  }
  //opts: {sort: "asc"} => to sort the created groups in ascending order
  let groupedMediaInputData = {};
  const insertionsGrouped = groupAndMerge(inputData, availableMediaDimensions, { sumBaseline: true }).map((entry) => {
    entry.percentageChange = 0;
    return entry;
  }); //hide certain dimensions and then club duplicate insertions together
  for (const insertion of insertionsGrouped) {
    const groupKey = insertion?.["media-grouping"];
    if (groupKey !== undefined) {
      if (!groupedMediaInputData.hasOwnProperty(groupKey)) {
        groupedMediaInputData[groupKey] = { baseline: 0, children: {}, percentageChange: 0 };
      }
      groupedMediaInputData[groupKey].baseline += insertion.baseline;
      groupedMediaInputData[groupKey].scenario += insertion.baseline;
      groupedMediaInputData[groupKey].children[serialisekey(insertion.id)] = insertion;
    }
  }

  if (opts.sort) {
    const sorted = _.toPairs(groupedMediaInputData).sort(([key1, value1], [key2, value2]) => {
      const delta = value1.baseline - value2.baseline;
      return opts.sort === "desc" ? delta : -1 * delta;
    });
    groupedMediaInputData = _.fromPairs(sorted);
  }
  return groupedMediaInputData;
}

function serialisekey(key) {
  return JSON.stringify(key);
}
function deserialiseKey(key) {
  return JSON.parse(key);
}

/**
 * Groups non-media input data based on driver-custom values.
 * @param {Array} inputData - The input data to be grouped. Each entry must have an id.
 * @param {Array} driverCustom - The custom driver values.
 * @returns {Object} - The grouped non-media input data.
 */
export function groupNonMediaInputData(inputData, driverCustom = []) {
  if (!inputData) {
    return {};
  }
  const buckets = getNonMediaBuckets(driverCustom);
  let groupedNonMediaInputData = {};
  for (const [k, v] of buckets) {
    const driverCustomMp = {};
    for (const driver of v) {
      driverCustomMp[driver] = {
        percentageChange: 0,
        children: {},
      };
    }

    groupedNonMediaInputData[k] = driverCustomMp;
  }
  for (const [bucketDimensions, groupingMp] of Object.entries(groupedNonMediaInputData)) {
    for (const [grouping, groupingObj] of Object.entries(groupingMp)) {
      const matchingEntries = inputData.filter((entry) => {
        if (entry["driver-custom"] === null) {
          //we have to club all null under a single group named "__drivers_not_available__" shown in UI as "Other Business Drivers"
          return grouping === "__drivers_not_available__";
        }
        return entry["driver-custom"] === grouping;
      });
      if (matchingEntries?.length > 0) {
        const matchingEntriesGrouped = groupAndMerge(matchingEntries, bucketDimensions.split(","));
        for (const entry of matchingEntriesGrouped) {
          groupingObj.children[entry.id] = { ...entry, percentageChange: 0 };
        }
      }
    }
  }

  return groupedNonMediaInputData;
}

function getNonMediaBuckets(driverCustom) {
  //group the drivers into buckets based on the available dimensions configured in busienss groupings.
  const buckets = new Map();
  for (const driver of driverCustom) {
    if (driver.slug !== "paid-media") {
      const availableDimension = (driver.dimension?.sort() ?? []).join(",");
      if (buckets.has(availableDimension)) {
        if (!buckets.get(availableDimension).includes(driver.slug)) {
          buckets.get(availableDimension).push(driver.slug);
        }
      } else {
        buckets.set(availableDimension, [driver.slug]);
      }
    }
  }
  //we have to club all null under a single group named "__drivers_not_available__" with no drilldown shown in UI as "Other Business Drivers"
  buckets.set("", [...(buckets.get("") ?? []), "__drivers_not_available__"]);
  return buckets;
}

function groupAndMerge(objectsArray, dimensionsToShow, opts = {}) {
  //we only show some dimensions to user and hide others but after hiding certain dimensions the insertions may not be unique so we need to club those together by merging their ids in an array.
  const createGroupKey = (obj) => dimensionsToShow.map((key) => obj[key]).join("-");

  let grouped = _.groupBy(objectsArray, createGroupKey);

  let result = _.map(grouped, (group) => {
    let mergedObject = _.pick(group[0], dimensionsToShow);
    if (opts.nestedIds) {
      mergedObject.id = _.map(group, "id");
    } else {
      mergedObject.id = _.flatten(_.map(group, "id"));
    }

    if (opts.sumBaseline) {
      mergedObject.baseline = _.sumBy(group, "baseline");
    }
    return mergedObject;
  });
  return result;
}

export function calculateMediaParentPercentageChange(children = {}) {
  let totalBaseline = 0;
  let totalScenario = 0;
  for (const child of Object.values(children)) {
    totalBaseline += child.baseline;
    totalScenario += child.baseline * (1 + child.percentageChange / 100);
  }

  return {
    totalBaseline,
    percentageChange: totalBaseline === 0 ? 0 : ((totalScenario - totalBaseline) / totalBaseline) * 100,
  };
}

export function useMediaVariablesCount() {
  const mediaInputData = useSelector(selectMediaInputData);
  return useMemo(() => {
    return getMediaVariablesCount(mediaInputData);
  }, [mediaInputData]);
}

export function getMediaVariablesCount(mediaInputData = {}) {
  let mediaVariablesCount = 0;
  let mediaVariablesChangedCount = 0;
  for (const [, data] of Object.entries(mediaInputData)) {
    for (const [key, value] of Object.entries(data.children)) {
      let count = value.__group__ ? deserialiseKey(key).length : 1;
      mediaVariablesCount += count;
      if (!isPercentageChangeZero(value.percentageChange) && value.baseline !== 0) {
        //we can't change scenario for 0 baseline so they shouldn't be counted as changed variables
        mediaVariablesChangedCount += count;
      }
    }
  }
  return { mediaVariablesCount, mediaVariablesChangedCount };
}
export function useNonMediaVariablesCount() {
  const nonMediaInputData = useSelector(selectNonMediaInputData);
  return useMemo(() => {
    return getNonMediaVariablesCount(nonMediaInputData);
  }, [nonMediaInputData]);
}

export function getNonMediaVariablesCount(nonMediaInputData = {}) {
  let nonMediaVariablesCount = 0;
  let nonMediaVariablesChangedCount = 0;
  for (const [, bucketData] of Object.entries(nonMediaInputData)) {
    for (const [, groupingData] of Object.entries(bucketData)) {
      for (const [, value] of Object.entries(groupingData.children)) {
        nonMediaVariablesCount++;
        if (!isPercentageChangeZero(value.percentageChange)) {
          nonMediaVariablesChangedCount++;
        }
      }
    }
  }
  return { nonMediaVariablesCount, nonMediaVariablesChangedCount };
}

function isPercentageChangeZero(value = 0) {
  return Math.abs(value).toFixed(3) === (0).toFixed(3);
}

export function useMediaTotalValues() {
  const mediaInputData = useSelector(selectMediaInputData);
  return useMemo(() => {
    return calculateTotalMediaScenario(mediaInputData);
  }, [mediaInputData]);
}

export function calculateTotalMediaScenario(mediaInputData = {}) {
  let totalBaseline = 0;
  let totalScenario = 0;

  for (const [, data] of Object.entries(mediaInputData)) {
    totalBaseline += data.baseline;
    totalScenario += data.baseline * (1 + data.percentageChange / 100);
  }
  return { totalBaseline, totalScenario };
}

export function groupLevelInputDisabled(children = []) {
  if (children.length === 0) {
    return true;
  }
  const firstPercentageChange = children[0].percentageChange;
  return children.some((child) => child.percentageChange?.toFixed(2) !== firstPercentageChange?.toFixed(2));
}
export function groupLevelInputChanged(children = []) {
  if (children.length === 0) {
    return false;
  }
  return children.some((child) => !isPercentageChangeZero(child.percentageChange));
}

export function countMediaGroupingChildrenChanged(children = {}) {
  let variableCount = 0;
  let changedVariableCount = 0;
  for (const [key, value] of Object.entries(children)) {
    let count = value.__group__ ? deserialiseKey(key).length : 1;
    variableCount += count;
    if (!isPercentageChangeZero(value.percentageChange)) {
      changedVariableCount += count;
    }
  }
  return { variableCount, changedVariableCount };
}

export function calculateNewGroupBaseline(groupingInsertions = [], filter = {}) {
  const insertionsClubbedWithFilter =
    groupAndMerge(groupingInsertions, Object.keys(filter), { sumBaseline: true }) ?? [];

  let totalBaseline = 0;
  for (const entry of insertionsClubbedWithFilter) {
    if (_.isMatch(entry, filter)) {
      totalBaseline += entry.baseline;
    }
  }
  return totalBaseline;
}

export function isOverlapWithExistingGroups(
  groupingInsertions = [],
  availableMediaDimensions = [],
  filter = {},
  children = {},
) {
  const insertionsGrouped = groupAndMerge(groupingInsertions, availableMediaDimensions, { sumBaseline: true }) ?? [];

  const matchingInsertions = insertionsGrouped.filter((entry) => _.isMatch(entry, filter));

  //check if any exisiting group have the same ids which we have
  for (const [key, value] of Object.entries(children)) {
    if (value.__group__) {
      const idsInGroup = _.flattenDeep(deserialiseKey(key));
      if (matchingInsertions.some((entry) => _.intersection(entry.id, idsInGroup)?.length > 0)) {
        return true;
      }
    }
  }
  return false;
}

export function insertCustomGroupInChildren(
  groupingInsertions,
  availableMediaDimensions = [],
  filter = {},
  children = {},
  percentageChange = 0,
  editId,
) {
  try {
    const insertionsGrouped = groupAndMerge(groupingInsertions, availableMediaDimensions, { sumBaseline: true }) ?? [];

    const matchingInsertions = insertionsGrouped.filter((entry) => _.isMatch(entry, filter));
    const notMatchingInsertions = insertionsGrouped.filter((entry) => !_.isMatch(entry, filter));
    // club matchingInsertions into one object, this will be our group, with a single object in array
    const mergedMatchingInsertions =
      groupAndMerge(matchingInsertions, Object.keys(filter), { sumBaseline: true, nestedIds: true }) ?? [];
    const newChildren = {};
    let insertionIdsInGroups = []; // all insertions ids which are in any of the custom groups except the one which is being edited

    for (const [key, child] of Object.entries(children)) {
      // copy all the earlier created groups except the one which is being edited.
      if (child.__group__) {
        if (key !== editId) {
          newChildren[key] = child;
          insertionIdsInGroups = [...insertionIdsInGroups, ..._.flattenDeep(deserialiseKey(key))];
        }
      }
    }

    for (const entry of notMatchingInsertions) {
      const key = serialisekey(entry.id);
      if (children[key]) {
        newChildren[key] = children[key];
      } else if (_.intersection(_.flattenDeep(entry.id), insertionIdsInGroups).length === 0) {
        //checking if the insertion is not already in any of the existing groups
        // some insertions can be removed from edited groups and need to be added again
        newChildren[key] = { ...entry, percentageChange: 0 };
      }
    }

    // if no insertion is matching then return newChildren as it is
    if (mergedMatchingInsertions.length === 0) {
      return newChildren;
    }

    //insert the new group in the newChildren
    const newGroupKey = serialisekey(mergedMatchingInsertions[0].id);
    newChildren[newGroupKey] = {
      ...mergedMatchingInsertions[0],
      percentageChange,
      __group__: true,
    };

    return newChildren;
  } catch {
    return children;
  }
}

export function mapMediaStateToInsertions(apiMediaInputData = [], mediaInputData = {}, opts = { omitId: true }) {
  //creating a lookup object for fast lookup of percentage change against id
  const percentageChangeLookup = {};
  for (const [, value] of Object.entries(mediaInputData)) {
    for (const [key, entry] of Object.entries(value.children)) {
      const ids = _.flattenDeep(deserialiseKey(key));
      for (const id of ids) {
        percentageChangeLookup[id] = entry.percentageChange;
      }
    }
  }

  return apiMediaInputData.map((entry) => {
    const entryCopy = { ...entry };
    entryCopy.percentageChange = percentageChangeLookup[entryCopy.id] ?? 0;
    return opts.omitId ? _.omit(entryCopy, "id") : entryCopy;
  });
}

export function mapNonMediaStateToInsertions(apiNonMediaInputData, nonMediaInputData, opts = { omitId: true }) {
  //creating a lookup object for fast lookup of percentage change against id
  const percentageChangeLookup = {};
  for (const [, bucketData] of Object.entries(nonMediaInputData)) {
    for (const [, groupingData] of Object.entries(bucketData)) {
      for (const [key, entry] of Object.entries(groupingData.children)) {
        //TODO: can we use same logic for serialise and deserialise for media and non media
        const ids = _.flattenDeep(key.split(",")); //key is a string of comma separated values in case of non media
        for (const id of ids) {
          percentageChangeLookup[id] = entry.percentageChange;
        }
      }
    }
  }

  return apiNonMediaInputData.map((entry) => {
    const entryCopy = { ...entry };
    entryCopy.percentageChange = percentageChangeLookup[entryCopy.id] ?? 0;
    return opts.omitId ? _.omit(entryCopy, "id") : entryCopy;
  });
}

export function calculateBaselinePeriod(range) {
  return range.map((item) => dateToString(subYears(new Date(item), 1)));
}
export function resetMediaVariables(state) {
  state.mediaInputData = resetPercentChange(state.mediaInputData);
}

export function resetNonMediaVariables(state) {
  state.nonMediaInputData = resetPercentChange(state.nonMediaInputData);
}

function resetPercentChange(groupings) {
  Object.keys(groupings).forEach((key) => {
    const obj = groupings[key];
    if (obj?.percentageChange === undefined) {
      Object.keys(obj).forEach((group) => {
        obj[group].percentageChange = 0;
        if (obj[group].children) resetPercentChange(obj[group].children);
      });
    } else {
      obj.percentageChange = 0;
      if (obj?.children) resetPercentChange(obj?.children);
    }
  });
  return groupings;
}
export function simulatorInputNotifyNew({ message }) {
  store.dispatch(
    notifyNew({
      message,
      extraStyles: { marginBottom: "90px", padding: "2px 10px", fontSize: "12px", maxWidth: "320px" },
      //All simulator notifications are non-persistent and don't have a close button
      closeButton: false,
      isPersistent: false,
    }),
  );
}
