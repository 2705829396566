import { getTheme } from "getTheme";

export default function HighlightText(text, searchText) {
  // Escape special characters in searchText
  const escapedSearchText = searchText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regex = new RegExp(`(${escapedSearchText})`, `ig`);
  const theme = getTheme();
  return text.split(regex).map((part, index) => {
    return regex.test(part) ? (
      text[regex.lastIndex] === " " || text[regex.lastIndex - 1] === " " ? (
        <div style={{ color: theme.palette.primary.main }} key={index}>
          {part.trim()}&nbsp;
        </div>
      ) : (
        <div style={{ color: theme.palette.primary.main }} key={index}>
          {part}
        </div>
      )
    ) : (
      <>{part}</>
    );
  });
}
