import React from "react";
import { produce } from "immer";
import { Title, TableLayout } from "./layoutComponents";
import EditorItem from ".";
import { useTheme } from "@mui/styles";

export default function EditorItemForObjects(props) {
  const theme = useTheme();
  const { schema, data = {}, onChange, onNavigate, withinTable = false, topLevel = false, prodData = {} } = props;
  const content = Object.entries(schema.properties || {}).map(([key, propertySchema], i, allSchemaProperties) => {
    // Some elements are not to be shown in the editor
    if (propertySchema.ui__hidden) return null;

    let newPropertySchema = propertySchema;
    // Alow property enums to be dependant on the values of all the object
    if (propertySchema?.ui__enum && typeof propertySchema.ui__enum === "function")
      newPropertySchema.enum = propertySchema.ui__enum(data);

    const editor = (
      <EditorItem
        {...props}
        topLevel={false}
        withinTable={false}
        objectKey={key}
        onNavigate={onNavigate}
        schema={newPropertySchema}
        isDisabled={props.isDisabled || (schema.ui__uniqueKey === key && data?.[key] !== undefined)}
        data={data?.[key]}
        onChange={
          onChange &&
          ((localOnChange) =>
            onChange(
              produce((s) => {
                s[key] = localOnChange(s[key]);
                // This allows to apply a default value based on the whole object after every change
                // The truth is doing this like this makes it loose all the expressivity of using Immer
                // so a better way needs to be found
                allSchemaProperties.forEach(([k, schema]) => {
                  if (schema?.ui__default && typeof schema.ui__default === "function" && s[k] === undefined) {
                    const updatedObjectValue = { ...(data || {}), [key]: localOnChange(s[key]) };
                    s[k] = schema.ui__default(updatedObjectValue); // get the new value based onthe default function
                  }
                });
              }, {}),
            ))
        }
      />
    );

    const largeMode = topLevel ? propertySchema.type === "object" || propertySchema.type === "array" : false;
    const title = (
      <Title
        {...propertySchema}
        slug={key}
        large={largeMode}
        error={
          (propertySchema.ui__isError &&
            //below conditional is used for showing warning for mi+ visible impact per dimension changes affecting investment plan
            propertySchema.ui__isError(data?.[key], prodData?.[key], prodData?.["type"])) ||
          (propertySchema.enum && !propertySchema.enum.includes(data?.[key])
            ? "Please provide a valid selection"
            : undefined)
        }
      />
    );

    return (
      <React.Fragment key={key}>
        {topLevel ? (
          <>
            {!withinTable && largeMode && title}

            {largeMode ? (
              <div style={{ background: "white" }}>{editor}</div>
            ) : (
              <div
                style={{
                  display: "flex",
                  ...(!propertySchema.ui__readOnly
                    ? { background: "white", borderBottom: `1px solid ${theme.palette.greyish[20]}` }
                    : {}),
                }}
              >
                <div
                  style={{
                    width: "50%",
                    height: "35px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {title}
                </div>
                <div
                  style={{
                    width: "50%",
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    height: "35px",
                  }}
                >
                  {editor}
                </div>
              </div>
            )}
          </>
        ) : (
          editor
        )}
      </React.Fragment>
    );
  });

  // If we're inside of a table or this is the top level just return the content
  if (withinTable || topLevel) return content;
  // Otherwise we need to create a two column layout to show 'property header | editor'
  else return <TableLayout columns={2}>{content}</TableLayout>;
}
