import backend from "backend";
import _ from "lodash";
import { ConfirmationModal } from "shared/styledComponents/ConfirmationModal";

/*
This popup will be shown after clicking on save draft if
  1. the model have any investment plans
  2. and if the dimensions affecting the investment plans have changed
*/

export function ImpactDimensionChangePopup({ open, onClose, confirmFn, resetFn }) {
  return (
    <ConfirmationModal
      open={open}
      title={"Change visible dimensions in Media Insights+, Predictions & Activities?"}
      content={
        "Changing the visible dimensions will impact your ability to drill down into data, modify prediction constraints, and access to previously available data insights."
      }
      confirmText={"CHANGE"}
      cancelText={"NO"}
      style={{ width: 390 }}
      onConfirm={() => {
        confirmFn();
        onClose();
      }}
      onCancel={() => {
        resetFn();
        onClose();
      }}
    />
  );
}

//checks if the current model have investment plan or not, then returns true/false
export function useInvestmentPlanAvailable(internalSelectedModel) {
  const SWR_OPT = { dedupingInterval: 500 * 1000 };
  const modelSlug = internalSelectedModel;
  //default value is set to true as if api fails then we want to show popup
  const { data = true } = backend.useData(
    internalSelectedModel ? `v4/investment-plans/is-investment-plans-present/${modelSlug}` : null,
    {},
    SWR_OPT,
  );
  return data;
}

//checks if there are such changes in impact that will affect the investment plans for direct/indirect use direct key and for other model type use combined key
export function checkImpactPerDimensionChanged(
  dataVisibleImpactPerDimension,
  prodDataVisibleImpactPerDimension,
  modelType,
) {
  if (
    dataVisibleImpactPerDimension === undefined ||
    prodDataVisibleImpactPerDimension === undefined ||
    modelType === undefined
  ) {
    //when the key is not in prod config
    return false;
  }
  if (modelType === "direct/indirect") {
    if (
      _.isEqual(
        _.sortBy(dataVisibleImpactPerDimension["direct"]),
        _.sortBy(prodDataVisibleImpactPerDimension["direct"]),
      )
    ) {
      return false;
    }
  } else {
    if (
      _.isEqual(
        _.sortBy(dataVisibleImpactPerDimension["combined"]),
        _.sortBy(prodDataVisibleImpactPerDimension["combined"]),
      )
    ) {
      return false;
    }
  }
  return true;
}
