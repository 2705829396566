import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

export const NewSwitch = (props) => {
  const {
    checked,
    onChange,
    disabled,
    darkUnchecked, // to show a little darker color when unchecked
  } = props;

  const theme = useTheme();
  return (
    <ToggleContainer
      className={`${checked ? "active" : "inactive"} ${disabled ? "disabled" : ""}`}
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      onClick={() => !disabled && onChange(!checked)}
    >
      <svg width="32" height="16" viewBox="0 0 32 16" fill="none">
        <Rect
          width="32"
          height="16"
          rx="8"
          fill={
            checked ? theme.palette.primary.main : darkUnchecked ? theme.palette.greyish[20] : theme.palette.greyish[10]
          }
        />
        <Circle
          checked={checked}
          id="toggle-circle"
          cx="8"
          cy="8"
          r="5"
          fill={checked ? "white" : theme.palette.primary.main}
        />
      </svg>
    </ToggleContainer>
  );
};

const ToggleContainer = styled.div`
  cursor: pointer;
  &.disabled {
    opacity: 0.7;
    cursor: default;
  }
  height: 16px;
`;

const Circle = styled.circle`
  transition:
    transform 0.3s ease-in-out,
    fill 0.2s ease-in-out;
  transform: ${({ checked }) => (checked ? "translateX(16px)" : "none")};
`;

const Rect = styled.rect`
  transition: fill 0.3s ease-in-out;
`;
