import {
  addDays,
  addMonths,
  addQuarters,
  addYears,
  startOfDay,
  endOfDay,
  startOfYear,
  endOfYear,
  startOfQuarter,
  endOfQuarter,
  startOfMonth,
  endOfMonth,
} from "date-fns";
import React from "react";

export default function useQuickPeriods({ showPresetsFuture, showPresetsPast }) {
  return React.useMemo(() => {
    const today = startOfDay(new Date());

    return [
      ["p", "Last 365 days", startOfDay(addDays(today, -364)), endOfDay(today)],
      ["p", "Year to date", startOfYear(today), endOfDay(today)],
      ["f", "This month", startOfMonth(today), endOfMonth(today)],
      ["f", "Next month ", startOfMonth(addMonths(today, 1)), endOfMonth(addMonths(today, 1))],
      ["p", "Last month ", startOfMonth(addMonths(today, -1)), endOfMonth(addMonths(today, -1))],
      ["f", "This quarter", startOfQuarter(today), endOfQuarter(today)],
      ["f", "Next quarter", startOfQuarter(addQuarters(today, 1)), endOfQuarter(addQuarters(today, 1))],
      ["p", "Last quarter", startOfQuarter(addQuarters(today, -1)), endOfQuarter(addQuarters(today, -1))],
      ["f", "This year", startOfYear(today), endOfYear(today)],
      ["f", "Next year", startOfYear(addYears(today, 1)), endOfYear(addYears(today, 1))],
      ["p", "Last year", startOfYear(addYears(today, -1)), endOfYear(addYears(today, -1))],
    ]
      .filter(([type]) => {
        if (!showPresetsFuture && type === "f") return false;
        if (!showPresetsPast && type === "p") return false;
        return true;
      })
      .map(([type, label, start, end]) => ({ label, interval: { start, end } }));
  }, [showPresetsPast, showPresetsFuture]);
}
