import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { useAccount } from "auth";
import { developers } from "auth/commonConstants";
import { jwtDecode } from "jwt-decode";
import React from "react";
import FeatureToggler from "./FeatureToggler";
import LittleToolsForCoworkers from "./LittleToolsForCoworkers";
import LittleToolsForDevelopers from "./LittleToolsForDevelopers";

export default function AdminControl() {
  const { selectedModel, conf, loggedIn } = useAccount();
  const modelConf = selectedModel && conf.models[selectedModel];
  const [open, setOpen] = React.useState(false);
  if (!loggedIn) return null;
  const valModel = modelConf?.activeVersion === "validation";

  return (
    <AdminControlContainer open={open}>
      <AdminControlHeader
        style={valModel ? { backgroundColor: "rgb(72, 146, 109)" } : {}}
        onClick={() => setOpen((open) => !open)}
        data-ref="toggle-feature-panel"
      >
        <AdminControlHeading>
          <ModelTag
            style={valModel ? { color: "rgb(72, 146, 109)", backgroundColor: "white" } : { border: "0.5px solid gray" }}
          >
            {valModel && `VAL ${open ? "MODEL" : ""}`}
            {modelConf?.activeVersion === "prod" && `PROD ${open ? "MODEL" : ""}`}
            {modelConf === undefined && "—"}
          </ModelTag>

          <span title={modelConf?.socketSlug || "No model selected"}>
            {modelConf?.socketSlug || "No model selected"}
          </span>
        </AdminControlHeading>
        <span style={{ alignSelf: "center" }}>{open ? "+" : "–"}</span>
      </AdminControlHeader>
      {open ? (
        <AdminControlBody className="customScroll" data-ref="feature-panel-open">
          <FeatureToggler />
          {modelConf && <LittleToolsForCoworkers />}
          {(jwtDecode(sessionStorage.getItem("current-token-v2"))?.defaultRole === "developer" ||
            developers.some((dev) => dev === sessionStorage.getItem("msalUsername").toLowerCase())) && (
            <LittleToolsForDevelopers />
          )}
        </AdminControlBody>
      ) : null}
    </AdminControlContainer>
  );
}

const slideIn = keyframes`
  from {
    left: -100%;
  }
  to {
    left: 0px;
  }
`;

const AdminControlContainer = styled.div`
  --closed-width: 200px;
  --open-width: 320px;
  --footer-buffer: 52px;
  position: fixed;
  left: 0px;
  margin-left: ${({ open }) => (open ? "40px" : "0px")};
  bottom: ${({ open }) => (open ? "var(--footer-buffer)" : "calc(var(--closed-width) + var(--footer-buffer))")};
  width: ${({ open }) => (open ? "var(--open-width)" : "var(--closed-width)")};
  transform: ${({ open }) => (open ? "none" : "rotate(90deg)")};
  transform-origin: ${({ open }) => (open ? "center" : "bottom left")};
  text-align: left;
  background-color: #000000cf;
  color: white;
  z-index: 9999;
  line-height: 1.2;
  font-size: 14px;
  font-weight: 300;
  animation: ${({ open }) =>
    open
      ? css`
          ${slideIn} 0.5s ease both
        `
      : "none"};
`;

const AdminControlHeader = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

const AdminControlHeading = styled.h1`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  color: white;
  margin-right: 10px;
  text-overflow: ellipsis;
`;

const AdminControlBody = styled.div`
  padding: 0 10px 2em 10px;
  max-height: 70vh;
  overflow: auto;
`;

const ModelTag = styled.div`
  margin-right: 10px;
  padding: 5px;
  line-height: 1.2;
  border-radius: 0px;
  letter-spacing: 1px;
  vertical-align: middle;
  font-size: 10px;
  display: inline-block;
`;
