import { PublicClientApplication } from "@azure/msal-browser";
import { homepageURLS } from "./commonConstants";

export const msalConfig = {
  auth: {
    clientId: "6ed7548c-79b5-4343-88a8-da38cc2930bc",
    authority: "https://login.microsoftonline.com/1e355c04-e0a4-42ed-8e2d-7351591f0ef1",
    redirectUri: homepageURLS.some((keyword) => window.location.href.includes(keyword))
      ? window.location.origin + "/hai/"
      : window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
};

export const getMsalToken = async (instance, accounts) => {
  try {
    await instance.acquireTokenSilent({
      scopes: loginRequest.scopes,
      account: accounts[0], // Use the first account
    });
    // return response.accessToken;
  } catch (error) {
    console.error("MSAL Token acquisition error:", error);
  }
};

export const getProfileImg = async (instance, accounts) => {
  const token = sessionStorage.getItem("msalToken");
  if (token) {
    // Get the user's avatar image from the Microsoft Graph API.
    const profilePic = await fetch(`https://graph.microsoft.com/v1.0/me/photos/48x48/$value`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "image/webp",
      },
      responseType: "blob",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL || window.webkitURL;
        const blobUrl = url.createObjectURL(blob);
        return blobUrl;
      });
    return profilePic;
  }
};

export const logoutMSAL = () => {
  return msalInstance.logoutRedirect();
};
