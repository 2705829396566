import React from "react";

import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import store from "store";
import { notifyNew } from "store/action-creators";
import { mutate } from "swr";
import { grey } from "@mui/material/colors";

const BroadcastStationEditor = ({ broadcastEditorState, setBroadcastEditorState }) => {
  const [editorText, setEditorText] = React.useState(
    Boolean(broadcastEditorState?.currEditBroadcast?.broadcastmessage)
      ? broadcastEditorState?.currEditBroadcast?.broadcastmessage
      : "",
  );

  const addNewBroadcast = () => {
    //API call
    fetch(`/backend-api/admin/v1/broadcasts`, {
      method: "POST",
      body: JSON.stringify({ broadcastMessage: editorText }),
      headers: { "Content-Type": "application/json", msal_user_auth: sessionStorage.getItem("msalToken") },
    })
      .then(async (res) => (res.ok ? res.json() : Promise.reject(new Error(await res.text()))))
      .catch((error) => {
        store.dispatch(notifyNew({ message: `Failed to add broadcast`, isError: true }));
      })
      .then(() => {
        mutate(`/backend-api/admin/v1/broadcasts`); // Revalidate the list of broadcasts
      });
  };

  const editBroadcast = () => {
    const itemToEdit = broadcastEditorState?.currEditBroadcast;
    if (Boolean(itemToEdit) === false) {
      store.dispatch(notifyNew({ message: `Failed to update broadcast`, isError: true }));
      return;
    }

    // First optimistic update of the list with the new changes
    mutate(
      `/backend-api/admin/v1/broadcasts`,
      (broadcastItems) =>
        broadcastItems.map((item) => (item.id === itemToEdit.id ? { ...item, broadcastmessage: editorText } : item)),
      false, // Signals optimistic update
    );

    //actual API call
    fetch(`/backend-api/admin/v1/broadcasts/${itemToEdit.id}`, {
      method: "PUT",
      body: JSON.stringify({ broadcastMessage: editorText }),
      headers: { "Content-Type": "application/json", msal_user_auth: sessionStorage.getItem("msalToken") },
    })
      .then(async (res) => (res.ok ? res.json() : Promise.reject(new Error(await res.text()))))
      .catch((error) => {
        store.dispatch(notifyNew({ message: `Failed to update broadcast`, isError: true }));
      })
      .then(() => {
        mutate(`/backend-api/admin/v1/broadcasts`); // Revalidate the list of broadcasts
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (editorText?.trim() === "") {
      //return early if broadcast message is empty or only space characters.
      store.dispatch(notifyNew({ message: `Cannot send empty broadcast`, isError: true }));
      return;
    }

    if (broadcastEditorState.type === "add") {
      closeEditor();
      addNewBroadcast();
    } else if (broadcastEditorState.type === "edit") {
      closeEditor();
      editBroadcast();
    } else {
      throw new Error("Unknown editor state");
    }
  };

  const closeEditor = () => {
    setBroadcastEditorState({ ...initialBroadcastEditorState });
    setEditorText("");
  };

  return (
    <EditorBody>
      <form onSubmit={handleSubmit}>
        <textarea
          id="broadcastMessage"
          name="broadcastMessage"
          rows="4"
          cols="50"
          value={editorText}
          onChange={(event) => setEditorText(event.target.value)}
        ></textarea>
        <ActionButtonsContainer>
          <Button size="small" variant="contained" disableElevation onClick={() => closeEditor()}>
            Discard
          </Button>
          <Button
            type="submit"
            size="small"
            data-test-id="create-new-broadcast"
            variant="contained"
            color="primary"
            disableElevation
          >
            {broadcastEditorState.type === "add"
              ? "Send Broadcast"
              : broadcastEditorState.type === "edit"
                ? "Edit Broadcast"
                : "Unknown editor action"}
          </Button>
        </ActionButtonsContainer>
      </form>
    </EditorBody>
  );
};

export const initialBroadcastEditorState = {
  //Initial states
  isEditorOpen: false,
  type: "add",
  currEditBroadcast: {}, //For editing broadcasts
};

const EditorBody = styled.div`
  text-align: end;

  form {
    textarea {
      resize: vertical;
      width: 100%;
      border: 2px solid rgba(0, 0, 0, 0.12);
      padding: 8px;

      font-size: 1rem;
      font-weight: 400;
      font-family: "Brown", "Helvetica", sans-serif;
      line-height: 22px;
    }
  }
`;

const ActionButtonsContainer = styled.div`
  padding-top: 4px;

  & > button {
    text-transform: none;
  }
  & > button:first-of-type {
    //discard button
    margin-right: 16px;
    color: ${grey[500]};
    border: 1px solid ${grey[500]};
    background-color: #fafafa;
  }
`;
export default BroadcastStationEditor;
