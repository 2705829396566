import React from "react";
import Fuse from "fuse.js";

const SEARCH_KEYS = ["value"]; // We'll look only on the value field

export default function useFuzzySearch({ text, options }) {
  const { flattenedList, fuseIndex } = React.useMemo(() => {
    const flattenedList = Object.keys(options)
      .map((dimension) => options[dimension].map((value) => ({ dimension, value })))
      .reduce((acc, d) => [...acc, ...d], []);
    const fuseIndex = Fuse.createIndex(SEARCH_KEYS, flattenedList);

    return { flattenedList, fuseIndex };
  }, [JSON.stringify(options)]); // eslint-disable-line

  const [{ searchResults, indexWithBestScore }, setState] = React.useState({
    searchResults: [],
    indexWithBestScore: null,
  });

  React.useEffect(() => {
    const handler = setTimeout(() => {
      const fuse = new Fuse(
        flattenedList,
        {
          shouldSort: false, // So the list can be grouped by dimension
          includeScore: true, // This will allow us to preselect the best match
          threshold: 0.4, // Use a more restrictive threshold not to get so much litter
          keys: SEARCH_KEYS,
        },
        fuseIndex,
      );
      const fuseOutput = fuse.search(text);
      const scores = fuseOutput.map((d) => d.score);
      const searchResults = fuseOutput.map((d) => d.item);

      setState({
        searchResults,
        indexWithBestScore: searchResults.length > 0 ? scores.indexOf(Math.min(...scores)) : null,
      });
    }, 200);

    return () => clearTimeout(handler);
  }, [text]); // eslint-disable-line

  return { searchResults, indexWithBestScore, flattenedList };
}
