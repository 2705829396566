export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "PLANS_NEW_PARTIAL_INVESTMENT_MAX":
      return { ...state, partialInvestmentMax: action.value };
    case "PLANS_NEW_EFFECTS_MIN":
      return { ...state, effectsQuantilesMin: action.value };
    case "PLANS_NEW_EFFECTS_MAX":
      return { ...state, effectsQuantilesMax: action.value };
    case "PLANS_MEDIA_MIX_ORDER":
      return { ...state, mixOrder: action.order };
    case "PLANS_TOGGLE_MIX_EXPAND":
      return { ...state, mixExpanded: !state.mixExpanded };
    default:
      return state;
  }
}

const initialState = {
  effectsQuantilesMin: undefined, // For distribution charts.
  effectsQuantilesMax: undefined, // For distribution charts
  partialInvestmentMax: undefined, // For the max width of the bars. Could be 30000
  mixOrder: [], // could be ['outdoor', 'print', 'tv'] or [ 'cbs', 'abc' ]
  mixExpanded: false,
};
