import { useTheme } from "@emotion/react";
import { InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { ReactComponent as CrossIcon } from "shared/Icons/crossGrey.svg";
import { ReactComponent as SearchBarIcon } from "./Assets/SearchbarIcon.svg";

export const SearchBar = ({ searchText, setSearchText, selection }) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const theme = useTheme();
  return (
    <OrgSearchBar isFocused={isFocused} searchText={searchText}>
      <TextField
        key={"search"}
        sx={{
          width: "100%",
          color: theme.palette.greyish[50],
          "& .MuiInput-input": { padding: 0, color: theme.palette.greyish[50] },
        }}
        size="medium"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {searchText && searchText.length !== 0 ? (
                <CrossIcon style={{ cursor: "pointer" }} onClick={() => setSearchText("")} height={20} width={20} />
              ) : (
                <SearchBarIcon height={20} width={20} />
              )}
            </InputAdornment>
          ),
          autoComplete: "off",
          disableUnderline: true,
          autoFocus: isFocused, // set the focused state
        }}
        hiddenLabel
        id="user-search"
        variant="standard"
        placeholder={selection === "org" ? "Search an organization" : "Search a model"}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onFocus={() => setIsFocused(!isFocused)} // handle focus event
        onBlur={() => setIsFocused(!isFocused)} // handle blur event
      />
    </OrgSearchBar>
  );
};

const OrgSearchBar = styled("div")(
  ({ isFocused, searchText, theme }) => `
  margin-inline: auto;
  display: flex;
  height: 32px;
  align-item: center;
  justify-content: space-between;
  min-width: 240px;
  padding: 6px 8px;
  border-bottom: ${`1.5px solid ${isFocused ? theme.palette.primary.main : "#000"}`};
  background: ${theme.palette.greyish[10]};
  margin-top: 20px;
  width: 920px;
`,
);
