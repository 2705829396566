import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled/macro";

export default function ValidateError({ validating, errorMessage }) {
  const theme = useTheme();
  if (!validating && !errorMessage) return;
  return (
    <Box
      sx={{
        display: "flex",
        height: "30px",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.primary.main,
      }}
    >
      {validating ? (
        <>
          <CircularProgress size={20} thickness={5} sx={{ marginRight: "8px" }} />
          {"  "} Validating the constraint. Please wait.
        </>
      ) : (
        errorMessage && <ErrorMessageWrapper>{errorMessage}</ErrorMessageWrapper>
      )}
    </Box>
  );
}

const ErrorMessageWrapper = styled.span`
  width: 100%;
  text-align: center;
  background: #e10000;
  color: #fff;
  padding: 3px;
`;
