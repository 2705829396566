import React from "react";
import styled from "@emotion/styled";
import { Icon, Menu, Fade, List, ListItem, ListItemIcon, ListItemText, Checkbox } from "@mui/material";
import { Cell, Title, TableLayout } from "admin/EditorItem/layoutComponents";
import { intersection } from "lodash";
import { defaultImpactPerDimensionValues as defaultValues } from "pages/media-insights-plus/metrics";
import EditorItemForText from "admin/EditorItem/EditorItemForText";
import useDimensionValuesV3 from "shared/hooks/useDimensionValues-v3";
import { produce } from "immer";
import formattedLabel from "shared/helpers/formattedLabel";
import { ReactComponent as ArrowIcon } from "shared/Icons/chevron-grey.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DraggableDroppableIcon from "shared/components/DraggableDroppableIcon";

const ImpactPerDimensionEditor = ({
  data = defaultValues,
  onChange,
  isDisabled,
  modelSlug,
  labelDictionary,
  modelType,
}) => {
  const dimensionValuesMedia = useDimensionValuesV3(modelSlug, { onlyMedia: true });

  const mediaDimensionsInModel = React.useMemo(() => {
    return Object.keys(dimensionValuesMedia?.[0] ?? {})?.sort(
      (a, b) => (a === "media-grouping" ? -1 : b === "media-grouping" ? 1 : 0), //media-grouping always first in order
    );
  }, [dimensionValuesMedia]);

  const getLabelForDimension = (dimension) => {
    //get labels to show in ui for dimension values
    return formattedLabel(labelDictionary, null, dimension);
  };

  const headers = ["Impact", "Dimensions"].map((title) => (
    <Title key={title} title={title} isUppercase={true} extraStylesDiv={{ fontSize: 12 }} />
  ));

  return (
    <>
      {mediaDimensionsInModel?.length > 0 && (
        <TableLayout columns={headers?.length || 1}>
          {Boolean(headers?.length) && headers}
          {modelType === "direct/indirect" ? (
            <>
              <EditableRow
                keyTitle="direct"
                data={data}
                onChange={onChange}
                isDisabled={isDisabled}
                options={mediaDimensionsInModel}
                getLabelForDimension={getLabelForDimension}
                modelType={modelType}
              />
              <EditableRow
                keyTitle="indirect"
                data={data}
                onChange={onChange}
                isDisabled={isDisabled}
                options={mediaDimensionsInModel}
                getLabelForDimension={getLabelForDimension}
                modelType={modelType}
              />
              <EditableRow
                keyTitle="combined"
                data={data}
                onChange={onChange}
                isDisabled={isDisabled}
                options={mediaDimensionsInModel}
                getLabelForDimension={getLabelForDimension}
                modelType={modelType}
                onlyDraggable //combined is derived from direct and indirect and only order can be changed for this
              />
            </>
          ) : (
            <>
              <EditableRow
                keyTitle="combined"
                uiTitle="direct" //For Non-d/i model, we want to show only key i.e. direct.
                // combined key disguised as direct key for ui to avoid changing conf structure. Implementation of change in conf will be taken up in future.
                data={data}
                onChange={onChange}
                isDisabled={isDisabled}
                options={mediaDimensionsInModel}
                getLabelForDimension={getLabelForDimension}
                modelType={modelType}
              />
            </>
          )}
        </TableLayout>
      )}
    </>
  );
};

function EditableRow({
  uiTitle,
  keyTitle,
  data = {},
  onChange,
  isDisabled,
  options = [],
  getLabelForDimension,
  modelType,
  onlyDraggable = false,
}) {
  const sortedOptions =
    data[keyTitle]?.length > 0
      ? options.toSorted((a, b) => {
          //sort options according to they are present in existing data, if not present in data then push them to bottom in order.
          if (data[keyTitle].includes(a) && !data[keyTitle].includes(b)) return -1;
          else if (data[keyTitle].includes(b) && !data[keyTitle].includes(a)) return 1;
          else if (data[keyTitle].includes(a) && data[keyTitle].includes(b))
            return data[keyTitle].indexOf(a) - data[keyTitle].indexOf(b);
          else return -Infinity;
        })
      : options;
  return (
    <>
      <Cell style={{ height: "35px" }}>
        <EditorItemForText
          schema={{ title: uiTitle ?? keyTitle, ui__readOnly: true }}
          placeholderStyle={{ color: "#000" }}
        />
      </Cell>
      <ImpactsDropdown
        keyTitle={keyTitle}
        data={data}
        onChange={onChange}
        isDisabled={isDisabled}
        options={sortedOptions}
        getLabelForDimension={getLabelForDimension}
        modelType={modelType}
        onlyDraggable={onlyDraggable}
      />
    </>
  );
}

function ImpactsDropdown({
  keyTitle,
  data = {},
  onChange,
  isDisabled,
  options = [],
  getLabelForDimension,
  modelType,
  onlyDraggable, //true if select unselect behaviour not wanted only ordering is required
}) {
  const [orderedOptions, setOrderedOptions] = React.useState(options);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const disabled = isDisabled || !onChange;

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    onChange &&
      onChange(() =>
        produce(data, (draftData) => {
          const items = Array.from(orderedOptions);
          const [reorderedItem] = items.splice(result.source.index, 1);
          items.splice(result.destination.index, 0, reorderedItem);
          setOrderedOptions(items);
          const temp = intersection(items, draftData[keyTitle]);
          draftData[keyTitle] = temp;
        }),
      );
  };

  const handleClick = (option) => {
    if (option === "media-grouping" || onlyDraggable) {
      //cannot unselect media grouping
      return;
    }
    onChange &&
      onChange(() =>
        produce(data, (draftData) => {
          const index = draftData[keyTitle].findIndex((dim) => dim === option);

          if (index !== -1) {
            draftData[keyTitle].splice(index, 1);
          } else {
            draftData[keyTitle].push(option);
          }

          //same order as that of in the options order state
          draftData[keyTitle].sort((dim1, dim2) => orderedOptions.indexOf(dim1) - orderedOptions.indexOf(dim2));

          if (modelType === "direct/indirect") {
            // in case of direct indirect combined is derived from direct and indirect

            //combined is the higher level of both direct and indirect
            draftData["combined"] = intersection(draftData["direct"], draftData["indirect"]);
          }
        }),
      );
  };
  return (
    <>
      <Cell
        onClick={(e) => setAnchorEl(e.currentTarget)}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          padding: "0 15px",
          fontSize: "14px",
          height: 35,
        }}
      >
        <span
          style={{
            width: "calc(100% - 20px)",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "#000000",
            padding: "4px 0",
            opacity: modelType === "direct/indirect" && keyTitle === "combined" && 0.5,
          }}
        >
          {data[keyTitle].map((dim) => getLabelForDimension(dim)).join(", ")}
        </span>
        <Icon>
          <ArrowIcon
            style={{
              transform: Boolean(anchorEl) ? "rotate(180deg)" : "rotate(0)",
              transition: "transform 0.2s ease",
            }}
          />
        </Icon>
      </Cell>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{ style: { maxWidth: 350 } }}
        MenuListProps={{ disablePadding: true }}
        TransitionComponent={Fade}
      >
        {onlyDraggable && (
          <InfoHeader>Selecting or deselecting an option is not allowed, only ordering can be changed.</InfoHeader>
        )}
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="dimensionsItem" index={0}>
            {(provided) => (
              <StyledList dense disablePadding {...provided.droppableProps} ref={provided.innerRef} disabled={disabled}>
                {/* Render "media grouping default" option separately */}
                <ListItem disabled={disabled}>
                  <ListItemIcon style={{ minWidth: "fit-content" }}>
                    <Checkbox
                      edge="start"
                      checked={Boolean(data[keyTitle]?.includes("media-grouping"))}
                      tabIndex={-1}
                      disableRipple
                      color="primary"
                      style={{ padding: 0, margin: 0 }}
                      disabled={disabled || onlyDraggable}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${getLabelForDimension("media-grouping")} (default)`}
                    sx={{ span: { fontSize: "12px" } }}
                  />
                </ListItem>

                {/* Render other options */}
                {orderedOptions.map((option, index) => {
                  // Skip rendering if it's "media grouping default" option
                  if (option === "media-grouping") return null;
                  return (
                    <Draggable key={option} draggableId={option} index={index} isDragDisabled={disabled}>
                      {(provided, snapshot) => (
                        <ListItem
                          key={option}
                          disabled={disabled}
                          onClick={() => handleClick(option)}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <ListItemIcon style={{ minWidth: "fit-content" }}>
                            <Checkbox
                              edge="start"
                              disabled={disabled || onlyDraggable}
                              checked={Boolean(data[keyTitle]?.includes(option))}
                              tabIndex={-1}
                              disableRipple
                              color="primary"
                              style={{ padding: 0, margin: 0 }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={getLabelForDimension(option)} sx={{ span: { fontSize: "12px" } }} />
                          <DraggableDroppableIcon
                            isChecked={Boolean(data[keyTitle]?.includes(option))}
                            provided={provided}
                            isDragging={snapshot.isDragging}
                          />
                        </ListItem>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </StyledList>
            )}
          </Droppable>
        </DragDropContext>
      </Menu>
    </>
  );
}
const InfoHeader = styled.div`
  --border-color: ${({ theme }) => theme.palette.greyish[20]};
  font-size: 10px;
  color: ${({ theme }) => theme.palette.greyish[50]};
  padding: 8px;
  border-bottom: 1px solid var(--border-color);
  background: #fafafa;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const StyledList = styled(List)`
  outline: none;
  pointer-events: ${({ disabled }) => disabled && "none"};

  .MuiListItem-root {
    padding: 4px 8px;
    gap: 8px;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
`;

export default ImpactPerDimensionEditor;
