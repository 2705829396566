import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { setCurrency } from "shared/helpers/formatters";
import { setLocale } from "shared/helpers/internationalization";
import { sessionStorageSync, useSessionStorageSync } from "shared/helpers/sessionStorageSync";
import store from "store";
import {
  logoutResetStore,
  mediaInsightsClearAllFilters,
  mediaInsightsPlusClearAllFilters,
  mediaInsightsPlusRemoveSelectedActivity,
  businessInsightsPlusFilterUpdate,
  notifyNew,
} from "store/action-creators.js";
import useSWR from "swr";
import * as commonConstants from "./commonConstants";
import { resetActivitiesState } from "store/reducers/activities";
import { resetSimulationState } from "store/reducers/simulator";

export const modelInit = modelInitCreator();

export function useAccount() {
  const featureOverrides = useSelector((s) => s.featureOverrides);
  const [selectedModel, setSelectedModel] = useSessionStorageSync("selected-model");
  const [globalScope, setGlobalScope] = useSessionStorageSync("globalScope");

  const [jwt] = useSessionStorageSync("current-token-v2");
  const { data = null } = useSWR(jwt || null, accountFetcher, {
    dedupingInterval: 60 * 1000, // Do not revalidate very often
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  function isFeatureAvailable(featureName, defaultModel = selectedModel) {
    const currentModel = defaultModel || (data?.conf?.models && Object.keys(data.conf.models)?.[0]);
    const featuresThisModelSupports = (data?.conf?.features || [])
      .filter((d) => !d.blacklistedModels.includes(currentModel))
      .map((d) => d.name);
    const override = featureOverrides[featureName]?.state;
    return override !== undefined ? override : featuresThisModelSupports.includes(featureName);
  }

  function isClaim(scope) {
    try {
      const tokenClaims = getClaimsToken() ? jwtDecode(getClaimsToken()) : {};
      return tokenClaims?.scope?.includes(scope) || JSON.parse(globalScope).includes(scope);
    } catch (e) {
      return false;
    }
  }

  const userId = sessionStorageSync.getItem("userId");
  const conf = data?.conf || { models: {}, features: [] };
  return {
    conf,
    claims: data?.claims || {},
    selectedModel: selectedModel,
    selectedModelConf: conf.models[selectedModel] || {},
    //selectedModelType: We have a type attribute in the model conf that currently have two values 'direct/indirect' and 'sales'.
    selectedModelType: data?.conf?.models?.[selectedModel]?.type ?? "sales", //if no type assume "sales" for backward compatibility.
    loggedIn: Boolean(data),
    loggingIn: !Boolean(data) && jwt !== null,
    isDraft: data?.claims?.version === "validation",
    logout,
    isFeatureAvailable,
    isClaim,
    setGlobalScope: (scope) => {
      setGlobalScope(scope);
    },
    onSelectModel: (newSelectedModel) => {
      setSelectedModel(newSelectedModel);
      const modelConf = data?.conf?.models[newSelectedModel] || {};
      if (modelConf) modelInit(newSelectedModel, modelConf, "changing model");
      console.info(`%cModel changed: ${newSelectedModel}`, "color:orange");
      pendoUpdate(userId, data, newSelectedModel);
    },
  };
}

let logoutTimeoutRef;
export function accountFetcher(jwt) {
  return getClaims(jwt)
    .then((tokenContent) => {
      console.info("%cInit session...", "color:orange");

      // Log out a bit before the token expires (to be replaced with token refresh)
      const secondsToExpiredToken = tokenContent.exp - Date.now() / 1000;
      console.info(`%cLogging out in ${Math.round(secondsToExpiredToken / 60)} minutes...`, "color:orange");
      if (logoutTimeoutRef) clearTimeout(logoutTimeoutRef);
      logoutTimeoutRef = setTimeout(logout, (secondsToExpiredToken - 1) * 1000);

      return fetch(`/backend-api/v1/conf`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("current-token-v2")}`,
        },
      })
        .then(async (res) => (res.ok ? res.json() : Promise.reject(new Error(await res.text()))))
        .then((conf) => {
          let selectedModel = sessionStorageSync.getItem("selected-model");
          if (!Object.keys(conf.models).includes(selectedModel)) {
            sessionStorageSync.removeItem("selected-model");
            selectedModel = Object.keys(conf.models)[0]; // Auto-choose first model
          }
          const model = conf.models[selectedModel];
          if (model) modelInit(selectedModel, model, "account fetcher");

          console.info(`%cLogged in as ${tokenContent.email}`, "color:orange");
          return { claims: tokenContent, conf };
        });
    })
    .catch((err) => {
      console.error(err);
      logout(`While getting claims/conf: ${err.message}`);
      store.dispatch(notifyNew({ message: "There was a problem retrieving data", isError: true }));
      return null;
    });
}

function getClaims(token) {
  return new Promise((resolve, reject) => {
    try {
      resolve(jwtDecode(token));
    } catch (err) {
      reject("Invalid token");
    }
  });
}

function modelInitCreator() {
  /*why a creator for modelInit: currently the model initi dispatches "MODEL_INIT" which the legacy part of code needs 
    but it get triggered on every account fetch and refreshes the activity page even if we have selected one and comparing
    plans, so with this creator we keep track of currently prev selected model and trigger the dispatch only if the model changes.
    
    This way we prevent the activity page from refreshing each time this function is called. 

    TODO legacy: we might need to think a new way of doing model init
  */
  let prevSelectedModel = null;
  // TODO: Find ways of getting rid of this extra step
  return (slug, { socketSlug, currency, market }, why) => {
    console.info(`%cModel init: ${slug} (${why})`, "color:orange");

    // Initialise helper globals
    // TODO: Find a less dangerous way of doing formatters
    setCurrency(currency);
    setLocale({ market, currency });

    if (prevSelectedModel !== slug) {
      // reset states only when model is changes irrespective of modelinit is called or not
      prevSelectedModel = slug;
      store.dispatch({
        type: "MODEL_INIT",
        socketSlug, // Activities sagas will need this
      });

      store.dispatch(mediaInsightsClearAllFilters({ section: "media" }));
      store.dispatch(mediaInsightsPlusRemoveSelectedActivity()); //reset the focused activity when changing models
      store.dispatch(mediaInsightsPlusClearAllFilters()); //reset the MI+ filters when changing models
      store.dispatch(businessInsightsPlusFilterUpdate({}));
      store.dispatch(resetActivitiesState());
      store.dispatch(resetSimulationState());
    }
  };
}

export function getAadClaims(organizationSlug, version) {
  return fetch(`/backend-api/auth/v2/user/getclaims/${organizationSlug}/${version}`, {
    method: "GET",
    headers: { "Content-Type": "application/json", msal_user_auth: sessionStorage.getItem("msalToken") },
    // body: JSON.stringify({ msal_user_auth: sessionStorage.getItem("msalToken"), organization: organizationSlug }),
  })
    .then(async (res) => (res.ok ? res.text() : Promise.reject(new Error(await res.text()))))
    .then((claimsToken) => {
      sessionStorageSync.removeItem("token-array");
      sessionStorageSync.removeItem("current-token");
      const sessionToken = JSON.parse(claimsToken).sessionToken;
      sessionStorageSync.setItem("current-token-v2", sessionToken);
      sessionStorageSync.setItem("userId", JSON.parse(claimsToken).oId);
      return jwtDecode(claimsToken);
    })
    .catch((err) => {
      store.dispatch(notifyNew({ message: "There was a problem retrieving data", isError: true }));
      console.error(err);
      sessionStorageSync.clear();
    });
}

export async function logout(msgReason) {
  const claimsToken = sessionStorage.getItem("current-token-v2");
  if (claimsToken === null) return; // Already logged out

  console.info(`%cLogging out... ${msgReason ? `(${msgReason})` : ""}`, "color:orange");
  store.dispatch(logoutResetStore()); // Notify the Redux store about the logout for state reset
  sessionStorageSync.clear();

  try {
    const claims = jwtDecode(claimsToken);
    if (claims.jti)
      await fetch("/backend-api/auth/v1/logout", {
        method: "POST",
        headers: { Authorization: `Bearer ${claimsToken}` },
      }).then(() => {
        sessionStorageSync.clear();
      });
  } catch (error) {
    console.info("%cCould not notify the backend of the logout", "color:orange");
  }

  console.info("%cLogged out!", "color:orange");
}

export async function endSession() {
  const claimsToken = sessionStorage.getItem("current-token-v2");
  if (claimsToken === null) return; // Already logged out

  console.info("%cEnding session...", "color:orange");
  store.dispatch(logoutResetStore()); // Notify the Redux store about the logout for state reset
  sessionStorageSync.clear();

  return fetch("/backend-api/auth/v1/end-session", {
    method: "POST",
    headers: { Authorization: `Bearer ${claimsToken}` },
  }).catch((err) => console.error("Could not tell the backend to end the session", err));
}

export function getClaimsToken() {
  const claimsToken = sessionStorage.getItem("current-token-v2");
  if (claimsToken === null) return;
  try {
    jwtDecode(claimsToken);
    return claimsToken;
  } catch (err) {
    // The token seems not to be valid anymore (probably expired)
    sessionStorageSync.clear();
  }
}

async function pendoUpdate(userId, data, selectedModel) {
  const claimsToken = sessionStorage.getItem("current-token-v2");
  const email = data?.claims?.email.toLowerCase();
  if (claimsToken === null || email === undefined) return; // Already logged out
  const selectedModelName = data?.conf?.models[selectedModel].name;
  const requestToEncrypt = {
    userId: userId,
    accountId: data?.conf?.organization?.slug,
  };
  const unEncryptedItems = {
    type:
      data?.claims?.email === "N/A" ||
      commonConstants.internalEmails.includes(email.substring(email.indexOf("@") + 1, email.lastIndexOf(".")))
        ? "Kantar"
        : "Client",
    name: data?.conf?.organization?.name + "_" + data?.conf?.organization?.slug,
    model: selectedModelName + "_" + selectedModel,
  };
  let jsonObj = [];
  Object.entries(requestToEncrypt).forEach(([key, value]) => {
    let item = {};
    item["Name"] = key;
    item["Value"] = value;
    jsonObj.push(item);
  });
  const req = { EncryptionItems: jsonObj };
  await fetch(commonConstants.URLS.ENCRYPT_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("current-token-v2")}`,
    },
    body: JSON.stringify(req),
  })
    .catch((err) => console.error("Could not encrypt ids for pendo", err))
    .then((response) => response.json())
    .then((response) => {
      const currentEnv = process.env.REACT_APP_ENV ?? "PROD";
      const prefix = commonConstants.pendoPrefix[currentEnv];
      const inputForPendo = {
        visitor: {
          id: prefix + response.EncryptionItems.filter((a) => a.Name === "userId")[0].EncryptedValue,
          type: unEncryptedItems.type,
          org_model: unEncryptedItems.model,
        },
        account: {
          id: prefix + response.EncryptionItems.filter((a) => a.Name === "accountId")[0].EncryptedValue,
          name: unEncryptedItems.name,
        },
      };
      window.pendo.initialize(inputForPendo);
      window.pendo.identify(inputForPendo);
    });
}
