import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled/macro";
import { useTheme } from "@mui/styles";
import {
  selectSimulationPeriod,
  selectBaselinePeriod,
  selectMediaNewChildGroupFilter,
  selectMediaInputData,
  selectApiMediaInputData,
  selectNonMediaInputData,
  selectApiNonMediaInputData,
  setSimulationStatus,
  setModifying,
  deleteLocalSavedData,
  selectSimAdminConfigItems,
} from "store/reducers/simulator";
import { TextButton } from "shared/styledComponents/TextButton";
import React from "react";
import { LinearProgress, Modal } from "@mui/material";
import { ReactComponent as CrossIcon } from "shared/Icons/cross.svg";
import { NewSwitch } from "shared/components/NewSwitch";
import { IconWrapper } from "shared/styledComponents/IconWrapper";
import { queryParamsBuilder } from "../helper";
import { useAccount } from "auth";
import backend from "backend";
import useLongPollingSimulator from "shared/hooks/useLongPollingSimulator";
import {
  mapMediaStateToInsertions,
  mapNonMediaStateToInsertions,
  useMediaVariablesCount,
  useNonMediaVariablesCount,
} from "store/reducerUtils/simulatorUtils";
import moment from "moment";
import CustomListItem from "shared/styledComponents/CustomListItem";
import { softDeleteSimulation } from "../../SimulatorOutput/useData";

function Footer() {
  const dispatch = useDispatch();
  const { selectedModel, isFeatureAvailable } = useAccount();
  const { mediaVariablesCount, mediaVariablesChangedCount } = useMediaVariablesCount();
  const { nonMediaVariablesCount, nonMediaVariablesChangedCount } = useNonMediaVariablesCount();
  const totalCount = mediaVariablesCount + nonMediaVariablesCount;
  const changedCount = mediaVariablesChangedCount + nonMediaVariablesChangedCount;
  const scenarioPeriod = useSelector(selectSimulationPeriod);
  const baselinePeriod = useSelector(selectBaselinePeriod);
  const customGroupingState = useSelector(selectMediaNewChildGroupFilter);
  const newGroupActive = customGroupingState?.slug; //any other grouping new group is active

  const [open, setOpen] = React.useState(false);
  const [emailNotification, setEmailNotification] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const { data: simLongPollingData, invalidate } = useLongPollingSimulator(selectedModel);
  const { simulationId } = simLongPollingData;
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const theme = useTheme();

  const mediaInputData = useSelector(selectMediaInputData);
  const apiMediaInputData = useSelector(selectApiMediaInputData) ?? [];
  const nonMediaInputData = useSelector(selectNonMediaInputData);
  const apiNonMediaInputData = useSelector(selectApiNonMediaInputData) ?? [];
  const simAdminConfigItems = useSelector(selectSimAdminConfigItems);

  const noBaselineDataAvailable = apiMediaInputData.length === 0 && apiNonMediaInputData.length === 0;

  const data = [
    {
      title: "High-level result",
      description:
        "It will take few minutes to prepare high-level result. You will be able to request a detailed result afterwards.",
      point_heading: "High-level result includes:",
      point_description: "total media effect per week,;total non-media effect per week.",
      email_toggle: false,
      type: "fast",
    },
    {
      title: "Detailed result",
      description:
        " It may take more than an hour to prepare a detailed result. While you are waiting, you will receive a high-level result.",
      point_heading: "Detailed result includes:",
      point_description:
        "a breakdown of the impact of media variables,;a breakdown of the impact of non-media variables.",
      email_toggle: true,
      type: "full",
    },
  ];

  const simulateClickHandler = (type) => {
    dispatch(deleteLocalSavedData()); //delete local saved input data when simulating
    const paramsString = queryParamsBuilder({
      simulationStart: scenarioPeriod[0],
      simulationEnd: scenarioPeriod[1],
      type,
      baselineStart: baselinePeriod[0],
      baselineEnd: baselinePeriod[1],
      emailNotification,
    });
    const payload = getSimulationPayload({
      apiMediaInputData,
      mediaInputData,
      apiNonMediaInputData,
      nonMediaInputData,
      period: scenarioPeriod,
      simAdminConfigItems,
    });
    backend.post(
      `v1/simulation/simulate/${selectedModel}?${paramsString}&experimental=${isFeatureAvailable("Use experimental optimizer")}`,
      payload,
    );
    if (simulationId) {
      setLoading(true);
      softDeleteSimulation({ simulationId }).then((d) => {
        setLoading(false);
        handleOnSuccess(type);
      });
    } else {
      handleOnSuccess(type);
    }
  };

  const handleOnSuccess = (type) => {
    handleClose();
    // explicitly update the long-polling status to res
    // This will result in the immediately redirect to input page
    invalidate({ status: "notfound" });
    const statusObj =
      type === "fast"
        ? { statusFast: "submitted", statusFull: "NA" }
        : { statusFast: "submitted", statusFull: "submitted" };
    dispatch(setModifying(false));
    dispatch(setSimulationStatus({ ...statusObj, status: "NA" }));
  };

  return (
    <Box>
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <span>
          <Text style={{ color: theme.palette.blackish.main }}>CHANGED </Text>
          <Text style={{ color: theme.palette.primary.main, fontWeight: 700 }}>{changedCount}</Text>
          <Text style={{ color: theme.palette.greyish[50] }}>/ {totalCount} variables</Text>
        </span>
        <DescriptionText>
          All variables will be included in the simulation with default baseline values unless you change them.
        </DescriptionText>
      </div>
      <TextButton disabled={newGroupActive || noBaselineDataAvailable} onClick={() => handleOpen()} variant="contained">
        SIMULATE
      </TextButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer>
          {loading && <LinearProgress />}
          <Container style={{ opacity: loading && "0.5" }}>
            <Header>
              <HeaderText>What kind of result do you want?</HeaderText>
              <IconWrapper>
                <CrossIcon style={{ cursor: "pointer" }} onClick={() => handleClose()} />
              </IconWrapper>
            </Header>
            <Content>
              {data.map((d) => (
                <LeftContent key={d.title}>
                  <h1>{d.title}</h1>
                  <Description>
                    <div>{d.description}</div>
                    <div> {d.point_heading} </div>
                    <div style={{ paddingLeft: "6px" }}>
                      {d?.point_description.split(";")?.map((m, i) => (
                        <CustomListItem key={i}>{m}</CustomListItem>
                      ))}
                    </div>
                  </Description>
                  {d.email_toggle && (
                    <Email>
                      <NewSwitch
                        checked={emailNotification}
                        onChange={() => {
                          setEmailNotification((preStatus) => !preStatus);
                        }}
                      />
                      <p>Send an email when the detailed simulation result is ready.</p>
                    </Email>
                  )}
                  <div style={{ flexGrow: "1", display: "flex", justifyContent: "center", alignItems: "end" }}>
                    <TextButton onClick={() => simulateClickHandler(d.type)} variant="contained">
                      SELECT
                    </TextButton>
                  </div>
                </LeftContent>
              ))}
            </Content>
            <BottomText>
              We recommend selecting a high-level result first to form a perspective view, and only after that request a
              detailed result.
            </BottomText>
          </Container>
        </MainContainer>
      </Modal>
    </Box>
  );
}

function getSimulationPayload({
  apiMediaInputData,
  mediaInputData,
  apiNonMediaInputData,
  nonMediaInputData,
  period,
  simAdminConfigItems,
}) {
  const mediaInsertions = mapMediaStateToInsertions(apiMediaInputData, mediaInputData);
  const nonMediaInsertions = mapNonMediaStateToInsertions(apiNonMediaInputData, nonMediaInputData);
  const stateToSave = getSimulationInputStateToSave({
    apiMediaInputData,
    mediaInputData,
    apiNonMediaInputData,
    nonMediaInputData,
    period,
    simAdminConfigItems,
  });
  return {
    insertions: [...mediaInsertions, ...nonMediaInsertions],
    ...stateToSave,
    localRequestTime: moment().format("YYYY-MM-DD HH:mm:ss"), //to show local time in email just like we show in optimisation
  };
}
export function getSimulationInputStateToSave({
  apiMediaInputData,
  mediaInputData,
  apiNonMediaInputData,
  nonMediaInputData,
  period,
  simAdminConfigItems,
}) {
  //WARNING: apart from sim start api the local storage saving also follows the same structure, remember to update that flow as well if you update anything here.
  return {
    mediaTableState: { apiMediaInputData, mediaInputData },
    nonMediatableState: { apiNonMediaInputData, nonMediaInputData },
    period,
    simAdminConfigItems,
  };
}

const DescriptionText = styled("span")`
  color: #000;
  font-size: 12px;
  font-weight: 400;
`;

const Text = styled("span")`
  font-size: 14px;
  font-weight: 400;
`;

const Box = styled.div`
  --footer-width: 770px;
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  width: var(--footer-width);
  left: calc(50vw - var(--footer-width) / 2);
  height: 64px;
  bottom: 16px;
  background: #fff;
  padding: 13px 15px;
  box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.05);
  z-index: 999;
`;

const Container = styled.div`
  display: flex;
  width: 1200px;
  padding-bottom: 32px;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  background-color: white;
  padding: 0px 30px 0px 30px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 25px;
`;

const HeaderText = styled.div`
  color: #000;
  font-size: 20px;
  letter-spacing: 0.3px;
`;
const MainContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1200px;
  background-color: white;
`;
const BottomText = styled.div`
  color: #858574;
  font-size: 12px;
  line-height: 100%;
  margin-bottom: 32px;
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
`;

const Email = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 17px;
  p {
    color: #000;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.3px;
    font-weight: 400;
    margin: 0;
  }
`;

const LeftContent = styled.div`
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  height: 323px;
  font-weight: 300;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  background: #fff;
  box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.05);
  h1 {
    color: #000;
    font-size: 18px;
    letter-spacing: 0.3px;
    font-weight: 400;
    margin: 0;
  }
  div {
    color: ${({ theme }) => theme.palette.blackish.main};
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.3px;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  h1 {
    color: ${({ theme }) => theme.palette.blackish.main};
    font-size: 18px;
    letter-spacing: 0.3px;
  }
  div {
    color: ${({ theme }) => theme.palette.blackish.main};
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.3px;
  }
`;

export default Footer;
