import { getFilteringFunction } from "shared/helpers/filterOutUselessDimensionValues";

export default function generateInsertionsWithGroupingsCsv(dimensionValues, groupingToFilter) {
  // Notice how we're collapsing everything into a CSV formatted
  // string that one can copy directly into a spreadsheet
  let headers;
  const body = Object.keys(groupingToFilter)
    .map((grouping) =>
      dimensionValues
        .filter(getFilteringFunction(groupingToFilter[grouping]))
        .map((insertion) => {
          if (headers === undefined) headers = ["grouping", Object.keys(insertion)];
          return [
            grouping,
            ...Object.values(insertion).map((d) => (d === "valuemissing" || d === "blank" ? "" : d)),
          ].join(",");
        })
        .join("\n"),
    )
    .join("\n");
  const csv = headers + "\n" + body;

  return csv;
}
