import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

// To harmonise how large tooltips are shown accross the app
export default function CustomTooltip({
  content,
  tooltipMargin,
  children,
  placement,
  dark = false,
  tooltipStyleOverrides = {},
  ...props
}) {
  return (
    <Tooltip
      disableInteractive
      title={content}
      placement={placement ?? "right"}
      enterDelay={50} // So that I don't get many tooltips rendered when hovering the timeline
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 0 }} // I want it fast, no transition
      componentsProps={{
        tooltip: {
          sx: {
            color: dark ? "#f9f9f9" : "rgba(0, 0, 0, 0.87)",
            backgroundColor: dark ? "#323232" : "white",
            borderRadius: "0px",
            border: "0.5px solid rgba(0, 0, 0, 0.15)",
            boxShadow: !dark && "0px 4px 20px 3px rgba(0, 0, 0, 0.10)",
            fontSize: "inherit",
            fontWeight: "inherit",
            lineHeight: "inherit",
            padding: 0,
            maxWidth: "none",
            marginLeft: tooltipMargin && `${tooltipMargin} !important`,
            ...tooltipStyleOverrides,
          },
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
}
