import React from "react";
import {
  endOfYear,
  endOfQuarter,
  endOfMonth,
  endOfWeek,
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  isWithinInterval,
  addDays,
  addYears,
  getYear,
  getWeekOfMonth,
  format,
  getWeeksInMonth,
  getDaysInMonth,
  getDay,
  startOfWeek,
  startOfQuarter,
} from "date-fns";
import { styled } from "@mui/system";
import { prettyDate } from "shared/helpers/time";
import RenderIfVisible from "shared/components/RenderIfVisible";
import { useTheme } from "@mui/material/styles";
import { assert } from "chai";

export default function Calendar({
  granularity = "months",
  interval = null,
  visibleInterval = { start: undefined, end: undefined },
  allowedInterval = { start: undefined, end: undefined },
  selectedInterval,
  onHoverInterval,
  onClick,
  weekStartsOn,
  firstWeekContainsDate,
  disableDayGranularity,
}) {
  const chosenSectionEl = React.useRef(null);
  const scrollableEl = React.useRef(null);

  React.useEffect(() => {
    if (chosenSectionEl.current?.offsetTop)
      scrollableEl.current.scrollTop =
        chosenSectionEl.current.offsetTop > 60
          ? chosenSectionEl.current.offsetTop - 60
          : chosenSectionEl.current.offsetTop;
  }, [granularity]);

  function isDisabled(thisInterval) {
    if (!allowedInterval?.start || !allowedInterval?.end) return false;
    const bounds = { start: allowedInterval.start || thisInterval.start, end: allowedInterval.end || thisInterval.end };
    return [thisInterval.start, thisInterval.end].some((d) => !isWithinInterval(d, bounds));
  }

  function isChosen(thisInterval) {
    return (
      interval?.start &&
      interval?.end &&
      thisInterval?.start &&
      thisInterval?.end &&
      isWithinInterval(thisInterval.start, interval) &&
      isWithinInterval(thisInterval.end, interval)
    );
  }

  function isSelected(thisInterval) {
    return (
      selectedInterval?.start &&
      selectedInterval?.end &&
      thisInterval?.start &&
      thisInterval?.end &&
      isWithinInterval(thisInterval.start, selectedInterval) &&
      isWithinInterval(thisInterval.end, selectedInterval)
    );
  }

  const startYear = visibleInterval.start && format(visibleInterval.start, "yyyy");
  const endYear = visibleInterval.end && format(visibleInterval.end, "yyyy");
  const years = [-6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4] // Range from 6 years earlier to 4 years later
    .map((delta) => getYear(addYears(new Date(), delta)))
    .filter((year) => {
      if (startYear && year < startYear) return false;
      if (endYear && year > endYear) return false;
      return true;
    });

  function getDailyGranularityCalendar() {
    const selectedSectionId = format(interval?.start || new Date(), "yyyy-MM");
    const months = years.reduce(
      (acc, year) => [...acc, ...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((d) => ({ year, month: d }))],
      [],
    );
    // filtering out the visible month to get the exact count of visible month
    const filterVisibleMonth = months.filter(({ year, month }) => {
      const monthInterval = getInterval({ year, month, type: "month" });
      if (visibleInterval?.start && monthInterval.end < visibleInterval.start) return false;
      if (visibleInterval?.end && monthInterval.start > visibleInterval.end) return false;
      return true;
    });
    return filterVisibleMonth.map(({ year, month }, i) => {
      const monthInterval = getInterval({ year, month, type: "month" });
      const weeksInMonth = getWeeksInMonth(monthInterval.start, { weekStartsOn });
      const sectionId = format(monthInterval.start, "yyyy-MM");
      const skipFirstWeek = i !== 0 && getDayOfTheWeekPosition(monthInterval.start, weekStartsOn) > 0;
      const extendLastWeek = getDayOfTheWeekPosition(monthInterval.end, weekStartsOn) !== 6;
      const heightMultiple = 27;
      return (
        <RenderIfVisible
          key={sectionId}
          ref={sectionId === selectedSectionId ? chosenSectionEl : null}
          defaultHeight={heightMultiple * weeksInMonth}
        >
          <div
            style={{
              position: "relative",
              // add margin only for last item to make the bottom part visible as the other element is position:absolute
              marginBottom: filterVisibleMonth.length > 1 && i === filterVisibleMonth.length - 1 ? "75px" : null,
              height: heightMultiple * weeksInMonth,
            }}
          >
            <DefinedPeriod
              style={{
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                padding: 10,
                ...(isDisabled(monthInterval) ? DISABLED_STYLES : {}),
              }}
              onMouseEnter={() => onHoverInterval(monthInterval)}
            >
              {prettyDate(monthInterval.start, { interval: "month", showYear: true })}
            </DefinedPeriod>

            {[...Array(weeksInMonth).keys()].map((d, i, arr) => {
              const weekInterval = getInterval({ year, month, day: 1 + i * 7, type: "week", weekStartsOn });

              if (i === 0 && skipFirstWeek) return null;

              return (
                <DefinedPeriod
                  key={i}
                  style={{
                    left: 85,
                    right: 0,
                    top: i * heightMultiple,
                    ...(isDisabled(weekInterval) ? DISABLED_STYLES : {}),
                    height: i === arr.length - 1 && extendLastWeek ? 2 * heightMultiple : undefined,
                    zIndex: 100 /* To select 2 row weeks */,
                  }}
                  onMouseEnter={() => onHoverInterval(weekInterval)}
                >
                  W{format(weekInterval.start, "I", { weekStartsOn, firstWeekContainsDate })}
                </DefinedPeriod>
              );
            })}

            {[...Array(getDaysInMonth(monthInterval.start, { weekStartsOn })).keys()].map((i) => {
              const dayInterval = getInterval({ year, month, day: 1 + i, type: "day", weekStartsOn });

              return (
                <DefinedTargetPeriod
                  title={format(dayInterval.start, "iii, MMM dd")}
                  key={i}
                  style={{
                    left: 150 + getDayOfTheWeekPosition(dayInterval.start, weekStartsOn) * 28,
                    width: 28,
                    top: (getWeekOfMonth(dayInterval.start, { weekStartsOn }) - 1) * heightMultiple,
                    display: "flex",
                    justifyContent: "center",
                    ...(isDisabled(dayInterval) ? DISABLED_STYLES : {}),
                    ...(disableDayGranularity ? { pointerEvents: "none" } : {}),
                    zIndex: 101,
                  }}
                  selected={isSelected(dayInterval)}
                  chosen={isChosen(dayInterval)}
                  onMouseEnter={() => onHoverInterval(dayInterval)}
                >
                  {i + 1}
                </DefinedTargetPeriod>
              );
            })}
          </div>
        </RenderIfVisible>
      );
    });
  }

  function getDayOfTheWeekPosition(date, weekStartsOn) {
    assert.isDefined(weekStartsOn, "weekStartsOn is needed to get the day of the week");
    const dayOfWeek = getDay(date, { weekStartsOn });
    return weekStartsOn === 1 ? (dayOfWeek === 0 ? 6 : dayOfWeek - 1) : dayOfWeek;
  }

  function getMonthlyGranularityCalendar() {
    const selectedSectionId = format(interval.start || new Date(), "yyyy");
    return years.map((year, yearIndex) => {
      const yearInterval = getInterval({ year, month: 0, type: "year" });
      const sectionId = format(yearInterval.start, "yyyy");

      return (
        <div
          key={sectionId}
          ref={sectionId === selectedSectionId ? chosenSectionEl : null}
          style={{
            // adding margin-top as we removed "NoMoreHere" element
            marginTop: yearIndex === 0 && "38.5px",
            position: "relative",
            height: 105,
            borderTop: "1px solid #f5f5f5",
          }}
        >
          <DefinedPeriod
            style={{ left: 0, top: 0, right: 0, bottom: 0, ...(isDisabled(yearInterval) ? DISABLED_STYLES : {}) }}
            onMouseEnter={() => onHoverInterval(yearInterval)}
          >
            {year}
          </DefinedPeriod>

          {["Q1", "Q2", "Q3", "Q4"].map((quarter, i) => {
            const quarterInterval = getInterval({ year, month: i * 3, type: "quarter" });

            return (
              <DefinedPeriod
                key={quarter}
                style={{
                  left: 100,
                  right: 0,
                  top: `${i * 25}%`,
                  ...(isDisabled(quarterInterval) ? DISABLED_STYLES : {}),
                }}
                onMouseEnter={() => onHoverInterval(quarterInterval)}
              >
                {quarter}
              </DefinedPeriod>
            );
          })}

          {["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].map((month, i) => {
            const monthInterval = getInterval({ year, month: i, type: "month" });

            return (
              <DefinedTargetPeriod
                key={month}
                style={{
                  left: `${45 + (i % 3) * 16}%`,
                  top: `${Math.floor(i / 3) * 25}%`,
                  width: 60,
                  ...(isDisabled(monthInterval) ? DISABLED_STYLES : {}),
                  display: "flex",
                  justifyContent: "center",
                }}
                selected={isSelected(monthInterval)}
                chosen={isChosen(monthInterval)}
                onMouseEnter={() => onHoverInterval(monthInterval)}
              >
                {month}
              </DefinedTargetPeriod>
            );
          })}
        </div>
      );
    });
  }

  const daysOfTheWeekHeader = useDaysOfTheWeekHeader(weekStartsOn);
  return (
    <>
      {granularity === "days" && daysOfTheWeekHeader}
      <ScrollingPickerFrame
        className="customScroll"
        style={{ fontSize: 14 }}
        ref={scrollableEl}
        onMouseLeave={() => onHoverInterval(null)}
        onClick={onClick}
      >
        {/* <NoMoreHere /> */}
        {granularity === "days" && getDailyGranularityCalendar()}
        {granularity === "months" && getMonthlyGranularityCalendar()}
        {/* <NoMoreHere /> */}
      </ScrollingPickerFrame>
    </>
  );
}

function getInterval({ year, month = 0, day = 1, type, weekStartsOn }) {
  const ref = new Date(year, month, day);
  switch (type) {
    case "year":
      return { start: startOfYear(ref), end: endOfYear(ref) };
    case "quarter":
      return { start: startOfQuarter(ref), end: endOfQuarter(ref) };
    case "month":
      return { start: startOfMonth(ref), end: endOfMonth(ref) };
    case "week":
      return { start: startOfWeek(ref, { weekStartsOn }), end: endOfWeek(ref, { weekStartsOn }) };
    default:
    case "day":
      return { start: startOfDay(ref), end: endOfDay(ref) };
  }
}

// Shows something like M T W T F S S, for the daily/weekly view
function useDaysOfTheWeekHeader(weekStartsOn) {
  const theme = useTheme();
  const daysOfTheWeek = React.useMemo(
    () => [...Array(7)].map((d, i) => format(addDays(startOfWeek(new Date(), { weekStartsOn }), i), "EEEEE")),
    [weekStartsOn],
  );
  return (
    <DaysOfTheWeekHeaderFrame style={{ color: theme.palette.greyish[50] }}>
      {daysOfTheWeek.map((dayCode, i) => (
        <div key={i}>{dayCode}</div>
      ))}
    </DaysOfTheWeekHeaderFrame>
  );
}

const DaysOfTheWeekHeaderFrame = styled("div")`
  display: flex;
  background: #fafafa;
  margin-top: 32px;
  padding-left: 150px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.greyish[20]};
  font-size: 14px;
  height: 31px;

  & > div {
    width: 28px;
    padding: 4px 0 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const DISABLED_STYLES = {
  color: "#D4D4C9",
  pointerEvents: "none",
};

const BaseThing = styled("div")`
  position: absolute;
  user-select: none;
  text-align: left;
  padding: 5px;
  cursor: pointer;
`;

const DefinedPeriod = styled(BaseThing)`
  position: absolute;
  user-select: none;
  text-align: left;
  padding: 5px;
  color: ${({ theme }) => theme.palette.greyish[50]};

  :hover {
    background-color: #f5f5f5;
  }
`;

function DefinedTargetPeriod({ chosen, selected, children, style = {}, onMouseEnter, title }) {
  const theme = useTheme();
  return (
    <BaseThing
      title={title}
      style={{
        color: (function () {
          if (chosen) return "white";
          else return "inherit";
        })(),
        backgroundColor: (function () {
          if (selected && chosen) return theme.palette.primary.light;
          if (chosen) return theme.palette.primary.main;
          if (selected) return theme.palette.greyish[20];
          else return "inherit";
        })(),
        ...style,
      }}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </BaseThing>
  );
}

const ScrollingPickerFrame = styled("div")`
  --vertical-scrollbar-margin-bottom: 70px;
  isolation: isolate; /* So that the use of z-index doesn't bleed out */
  width: 100%;
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  /* Scrollbars are tricky to style, for now I'm disabling it */
  /* &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  } */
`;

// feedback from UX: need to remove the blank gray area.
// const NoMoreHere = styled("div")`
//   height: 110px;
//   background: #eeeeee;
//   border-top: 1px solid #f5f5f5;
//   pointer-events: none;
// `;
