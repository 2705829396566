const toTitleCase = (phrase) => {
  if (!phrase) return "null";
  return phrase
    ?.toLowerCase()
    ?.split(" ")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(" ");
};

/*
  opts: {returnNull: true} if id is null it will return null back.
*/
const formattedLabel = (labelDictionary, id, dimension, opts = {}) => {
  if (id === "blank" || id === "valuemissing") {
    // we have to show valuemissing as Untagged
    return "Untagged";
  }
  if (id === null && opts.returnNull) {
    return "null";
  }
  if (id === undefined) {
    return undefined;
  }
  const key = id ? `${dimension}.${id}` : dimension;
  const defaultLabel = id ? toTitleCase(id) : toTitleCase(dimension);
  return labelDictionary[key] || defaultLabel;
};

export default formattedLabel;
