import { InputAdornment, MenuItem, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Fade from "@mui/material/Fade";
import ListItem from "@mui/material/ListItem";
import Menu from "@mui/material/Menu";
import { useTheme } from "@mui/styles";
import { produce } from "immer";
import React from "react";
import { ReactComponent as SearchIcon } from "shared/Icons/Magnifier.svg";
import DeleteIconButton from "shared/components/DeleteIconButton";
import EditorItem from ".";
import { Cell, TableLayout, Title } from "./layoutComponents";

export default function EditorItemForArraysV2(props) {
  if (props.schema.items?.enum) return <DropdownVariant {...props} />;
  const { schema, data = [], onChange, onNavigate } = props;
  const headers =
    schema.items?.ui__openSeparatellyWith || data.length === 0
      ? null
      : Object.entries(schema.items?.properties || {}).map(([key, props]) => <Title key={key} {...props} />);

  if (!data.map) console.log("DATA .MAP", data, schema);

  return (
    <TableLayout columns={headers?.length || 1} extra={45}>
      {headers && headers.length > 0 && (
        <>
          {headers}
          <Cell />
        </>
      )}

      {data.map((d, i) => (
        <React.Fragment key={i}>
          <EditorItem
            {...props}
            withinTable
            schema={schema.items}
            data={d}
            onNavigate={onNavigate}
            onChange={(localOnChange) =>
              onChange(
                produce((s) => {
                  if (s) s[i] = localOnChange(s[i]);
                }),
              )
            }
          />

          <Cell>
            {!props.isDisabled && !schema.ui__readOnly && (
              <DeleteIconButton onClick={() => onChange((prev) => prev.filter((d, j) => i !== j))} />
            )}
          </Cell>
        </React.Fragment>
      ))}

      {!props.isDisabled && !schema.ui__readOnly && (
        <div style={{ padding: "12px 12px" }}>
          <Button
            variant="outlined"
            disabled={
              schema.items.ui__uniqueKey && schema.items.properties[schema.items.ui__uniqueKey]?.enum.length === 0
            }
            color="primary"
            onClick={() => {
              return onChange((prev) => [...(prev || []), schema.items?.default]);
            }}
          >
            + Add
          </Button>{" "}
          {data.length === 0 && (
            <span style={{ padding: "10px 12px", opacity: 0.5 }}>{`No ${
              schema?.title?.toLowerCase() || "items"
            }`}</span>
          )}
        </div>
      )}
    </TableLayout>
  );
}

function DropdownVariant({
  schema,
  data = [],
  onChange,
  isNew,
  isDisabled,
  showLabel = true,
  search = false,
  extraStyles = {},
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const options = schema.items.enum || [];
  const getLabel = (slug) => schema.items.ui__labels?.[slug] || slug;
  const disabled = isDisabled || schema.ui__readOnly || !onChange;

  const itemsToShow = [...options]
    .filter((a) => a !== undefined && a !== null)
    .filter((a) => getLabel(a).toLowerCase().includes(searchText))
    .sort((a, b) => data.includes(b) - data.includes(a));
  const theme = useTheme();
  const assignedItems = data.filter((d) => schema.items?.enum.includes(d));
  return (
    <>
      <Cell
        onClick={(e) => setAnchorEl(e.currentTarget)}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          padding: "10px 14px",
          ...extraStyles,
        }}
      >
        <span
          style={{
            width: "calc(100% - 20px)",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            opacity: 1,
            color: anchorEl !== null && theme.palette.primary.main,
            fontWeight: anchorEl !== null && 700,
          }}
        >
          {assignedItems.length > 0
            ? `${assignedItems.length}/${schema.items?.enum?.length}` +
              (showLabel ? ` · ${assignedItems.map(getLabel).join(", ")}` : "")
            : isNew
              ? `${schema?.title || "items"}`
              : `${assignedItems.length}/${schema.items?.enum?.length}`}
        </span>
      </Cell>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: isNew ? "top" : "bottom", horizontal: "left" }}
        transformOrigin={{
          vertical: isNew ? "bottom" : "top", //for add new users section,  popover will open above otherwise bottom
          horizontal: "left",
        }}
        TransitionComponent={Fade}
        MenuListProps={{ disablePadding: true }}
      >
        {search ? (
          <ListItem
            dense
            key="Search"
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 2,
              padding: 0,
            }}
          >
            <TextField
              key={"search"}
              sx={{ width: "100%" }}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
              hiddenLabel
              id="user-search"
              variant="filled"
              placeholder="Search by org name"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLowerCase())}
            />
          </ListItem>
        ) : (
          <MenuItem
            key="all-items"
            style={{
              cursor: disabled ? "not-allowed" : "pointer",
              borderBottom: `1px solid ${theme.palette.greyish[50]}`,
              height: "38px",
              display: "flex",
              gap: "8px",
              alignItems: "center",
              color: "#000",
              background: "#fafafa",
              fontSize: "12px",
              padding: "4px 8px",
              position: "sticky",
              top: 0,
              zIndex: 2,
            }}
            onClick={onChange && (() => onChange(() => (data?.length > 0 ? [] : [...options])))}
          >
            {!disabled && (
              <Checkbox
                disabled={disabled}
                checked={assignedItems.length > 0}
                tabIndex={-1}
                indeterminate={assignedItems.length !== 0 && assignedItems.length !== schema.items.enum.length}
                disableRipple
                color="primary"
                style={{ padding: 0 }}
                size="large"
              />
            )}
            <span>{`${isDisabled ? "Accessible" : "All"} ${schema?.title || "items"}`}</span>
          </MenuItem>
        )}

        {itemsToShow.length === 0 && <div style={{ margin: 10 }}>There are no options available.</div>}

        <div style={{ maxHeight: 250, overflow: "auto" }} className="customScroll">
          {itemsToShow.map((option) => (
            <MenuItem
              key={option || "unknown"}
              style={{
                padding: "4px 8px",
                fontSize: "12px",
                height: "38px",
                minWidth: "240px",
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
              disabled={isDisabled && !data?.includes(option)}
              onClick={
                onChange &&
                (() =>
                  onChange(
                    // Keep the same order in the enum
                    () =>
                      [...options].filter((d) =>
                        d === option ? !assignedItems.includes(d) : assignedItems.includes(d),
                      ),
                  ))
              }
            >
              {!disabled && (
                <Checkbox
                  disabled={disabled}
                  checked={assignedItems?.includes(option)}
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                  style={{ padding: 0 }}
                  size="large"
                />
              )}
              <span>{getLabel(option)}</span>
            </MenuItem>
          ))}
        </div>
      </Menu>
    </>
  );
}
