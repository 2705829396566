import backend from "backend";
import _ from "lodash";
import { filterV3Dimensions } from "shared/helpers/filterV3Dimensions";
export default function useDimensionValuesV3(modelSlug, opts = {}) {
  const endpoint = opts.onlyMedia ? "media" : "decomposition";
  const { data: dimensionValues = [] } = backend.useData(
    `v3/historical/${modelSlug}/${endpoint}/dimension-values`,
    {},
    {
      dedupingInterval: 180 * 1000, // Do not revalidate very often
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  if (opts.onlyMedia) {
    //Remove effect_type and driver_type to use in MI+ related components
    return dimensionValues.map((obj) => _.omit(obj, ["driver_type", "effect_type"]));
  }

  return filterV3Dimensions(dimensionValues);
}
