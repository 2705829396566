import { getClaimsToken } from "auth";
import useSWR from "swr";

const fetcher = (url, headers) => fetch(url, { headers }).then((res) => res.json());

export function useData(organization) {
  const { isLoading: usersLoading, data: users } = useSWR(`/backend-api/auth/v2/user/getall/${organization}`, (url) =>
    fetcher(url, { Authorization: `Bearer ${getClaimsToken()}` }),
  );

  const { isLoading: confLoading, data: conf } = useSWR(
    `/backend-api/admin/v1/organization-confs/${organization}`,
    (url) => fetcher(url, { Authorization: `Bearer ${getClaimsToken()}` }),
  );
  const globallyAvailableFeatures = Object.entries(conf?.prod?.conf?.features || {}).map(([id, data]) => data.name);
  const globallyAvailableModels = Object.entries(conf?.prod?.conf?.models || {})
    .filter(([slug, data]) => data.available)
    .map(([slug]) => slug);

  const modelsLabels = Object.assign(
    {},
    ...Object.entries(conf?.prod?.conf?.models || {}).map(([slug, data]) => ({
      [slug]: `${data.brand} ${data.name}`,
    })),
  );

  return {
    loading: confLoading || usersLoading,
    users,
    globallyAvailableFeatures,
    globallyAvailableModels,
    modelsLabels,
  };
}
