import React from "react";

// Adapted from NightCafeStudio's idea: https://github.com/NightCafeStudio/react-render-if-visible/blob/main/src/render-if-visible.tsx
export default React.forwardRef(function RenderIfVisible(
  { initialVisible = false, defaultHeight = 300, visibleOffset = 1000, stayRendered = false, children },
  ref,
) {
  const [isVisible, setIsVisible] = React.useState(initialVisible);
  const wasVisible = React.useRef(initialVisible);
  const placeholderHeight = React.useRef(defaultHeight);
  const intersectionRef = React.useRef(null);

  React.useEffect(() => {
    if (intersectionRef.current) {
      const localRef = intersectionRef.current;
      const observer = new IntersectionObserver(
        (entries) => {
          if (!entries[0].isIntersecting) placeholderHeight.current = localRef.offsetHeight;
          // Only on browsers
          if (typeof window !== "undefined" && window.requestIdleCallback)
            window.requestIdleCallback(() => setIsVisible(entries[0].isIntersecting), { timeout: 600 });
          else setIsVisible(entries[0].isIntersecting);
        },
        { root: null, rootMargin: `${visibleOffset}px 0px ${visibleOffset}px 0px` },
      );

      observer.observe(localRef);
      return () => {
        if (localRef) observer.unobserve(localRef);
      };
    }
    return () => {};
  }, [visibleOffset]);

  React.useEffect(() => {
    if (isVisible) wasVisible.current = true;
  }, [isVisible]);

  return React.createElement("div", {
    children:
      isVisible || (stayRendered && wasVisible.current) ? (
        <>{children}</>
      ) : (
        React.createElement("div", {
          ref,
          className: "renderIfVisible-placeholder",
          style: { height: placeholderHeight.current },
        })
      ),
    ref: intersectionRef,
    className: "renderIfVisible",
  });
});
