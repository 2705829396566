import React from "react";
import BroadcastStationEditor from "./BroadcastEditor";

import "./broadcaststation.css";
import BroadcastList from "./BroadcastListitem/broadcastList";

export default function BroadcastStationControl({ broadcastEditorState, setBroadcastEditorState }) {
  return (
    <div className="broadcaststation__body">
      {broadcastEditorState.isEditorOpen ? (
        <div className="broadcaststation__editor">
          <BroadcastStationEditor
            setBroadcastEditorState={setBroadcastEditorState}
            broadcastEditorState={broadcastEditorState}
          />
        </div>
      ) : null}
      <BroadcastList setBroadcastEditorState={setBroadcastEditorState} broadcastEditorState={broadcastEditorState} />
    </div>
  );
}
