import styled from "@emotion/styled";
import { Divider, IconButton, List } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { useEffect, useState } from "react";
import BroadcastItem from "root/BroadcastPanel/BroadcastItem";
import store from "store";
import { notifyNew } from "store/action-creators";
import useSWR, { mutate } from "swr";

//Action button Icons
import { ReactComponent as ActivateBroadcastIcon } from "./Assets/Activate.svg";
import { ReactComponent as DeactivateBroadcastIcon } from "./Assets/Deactivate.svg";
import { ReactComponent as DeleteBroadcastIcon } from "./Assets/Delete.svg";
import { ReactComponent as EditBroadcastIcon } from "./Assets/Edit.svg";

//bevled rectangle backgrounds for action buttons
import LargeBackground from "./Assets/beveled-rectangle-large.svg";
import SmallBackground from "./Assets/beveled-rectangle-small.svg";

const BroadcastList = ({ broadcastEditorState, setBroadcastEditorState }) => {
  const editWindowTime = 600; // in seconds (10 minute * 60 = 600 seconds)
  const currentDateTime = new Date().toISOString(); // get Current date time to calculate edit window

  const fetcher = (url, headers) => {
    return fetch(url, { headers }).then(async (res) =>
      res.ok ? res.json() : Promise.reject(new Error(await res.text())),
    );
  };

  const {
    data: broadcastItems = [],
    isLoading,
    isValidating,
  } = useSWR(
    `/backend-api/admin/v1/broadcasts`,
    (url) => fetcher(url, { msal_user_auth: sessionStorage.getItem("msalToken") }),
    {
      dedupingInterval: 60 * 1000, // Do not revalidate very often
      revalidateOnFocus: true,
      revalidateOnReconnect: false,
      refreshInterval: 120 * 1000,
    },
  );

  const handlerDeleteBroadcast = (broadcastId) => {
    mutate(
      `/backend-api/admin/v1/broadcasts`,
      broadcastItems.filter((item) => {
        return item.id !== broadcastId;
      }),
      false, // Signals optimistic update
    );

    fetch(`/backend-api/admin/v1/broadcasts/${broadcastId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", msal_user_auth: sessionStorage.getItem("msalToken") },
    })
      .then(async (res) => (res.ok ? res.json() : Promise.reject(new Error(await res.text()))))
      .then(() => {
        store.dispatch(
          notifyNew({
            message: `Broadcast removed`,
            horizontalPosition: "right",
            action: {
              text: "Undo",
              onClick: () =>
                // If the user clicks UNDO, we call a recover endpoint undeleting the broadcast
                // Not an existing endpoint, would need to be added. Or maybe the update endpoint could
                // be used to set isDeleted to false?
                fetch(`/backend-api/admin/v1/broadcasts/recover`, {
                  method: "POST",
                  body: JSON.stringify({ broadcastId }),
                  headers: { "Content-Type": "application/json", msal_user_auth: sessionStorage.getItem("msalToken") },
                })
                  .then(async (res) => (res.ok ? res.json() : Promise.reject(new Error(await res.text()))))
                  .then(() => mutate(`/backend-api/admin/v1/broadcasts`)) // Tells useSWR to refresh the list
                  .catch((err) => {
                    store.dispatch(
                      notifyNew({
                        message: `We could not recover the broadcast`,
                        isError: true,
                      }),
                    );
                  }),
            },
          }),
        );
      })
      .catch((err) => {
        store.dispatch(notifyNew({ message: `Error while deleting broadcast...`, isError: true }));
      })
      .then(() => {
        mutate(`/backend-api/admin/v1/broadcasts`); // Revalidate the list of broadcasts
      });
  };

  const toggleBroadcastStatus = (broadcastId, updates) => {
    // First optimistic update of the list with the new changes
    mutate(
      `/backend-api/admin/v1/broadcasts`,
      broadcastItems.map((item) => (item.id === broadcastId ? { ...item, ...updates } : item)),
      false, // Signals optimistic update
    );
    // Then actual update of the backend data
    return fetch(`/backend-api/admin/v1/broadcasts/${broadcastId}`, {
      method: "PUT",
      body: JSON.stringify(updates),
      headers: { "Content-Type": "application/json", msal_user_auth: sessionStorage.getItem("msalToken") },
    })
      .then(async (res) => (res.ok ? res.json() : Promise.reject(new Error(await res.text()))))
      .catch((err) => {
        // Notify the user in case of error
        store.dispatch(notifyNew({ message: `Failed to update broadcast`, isError: true }));
      })
      .then(() => {
        mutate(`/backend-api/admin/v1/broadcasts`); // Revalidate the list of broadcasts
      });
  };

  const updateBroadCast = ({ item }) => {
    if (broadcastEditorState.isEditorOpen) {
      // Tell user to discard current draft before editing another
      store.dispatch(notifyNew({ message: `Discard the current draft in the editor before editing new item.` }));
    }
    setBroadcastEditorState((previousState) => ({
      ...previousState,
      isEditorOpen: true,
      type: "edit",
      currEditBroadcast: item,
    }));
  };

  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  if (!isValidating && broadcastItems?.length === 0) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>No information to report at this point in time.</div>
    );
  }

  return (
    <div className="broadcaststation__body">
      <List>
        {broadcastItems.map((item) => (
          <div key={item.id}>
            <div className="broadcaststation__list-item">
              <ActionButtonsContainer showEditIcon={editWindowTime - diff_seconds(currentDateTime, item.date) > 0}>
                {diff_seconds(currentDateTime, item.date) < editWindowTime ? (
                  <CountDown
                    seconds={editWindowTime - diff_seconds(currentDateTime, item.date)}
                    handleClick={() => updateBroadCast({ item })}
                  />
                ) : null}
                {item.status === "active" ? (
                  <CustomIconButton
                    aria-label="Deactivate broadcast"
                    title="Deactivate broadcast"
                    onClick={() => toggleBroadcastStatus(item.id, { status: "inactive" })}
                    iconSrc={DeactivateBroadcastIcon}
                  />
                ) : (
                  <CustomIconButton
                    aria-label="Activate broadcast"
                    title="Activate broadcast"
                    onClick={() => toggleBroadcastStatus(item.id, { status: "active" })}
                    iconSrc={ActivateBroadcastIcon}
                  />
                )}
                <CustomIconButton
                  aria-label="Delete broadcast"
                  title="Delete broadcast"
                  onClick={() => handlerDeleteBroadcast(item.id)}
                  iconSrc={DeleteBroadcastIcon}
                />
              </ActionButtonsContainer>
              <BroadcastItem item={item} />
            </div>
            <Divider />
          </div>
        ))}
      </List>
    </div>
  );
};

const CountDown = ({ seconds, handleClick }) => {
  const [timeLeft, setTimeLeft] = useState(seconds);
  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);
  return timeLeft > 0 ? (
    <CustomIconButton
      aria-label="Edit broadcast"
      title="Edit broadcast"
      onClick={() => handleClick()}
      iconSrc={EditBroadcastIcon}
    />
  ) : null;
};

function CustomIconButton({ iconSrc: SrcIcon, ...props }) {
  return (
    <IconButton {...props}>
      <SrcIcon style={{ width: 24, height: 24 }} />
    </IconButton>
  );
}

const ActionButtonsContainer = styled.div`
  background: url(${(props) => (props.showEditIcon ? LargeBackground : SmallBackground)});

  width: ${(props) => (props.showEditIcon ? "170px" : "120px")};
  margin-left: auto;
  margin-right: 0;

  display: flex;
  justify-content: flex-end;
  padding: 6px 16px 6px 32px;
`;

const diff_seconds = (dt2, dt1) => {
  let duration = moment.duration(moment(dt2).diff(moment(dt1)));
  let minutes = duration.asMinutes();
  return minutes * 60;
};

export default BroadcastList;
