import React from "react";

export default function useAutocomplete({
  filteredList,
  noResults,
  indexWithBestScore,
  onSelect,
  onRemoveNearest,
  onEsc,
}) {
  // This is to keep the state when the user clicks up/down arrows
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const selectedItem = filteredList[selectedIndex] || null;

  React.useEffect(() => {
    if (noResults) setSelectedIndex(null);
    else setSelectedIndex(indexWithBestScore);
  }, [noResults, indexWithBestScore]);

  function onKeyDown({ key }) {
    switch (key) {
      case "Escape":
        onEsc();
        break;
      case "Enter":
        if (selectedItem) {
          setSelectedIndex(null);
          onSelect(selectedItem);
        }
        break;
      case "Backspace":
        onRemoveNearest();
        break;
      case "ArrowDown":
        if (selectedIndex === null) setSelectedIndex(0);
        // Complete list, start with 0
        else if (selectedIndex < filteredList.length - 1) setSelectedIndex(selectedIndex + 1);
        break;
      case "ArrowUp":
        if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
        break;
      default:
    }
  }

  return {
    bind: { onKeyDown },
    selectedItem,
  };
}
