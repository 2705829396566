import backend from "backend";
import buildOptimizationParams from "./buildOptimizationParams";

function validateRequest({
  socketSlug,
  whatSocketCallsModelslug,
  objective,
  isAdding,
  addedOrEditedConstraint,
  useExperimentalOptimizer,
  activityV2StateData,
  activityV2StateInlineEditor,
}) {
  const data = buildOptimizationParams({
    objective,
    modelslug: whatSocketCallsModelslug,
    isAdding,
    useExperimentalOptimizer,
    extraConstraint: addedOrEditedConstraint,
    activityV2StateData,
    activityV2StateInlineEditor,
  });

  return backend
    .post(`v3/predictions/${socketSlug}/validate`, data)
    .then((res) => {
      console.log("Contraints validated:", res);
      return res;
    })
    .catch((err) => {
      console.error("Error while verifying constraints", err);
      // Try to find out what's going on with these errors
      // activityV2NotifyValidationError("Internal system error, please try again");
    });
}

export default validateRequest;
