import styled from "@emotion/styled/macro";
import { Icon } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import useIdToColor from "pages/business-insights-plus/useIdToColor";
import idToColor from "pages/business-insights/idToColor"; // To be moved up
import React from "react";
import formattedLabel from "shared/helpers/formattedLabel";
import { ReactComponent as ArrowIcon } from "shared/Icons/chevron-black.svg";

export default function OptionsForOneDimension({
  dimension,
  options,
  expandedOverride,
  onSelect,
  labelDictionary = {},
}) {
  const theme = useTheme();
  const driverTypeToColor = idToColor(undefined);
  const driverCustomToColor = useIdToColor({ opts: { getDriverCustomColor: true } });
  const [expanded, setExpanded] = React.useState(expandedOverride); // Only groupings start expanded

  const anyChecked = options.some((d) => d.chosen);
  const optionsCount = options.length;
  const checkedCount = options.filter((d) => d.chosen).length;
  const anySelected = options.some((d) => d.selected);

  React.useEffect(() => {
    if (expandedOverride) setExpanded(true);
    else setExpanded(false);
  }, [expandedOverride]);
  return (
    <React.Fragment key={dimension}>
      <ListItem
        key={dimension}
        style={{ cursor: "pointer", padding: "4px 8px" }}
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => setExpanded((expanded) => !expanded)}
      >
        <DimensionLabel
          primary={
            <>
              <span data-test-id={labelDictionary[dimension] || dimension}>{`${formattedLabel(
                labelDictionary,
                null,
                dimension,
              )}`}</span>
              {checkedCount > 0 ? (
                <>
                  <span style={{ marginLeft: 10, color: theme.palette.primary.main }}>{checkedCount}</span>
                  <span style={{ color: theme.palette.greyish[50], marginLeft: 6 }}>/</span>
                  <span style={{ color: theme.palette.greyish[25] }}>{` ${optionsCount}`}</span>
                </>
              ) : (
                <span style={{ color: theme.palette.greyish[25], marginLeft: 10 }}>
                  {optionsCount > 0 ? optionsCount : ""}
                </span>
              )}
            </>
          }
          style={{ color: theme.palette.greyish[50] }}
          primaryTypographyProps={{ fontSize: "12px" }}
        />
        {!anySelected && (
          <ArrowIcon
            style={{ transform: expanded ? "rotate(0deg)" : "rotate(180deg)", transition: "transform 0.2s ease" }}
          />
        )}
      </ListItem>
      <Collapse in={anySelected || expanded}>
        {[
          ...options.map((d) => ({
            ...d,
            label:
              d.value === "valuemissing" || d.value === "blank"
                ? "Untagged"
                : formattedLabel(labelDictionary, d.value, d.dimension),
          })),
        ]
          .sort((a, b) => a.label.localeCompare(b.label))
          .map(({ value, label, selected, chosen }) => (
            <ListItem
              button
              key={value}
              selected={selected}
              onMouseDown={(e) => e.preventDefault()}
              onClick={(e) => onSelect({ value, dimension })}
              style={{ height: 38, padding: "4px 8px", gap: 8 }}
            >
              {dimension !== "grouping" && ( // Groupings don't need a checkbox, because they cannot be toggled
                <StyledIcon>
                  <Checkbox
                    size="large"
                    checked={chosen || false}
                    tabIndex={-1}
                    indeterminate={!anyChecked}
                    disableRipple
                    color="primary"
                    sx={{ padding: 0, "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  />
                </StyledIcon>
              )}
              <ListItemText
                // See https://blackwoodseven.slack.com/archives/CJW82FTK6/p1579251863004000
                // See also https://blackwoodseven.slack.com/archives/CJW82FTK6/p1613033636010500?thread_ts=1613028808.008500&cid=CJW82FTK6
                primary={
                  <>
                    {dimension === "driver_type" ? (
                      <CategoryDot style={{ backgroundColor: driverTypeToColor(value) }} />
                    ) : null}
                    {dimension === "driver-custom" ? (
                      <CategoryDot style={{ backgroundColor: driverCustomToColor(value) }} />
                    ) : null}
                    <>{label}</>
                  </>
                }
                primaryTypographyProps={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              />
            </ListItem>
          ))}
      </Collapse>
    </React.Fragment>
  );
}

const DimensionLabel = styled(ListItemText)`
  text-transform: uppercase;
`;

const CategoryDot = styled.span`
  height: 14px;
  width: 14px;
  border-radius: 50%;
`;

const StyledIcon = styled(Icon)`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
`;
