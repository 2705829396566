import styled from "@emotion/styled/macro";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/styles";
import { readableStamp } from "admin/helpers";
import { useNavigate } from "react-router-dom";

export default function AvailableOrgs({ organizationsList }) {
  const navigate = useNavigate();
  return (
    <List style={{ padding: 0, paddingBottom: "var(--bottom-buffer)" }}>
      {[...organizationsList]
        .sort(({ orgName: a }, { orgName: b }) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
        .map((d) => (
          <OrganizationListItem key={d.orgSlug} {...d} onSelect={(id) => navigate(`/?org=${id}`)} />
        ))}
    </List>
  );
}

function OrganizationListItem({
  organization,
  orgName,
  orgSlug,
  id,
  lastChanged,
  author,
  hasValidationDraft,
  validationLastChanged,
  validationAuthor,
  userCount = 0,
  onSelect,
}) {
  const theme = useTheme();
  return (
    <ListItem
      key={organization}
      onClick={() => onSelect(id, orgSlug, orgName)}
      button
      style={{
        background: "white",
        borderBottom: `1px solid ${theme.palette.greyish[20]}`,
        //borderTop: i === 0 ? "1px solid lightgray" : undefined,
        padding: "15px 15px 10px 15px",
      }}
    >
      <ListItemText
        primary={
          <>
            {orgName}
            {hasValidationDraft && (
              <>
                <DraftBatch />
                <DraftUpdated>{readableStamp(validationLastChanged, validationAuthor)}</DraftUpdated>
              </>
            )}
          </>
        }
        secondary={<>Taken to production {readableStamp(lastChanged, author)}</>}
        style={{ margin: 0, display: "flex", flexDirection: "column", gap: 4 }}
        sx={{ "& .MuiListItemText-secondary": { color: theme.palette.greyish[50] } }}
      />
    </ListItem>
  );
}

const DraftBatch = styled.span`
  margin-left: 8px;
  color: white;
  font-weight: 400;
  font-size: 14px;
  background: #ffa000;
  padding: 2px 8px;
  border-radius: 0px;
  &:before {
    content: "draft";
  }
`;

const DraftUpdated = styled.span`
  margin-left: 6px;
  font-size: 14px;
  color: #ffa000;
  line-height: 1.4;
`;
