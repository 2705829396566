export const URLS = {
  ENCRYPT_URL: "/backend-api/v1/utility/encrypt",
};

export const internalEmails = ["kantar", "blackwoodseven", "bw7"];

export const homepageURLS = ["ummo", "liftroi"];

export const developers = [
  "samarth.udgiri@kantar.com",
  "siddharthdattatraya.ausekar@kantar.com",
  "jitendra.patel@kantar.com",
  "adarsh.aniket@kantar.com",
  "aman.anand@kantar.com",
  "tanmay.goyal@kantar.com",
  "hibai.unzueta@kantar.com",
];

export const pendoPrefix = {
  PROD: "HAI_Prod_",
  QA: "HAI_Stage_",
  DEV: "HAI_Dev_",
  LOCAL: "HAI_Local_",
};

export const whiteListedPaths = ["__playground__", "__docs__"];
