import { createTheme } from "@mui/material";
import { ReactComponent as CheckBoxCheckedIcon } from "shared/Icons/checkbox_checked.svg";
import { ReactComponent as CheckBoxIntermidiateIcon } from "shared/Icons/checkbox_intermidiate.svg";
import { ReactComponent as CheckBoxUncheckedIcon } from "shared/Icons/checkbox_unchecked.svg";
import { ReactComponent as BW7CheckBoxCheckedIcon } from "shared/Icons/bw7_checkbox_checked.svg";
import { ReactComponent as BW7CheckBoxIntermidiateIcon } from "shared/Icons/bw7_checkbox_intermidiate.svg";
import { ReactComponent as BW7CheckBoxUncheckedIcon } from "shared/Icons/bw7_checkbox_unchecked.svg";

export function getTheme(isBW7theme = false) {
  const fontSize = 16;
  const fontFamily = `"${isBW7theme ? "LabGrotesque" : "Brown"}", "Helvetica", sans-serif`;
  const blackishMain = "#000";
  const whiteishMain = "#fafafa";
  const pureWhite = "#ffffff";
  const DEFAULT_BORDER_RADIUS = 0;
  return createTheme({
    palette: {
      type: "light",
      primary: {
        main: isBW7theme ? "#28CEC0" : "#7023A8",
        contrastText: pureWhite,
        shade: isBW7theme ? "#C6F4F0" : "#E6D4F1",
      },
      secondary: { main: "#f50057" },
      blackish: { main: blackishMain, contrastText: pureWhite },
      whiteish: { main: whiteishMain, contrastText: pureWhite },
      alertYellow: { main: "#FEDB00" },
      alertRed: { main: "#E10000", contrastText: pureWhite },
      greyish: { 5: "#F5F5F5", 10: "#F2F1EE", 20: "#D4D4C9", 25: "#AEAE9F", 50: "#858574", 80: "#323232" },
      significantGreen: { main: "#00D200", contrastText: pureWhite },
    },
    typography: {
      fontFamily,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize,
            fontFamily,
            color: whiteishMain,
            backgroundColor: blackishMain,
            height: "100%",
            lineHeight: "var(--default-line-height)",
          },
          input: { fontFamily: "inherit", fontSize: "100%", lineHeight: "var(--default-line-height)", margin: 0 },
          button: {
            fontFamily: "inherit",
            fontSize: "100%",
            lineHeight: "var(--default-line-height)",
            margin: 5,
            letterSpacing: "var(--default-letter-spacing)",
            fontWeight: 400,
          },
        },
      },
      MuiButton: {
        defaultProps: { disableElevation: true },
        styleOverrides: {
          root: {
            borderRadius: DEFAULT_BORDER_RADIUS,
            lineHeight: "var(--default-line-height)",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          /*
          Purpose: This should mainly be used for text information only,
          if you want a more control and want to pass component in the content then use CustomTooltip
          
          Usage: using class
         <Tooltip classes={{ tooltip: "size-md" }}>
          */
          tooltip: {
            borderRadius: DEFAULT_BORDER_RADIUS,
            backgroundColor: "#323232",
            color: "#FAFAFA",
            fontSize: "12px",
            padding: "4px 8px", //changed conditionally based on arrow
            "&.MuiTooltip-tooltipArrow": {
              maxWidth: "220px", //Size md.
              padding: "10px",
              "&.size-sm": {
                maxWidth: "136px",
              },
              "&.size-lg": {
                maxWidth: "350px",
              },
            },
          },
          arrow: {
            "&:before": {
              backgroundColor: "#323232",
            },
          },
        },
      },

      MuiPaper: {
        styleOverrides: { root: { borderRadius: DEFAULT_BORDER_RADIUS, color: "black" } },
      },

      MuiButtonBase: {
        styleOverrides: { root: { borderRadius: DEFAULT_BORDER_RADIUS } },
      },
      MuiInputBase: {
        styleOverrides: { root: { borderRadius: DEFAULT_BORDER_RADIUS } },
      },
      MuiSnackbarContent: {
        styleOverrides: { root: { borderRadius: DEFAULT_BORDER_RADIUS } },
      },
      MuiOutlinedInput: {
        styleOverrides: { root: { borderRadius: DEFAULT_BORDER_RADIUS } },
      },
      MuiChip: {
        styleOverrides: { root: { borderRadius: DEFAULT_BORDER_RADIUS } },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: DEFAULT_BORDER_RADIUS,
            "&:hover": {
              borderRadius: DEFAULT_BORDER_RADIUS,
            },
            "& .MuiTouchRipple-root .MuiTouchRipple-child": {
              //click ripples also square border
              borderRadius: DEFAULT_BORDER_RADIUS,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: "#F2F1EE",
            },
            "&:active": {
              backgroundColor: "#AEAE9F",
            },
            "&.Mui-selected": {
              backgroundColor: isBW7theme ? "#28CEC0" : "#7023A8",
              color: "#fff",
            },
            "&.Mui-selected:hover": {
              backgroundColor: isBW7theme ? "#28CEC0" : "#7023A8",
            },
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          icon: isBW7theme ? <BW7CheckBoxUncheckedIcon /> : <CheckBoxUncheckedIcon />,
          checkedIcon: isBW7theme ? <BW7CheckBoxCheckedIcon /> : <CheckBoxCheckedIcon />,
          indeterminateIcon: isBW7theme ? <BW7CheckBoxIntermidiateIcon /> : <CheckBoxIntermidiateIcon />,
        },
        styleOverrides: {
          root: {
            color: "#858574",
            "&.MuiCheckbox-sizeSmall": {
              height: "12px",
              width: "12px",
            },
            "&.MuiCheckbox-sizeMedium": {
              height: "16px",
              width: "16px",
            },
            "&.MuiCheckbox-sizeLarge": {
              height: "20px",
              width: "20px",
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            boxShadow: "0px 4px 20px 3px rgba(0, 0, 0, 0.10)",
            "&&": {
              "--popover-menu-list-max-height": "min(400px,calc(100% - 32px))", //use this to set the same max height for menu list if you need to have someting sticky at the top.
              maxHeight: "var(--popover-menu-list-max-height)",
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            "&.MuiModal-backdrop:not(.MuiBackdrop-invisible)": {
              backgroundColor: "rgb(0, 0, 0, 0.3)",
            },
          },
        },
      },
    },
    // This is not to be used by MUI. See https://mui.com/material-ui/customization/theming/#custom-variables
    extraData: {
      darkHeader: !isBW7theme,
    },
  });
}
