import numeral from "numeral";
import { devErrorLogger } from "./devErrorLogger";

let currency = "USD";
export const currencyFormatter = (amount) => `${currency} ${amount}`;
export const setCurrency = (curr) => {
  currency = curr;
};

const round = (value) => numeral(value).format("0");
export const formatRemovingDecimals = (value) => {
  if (value === null || value === undefined) return "N/A";

  return Math.abs(+value) > 9999
    ? numeral(round(value)).format("0.[0]a").toUpperCase()
    : numeral(round(value)).format("0,0");
};

export function formatTableValue(value, opts = {}) {
  if (typeof value !== "number") return opts.zeroOnNull ? "0.0" : "";
  return numeral(value.toFixed(3)).format(Math.abs(+value) < 1000 ? `0,0.0` : `0,0`);
}

export function formatInputPercentage(value) {
  //format the percent value inputed by the user, currently used in simulator formates to always two decimal places
  if (typeof value !== "number") {
    devErrorLogger("Check the value passed to formatInputPercentage, it seems not to be a number");
    return "0.00";
  }
  return numeral(value.toFixed(4)).format(`0,0.00`);
}

const PRECISION = 5; //IMPORTANT: update the edge case test cases accordingly if you change the precision
export function formatValue(value, opts = {}) {
  if (typeof value !== "number") {
    devErrorLogger("Check the value passed to formatValue, it seems not to be a number");
    return "N/A";
  }

  const roundedValue = parseFloat(value.toFixed(PRECISION));
  const isGoingToFormatToZero = roundedValue >= -0.05 && roundedValue < 0.05;
  const prefix = opts.plusSignOnPositives && !isGoingToFormatToZero ? "+" : ""; //only add the plus prefix if the formatted number is not going to be 0
  // Numeral has an ongoing bug with very small numbers https://github.com/adamwdraper/Numeral-js/issues/512
  // Adding .toFixed(5) is a workaround
  return numeral(value.toFixed(PRECISION))
    .format(
      Math.abs(+value) <= 999
        ? `${prefix}0,0.0`
        : Math.abs(+value) > 999 && Math.abs(+value) <= 9999
          ? `${prefix}0,0`
          : `${prefix}0.0a`,
    )
    .toUpperCase()
    .replace("-", "−");
}

export function formatTooltipValue(value, opts = {}) {
  if (typeof value !== "number") {
    devErrorLogger("Check the value passed to formatTooltipValue, it seems not to be a number");
    return "N/A";
  }
  // Numeral has an ongoing bug with very small numbers https://github.com/adamwdraper/Numeral-js/issues/512
  // Adding .toFixed(5) is a workaround
  return numeral(value.toFixed(PRECISION))
    .format(Math.abs(+value) < 1 ? `0,0.00` : Math.abs(+value) < 10000 ? `0,0.0` : `0,0`)
    .toUpperCase()
    .replace("-", "−");
}

const CUTOFF_VALUE = 1e-4;
export const isValueInsignificant = (value) => {
  //We don't want to show very insignificant values in UI in some places the current rule for this is if the value is between -CUTOFF_VALUE to CUTOFF_VALUE

  return Math.abs(value) < CUTOFF_VALUE;
};

export const formatOneDecimal = (value) => {
  //decimal separator .  thousands separator ,
  if (value === null || value === undefined) return "";
  return value.toLocaleString(undefined, { maximumFractionDigits: 1 });
};

export const formatRemovingDecimalsExtended = (value) => {
  if (value === null || value === undefined) return "";
  return value.toLocaleString(undefined, { maximumFractionDigits: 0 });
};

export const formatSynergies = (value) => {
  if (value === null || value === undefined || value < CUTOFF_VALUE) return "";
  return formatRemovingDecimalsExtended(value);
};

// Strings
export const makeSlug = (name) => name.replace(/\W/g, "");

export const getLabel = (column, topFilterDimension, labelDictionary) => {
  // See https://blackwoodseven.slack.com/archives/CJW82FTK6/p1613033636010500?thread_ts=1613028808.008500&cid=CJW82FTK6
  const name =
    column === "valuemissing" || column === "blank"
      ? `Untagged`
      : labelDictionary[`${topFilterDimension}.${column}`] || column;
  return name;
};

export const maxChars = (str, max) => {
  try {
    return str.length > max ? str.substring(0, max) + "..." : str;
  } catch {
    return "";
  }
};

const roles = [
  {
    slug: "superAdmin",
    name: "Super Admin",
  },
  {
    slug: "deliveryLead",
    name: "Delivery Lead",
  },
  {
    slug: "admin",
    name: "Admin",
  },
  {
    slug: "deliveryTeam",
    name: "Delivery Team",
  },
  {
    slug: "client",
    name: "Client",
  },
  {
    slug: "clientService",
    name: "Client Service",
  },
  {
    slug: "developer",
    name: "Developer",
  },
];

export const getRoleLabel = (roleSlug) => {
  return roles.find((a) => a.slug === roleSlug)?.name || "";
};
