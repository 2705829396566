import { combineReducers, configureStore } from "@reduxjs/toolkit";
import deepFreeze from "deep-freeze";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { thunk } from "redux-thunk";
import activitiesReducer from "./reducers/activities";
import activityDialog from "./reducers/activityDialog";
import addActivityDialogReducer from "./reducers/addActivityDialogs";
import businessInsights from "./reducers/businessInsights";
import businessInsightsPlus from "./reducers/businessInsightsPlus";
import featureOverrides from "./reducers/featureOverrides";
import mediaInsights from "./reducers/mediaInsights";
import mediaInsightsPlus from "./reducers/mediaInsightsPlus";
import navigation from "./reducers/navigation";
import notify from "./reducers/notify";
import plans from "./reducers/plans";
import predictions from "./reducers/predictions";
import seqNum from "./reducers/seqNum";
import simulator, { saveSimulatorInputMiddleware } from "./reducers/simulator";
import optimization from "./reducers/optimization";
import activityDrilldown from "./reducers/activityDrilldown";

// To be slowly removed
import { ActivateInvestmentPlanSaga } from "legacy/ActivateInvestmentPlan/store";
import Activities from "legacy/Activities/reducers";
import activitiesModuleSaga from "legacy/Activities/store/sagas";
import AddActivityDialog from "legacy/AddActivityDialog/reducer";

const loggerMiddleware = createLogger({
  collapsed: (getState, action, logEntry) => !logEntry.error,
  colors: {
    title: (action) => (action.meta ? action.meta.color || "inherit" : "inherit"),
  },
});

const errorReportingMiddleware = (store) => (next) => (action) => {
  try {
    return next(action);
  } catch (err) {
    console.error("Caught an exception!", err);
  }
};

const sagaMiddleware = createSagaMiddleware();

// Adding this middleware will deep freeze the state before actually
// dispatching the action to the store.
// Helpful to add in tests.
const deepFreezeMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    deepFreeze(getState());
    return next(action);
  };

const rootReducer = (state, action) => {
  // Reset store after a logout
  if (action.type === "LOGOUT_RESET_STORE") state = undefined;

  return combineReducers({
    navigation,
    featureOverrides,
    seqNum,
    businessInsights,
    mediaInsights,
    mediaInsightsPlus,
    businessInsightsPlus,
    activities: activitiesReducer,
    simulator,
    predictions,
    activityDialog,
    addActivityDialogReducer,
    plans,
    notify,
    AddActivityDialog,
    Activities,
    optimization,
    activityDrilldown,
  })(state, action);
};

const create = (options, initialState = {}) => {
  const middleWares = [thunk, sagaMiddleware, errorReportingMiddleware, saveSimulatorInputMiddleware];

  if (options && options.enableLogging && process.env.NODE_ENV !== "PROD") middleWares.push(loggerMiddleware);
  if (options && options.deepFreeze) middleWares.push(deepFreezeMiddleware);

  return configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(...middleWares),
  });
};

export { create };

const defaultStore = create({ enableLogging: true });

// Initialise sagas
if (!process.env.STORYBOOK_GIT_ORIGIN) [ActivateInvestmentPlanSaga, activitiesModuleSaga].map(sagaMiddleware.run);

export default defaultStore;
