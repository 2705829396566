import _ from "lodash";
import backend from "backend";
import { useAccount } from "auth";
import { useMemo } from "react";
// Descriptions are static
export function useDescriptions() {
  return _.mapValues(getStaticNames(), (d) => d.desc);
}

// gives labels for driver-custom drivers
export function useCustomDriverLabels() {
  let obj = getStaticNames();
  return Object.keys(obj)
    .filter((key) => key.includes("driver-custom") && !key.includes("__drivers_not_available__")) // do not include __drivers_not_available__ custom key in suggestions
    .map((key) => ({ label: obj[key].label, desc: obj[key].desc ?? "" }));
}

export function useLabels({ objectiveUnitName, market, mediaGroupings = [], driverCustom = [] }) {
  const { selectedModelConf: modelConf, conf } = useAccount();
  const { socketSlug, activeVersion } = modelConf;

  //These two endpoints get the label to show on UI for different slugs, publisher endpoint for only publishers and dimensions for all other
  const { data: publishers = [] } = backend.useData(
    `v1/dictionaries/publishers/${market}/${conf?.organization?.id}`,
    {},
    {
      dedupingInterval: 300 * 1000, // Do not revalidate very often
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const { data: labels = {} } = backend.useData(
    conf?.organization?.id
      ? `v1/dictionaries/dimensions/${socketSlug}/${activeVersion}/${market}/${conf?.organization?.id}`
      : null,
    {},
    {
      dedupingInterval: 300 * 1000, // Do not revalidate very often
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return useMemo(
    () => ({
      ..._.mapValues(getStaticNames(objectiveUnitName), (d) => d.label),
      ...mediaGroupings?.reduce((acc, d) => ({ ...acc, [`grouping.${d.slug}`]: d.name }), {}),
      ...driverCustom?.reduce((acc, d) => ({ ...acc, [`driver-custom.${d.slug}`]: d.name }), {}),
      ...publishers?.reduce((acc, d) => ({ ...acc, [`publisher.${d.slug}`]: d.name }), {}),
      ...publishers?.reduce(
        (acc, d) => (d.channelName && d.channelSlug ? { ...acc, [`channel.${d.channelSlug}`]: d.channelName } : acc),
        {},
      ),
      ...labels,
      ...mediaGroupings?.reduce((acc, d) => ({ ...acc, [`media-grouping.${d.slug}`]: d.name }), {}),
      "media-grouping": "Media Grouping",
      "driver-custom": "Business Grouping",
      datatypename: "Driver",
    }),
    [driverCustom, labels, mediaGroupings, objectiveUnitName, publishers],
  );
}

function getStaticNames(objective) {
  return {
    campaign_product: { label: "Campaign Product" },
    campaignproduct: { label: "Campaign Product" },
    creative_format: { label: "Creative Format" },
    creativeformat: { label: "Creative Format" },
    campaign_type: { label: "Campaign Type" },
    campaigntype: { label: "Campaign Type" },
    publisher: { label: "Publisher" },
    driver_type: { label: "Driver" },
    device: { label: "Device" },
    variable: { label: "Variable" },
    subVariable: { label: "Sub-Variable" },
    grouping: { label: "Media Grouping" },
    "media-group": { label: "Media Grouping" }, // Legacy
    type: { label: "Type" }, // Seen for driver_type values 'sponsorships' and 'online_direct_marketing', probably with different meaning
    // campaigntype values
    "campaigntype.tactical": { label: "Tactical" },
    "campaigntype.branding": { label: "Branding" },

    // effect_type values
    effect_type: { label: "Effect" },
    "effect_type.direct": { label: "Direct" },
    "effect_type.indirect": { label: "Indirect" },
    "effect_type.total": { label: "Total" },

    // driver_type values
    "driver_type.__drivers_not_available__": {
      // Special case for drivers not enabled in C3
      label: "Other Business Drivers",
      desc: "Drivers that impact your objective, but are not available. Please contact your Customer Success lead to learn more.",
    },
    "driver_type.base": {
      label: "Base",
      desc: "Seasonal effects and outliers that the model cannot attribute to specific events.",
    },
    "driver_type.economy": {
      label: "Macro-Economical Factors",
      desc: "This can include factors such as unemployment, consumer trust index, etc.",
    },
    "driver_type.season": {
      label: "Seasonality",
      desc: "Season patterns across certain weeks, months, quarters, etc.",
    },
    "driver_type.weather": { label: "Weather Conditions", desc: "This includes the amount of rain, sun hours, etc." },
    "driver_type.holidays": { label: "Holidays", desc: "General holidays, such as Easter, Christmas, etc." },
    "driver_type.competitors": { label: "Competitor Activities" },
    "driver_type.competitor_pricing": { label: "Competitor Product Pricing" }, // No description because it doesn't add much to the label itself
    "driver_type.competitor_distribution": {
      label: "Competitor Distribution",
      desc: "Distribution of the competitors' own and partner stores.",
    },
    "driver_type.competitor_spend": { label: "Competitor Spend", desc: "What your main competitors spend." }, // New
    "driver_type.paid_media": { label: "Paid Media" }, // No description because it doesn't add much to the label itself
    "driver_type.paid_media_impressions": {
      label: "Paid Media (Impressions)",
      desc: "Paid media activities where impressions are available.",
    }, // New
    "driver_type.paid_media_gross": {
      label: "Paid Media (Gross)",
      desc: "Paid media activities where impressions are not available but gross cost is.",
    }, // New
    "driver_type.halo_media": {
      label: "Halo Media",
      desc: "Paid media for other brands or products different from the one modeled here.",
    },
    "driver_type.other_media": {
      label: "Other Media",
      desc: "Paid media with insufficient data to fulfill requirements for the paid media impressions and gross templates — e.g., spend in bundled publishers, spend without associated impressions, impressions without associated spend.",
    },
    "driver_type.online_direct_marketing": {
      label: "Online Direct Marketing",
      desc: "Non-paid online direct marketing communications sent to customers or potential customers — e.g., sending out emails from your own CRM database, non-paid social media posts, views of a video posted on the customer’s YouTube channel, or app notifications pushed via the customer’s app.",
    },
    "driver_type.offline_direct_marketing": {
      label: "Offline Direct Marketing",
      desc: "E.g., sending out flyers or letters to your own CRM database, calling about a new offer, or handing out flyers in and around your property (this would be seen as paying for distributing something, but since we do not know the impact other than the amount of printed flyers, it goes into offline direct marketing).",
    },
    "driver_type.distribution": { label: "Distribution", desc: "Distribution of both own and partner retail stores." },
    "driver_type.pricing": { label: "Product Pricing" }, // No description because it doesn't add much to the label itself
    "driver_type.product_changes": {
      label: "Product Changes",
      desc: "Intentional changes to the product or service, like a price or fee change, new packaging change, new policy, or a new global brand strategy.",
    },
    "driver_type.organic_activities": {
      label: "Organic Activities",
      desc: "Organic traffic to the website, including SEO and direct visitors.",
    },
    "driver_type.non_paid_online_visitors": {
      label: "Online Visitors",
      desc: "Online visitors on owned assets — e.g., website visits/sessions, app installations or downloads.",
    }, // New
    "driver_type.call_center_response_rate": { label: "Call Center Response Rate" }, // No description because it doesn't add much to the label itself
    "driver_type.point_of_sales": {
      label: "Point of Sales",
      desc: "Point of Sales related activities – e.g., Open House, Special Local Deals.",
    },
    "driver_type.internal_factors": {
      label: "Internal Factors",
      desc: "Internal events that have had an impact but do not have a measurable value – e.g., large partnerships, mergers, rebranding, PR issues.",
    },
    "driver_type.external_factors": {
      label: "External Factors",
      desc: "“The world happening around you.” These events impact the objective but were not started by the company — e.g., new laws, competitor events, new tax regulations, natural disasters, etc.",
    },
    "driver_type.branding": { label: "Brand Awareness" }, // No description because it doesn't add much to the label itself
    "driver_type.brand_index": { label: "Brand Index" }, // No description because it doesn't add much to the label itself
    "driver_type.sales_incentive": { label: "Sales Incentive", desc: "Incentives to drive more sales." },
    "driver_type.pr": {
      label: "PR",
      desc: "Non-paid PR insertions that a reach or exposure metric and an indicator of the insertion's tonality can be provided for.",
    },
    "driver_type.non_paid_sponsorships": {
      label: "Sponsorships",
      desc: "Non-Media Paid sponsorships that expose the brand or product in general media.",
    },
    "driver_type.interest_rate": {
      label: "Interest Rate",
      desc: "This includes one of the following: a) the interest rate that your customer pays for obtaining a loan, OR b) the interest paid out to your customers for depositing money on an account.",
    },
    "driver_type.competitor_interest_rate": {
      label: "Competitor Interest Rate",
      desc: "This includes one of the following: a) the interest rate that the competitors' customer pays for obtaining a loan, OR b) the interest paid out to the competitors' customer for depositing money on an account.",
    },
    "driver_type.inbound_calls": { label: "Inbound Calls", desc: "Inbound calls received via a call center." },
    "driver_type.abandoned_calls": { label: "Abandoned Calls" },
    "driver_type.outbound_calls": { label: "Outbound Calls" },
    "driver_type.leads": { label: "Leads", desc: "Opportunities created through physical meetings or calls." },
    "driver_type.competitor_branding": { label: "Competitor Branding" },
    "driver_type.technical_penetration": {
      label: "Technical Penetration",
      desc: "This represents the number of households that have the option to become clients.",
    },
    "driver_type.app_activity": {
      label: "App Activity",
      desc: "Impact from customer-owned Apps. Measured by the number of downloads from app stores or end-user activities on the app (e.g., visits, or posts)",
    },
    // Special treatment in the UI. It will also include categories not whitelisted
    // The enumeration of those unlisted categories will be included in the description
    "driver_type.other": {
      label: "Other Factors",
      desc: "Other factors may include residuals (model deviations from reality).", // All non-decomposed elements will also be mentioned in the description
    },
    // custom drivers
    "driver-custom.__drivers_not_available__": {
      // will use this for ungrouped elements which the API returns as null
      label: "Other Business Drivers",
      desc: "Drivers that impact your objective, but are not available. Please contact your Customer Success lead to learn more.",
    },
    "driver-custom.other-business-drivers": {
      label: "Other Business Drivers",
      desc: "Drivers that impact your objective, but are not available. Please contact your Customer Success lead to learn more.",
    },
    "driver-custom.base": {
      label: "Base",
      desc: "Seasonal effects and outliers that the model cannot attribute to specific events.",
    },
    "driver-custom.macro-economical-factors": {
      label: "Macro-Economical Factors",
      desc: "This can include factors such as unemployment, consumer trust index, etc.",
    },
    "driver-custom.seasonality": {
      label: "Seasonality",
      desc: "Season patterns across certain weeks, months, quarters, etc.",
    },
    "driver-custom.weather-conditions": {
      label: "Weather Conditions",
      desc: "This includes the amount of rain, sun hours, etc.",
    },
    "driver-custom.holidays": { label: "Holidays", desc: "General holidays, such as Easter, Christmas, etc." },
    "driver-custom.competitor-activities": { label: "Competitor Activities" },
    "driver-custom.competitor-product-pricing": { label: "Competitor Product Pricing" },
    "driver-custom.competitor-distribution": {
      label: "Competitor Distribution",
      desc: "Distribution of the competitors' own and partner stores.",
    },
    "driver-custom.competitor-spend": { label: "Competitor Spend", desc: "What your main competitors spend." },
    "driver-custom.paid-media": { label: "Paid Media" },
    "driver-custom.paid-media-impressions-": {
      label: "Paid Media (Impressions)",
      desc: "Paid media activities where impressions are available.",
    },
    "driver-custom.paid-media-gross-": {
      label: "Paid Media (Gross)",
      desc: "Paid media activities where impressions are not available but gross cost is.",
    },
    "driver-custom.halo-media": {
      label: "Halo Media",
      desc: "Paid media for other brands or products different from the one modeled here.",
    },
    "driver-custom.other-media": {
      label: "Other Media",
      desc: "Paid media with insufficient data to fulfill requirements for the paid media impressions and gross templates” e.g. spend in bundled publishers, spend without associated impressions, impressions without associated spend.",
    },
    "driver-custom.online-direct-marketing": {
      label: "Online Direct Marketing",
      desc: "Non-paid online direct marketing communications sent to customers or potential customers ” e.g., sending out emails from your own CRM database, non-paid social media posts, views of a video posted on the customer’s own YouTube channel, or app notifications pushed via the customer’s own app.",
    },
    "driver-custom.offline-direct-marketing": {
      label: "Offline Direct Marketing",
      desc: "E.g., sending out flyers or letters to your own CRM database, calling about a new offer, or handing out flyers in and around your property (this would be seen as paying for distributing something, but since we do not know the impact other than the amount of printed flyers, it goes into offline direct marketing).",
    },
    "driver-custom.distribution": {
      label: "Distribution",
      desc: "Distribution of both own and partner retail stores.",
    },
    "driver-custom.product-pricing": { label: "Product Pricing" },
    "driver-custom.product-changes": {
      label: "Product Changes",
      desc: "Intentional changes to the product or service, like a price or fee change, new packaging change, new policy, or a new global brand strategy.",
    },
    "driver-custom.organic-activities": {
      label: "Organic Activities",
      desc: "Organic traffic to the website, including SEO and direct visitors.",
    },
    "driver-custom.online-visitors": {
      label: "Online Visitors",
      desc: "Online visitors on owned assets” e.g., website visits/sessions, app installations or downloads.",
    },
    "driver-custom.call-center-response-rate": { label: "Call Center Response Rate" },
    "driver-custom.point-of-sales": {
      label: "Point of Sales",
      desc: "Point of Sales related activities“ e.g., Open House, Special Local Deals.",
    },
    "driver-custom.internal-factors": {
      label: "Internal Factors",
      desc: "Internal events that have had an impact but do not have a measurable value“ e.g., large partnerships, mergers, rebranding, PR issues.",
    },
    "driver-custom.external-factors": {
      label: "External Factors",
      desc: "The world happening around you. These events impact the objective but were not started by the company” e.g., new laws, competitor events, new tax regulations, natural disasters, etc.",
    },
    "driver-custom.brand-awareness": {
      label: "Brand Awareness",
      desc: "This refers to the extent to which consumers are familiar with and recognize a brand.",
    },
    "driver-custom.brand-index": {
      label: "Brand Index",
      desc: "This quantifies how well a brand performs within a specific group of customers, compared with its average performance among all customers.",
    },
    "driver-custom.sales-incentive": { label: "Sales Incentive", desc: "Incentives to drive more sales." },
    "driver-custom.pr": {
      label: "PR",
      desc: "Non-paid PR insertions that a reach or exposure metric and an indicator of the insertion's tonality can be provided for.",
    },
    "driver-custom.sponsorships": {
      label: "Sponsorships",
      desc: "Non Media Paid sponsorships that expose the brand or product in general media.",
    },
    "driver-custom.interest-rate": {
      label: "Interest Rate",
      desc: "This includes one of the following: a) the interest rate that your customer pays for obtaining a loan, OR b) the interest paid out to your customers for depositing money on an account.",
    },
    "driver-custom.competitor-interest-rate": {
      label: "Competitor Interest Rate",
      desc: "This includes one of the following: a) the interest rate that the competitors' customer pays for obtaining a loan, OR b) the interest paid out to the competitors' customer for depositing money on an account.",
    },
    "driver-custom.inbound-calls": { label: "Inbound Calls", desc: "Inbound calls received via a call center." },
    "driver-custom.abandoned-calls": { label: "Abandoned Calls" },
    "driver-custom.outbound-calls": { label: "Outbound Calls" },
    "driver-custom.leads": { label: "Leads", desc: "Opportunities created through physical meetings or calls." },
    "driver-custom.competitor-branding": { label: "Competitor Branding" },
    "driver-custom.technical-penetration": {
      label: "Technical Penetration",
      desc: "This represents the number of households that have the option to become clients.",
    },
    "driver-custom.app-activity": {
      label: "App Activity",
      desc: "Impact from customer-owned Apps. Measured by the number of downloads from app stores or end-user activities on the app (e.g., visits, or posts)",
    },
    "driver-custom.other-factors": {
      label: "Other Factors",
      desc: "Other factors may include residuals (model deviations from reality).",
    },
    "driver-custom.core-base": { label: "Core Base", desc: "Proportion of Base Sales, not impacted by Brand Equity" },
    "driver-custom.core-equity": {
      label: "Core Equity",
      desc: "Contribution of Equity to base sales, which is built over the long term (not impacted by recent media activity)",
    },
    "driver-custom.incremental-due-to-equity": {
      label: "Incremental Due to Equity",
      desc: "Impact via changes in Equity, due to recent media activity",
    },
    // Metric KPI conf
    "nonmedia.effects_observed": { label: "Non-media effect" }, //for simulation purpose
    "nonmedia.profit_observed": { label: "Non-media profit" }, //for simulation purpose
    net_spend: { label: "Media Investment" },
    effects_aggregated: { label: `Media Generated ${objective}` },
    effects_observed: { label: `Media Generated ${objective}` },
    profit_aggregated: { label: "Media Generated Profit" },
    profit_observed: { label: "Media Generated Profit" },
    "net_spend/effects_aggregated": { label: "Cost per Action" },
    "profit_aggregated/net_spend": { label: "Media Profit-ROI" },
    "net_spend/effects_observed": { label: "Cost per Action" },
    "profit_observed/net_spend": { label: "Media Profit-ROI" }, //only for simulator purpose as we have profit_observed and not progit_aggregated there
    paid_media_exposure_impressions: { label: "Exposure (Impressions)" },
    paid_media_exposure_gross: { label: "Exposure (Gross)" },
    paid_media_clicks: { label: "Clicks" },
    "net_spend/paid_media_exposure_impressions*1000": { label: "Cost (CPM)" },
    "net_spend/paid_media_exposure_gross": { label: "Cost (%)" },
    "net_spend/paid_media_clicks": { label: "Cost (CPC)" },
  };
}
