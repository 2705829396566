import tinycolor from "tinycolor2";

const PALETTE = [
  { color: "rgb(200, 220, 200)", useFor: ["base"] },
  { color: "rgb(39, 207, 192)", useFor: ["paid_media", "paid_media_impressions", "paid_media_gross"] },
  { color: "rgb(129, 236, 236)", useFor: ["halo_media"] },
  { color: "rgb(85, 239, 196)", useFor: ["other_media"] },
  { color: "rgb(253, 203, 110)", useFor: ["pricing"] },
  { color: "rgb(232, 67, 167)", useFor: ["distribution"] },
  { color: "rgb(116, 185, 255)", useFor: ["product_changes"] },
  { color: "rgb(162, 155, 254)", useFor: ["economy"] },
  { color: "rgb(0, 184, 258)", useFor: ["holidays"] },
  { color: "rgb(0, 106, 201)", useFor: ["non_paid_online_visitors"] },
  { color: "rgb(9, 132, 227)", useFor: ["online_direct_marketing"] },
  { color: "rgb(108, 92, 231)", useFor: ["internal_factors"] },
  { color: "rgb(255, 234, 167)", useFor: ["offline_direct_marketing"] },
  { color: "rgb(258, 228, 69)", useFor: ["point_of_sales"] },
  { color: "rgb(250, 177, 160)", useFor: ["season"] },
  { color: "rgb(255, 118, 117)", useFor: ["pr"] },
  { color: "rgb(253, 121, 168)", useFor: ["organic_activities"] },
  { color: "rgb(225, 112, 85)", useFor: ["weather"] },
  { color: "rgb(214, 48, 49)", useFor: ["external_factors"] },
  { color: "rgb(228, 128, 69)", useFor: ["branding", "brand_index"] },
  { color: "rgb(28, 228, 269)", useFor: ["sales_incentive"] },
  { color: "rgb(158, 228, 269)", useFor: ["non_paid_sponsorships"] },
  { color: "rgb(228, 28, 229)", useFor: ["interest_rate"] },
  { color: "rgb(128, 28, 269)", useFor: ["call_center_response_rate"] },
  { color: "rgb(128, 228, 29)", useFor: ["inbound_calls"] },
  { color: "rgb(48, 118, 19)", useFor: ["abandoned_calls"] },
  { color: "rgb(28, 78, 69)", useFor: ["outbound_calls"] },
  { color: "rgb(128, 28, 69)", useFor: ["leads"] },

  // These are normally negative, try to use desaturated colors
  { color: "rgb(178, 190, 195)", useFor: ["competitor_distribution"] },
  { color: "rgb(99, 110, 114)", useFor: ["competitors", "competitor_spend"] },
  { color: "rgb(45, 52, 54)", useFor: ["competitor_pricing"] },
  { color: "rgb(45, 22, 4)", useFor: ["competitor_interest_rate"] },

  { color: "rgb(223, 230, 233)", useFor: ["other"] }, // Residuals will land here
  { color: "rgb(0, 0, 90)", useFor: ["__drivers_not_available__"] }, // Stuff not whitelisted in C3
];

// Looks like:
// { // Suppose that 'paid_media' color is 'red'
//   paid_media.radio: 'redish-color-1'
//   paid_media.tv: 'redish-color-2'
//   ...
// }
const shadesCache = {};
function getShade({ parentId, id, parentColor }) {
  const key = `${parentId}.${id}`;
  if (shadesCache[key]) return shadesCache[key];
  else {
    // We'll use the index to calculate how much the color will diverge
    const newIndex = Object.keys(shadesCache).filter((d) => d.startsWith(parentId)).length;
    shadesCache[key] = tinycolor(parentColor)
      .spin(newIndex * 8)
      .lighten(newIndex * 20 * Math.pow(0.6, newIndex))
      .toString();
    return shadesCache[key];
  }
}

const idToColor = (parentId) => (id) => {
  const color = (
    PALETTE.find(({ useFor }) => useFor && useFor.includes(parentId ? parentId : id)) || {
      color: "gray",
    }
  ).color;

  return parentId ? getShade({ parentId, id, parentColor: color }) : color;
};

export default idToColor;
