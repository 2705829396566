import { HIGH_PROBABILITY, MID_PROBABILITY, LOW_PROBABILITY, CUT_EDGES_PERCENT } from "shared/helpers/marketingStats";

const commonInvestmentPlansParams = {
  includeRequest: true,
  groupBy: "media-group",
  mediaGeneratedEffectsQuantiles: [
    CUT_EDGES_PERCENT,
    LOW_PROBABILITY,
    MID_PROBABILITY,
    HIGH_PROBABILITY,
    100 - CUT_EDGES_PERCENT,
  ].join(","), // Because of weird parsing in the backend
  mediaGeneratedRoiQuantiles: [HIGH_PROBABILITY],
  mediaGeneratedProfitQuantiles: [HIGH_PROBABILITY],
  cpaQuantiles: [HIGH_PROBABILITY],
};

export default commonInvestmentPlansParams;
