import { useAccount } from "auth";
import useData, { paramsSerialiser } from "backend/useData";
import backend from "backend";
import moment from "moment";

const SWR_OPTS = {
  dedupingInterval: 240 * 1000,
};

export function useOutputData({ metric, variableType, interval, simulationId, exception = [] }) {
  const { selectedModel: modelSlug } = useAccount();
  const { data = [], loading } = useData(
    simulationId && !exception.includes(variableType) && `v1/simulation/output/fast/${modelSlug}/${simulationId}`,
    {
      metric: metric,
      variableType: variableType,
      interval: interval,
    },
    {
      ...SWR_OPTS,
    },
  );

  return {
    data,
    loading,
  };
}

export function getAllActivityList({ modelSlug }) {
  const { data, loading } = backend.useData(`v2/activities`, { modelSlug });

  return {
    data,
    loading,
  };
}

export function saveSimulationToActivity({ socketSlug, activityId, simulationId, requestBody }) {
  return backend.put(`v2/activities/${socketSlug}/${activityId}/simulation-plan-ids/${simulationId}`, {
    ...requestBody,
  });
}

export function softDeleteSimulation({ simulationId }) {
  return backend.delete(`v1/simulation/${simulationId}`);
}

export function extendToDetailedSimulation({
  simulationId,
  emailNotification,
  modelSlug,
  experimental,
  localRequestTime,
}) {
  const paramsSerialize = paramsSerialiser({
    parentSimulationId: simulationId,
    type: "full",
    emailNotification,
    experimental,
  });
  return backend.post(`v1/simulation/simulate/${modelSlug}` + paramsSerialize, { localRequestTime });
}

export async function exportExcel(modelSlug, modelConf, labelDictionary, typeOfSimulation, simulationId) {
  const formatedDate = moment().format("DD_MM_YYYY_HH-mm");
  const apiRoute = `v3/export/simulation/${typeOfSimulation}/${modelSlug}/${simulationId}`;
  return backend.downloadBinary(
    apiRoute,
    `${modelConf.brand} ${modelConf.name}${typeOfSimulation === "full" ? "_Detailed" : "_HighLevel"} _Sim_${formatedDate}.xlsx`,
    {
      method: "POST",
      body: JSON.stringify({ ...labelDictionary }),
      headers: { "Content-Type": "application/json" },
    },
  );
}
