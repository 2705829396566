import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  mode: "",
};

const predictionSlice = createSlice({
  name: "activities",
  initialState: INITIAL_STATE,
  reducers: {
    setMode: (state, action) => {
      state.mode = action.payload;
    },
  },
});

export const { setMode: setPredictionMode } = predictionSlice.actions;
export const PredictionMode = (state) => state.predictions.mode;

export default predictionSlice.reducer;
