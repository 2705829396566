export default function paginateTabs(array, itemsPerPage, location) {
  const selectedObject = array.filter((a) => a.to.split("?")?.[0] === location.pathname)?.[0];

  // If selected object is not in the array, fallback to the regular pagination
  if (!selectedObject) return paginate(array, itemsPerPage);

  const selectedPageIndex = Math.floor(array.indexOf(selectedObject) / itemsPerPage);

  // Remove the selected object from the array to avoid duplicates
  const filteredArray = array.filter((a) => a.to.split("?")?.[0] !== location.pathname);

  // Split the filtered array into sub-arrays of size itemsPerPage
  const pages = [];
  for (let i = 0; i < filteredArray.length; i += itemsPerPage - 1) {
    const page = filteredArray.slice(i, i + itemsPerPage - 1);
    pages.push(page);
  }

  if (selectedObject === undefined) return [];
  if (pages.length === 0) return [[selectedObject]];
  pages.forEach((page, index) => {
    const insertIndex = Math.min(selectedPageIndex, index);
    page.splice(insertIndex, 0, selectedObject);
  });
  return pages;
}

function paginate(array, itemsPerPage) {
  const pages = [];
  for (let i = 0; i < array.length; i += itemsPerPage) {
    const page = array.slice(i, i + itemsPerPage);
    pages.push(page);
  }
  return pages;
}
