import { ErrorBoundary } from "react-error-boundary";
import { ReactComponent as ReloadIcon } from "shared/Icons/reload.svg";

function FailedComponentMessage({ title, styles = {}, reset }) {
  return (
    <div style={{ marginTop: 100, marginBottom: 100, textAlign: "center", fontSize: "1em", ...styles }}>
      We couldn't load <i>{title}</i>. <ReloadIcon onClick={reset} height="16" style={{ cursor: "pointer" }} />
      {Object.keys(styles).length === 0 && <br />}
      Please contact us so we can get a fix going.
    </div>
  );
}

export function CommonErrorBoundary({ children, resetKeys, fallbackTitle, overrideStyle }) {
  return (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) =>
        FailedComponentMessage({ title: fallbackTitle, styles: overrideStyle, reset: resetErrorBoundary })
      }
      resetKeys={resetKeys}
    >
      {children}
    </ErrorBoundary>
  );
}
