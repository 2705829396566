import React from "react";
import styled from "@emotion/styled/macro";
import { MenuItem, Popover, MenuList, Fade, Typography, Tabs, Tab, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { parseISO, startOfDay, endOfDay, isSameDay, startOfWeek, endOfWeek, min } from "date-fns";
import { dateToString, prettyDate } from "shared/helpers/time";
import Calendar from "./Calendar";
import useQuickPeriods from "./useQuickPeriods";
import useActivitiesPeriods from "./useActivitiesPeriods";
import { assert } from "chai";
import _ from "lodash";
import { useAccount } from "auth";
import { TextButton } from "shared/styledComponents/TextButton";

const CALENDAR_HEIGHT = 480;
const CALENDAR_WIDTH = 370;
const LEFT_BAR_WIDTH = 186;

// Regarding `weekStart`, we can't use the default for date-fns (0, Sunday),
// since many customers will have weeks starting on monday, so this taken from model conf.
// Regarding `firstWeekContainsDate` we can't use the default for date-fns (1, Jan 1),
// since many customers will use ISO week numbers that have always Jan 4 in the first week,
// so this is again taken from model conf
export default function PeriodPicker({
  period = undefined,
  allowedPeriod = undefined,
  onChange,
  inputRender = defaultInputRender,
  showPresetsFuture = false,
  showPresetsPast = false,
  minInterval = "day",
  modelConf,
}) {
  const theme = useTheme();
  const { isFeatureAvailable } = useAccount();
  assert.isDefined(modelConf?.weekly, "we need to know if this is a weekly model");
  assert.isDefined(modelConf?.weekStart, "we need to know what day of the week weeks start");
  // TODO: Remove temporary code when configuration is in place
  // Until then use a weekStart-based heuristic. See https://en.wikipedia.org/wiki/Week#Week_numbering:~:text=in%20the%20year.-,Other%20week%20numbering%20systems,-%5Bedit%5D
  //assert.isDefined(modelConf?.firstWeekContainsDate, 'we need to know what day of the year weeks start')
  modelConf.firstWeekContainsDate = modelConf.firstWeekContainsDate || (modelConf.weekStart === 1 ? 4 : 1);
  const [granularity, setGranularity] = React.useState(
    minInterval === "day" || minInterval === "week" ? "days" : "months",
  );
  // The period picker will toggle between choosing start date and end date
  const [isStart, setIsStart] = React.useState(true);
  const toggleStartEnd = () => setIsStart((s) => !s);
  const interval = stringPeriodToInterval(period);
  const allowedInterval = stringPeriodToInterval(allowedPeriod);
  const [hoverInterval, setHoverInterval] = React.useState(null);
  const [intervalPresetToBeAppliedIfClick, setIntervalPresetToBeAppliedIfClick] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const updatePeriod = (newInterval, activePlanId = null) => {
    onChange([newInterval.start, newInterval.end].map(dateToString), activePlanId);
    return new Promise((resolve) => setTimeout(resolve, 300));
  };

  const [intervalToBeAppliedIfClick, isExtended] = extendIntervalIfNeeded({
    interval: intervalPresetToBeAppliedIfClick || resolveIntervals({ isStart, interval, hoverInterval }),
    weekStartsOn: modelConf.weekStart,
    weeklyModel: modelConf.weekly,
  });

  const quickPeriods = useQuickPeriods({ market: modelConf.market, showPresetsFuture, showPresetsPast });
  const activitiesPeriods = useActivitiesPeriods(modelConf.socketSlug);

  const filterByAlowedInterval = ({ interval }) =>
    !(allowedInterval.start && allowedInterval.start <= interval.start) ||
    (allowedInterval.end && allowedInterval.end >= interval.end);
  const allPresets = [{ title: "Presets", data: quickPeriods.filter(filterByAlowedInterval) }];

  if (isFeatureAvailable("Activity evaluation") && activitiesPeriods.length > 0)
    allPresets.push({ title: "Activities", data: activitiesPeriods.filter(filterByAlowedInterval) });

  const leftBar = (showPresetsFuture || showPresetsPast) && (
    <Container style={{ width: LEFT_BAR_WIDTH }}>
      {allPresets.map(({ title, data }, i, arr) => (
        <React.Fragment key={title}>
          <PresetBox
            style={{
              maxHeight: arr.length > 1 ? "55%" : undefined,
              borderTop: i > 0 ? `1px solid ${theme.palette.greyish[20]}` : undefined,
            }}
          >
            <div
              style={{
                padding: "9px 0 0 15px",
                textTransform: "uppercase",
                color: theme.palette.greyish[25],
                fontSize: 12,
                letterSpacing: "1px",
                height: "23px",
              }}
            >
              {title}
            </div>
            <MenuList>
              {data.map(({ label, interval: thisInterval, activePlanId }, i) => (
                <MenuItem
                  key={i}
                  dense
                  selected={sameIntervals(thisInterval, interval)}
                  onClick={() => updatePeriod(intervalToBeAppliedIfClick, activePlanId).then(() => setAnchorEl(null))}
                  onMouseEnter={() => setIntervalPresetToBeAppliedIfClick(thisInterval)}
                  onMouseLeave={() => setIntervalPresetToBeAppliedIfClick(null)}
                >
                  <Tooltip title={label?.length >= 18 /* tooltip when it doesn't fit */ ? label : undefined}>
                    <Typography style={{ fontSize: 14 }} noWrap>
                      {label}
                    </Typography>
                  </Tooltip>
                </MenuItem>
              ))}
            </MenuList>
          </PresetBox>
          {i === 0 && isExtended && <WeeklyModelExtensionWarning />}
        </React.Fragment>
      ))}
    </Container>
  );

  return (
    <>
      {inputRender({
        period,
        label: quickPeriods.find((d) => sameIntervals(interval, d.interval))?.label || "Period",
        onClick: (event) => setAnchorEl((s) => (s ? null : event.currentTarget)),
        isOpen: Boolean(anchorEl),
      })}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{
          style: {
            overflow: "hidden",
            width: CALENDAR_WIDTH + (leftBar ? LEFT_BAR_WIDTH : 0),
            maxHeight: CALENDAR_HEIGHT,
            display: "flex",
            position: "relative",
            paddingBottom: 45,
            color: theme.palette.greyish[80],
          },
        }}
        TransitionComponent={Fade}
      >
        {leftBar}

        <div style={{ width: CALENDAR_WIDTH, position: "relative" }}>
          <Calendar
            granularity={granularity}
            interval={interval}
            selectedInterval={intervalToBeAppliedIfClick}
            onHoverInterval={setHoverInterval}
            visibleInterval={allowedInterval}
            allowedInterval={{
              // When we're choosing the end date, we cannot pick something earlier than what's chosen
              start: isStart ? allowedInterval.start : existingLatestDate(allowedInterval.start, interval.start),
              end: allowedInterval.end,
            }}
            onClick={() => {
              updatePeriod(intervalToBeAppliedIfClick);
              toggleStartEnd();
            }}
            weekStartsOn={modelConf.weekStart}
            firstWeekContainsDate={modelConf.firstWeekContainsDate}
            disableDayGranularity={modelConf.weekly}
          />

          <GranularityTabs
            value={granularity === "days" ? 0 : 1}
            onChange={(e, newValue) => setGranularity(newValue === 0 ? "days" : "months")}
            centered
          >
            <SmallerTab
              label={minInterval !== "day" || modelConf?.weekly ? "Pick weeks" : "Pick days"}
              disabled={minInterval !== "day" && minInterval !== "week"}
            />
            <SmallerTab label="Pick months" />
          </GranularityTabs>
        </div>

        <Footer>
          <div style={{ padding: "8px 0 8px 10px", position: "relative" }}>
            <IntervalExplained {...{ intervalToBeAppliedIfClick, interval, isExtended }} />
          </div>
          <TextButton variant="contained" onClick={() => setAnchorEl(null)}>
            OK
          </TextButton>
        </Footer>
      </Popover>
    </>
  );
}

// Allows users to have a sense of what will happen if they click on a period
function IntervalExplained({ intervalToBeAppliedIfClick, interval, isExtended }) {
  const theme = useTheme();
  return intervalToBeAppliedIfClick?.start || intervalToBeAppliedIfClick?.end
    ? ["start", "end"]
        .map((bound) => ({
          shouldHighlight: !isSameDay(interval?.[bound], intervalToBeAppliedIfClick?.[bound]),
          label:
            intervalToBeAppliedIfClick?.[bound] && prettyDate(intervalToBeAppliedIfClick?.[bound], { showYear: true }),
        }))
        .map(({ shouldHighlight, label }, i) => (
          <React.Fragment key={i}>
            <span
              style={
                shouldHighlight
                  ? {
                      backgroundColor: isExtended ? "#fff3c0" : "#f5f5f5",
                      color: theme.palette.primary.main,
                      padding: "5px 10px",
                      border: "1.5px solid",
                      borderRadius: 0,
                      borderColor: theme.palette.primary.main,
                      fontSize: 14,
                    }
                  : undefined
              }
            >
              {label}
            </span>
            {i === 0 && <Arrow>{" → "}</Arrow>}
          </React.Fragment>
        ))
    : ["start", "end"]
        .map((bound) => ({
          label: interval?.[bound] && prettyDate(interval?.[bound], { showYear: true }),
        }))
        .map(({ shouldHighlight, label }, i) => (
          <React.Fragment key={i}>
            <span style={{ fontSize: 14 }}>{label}</span>
            {label ? i === 0 && <Arrow>{" → "}</Arrow> : null}
          </React.Fragment>
        ));
}

function resolveIntervals({ isStart, interval, hoverInterval }) {
  const applyIfDefined = (f) => (array) => {
    const defined = array.filter((d) => !_.isNil(d));
    return defined.length > 0 ? f(defined) : undefined;
  };

  if (isStart)
    return {
      start: hoverInterval?.start,
      end: hoverInterval?.end,
    };
  else
    return {
      start: applyIfDefined(min)([hoverInterval?.start, interval?.start]) || interval?.start,
      end: hoverInterval?.end || interval?.end,
    };
}

function extendIntervalIfNeeded({ interval, weeklyModel, weekStartsOn }) {
  const extended =
    weeklyModel && interval?.start && interval?.end
      ? { start: startOfWeek(interval.start, { weekStartsOn }), end: endOfWeek(interval.end, { weekStartsOn }) }
      : interval;
  return [extended, extended?.start < interval?.start || extended?.end > interval?.end];
}

function defaultInputRender({ period, label }) {
  return (
    <pre>
      You need to specify a render prop.
      <br />
      {JSON.stringify({ period, label }, null, 2)}
    </pre>
  );
}

function stringPeriodToInterval(periodString = [undefined, undefined]) {
  return {
    start: periodString[0] && startOfDay(parseISO(periodString[0])),
    end: periodString[1] && endOfDay(parseISO(periodString[1])),
  };
}

function sameIntervals(a, b) {
  if (!(a && b && a.start && b.start && a.end && b.end)) return null;
  return isSameDay(a.start, b.start) && isSameDay(a.end, b.end);
}

function existingLatestDate(a, b) {
  if (!b) return a;
  else if (!a) return b;
  return b > a ? b : a;
}

const PresetBox = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.greyish[20]};
`;

const GranularityTabs = styled(Tabs)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  border-bottom: 1px solid ${({ theme }) => theme.palette.greyish[20]};
  min-height: 26px;
  height: 32px;
  color: ${({ theme }) => theme.palette.greyish[80]};
`;

const SmallerTab = styled(Tab)`
  min-height: 20px;
  padding: 7px 16px 13px 16px;
  text-transform: inherit;
  color: inherit;
`;

const WeeklyModelExtensionWarning = styled.div`
  pointer-events: none;
  width: 100%;
  height: 95px;
  font-size: 14px;
  left: 0;
  border-radius: 0px;
  border: 1px solid lightgray;
  background-color: #fff3c0;
  padding: 0px 12px;
  transition: top 0.2s ease-in-out;
  &::before {
    content: "This is a weekly model. Larger periods like months are extended to full weeks";
  }
`;

const Container = styled.div`
  border-right: 1px solid ${({ theme }) => theme.palette.greyish[20]};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Arrow = styled.span`
  font-size: 14px;
  color: #000;
`;
