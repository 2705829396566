export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "NOTIFY_NEW":
      return {
        ...state,
        open: true,
        message: action.message,
        action: action.action,
        isError: action.isError,
        isPersistent: action.isPersistent,
        horizontalPosition: action.horizontalPosition,
        closeButton: action.closeButton,
        extraStyles: action.extraStyles || {},
      };
    case "NOTIFY_CLOSE":
      return initialState;

    default:
      return state;
  }
}

const initialState = {
  open: false,
  message: undefined, // "Activity has been deactivated"
  action: undefined, // { text: "Undo", onClick: () => activate(...) }
  isError: false,
  isPersistent: false,
  horizontalPosition: "left",
  closeButton: true,
  extraStyles: {},
};
