export const HIGH_PROBABILITY = 90;
export const MID_PROBABILITY = 50;
export const LOW_PROBABILITY = 10;
export const CUT_EDGES_PERCENT = 5;

/**
 * NOTE: This function has to line up with how R does quantiles. Or numbers in the UI might not match... And this should likely be calculated by the backend to simplify UI responsibilities
 */
export const getDistributionQuantile = (samples, certainty) => {
  const percentile = 100 - certainty;

  const orderedSamples = samples.sort((a, b) => (a > b ? 1 : -1));

  const index = (percentile / 100) * (orderedSamples.length - 1);
  let result;

  if (Math.floor(index) === index) {
    result = orderedSamples[index];
  } else {
    const i = Math.floor(index);
    const fraction = index - i;
    result = orderedSamples[i] + (orderedSamples[i + 1] - orderedSamples[i]) * fraction;
  }

  return result;
};

export const getCostPerAction = (effectDistribution, mediaInvestment, certainty) => {
  let effect = getDistributionQuantile(effectDistribution, certainty);
  return mediaInvestment / effect;
};
