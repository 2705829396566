import {
  ADD_ACTIVITY_DIALOG_SHOW,
  ADD_ACTIVITY_DIALOG_HIDE,
  ADD_ACTIVITY_UPDATE_FIELD,
  ADD_ACTIVITY_SAVE_CONSTRAINT,
  ADD_ACTIVITY_DELETE_CONSTRAINT,
  ADD_ACTIVITY_EDIT_CONSTRAINT,
  ADD_ACTIVITY_VALIDATION_ERROR,
  ADD_ACTIVITY_CANCEL_EDIT_CONSTRAINT,
  RESET_BEFORE_ADD_NEW_CONSTRAINT,
  ADD_ACTIVITY_UPDATE_INLINE_EDITOR_FIELD,
  ADD_ACTIVITY_START_VALIDATING,
  ADD_ACTIVITY_VALIDATION_SUCCESS,
  ADD_ACTIVITY_SWITCH_BRIEF,
  ADD_ACTIVITY_DIALOG_SHOW_MODIFY,
} from "./actions";
import { v4 as uuid } from "uuid";

export const initialState = {
  isVisible: false,
  isModify: false,
  data: {
    selectedBrief: "budgetBased",
    budget: undefined,
    target: undefined,
    startDate: undefined,
    endDate: undefined,
    constraints: [],
  },
  inlineEditor: {
    editing: false,
    errorMessage: undefined,
    index: undefined,
    validating: false,
    constraint: {
      type: undefined,
      startDate: undefined,
      endDate: undefined,
      match: {
        dimension: undefined,
        value: undefined,
      },
      value: undefined,
    },
  },
  source: null,
};

const updateConstraint = (constraint, field, value) => {
  switch (field) {
    case "dimension":
      return {
        ...constraint,
        match: { dimension: value, value: constraint.match.value },
      };
    case "name":
      return {
        ...constraint,
        match: { dimension: constraint.match.dimension, value: value },
      };
    default:
      return { ...constraint, [field]: value };
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "BW7INTERNAL_LOAD_INPUTS":
      return { ...state, isVisible: true, data: action.data };

    case ADD_ACTIVITY_DIALOG_SHOW:
      return {
        ...state,
        source: null,
        data: initialState.data,
        isVisible: true,
        isModify: initialState.isModify,
      };

    case ADD_ACTIVITY_DIALOG_SHOW_MODIFY:
      return {
        ...state,
        data: {
          ...state.data,
          startDate: action.originalRequest.startDate,
          endDate: action.originalRequest.endDate,
          constraints: action.originalRequest.constraints,
          budget: action.originalRequest.budget || action.originalRequest.maxBudget,
          target: action.originalRequest.target,
          selectedBrief: action.originalRequest.type,
        },
        isVisible: true,
        isModify: true,
      };

    case ADD_ACTIVITY_DIALOG_HIDE:
      return {
        ...state,
        isVisible: false,
        inlineEditor: initialState.inlineEditor,
      };

    case ADD_ACTIVITY_UPDATE_FIELD:
    case ADD_ACTIVITY_SWITCH_BRIEF:
      return {
        ...state,
        data: {
          ...state.data,
          [action.label]: action.value,
        },
      };

    case ADD_ACTIVITY_UPDATE_INLINE_EDITOR_FIELD:
      return {
        ...state,
        inlineEditor: {
          ...state.inlineEditor,
          constraint: updateConstraint(state.inlineEditor.constraint, action.field, action.val),
        },
      };

    case ADD_ACTIVITY_SAVE_CONSTRAINT:
      const updatedData = [...state.data.constraints];
      const newConstraint = {
        id: uuid(),
        ...state.inlineEditor.constraint,
      };
      if (action.index === null) updatedData.push(newConstraint);
      else updatedData[action.index] = newConstraint;

      return {
        ...state,
        data: {
          ...state.data,
          constraints: updatedData,
        },
        inlineEditor: initialState.inlineEditor,
      };

    case ADD_ACTIVITY_START_VALIDATING:
      return {
        ...state,
        inlineEditor: {
          ...state.inlineEditor,
          validating: true,
        },
      };

    case ADD_ACTIVITY_VALIDATION_SUCCESS:
      return {
        ...state,
        inlineEditor: {
          ...state.inlineEditor,
          validating: false,
        },
      };

    case ADD_ACTIVITY_VALIDATION_ERROR:
      return {
        ...state,
        inlineEditor: {
          ...state.inlineEditor,
          validating: false,
          errorMessage: action.errorMessage,
        },
      };
    case RESET_BEFORE_ADD_NEW_CONSTRAINT:
      return {
        ...state,
        inlineEditor: {
          ...state.inlineEditor,
          constraint: {
            ...initialState.inlineEditor.constraint,
            startDate: state.data.startDate,
            endDate: state.data.endDate,
          },
        },
      };

    case ADD_ACTIVITY_EDIT_CONSTRAINT:
      const isAdding = action.index === null;
      return {
        ...state,
        inlineEditor: {
          ...state.inlineEditor,
          editing: true,
          index: action.index,
          constraint: isAdding
            ? {
                type: "fixed-spend",
                match: { dimension: "publisher", value: undefined },
                startDate: state.data.startDate,
                endDate: state.data.endDate,
              }
            : { ...state.data.constraints[action.index] },
        },
      };

    case ADD_ACTIVITY_CANCEL_EDIT_CONSTRAINT:
      return {
        ...state,
        inlineEditor: initialState.inlineEditor,
      };

    case ADD_ACTIVITY_DELETE_CONSTRAINT:
      return {
        ...state,
        data: {
          ...state.data,
          constraints: state.data.constraints.filter((d, i) => i !== action.index),
        },
      };

    default:
      return state;
  }
};

export default reducer;
