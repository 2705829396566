export const BACK_TO_ACTIVITIES_LIST = "BACK_TO_ACTIVITIES_LIST";
export const ACTIVITIES_TOGGLE_INVESTMENT_PLAN_SELECTION = "ACTIVITIES_TOGGLE_INVESTMENT_PLAN_SELECTION";
export const SORT_PREDICTIONS = "SORT_PREDICTIONS";
export const SORT_PREDICTIONS_DONE = "SORT_PREDICTIONS_DONE";
export const ACTIVITIES_TOGGLE_MEDIA_MIX_EXPANDED = "ACTIVITIES_TOGGLE_MEDIA_MIX_EXPANDED";
export const STEP = {
  PREDICTION_LIST: "PREDICTION_LIST",
  ACTIVITIES_LIST: "ACTIVITIES_LIST",
};

const toggleMediaMixExpanded = () => ({
  type: ACTIVITIES_TOGGLE_MEDIA_MIX_EXPANDED,
});

export const actionCreators = {
  toggleMediaMixExpanded,
};
