export const REMOVE_INVESTMENT_PLAN = "REMOVE_INVESTMENT_PLAN";
export const REMOVE_INVESTMENT_PLAN_SUCCESS = "REMOVE_INVESTMENT_PLAN_SUCCESS";
export const REMOVE_INVESTMENT_PLAN_ERROR = "REMOVE_INVESTMENT_PLAN_ERROR";

export const GET_ACTIVITY_SUMMARIES = "GET_ACTIVITY_SUMMARIES";
export const GET_ACTIVITY_SUMMARIES_ERROR = "GET_ACTIVITY_SUMMARIES_ERROR";
export const GET_ACTIVITY_SUMMARIES_SUCCESS = "GET_ACTIVITY_SUMMARIES_SUCCESS";

export const GET_PLANS_BY_ACTIVITY = "GET_PLANS_BY_ACTIVITY";
export const GET_PLANS_BY_ACTIVITY_ERROR = "GET_PLANS_BY_ACTIVITY_ERROR";
export const GET_PLANS_BY_ACTIVITY_SUCCESS = "GET_PLANS_BY_ACTIVITY_SUCCESS";
export const SET_SORT_ACTIVITIES_LIST = "SET_SORT_ACTIVITIES_LIST";
