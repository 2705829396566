import _ from "lodash";
import { useAccount } from "auth";

//This flags are used in media insights plus - showDirectIndirectSplit, showPercentageiKpinEpapnsion
const metrics = {
  net_spend: {
    label: "Media investment",
    isMoney: true,
    isRounded: true,
    showDirectIndirectSplit: false,
    showPercentageinKpiExpansion: true,
    showSynergy: false,
  },

  effects_aggregated: {
    label: (objectiveUnitName) => `Media generated ${objectiveUnitName}`,
    isRounded: true,
    showDirectIndirectSplit: true,
    showPercentageinKpiExpansion: true,
    showSynergy: true,
  },

  profit_aggregated: {
    label: "Media generated profit",
    isMoney: true,
    isRounded: true,
    showDirectIndirectSplit: true,
    showPercentageinKpiExpansion: true,
    showSynergy: true,
  },

  "net_spend/effects_aggregated": {
    label: "Cost per action",
    isMoney: true,
    dailyDisabled: true, // Because the uncertainty for daily is very high
    isRounded: true,
    showDirectIndirectSplit: true,
    showPercentageinKpiExpansion: false,
    showSynergy: false,
  },

  "profit_aggregated/net_spend": {
    label: "Media profit ROI",
    dailyDisabled: true, // Because the uncertainty for daily is very high
    showDirectIndirectSplit: true,
    showPercentageinKpiExpansion: false,
    showSynergy: false,
  },
};

// Sometimes labels need to be evaluated when this function is called
// (think: new stuff we depend on on the Redux store). That's why
// computed labels are functions.
export function getMetricLabelMIPlus(id, objectiveUnitName) {
  const { label } = metrics[id] || { label: id?.replace(/_/g, " ").toLowerCase() };
  return _.isFunction(label) ? label(objectiveUnitName) : label;
}

export function getDefaultMetricLabelMIPlus(id) {
  const { label } = metrics[id];
  return _.isFunction(label) ? label("{objectiveUnitName}") : label;
}

export function useMetricLabelGetter() {
  const { selectedModel: modelSlug, conf } = useAccount();
  const modelConf = conf.models[modelSlug] || {};
  const { mediaInsightsPlus__metrics_v1, objectiveUnitName } = modelConf;

  if (mediaInsightsPlus__metrics_v1 && mediaInsightsPlus__metrics_v1.length > 0)
    return (slug) =>
      (mediaInsightsPlus__metrics_v1.find((d) => d.slug === slug)?.label || slug)?.replace(
        "{objectiveUnitName}",
        objectiveUnitName,
      );
  else return {};
}

// Check for: isMoney, isRounded, dailyDisabled, timelineDisabled...
export function checkMetricFlag(id, flag) {
  return (metrics[id] || {})[flag] || false;
}

export const defaultImpactPerDimensionValues = {
  direct: ["media-grouping"],
  indirect: ["media-grouping"],
  combined: ["media-grouping"],
};

export default metrics;
