import { Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAccount } from "auth";
import { navigateToAvailableFeature } from "autoRedirect";
import { getTheme } from "getTheme";
import _ from "lodash";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as BackArrowIcon } from "shared/Icons/Back.svg";
import Card from "shared/components/Card";
import { CommonErrorBoundary } from "shared/components/ErrorBoundary";
import HighlightText from "shared/components/HighlightText";
import { sessionStorageSync } from "shared/helpers/sessionStorageSync";
import store from "store";
import { logoutResetStore } from "store/action-creators";
import useResizeObserver from "use-resize-observer";
import { SearchBar } from "./SearchBar";

const PAGE_SIZE = 24;

export default function Landing({ orgList = [], selection = "org", defaultRole = "", orgLoading = true }) {
  const { conf, logout, loggedIn, isFeatureAvailable, selectedModel } = useAccount();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = getTheme();
  const navigate = useNavigate();
  const { ref, width } = useResizeObserver();
  const [pagination, setPagination] = React.useState({
    from: 0,
    to: PAGE_SIZE,
  });
  const [pageCount, setPageCount] = React.useState(1);
  const [searchText, setSearchText] = React.useState("");
  const selectedOrg = JSON.parse(sessionStorage.getItem("selectedOrg"));
  const models = Object.values(conf?.models || {});

  const handlePagination = (e, page) => {
    setPageCount(() => page);
    const from = Math.min(PAGE_SIZE * (page - 1), selection === "org" ? orgList.length : modelList.length);
    const to = Math.min(PAGE_SIZE * (page - 1) + PAGE_SIZE, selection === "org" ? orgList.length : modelList.length);
    setPagination({ from: from, to: to });
  };
  React.useEffect(() => {
    if (selectedModel)
      navigateToAvailableFeature(isFeatureAvailable, navigate, searchParams, conf?.models?.[selectedModel]?.id);
  }, [conf?.models, isFeatureAvailable, navigate, searchParams, selectedModel]);

  React.useEffect(() => {
    if ((defaultRole === "client" || defaultRole === "clientService") && orgList.length === 1)
      navigate(`/?org=${orgList[0].id}`);
    if (models.length === 1)
      setSearchParams((params) => {
        params.set("model", Object.values(conf?.models)?.[0]?.id);
        return params;
      });
  }, [conf?.models, defaultRole, models.length, navigate, orgList, setSearchParams]);

  const modelList = _.sortBy(
    Object.entries(conf?.models || {}).map(([slug, data]) => ({ ...data, slug })),
    ["brand", "name"],
  );

  React.useEffect(() => {
    if (selection === "org") {
      setPagination({ from: (pageCount - 1) * PAGE_SIZE, to: Math.min(PAGE_SIZE * pageCount, orgList?.length) });
      sessionStorageSync.removeItem("current-token-v2");
      sessionStorageSync.removeItem("selected-model");
      sessionStorageSync.removeItem("selectedOrg");
      store.dispatch(logoutResetStore());
    } else {
      setPagination({ from: (pageCount - 1) * PAGE_SIZE, to: Math.min(PAGE_SIZE * pageCount, modelList?.length) });
    }
    // screenResolutionHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelList?.length, orgList?.length, selection]);

  const PaginationWrap = (
    <PaginationWrapper>
      <Pagination
        sx={{
          ".MuiPagination-ul": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "24px",
            color: "transparent",
          },
          ".MuiPaginationItem-root": {
            minWidth: "20px",
            "&.Mui-selected": {
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
              backgroundColor: theme.palette.primary.main,
              fontSize: "0px",
              height: "24px",
              width: "24px",
            },
            "&:not(.Mui-selected)": {
              backgroundColor: theme.palette.greyish[20],
              fontSize: "0px",
              height: "20px",
              width: "20px",
            },
          },
        }}
        count={
          selection === "org"
            ? Math.ceil(orgList?.length / PAGE_SIZE || 0)
            : Math.ceil(modelList?.length / PAGE_SIZE || 0)
        }
        size="small"
        hideNextButton={true}
        hidePrevButton={true}
        onChange={handlePagination}
        defaultPage={pageCount}
      />
    </PaginationWrapper>
  );

  if (selectedModel) return null;

  return (
    <CommonErrorBoundary fallbackTitle="Landing Page">
      <LandingFrame ref={ref} data-test-id="landing">
        <ModelListFrame>
          {orgList && orgList.length > 1 && selection === "models" && (
            <ModelList
              onClick={() => {
                setPageCount(1); // reset the page to 1, so that org selection will start from 1st page
                navigate("/");
                logout("User action to change org.");
              }}
            >
              <ModelChoice
                noHover
                style={{ alignSelf: "center", borderBottom: "0px", marginBottom: "20px", display: "flex", gap: "8px" }}
              >
                <BackArrow />
                {selectedOrg?.orgName}
              </ModelChoice>
            </ModelList>
          )}
          {!orgLoading && (
            <>
              <HelpText>{selection === "org" ? "Choose your organization" : "Choose the model"}</HelpText>
              {selection === "org" && orgList?.length > 24 && (
                <SearchBar selection={selection} searchText={searchText} setSearchText={setSearchText} />
              )}
              {selection === "models" && modelList?.length > 24 && (
                <SearchBar selection={selection} searchText={searchText} setSearchText={setSearchText} />
              )}
              {((selection === "org" && orgList.length > 0) || (selection === "models" && modelList.length > 0)) && (
                <ModelListOrgFrame searchText={searchText}>
                  <ModelListOrg PAGE_SIZE={pagination.to - pagination.from} searchText={searchText}>
                    {selection === "org" ? (
                      searchText.length === 0 ? (
                        convertToColumns(
                          orgList
                            ?.sort(({ orgName: a }, { orgName: b }) =>
                              a.toLowerCase().replaceAll(" ", "") > b.toLowerCase().replaceAll(" ", "") ? 1 : -1,
                            )
                            ?.slice(pagination.from, pagination.to),
                          width,
                        ).map((column, i) => (
                          <Column key={i}>
                            {column.map((org, index) => (
                              <ModelChoice
                                key={org?.orgSlug}
                                searchText={searchText}
                                onClick={() => navigate(`/?org=${org?.id}`)}
                              >
                                {org?.orgName}
                              </ModelChoice>
                            ))}
                          </Column>
                        ))
                      ) : (
                        <SearchList className="customScroll">
                          {orgList
                            ?.filter(
                              (item) =>
                                item?.orgName?.toLowerCase()?.includes(searchText.toLowerCase()) &&
                                searchText.trimStart().length === searchText.length,
                            )
                            .sort(({ orgName: a }, { orgName: b }) => {
                              const aStartsWithSearchText = a.toLowerCase().startsWith(searchText.toLowerCase());
                              const bStartsWithSearchText = b.toLowerCase().startsWith(searchText.toLowerCase());
                              if (aStartsWithSearchText && !bStartsWithSearchText) {
                                return -1;
                              } else if (!aStartsWithSearchText && bStartsWithSearchText) {
                                return 1;
                              } else {
                                return a.toLowerCase().localeCompare(b.toLowerCase());
                              }
                            })
                            .map(({ id: orgId, orgName }) => (
                              <ModelChoice
                                style={{ padding: "25px 16px", width: "920px" }}
                                searchText={searchText}
                                key={orgId}
                                onClick={() => navigate(`/?org=${orgId}`)}
                              >
                                {HighlightText(orgName, searchText)}
                              </ModelChoice>
                            ))}
                        </SearchList>
                      )
                    ) : searchText.length === 0 ? (
                      convertToColumns(modelList?.slice(pagination.from, pagination.to), width)?.map((column, i) => (
                        <Column key={i}>
                          {column?.map(({ slug, brand, name, id: modelId }) => (
                            <ModelChoice
                              SearchText={searchText}
                              key={slug}
                              onClick={() =>
                                setSearchParams((params) => {
                                  params.set("model", modelId);
                                  return params;
                                })
                              }
                            >
                              {brand} {name}
                            </ModelChoice>
                          ))}
                        </Column>
                      ))
                    ) : (
                      <SearchList className="customScroll">
                        {modelList
                          ?.filter(
                            ({ brand, name }) =>
                              `${brand} ${name}`?.toLowerCase()?.includes(searchText.toLowerCase()) &&
                              searchText.trim().length !== 0,
                          )
                          .map(({ slug, brand, name, id: modelId }) => (
                            <ModelChoice
                              style={{ padding: "25px 16px", width: "920px" }}
                              SearchText={searchText}
                              key={slug}
                              onClick={() =>
                                setSearchParams((params) => {
                                  params.set("model", modelId);
                                  return params;
                                })
                              }
                            >
                              {HighlightText(brand + " " + name, searchText)}
                            </ModelChoice>
                          ))}
                      </SearchList>
                    )}
                  </ModelListOrg>
                </ModelListOrgFrame>
              )}
              {selection === "models" && modelList.length === 0 && loggedIn && (
                <ErrorMessage>
                  Note: You do not have access to Models of this organization. Please reach out to your point of contact
                  in Kantar.
                </ErrorMessage>
              )}
              {!searchText.length && orgList.length > 24 && selection === "org" ? (
                PaginationWrap
              ) : (
                <div style={{ height: "24px" }} />
              )}
              {!searchText.length && modelList?.length > 24 && selection === "models" ? (
                PaginationWrap
              ) : (
                <div style={{ height: "24px" }} />
              )}
            </>
          )}
        </ModelListFrame>
      </LandingFrame>
    </CommonErrorBoundary>
  );
}

const LandingFrame = styled("div")`
  display: grid;
  min-height: 100vh;
  background: ${({ theme }) => theme.palette.whiteish.main};
  color: ${({ theme }) => theme.palette.blackish.main};
`;

const ModelList = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  background: #fafafa;
  gap: 0px 20px;
  overflow-x: hidden;
  border: none;
  box-shadow: none;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
`;

const ModelListOrg = styled("div")`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  background: #fafafa;
  margin: 20px 0px;
  height: 400px;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
`;

const ModelListOrgFrame = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const SearchList = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: 400px;
  overflow-x: hidden;
  width: 920px;
`;

const PaginationWrapper = styled("div")`
  display: flex;
  align-item: center;
  justify-content: center;
`;

const ModelListFrame = styled("div")`
  align-self: center;
  justify-self: center;
`;

const ModelChoice = styled("div")(
  ({ noHover, searchText, theme }) => `
  padding: 16px 10px;
  background-color:white;
  cursor: pointer;
  height: 50px;
  display:flex;
  align-items: center;
  &:hover {
    color: ${noHover ? "inherit" : "white"};
    background: ${noHover ? "inherit" : "black"};
  }
  border-bottom: 1px solid ${theme.palette.greyish[25]};
  &:last-child {
    border-bottom: none;
  }
  width: ${searchText?.length ? "800px" : "300px"};
`,
);

const Column = styled("div")`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.1);
  height: fit-content;
  width: 300px;
`;

const HelpText = styled("div")`
  text-align: center;
  margin-bottom: 20px;
`;

const BackArrow = styled(BackArrowIcon)`
  cursor: pointer;
  display: flex;
  align-self: center;
  height: 30px;
  width: 30px;
  & * {
    fill: ${(props) => props.theme.palette.primary.main};
  }
`;

const ErrorMessage = styled("div")`
  font-size: 18px;
  color: ${({ theme }) => theme.palette.greyish[50]};
  width: 100%;
  text-align: center;
  padding-top: 75px;
`;
function convertToColumns(arr, width) {
  const noOfRows = 8;
  if (arr.length <= noOfRows) {
    return [arr];
  } else if (arr.length <= 2 * noOfRows) {
    const firstColumnSize = Math.ceil(arr.length / 2);
    return [arr.slice(0, firstColumnSize), arr.slice(firstColumnSize)];
  } else {
    return [arr.slice(0, noOfRows), arr.slice(noOfRows, 2 * noOfRows), arr.slice(2 * noOfRows)];
  }
}
