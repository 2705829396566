import styled from "@emotion/styled/macro";
import { Icon } from "@mui/material";

// Icons
import { ReactComponent as DraggingIcon } from "shared/Icons/Dragging.svg";
import { ReactComponent as DraggableIconGrey } from "shared/Icons/draggable-icon-disabled.svg";
import { ReactComponent as DraggableIcon } from "shared/Icons/draggable-icon.svg";
import { ReactComponent as DraggingIconGrey } from "shared/Icons/dragging-grey.svg";

export default function DraggableDroppableIcon({ isChecked = false, isDragging = false, provided }) {
  return (
    <StyledIcon {...provided.dragHandleProps} checked={isChecked}>
      {getDragdownIcon(isChecked, isDragging)}
    </StyledIcon>
  );
}

function getDragdownIcon(isChecked, isDragging) {
  if (isChecked && isDragging) {
    return <DraggingIcon />;
  } else if (isChecked && !isDragging) {
    return <DraggableIcon />;
  } else if (!isChecked && isDragging) {
    return <DraggingIconGrey />;
  } else {
    return <DraggableIconGrey />;
  }
}

export const StyledIcon = styled(Icon)`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  & * {
    fill: ${({ theme, checked }) => (checked ? theme.palette.primary.main : theme.palette.greyish[20])};
  }
  &:hover {
    & * {
      fill: ${({ theme, checked }) => (checked ? theme.palette.primary.main : theme.palette.greyish[20])};
    }
  }
`;
