const valueTypes = [
  { id: "fixed-spend", label: "Fixed" },
  { id: "minimum-spend", label: "Minimum" },
  { id: "maximum-spend", label: "Maximum" },
];

export const getValueTypeIds = () => valueTypes.map((d) => d.id);

export const getValueTypeLabel = (id) => {
  const found = valueTypes.find((d) => d.id === id);

  if (found) return found.label;
  else console.error(`valueType: id '${id}' not found!`);
};
