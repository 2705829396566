import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { useAccount } from "auth";
import React from "react";
import { connect } from "react-redux";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import BroadcastPanel from "root/BroadcastPanel";
import { CommonErrorBoundary } from "shared/components/ErrorBoundary";
import { filteredSearchParams } from "shared/helpers/urlSearchParams";
import useBroadcastMessages from "shared/hooks/useBroadcastMessages";
import { navigationSetCurrentPage } from "store/action-creators";
import { ReactComponent as RightArrowIcon } from "./Assets/RightArrow.svg";
import bw7Logo from "./Assets/bw7-logo.png";
import { ReactComponent as KantarLogo } from "./Assets/newKanarLogo.svg";
import { ReactComponent as RightArrowBlackIcon } from "./Assets/rightArrowBlack.svg";
import ModelMenu from "./ModelMenu";
import NewPredictionBadge from "./NewPredictionBadge";
import paginateTabs from "./pagination";

export const NAVIGATION_HEIGHT = 85;
const ALL_TABS = [
  { to: `/media-insights`, feature: "Media insights", keep: ["org", "model"] },
  { to: `/media-insights-plus`, feature: "Media insights+", keep: ["org", "model"] },
  { to: `/business-insights`, feature: "Business insights", keep: ["org", "model"] },
  { to: `/business-insights-plus`, feature: "Business insights+", keep: ["org", "model"] },
  {
    to: `/predictions`,
    feature: "Predict",
    show: (
      <>
        Predictions <NewPredictionBadge />
      </>
    ),
    keep: ["org", "model"],
  },
  { to: `/activities`, feature: "Predict", show: "Activities", keep: ["org", "model"] },
];

function Navigation({ currentPage, setCurrentPage }) {
  const theme = useTheme();
  const { isFeatureAvailable } = useAccount();
  const [searchParams] = useSearchParams();
  // Used in StickyWrapper component.

  //broadcastPanelRef is used to open the BroadcastPanel from NewBroadcastBar(below) component.
  const broadcastPanelRef = React.useRef(null);
  const pages = ALL_TABS.filter(({ feature }) => isFeatureAvailable(feature)) // Only activated features
    .map((d) => ({
      ...d,
      show: d.show || d.feature, // Show the feature name unless overriden
      to: d.to + filteredSearchParams(searchParams, d.keep), // Add search params to be kept
    }));

  const location = useLocation();
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const itemsPerPage = windowWidth > 1300 ? 4 : 3;
  const paginatedPages = paginateTabs(pages, itemsPerPage, location);
  if (
    paginatedPages.length !== 0 &&
    (paginatedPages.length < currentPage || paginatedPages[currentPage - 1] === undefined)
  ) {
    setCurrentPage(currentPage - 1);
  }

  const visiblePages =
    paginatedPages[currentPage - 1]?.length > 1
      ? paginatedPages[currentPage - 1].sort(
          (a, b) => pages.findIndex((item) => item.to === a.to) - pages.findIndex((item) => item.to === b.to),
        )
      : paginatedPages[currentPage - 1];

  const reportWindowSize = () => {
    setWindowWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", reportWindowSize);
    return () => window.removeEventListener("resize", () => reportWindowSize);
  }, []);

  return (
    <StickyWrapper
      style={{
        backgroundColor: isFeatureAvailable("BW7 theme") ? theme.palette.whiteish.main : theme.palette.blackish.main,
        color: isFeatureAvailable("BW7 theme") ? theme.palette.blackish.main : theme.palette.whiteish.main,
      }}
    >
      <MainNavdiv>
        <SmoothDiv
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            gridArea: "logo",
            height: NAVIGATION_HEIGHT,
          }}
        >
          {isFeatureAvailable("BW7 theme") ? (
            <img src={bw7Logo} alt="The Blackwood Seven Logo" style={{ height: 60, width: "auto" }} />
          ) : (
            <>
              <KantarLogo
                alt="The Kantar Logo"
                style={{
                  height: 20,
                  width: 106,
                  transition: "height 200ms ease-in-out",
                }}
              />
              <SmoothDiv
                style={{
                  margin: "0px 10px",
                  display: "flex",
                  borderRight: "2px",
                  background: "white",
                  width: "2px",
                  height: 32,
                }}
              ></SmoothDiv>
              <SmoothDiv
                style={{
                  fontSize: 18,
                }}
              >
                Hamilton AI
              </SmoothDiv>
            </>
          )}
        </SmoothDiv>
        <SmoothDiv style={{ gridArea: "tabs" }}>
          <CommonErrorBoundary
            fallbackTitle="Navigation Tabs"
            overrideStyle={{
              textAlign: "center",
              fontSize: "1.2em",
              height: "var(--pages-as-tabs-height)",
              top: 10,
              position: "relative",
              marginBottom: 0,
              marginTop: 0,
            }}
          >
            <PagesAsTabs
              currentPage={currentPage}
              visiblePages={visiblePages}
              paginatedPages={paginatedPages}
              setCurrentPage={setCurrentPage}
              isFeatureAvailable={isFeatureAvailable}
            />
          </CommonErrorBoundary>
        </SmoothDiv>
        <ModelmenuAndIconsWrapper style={{ gridArea: "modelMenu" }}>
          <ModelMenu />
          <ActionItemsContainer>
            {/* The broadcast station will be visible universal without the feature flag */}
            <BroadcastPanel ref={broadcastPanelRef} />
          </ActionItemsContainer>
        </ModelmenuAndIconsWrapper>
        <SmoothDiv style={{ gridArea: "subMenu" }} id="navigation-submenu" />
      </MainNavdiv>
      <NewBroadcastBar broadcastPanelRef={broadcastPanelRef} />
    </StickyWrapper>
  );
}

export default connect(
  (state) => ({
    currentPage: state.navigation.currentPage,
  }),
  {
    setCurrentPage: navigationSetCurrentPage,
  },
)(Navigation);

function NewBroadcastBar({ broadcastPanelRef }) {
  const [showReadmoreBroadcastMessage, setReadmoreBroadcastMessage] = React.useState(false);
  const broadcastMessagebarRef = React.useRef(null);

  const { isNewBroadcastAvailable, latestBroadcast } = useBroadcastMessages();
  const latestBroadcastMessage = latestBroadcast?.broadcastmessage;
  React.useEffect(() => {
    setReadmoreBroadcastMessage(
      (prevVal) => broadcastMessagebarRef.current?.offsetWidth < broadcastMessagebarRef.current?.scrollWidth,
    );
  }, [latestBroadcastMessage]);

  if (!isNewBroadcastAvailable) {
    return;
  }

  return (
    <NewBroadcastBarContainer>
      <div>
        <p ref={broadcastMessagebarRef}>{latestBroadcastMessage}</p>
        {showReadmoreBroadcastMessage && (
          <button href="" onClick={() => broadcastPanelRef.current?.openBroadcastPanel()}>
            Read more
          </button>
        )}
      </div>
      <IconButton
        aria-label="close-broadcast-message"
        onClick={() => broadcastPanelRef.current?.closeNewBroadcastBar()}
      >
        <CancelIcon />
      </IconButton>
    </NewBroadcastBarContainer>
  );
}

const SmoothDiv = styled("div")`
  transition: 0.2s;
`;

const StickyWrapper = styled("div")`
  // variables to use for height of pagesAsTabs and submenu.
  --pages-as-tabs-height: 31px;
  --submenu-min-height: 40px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  height: var(--navbar-height);
`;

const LinkAsTab = styled(NavLink)(
  ({ theme }) => `
  display: block;
  color:  ${theme.extraData.darkHeader ? "#bdbdbd" : "#424242"};
  font-size: 16px;
  text-decoration: none;
  height: 31px;
  padding: 6px 16px 0 16px;
  border-top-width: 4px;
  border-top-style: solid;
  border-top-color: transparent;
  transition : border 300ms ease-out, color 300ms ease-out;

  :hover {
    color: ${theme.extraData.darkHeader ? "white" : "black"};
    border-top-color: ${theme.extraData.darkHeader ? "#353535" : "lightgray"};
  }

  &.active {
    color: ${theme.extraData.darkHeader ? "white" : "black"};
    border-top-color: ${theme.palette.primary.main};
  }
`,
);

const ActionItemsContainer = styled("div")`
  display: flex;
  gap: 1rem;
`;

const ModelmenuAndIconsWrapper = styled("div")`
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  height: 40px;
  justify-content: end;
`;

function PagesAsTabs({ currentPage, visiblePages, paginatedPages, setCurrentPage, isFeatureAvailable }) {
  const smoothDivStyle = {
    display: "flex",
    height: "var(--pages-as-tabs-height)",
    opacity: 1,
    pointerEvents: "auto",
  };
  return (
    <SmoothDiv style={smoothDivStyle}>
      {currentPage !== 1 && (
        <IconButton
          onClick={() => {
            setCurrentPage(currentPage - 1);
          }}
          style={{ padding: 0, alignItems: "end", marginRight: 5 }}
        >
          {isFeatureAvailable("BW7 theme") ? (
            <RightArrowBlackIcon height={20} width={20} style={{ position: "relative", transform: "rotate(180deg)" }} />
          ) : (
            <RightArrowIcon height={20} width={20} style={{ position: "relative", transform: "rotate(180deg)" }} />
          )}
        </IconButton>
      )}
      {visiblePages &&
        visiblePages.map(({ to, show }) => (
          <LinkAsTab key={show} to={to}>
            {show}
          </LinkAsTab>
        ))}
      {currentPage < paginatedPages.length && (
        <IconButton
          onClick={() => {
            setCurrentPage(currentPage + 1);
          }}
          style={{ padding: 0, alignItems: "end", marginLeft: 5 }}
        >
          {isFeatureAvailable("BW7 theme") ? (
            <RightArrowBlackIcon height={20} width={20} />
          ) : (
            <RightArrowIcon height={20} width={20} />
          )}
        </IconButton>
      )}
    </SmoothDiv>
  );
}

const NewBroadcastBarContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.alertYellow.main,
  color: "black",
  position: "relative",
  padding: "8px 0",
  div: {
    maxWidth: "var(--main-div-max-width)",
    margin: "0 auto",
    height: 24,
    display: "flex",
    alignItems: "center",
    gap: 8,

    padding: "0 var(--main-div-left-right-padding)",
    p: {
      margin: 0,

      //to show ellipsis and read more button if message doesn't fit in one line.
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    button: {
      //read more button
      flexShrink: 0,
      backgroundColor: "transparent",
      margin: 0,
      padding: 0,
      border: "none",
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  "& > button": {
    //close button
    color: "inherit",
    position: "absolute",
    right: 8,
    top: 0,
    width: 24,
  },
}));

const MainNavdiv = styled("div")`
  position: relative;
  margin: 0 auto;
  display: grid;
  max-width: var(--main-div-max-width);
  padding: 0 var(--main-div-left-right-padding);
  grid-template-areas: "logo tabs modelMenu" "logo subMenu subMenu";
  grid-template-columns: auto auto minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  grid-column-gap: 15px;
`;
