import tinycolor from "tinycolor2";

// This list of color should have at least as many colors as the number of media groupings,
// so it depends on what is configured. Still, if the amount of channel groups is too big,
// the rest will get the black color.

// The likelihood of many groupings is larger if there's many models that don't share the
// same naming for groupings

const COLOR_SET = [
  "rgb(39, 207, 192)",
  "rgb(158, 228, 269)",
  "rgb(258, 228, 69)",
  "rgb(250, 177, 160)",
  "rgb(255, 118, 117)",
  "rgb(253, 121, 168)",
  "rgb(129, 236, 236)",
  "rgb(225, 112, 85)",
  "rgb(214, 48, 49)",
  "rgb(228, 128, 69)",
  "rgb(28, 228, 269)",
  "rgb(228, 28, 229)",
  "rgb(128, 28, 269)",
  "rgb(116, 185, 255)",
  "rgb(255, 234, 167)",
  "rgb(108, 92, 231)",
  "rgb(9, 132, 227)",
  "rgb(0, 106, 201)",
  "rgb(0, 184, 258)",
  "rgb(162, 155, 254)",
  "rgb(232, 67, 167)",
  "rgb(253, 203, 110)",
  "rgb(85, 239, 196)",
  "rgb(128, 228, 29)",
  "rgb(213, 0, 249)",
  "rgb(40, 53, 147)",
  "rgb(121, 85, 72)",
  "rgb(33, 150, 243)",
  "rgb(255, 235, 59)",
  "rgb(126, 87, 194)",
  "rgb(85, 195, 225)",
  "rgb(255, 64, 129)",
  "rgb(0, 200, 83)",
  "rgb(255, 112, 67)",
  "rgb(41, 121, 255)",
  "rgb(204, 128, 207)",
  "rgb(38, 166, 154)",
  "rgb(253, 187, 84)",
];

let spareColorsByCategory = {};
let assignedColors = {};

/*
TODO: add a mechanism for resetting categories when the modelSlug changes 
because it will generate shades after running out of color even when it is not necessary.
*/
export default function getDynamicColor(category, id) {
  const key = [category, id].join(".");

  // Color not assigned before
  if (!assignedColors[key]) {
    if (!spareColorsByCategory[category]) {
      //new category assign
      spareColorsByCategory[category] = [...COLOR_SET];
    } else if (spareColorsByCategory[category]?.length === 0) {
      spareColorsByCategory[category] = generateShades(COLOR_SET, category);
    }
    assignedColors[key] = id === "other" ? "gray" : spareColorsByCategory[category].pop();
  }

  return assignedColors[key];
}

const generateShades = shadesGenerator();
function shadesGenerator() {
  const categoriesOccurance = new Map(); //tracks how many times shade is generated for each category
  return function generateShades(colorSet = [], category = "") {
    if (categoriesOccurance.has(category)) {
      categoriesOccurance.set(category, categoriesOccurance.get(category) + 1);
    } else {
      categoriesOccurance.set(category, 1);
    }

    const deviation = categoriesOccurance.get(category);

    return colorSet.map((color) => {
      const newColor = tinycolor(color).spin(4 * deviation);
      const isDark = newColor.isDark();

      if (isDark) {
        return newColor.lighten(20).toRgbString();
      } else {
        return newColor.darken(20).toRgbString();
      }
    });
  };
}
