import React from "react";
import styled from "@emotion/styled/macro";
import { useTheme } from "@mui/styles";
import { Popover, MenuList, ListItem, Tooltip } from "@mui/material";
import Fuse from "fuse.js";

export default function EditorItemForTextWithSuggestion({
  data,
  schema = {},
  onChange,
  isDisabled,
  autoFocus,
  options,
  extraStyles = {},
}) {
  const inputRef = React.useRef();
  const [state, setState] = React.useState(data);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null); //anchor reference for suggestion mui popover
  const open = Boolean(anchorEl); //prop for mui popover

  function getDescription(label) {
    return options?.find((option) => option.label.toLowerCase() === label.toLowerCase())?.desc;
  }

  // fuzzy-search implementation
  const fuseOptions = {
    keys: ["label"],
    threshold: 0.5,
  };
  const fuse = new Fuse(options, fuseOptions);
  const filteredOptions = state.length !== 0 ? fuse.search(state).map((obj) => obj.item) : options;

  React.useEffect(() => {
    setState(data);
  }, [data]);

  // Only call onChange in a debounced fashion
  React.useEffect(() => {
    const handler = setTimeout(() => {
      if (onChange && data !== state) {
        onChange(() => state);
        inputRef.current.scrollIntoView({
          behavior: "auto",
          block: "center",
        });
      }
    }, 300);
    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  function inputChangeHandler(e) {
    setAnchorEl(e.currentTarget);
    setState(schema.type === "integer" ? +e.target.value : e.target.value);
  }
  function clickHandler(text) {
    setState(text);
    handleClose(); //close when option is selected.
  }

  function handleClose() {
    setAnchorEl(null);
  }
  function focusChangeHandler(e) {
    setAnchorEl(e.currentTarget);
  }

  return (
    <div style={{ position: "relative", ...extraStyles }}>
      <Tooltip
        title={<TooltipDescription description={getDescription(state)} dimension="Grouping" />}
        disableFocusListener={open} //don't show tooltip when menu is opened
        disableInteractive
        arrow
      >
        <TextInput
          theme={theme}
          ref={inputRef}
          data-test-id={`adminInput${schema.title}`}
          autoFocus={autoFocus}
          placeholder={schema.title}
          style={{
            cursor: isDisabled || schema.ui__readOnly ? "not-allowed" : "auto",
            textOverflow: isDisabled && "ellipsis",
            whiteSpace: isDisabled && "nowrap",
            overflow: isDisabled && "hidden",
            color: "inherit",
          }}
          value={state ?? ""}
          onChange={inputChangeHandler}
          onFocus={focusChangeHandler}
          disabled={isDisabled || schema.ui__readOnly}
          spellCheck={false}
          autoComplete="new-password" // The only thing that seems to disable auto-complete https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
        />
      </Tooltip>
      {open && filteredOptions?.length > 0 && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{ vertical: -5 }}
          //prevent autofocus of mui popover element
          disableAutoFocus
          disableEnforceFocus
          disableRestoreFocus //do not refocus input when closed
          sx={{ ".MuiList-root": { padding: 0 }, maxHeight: "30vh" }}
        >
          <MenuList sx={{ maxWidth: "240px" }}>
            <HelpText>Optional: Select any of these predefined groupings to show description in BI tooltip</HelpText>
            {filteredOptions?.map((option, index) => (
              <Tooltip
                key={option.label}
                title={<TooltipDescription description={getDescription(option.label)} dimension="Grouping" />}
                TransitionProps={{ timeout: 0 }} //no transition can look weird when scrolling fast through the list
                placement="right"
                disableInteractive
                arrow
              >
                <OptionItem
                  onClick={() => {
                    clickHandler(option.label);
                  }}
                  autoFocus={false}
                  selected={String(option.label).toLowerCase() === state.toLowerCase()}
                  theme={theme}
                >
                  {option.label}
                </OptionItem>
              </Tooltip>
            ))}
          </MenuList>
        </Popover>
      )}
    </div>
  );
}

function TooltipDescription({ description, dimension }) {
  return description ? (
    <>
      <div style={{ marginBottom: 6 }}>{dimension} Description:</div>
      <div>{description}</div>{" "}
    </>
  ) : (
    <div>No description available for this {dimension}</div>
  );
}

const HelpText = styled(ListItem)`
  opacity: 1;
  color: #858574;
  white-space: normal;
  font-size: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.greyish[20]};
  background-color: #fafafa;
  padding: 8px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const OptionItem = styled(ListItem)`
  //&& to increase specificity
  && {
    cursor: pointer;
    background-color: ${({ selected, theme }) => (selected ? theme.palette.primary.main : "white")};
    color: ${({ selected }) => (selected ? "white" : "black")};
    font-size: 12px;
    padding: 8px;

    &:hover {
      background-color: ${({ theme, selected }) => (!selected ? theme.palette.greyish[10] : undefined)};
    }
  }
`;

const TextInput = styled.input`
  outline-color: ${({ theme }) => theme.palette.primary.main};
  display: table-cell;
  width: 100%;
  border: none;
  background: transparent;

  &:focus {
    outline-style: solid;
    outline-width: 2px;
  }
`;
