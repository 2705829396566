import { isWithinInterval, formatDistanceToNow, formatRelative, parseISO, subHours } from "date-fns";
import { enGB } from "date-fns/locale";

export function readableStamp(lastChanged, author) {
  if (!lastChanged && !author) return undefined;

  let line = "";
  if (lastChanged) {
    const parsed = parseISO(lastChanged);
    line += isWithinInterval(parsed, { start: subHours(new Date(), 1), end: new Date() })
      ? `${formatDistanceToNow(parsed)} ago `
      : formatRelative(parsed, new Date(), { locale: enGB }) + " ";
  }

  if (author) line += `by ${author}`;

  return line;
}

export const isValidRole = (roles, role) => {
  return role !== null && role !== undefined && roles.findIndex((a) => a.id === role) > -1;
};

export const isValidEmail = (email) => {
  const regexValidation =
    // eslint-disable-next-line no-useless-escape
    /^(?![-.'_!#^~])[a-zA-Z0-9-.'_!#^~]*[a-zA-Z0-9]@(?!.*\.$)(?!.*-$)(?!.*\.$)(?!.*-$)(?!.*\.\-)(?!.*\-\.)[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.[a-zA-Z0-9.-]*[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9](?<!-))$/;
  const lengthValidation = /^(?=.{1,64}@.{1,48}$).*\.[a-zA-Z0-9-]{2,24}$/;
  return regexValidation.test(email) && lengthValidation.test(email) && !email.includes("..");
};

export const isOnlyAlphabets = (str) => /^[a-zA-Z ]+$/.test(str);

export const isValidName = (str) => /^[a-zA-Z _-]+$/.test(str);

export function compareFlatJSON(obj1, obj2) {
  const diffs = {};

  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (Array.isArray(obj1[key])) {
        if (!Array.isArray(obj2[key])) {
          diffs[key] = {
            removed: obj1[key],
            added: undefined,
          };
        } else {
          const removed = obj1[key].filter((item) => !obj2[key].includes(item));
          const added = obj2[key].filter((item) => !obj1[key].includes(item));

          if (removed.length > 0 || added.length > 0) {
            diffs[key] = {
              removed: removed.length > 0 ? removed : undefined,
              added: added.length > 0 ? added : undefined,
            };
          }
        }
      } else if (obj1[key] !== obj2[key]) {
        diffs[key] = {
          oldValue: obj1[key],
          newValue: obj2[key],
        };
      }
    }
  }

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
      if (Array.isArray(obj2[key])) {
        diffs[key] = {
          removed: undefined,
          added: obj2[key],
        };
      } else {
        diffs[key] = {
          oldValue: undefined,
          newValue: obj2[key],
        };
      }
    }
  }

  return diffs;
}

export function findDuplicateAttributes(objectsList, attributeName) {
  const duplicates = {};
  for (const obj of objectsList) {
    const attributeValue = obj[attributeName];
    duplicates[attributeValue] = duplicates[attributeValue] || [];
    duplicates[attributeValue].push(obj);
  }

  return Object.fromEntries(Object.entries(duplicates).filter(([value, objs]) => objs.length > 1));
}
