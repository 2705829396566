import styled from "@emotion/styled";
import { ReactComponent as Bullet } from "shared/Icons/darkcircle.svg";

export default function CustomListItem({ children }) {
  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <div>
        {/* This div wraps the svg element and helps in aligning the bullet to first line of text in the list, We can use li tags but customizing it is not that flexible compared to this  */}
        <Bullet />
      </div>
      <Content>{children}</Content>
    </div>
  );
}

const Content = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.blackish.main};
  font-weight: 300;
  font-size: 14px;
`;
