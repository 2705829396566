import styled from "@emotion/styled/macro";
import { Checkbox, Fade, Icon, List, ListItem, ListItemIcon, ListItemText, Menu } from "@mui/material";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/styles";
import EditorItemForTextWithSuggestion from "admin/EditorItem/EditorItemForTextWithSuggestion";
import { produce } from "immer";
import _ from "lodash";
import useIdToColor from "pages/business-insights-plus/useIdToColor";
import PropTypes from "prop-types";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ColorPicker from "shared/components/ColorPicker";
import DimensionFilter from "shared/components/DimensionFilter";
import { getFilteringFunction } from "shared/helpers/filterOutUselessDimensionValues";
import getColorPickerOptions from "shared/helpers/getColorPickerOptions";
import { useCustomDriverLabels } from "shared/helpers/prettyNames";
import DraggableDroppableIcon from "shared/components/DraggableDroppableIcon";
//icons
import DeleteIconButton from "shared/components/DeleteIconButton";
import { findDuplicateAttributes } from "admin/helpers";
import formattedLabel from "shared/helpers/formattedLabel";
import { ReactComponent as ArrowIcon } from "shared/Icons/chevron-grey.svg";
function BusinessGroupingsEditor({
  data = [],
  prodData = [], //to see property in conf taken to production
  onChange,
  isDisabled,
  businessDimensionValues,
  labelDictionary,
}) {
  const theme = useTheme();
  const customDriverLabelOptions = useCustomDriverLabels();
  const [expanded, setExpanded] = React.useState("rest");
  const getDefaultColor = useIdToColor({ opts: { getDefaultDatatypenameColor: true } });
  const validFilters = data.map((d) => d.filter).filter((d) => !_.isEmpty(d));
  const ungrouped = businessDimensionValues.filter(
    (insertion) => !validFilters.some((filter) => getFilteringFunction(filter)(insertion)),
  );
  const prodDriverCustomGroupings = prodData?.["driverCustom"] ?? [];
  const sortedColorPickerOptions = getColorPickerOptions({ opts: { manualSort: true } });

  const getLabelForDimension = (dimension) => {
    //get labels to show in ui for dimension values
    const label = formattedLabel(labelDictionary, null, dimension);
    return label.charAt(0).toUpperCase() + label.slice(1);
  };
  return (
    <div>
      {businessDimensionValues.length > 0 && ungrouped.length === 0 && (
        <Message>All dimension values are now part of your business groupings.</Message>
      )}

      {data?.length !== 0 && (
        <ItemFrame style={{ padding: "8px 15px", color: "black", height: "49px" }}>
          {/* Column Headers */}
          <span style={{ flexGrow: 1 }}></span>
          <span style={{ width: isDisabled ? "240px" : "275px", fontSize: 14 }}>Dimensions to show</span>
        </ItemFrame>
      )}
      {(data || []).map(({ slug, name, filter, dimension, color: defaultColor }, i) => {
        const insertionsInThisGroup = _.isEmpty(filter)
          ? []
          : businessDimensionValues.filter(getFilteringFunction(filter));
        // dont show this elements in the dimension dropdown.
        const arrayNotIncludeInDimensions = ["driver-custom", "effect_type", "datatypename"];
        let allFIlterDimensionArray = [];
        filter?.datatypename?.forEach((e) => {
          allFIlterDimensionArray.push(
            _.uniq(
              insertionsInThisGroup
                .filter((d) => d.datatypename === e)
                .reduce(
                  (acc, item) => [
                    ...acc,
                    ...Object.keys(item).filter((d) => item[d] && !arrayNotIncludeInDimensions.includes(d)),
                  ],
                  [],
                ),
            ),
          );
        });

        let options = _.uniq(
          insertionsInThisGroup.reduce(
            (acc, item) => [
              ...acc,
              ...Object.keys(item).filter((d) => item[d] && !arrayNotIncludeInDimensions.includes(d)),
            ],
            [],
          ),
        );
        options = allFIlterDimensionArray.length > 1 ? _.intersection(...allFIlterDimensionArray) : options;
        options = options.filter((option) => {
          /*
            Filter out those options which has null value in some insertions
            Leaving those in options will cause a null value when we group by them as they are not present in all insertions
            Only dimensions which have a non null value in each insertions should be present for selection in subdimension
          */
          for (const insertion of insertionsInThisGroup) {
            if (!insertion[option]) {
              return false;
            }
          }
          return true;
        });

        const duplicateColors = Object.keys(findDuplicateAttributes(data, "color")) || [];
        return (
          <React.Fragment key={i}>
            <ItemFrame warning={duplicateColors.includes(defaultColor)} style={{ padding: "0px 15px", height: "53px" }}>
              <ColorPicker
                slug={slug}
                isDisabled={isDisabled}
                color={defaultColor}
                sortedColorPickerOptions={sortedColorPickerOptions}
                prodColor={prodDriverCustomGroupings?.find((grouping) => grouping?.slug === slug)?.color}
                onChange={
                  isDisabled
                    ? undefined
                    : (newColor) => {
                        onChange(
                          produce((s) => {
                            s[i].color = newColor;
                          }),
                        );
                      }
                }
              />

              <Tooltip
                title={
                  <div style={{ display: "flex", flexDirection: "column", gap: "9px" }}>
                    <span style={{ fontWeight: 400, color: theme.palette.greyish[20] }}>
                      {insertionsInThisGroup.length} dimension values in this group.
                    </span>
                    {expanded !== i && <span style={{ fontWeight: 700 }}>Click to expand</span>}
                  </div>
                }
                arrow
              >
                <Count onClick={() => setExpanded(expanded === i ? null : i)} color={defaultColor}>
                  {insertionsInThisGroup.length}
                </Count>
              </Tooltip>
              <div style={{ flexShrink: 0 }}>
                <EditorItemForTextWithSuggestion
                  autoFocus={false}
                  schema={{ title: "Grouping name" }}
                  data={name}
                  isDisabled={isDisabled}
                  extraStyles={{
                    width: "200px",
                    margin: "0px 15px",
                    fontSize: "14px",
                    color: theme.palette.greyish[50],
                  }}
                  onChange={
                    onChange &&
                    ((localOnChange) =>
                      onChange(
                        produce((s) => {
                          s[i].name = localOnChange(s[i].name);
                          // Sluggify
                          s[i].slug = s[i].name
                            .trim()
                            .toLowerCase()
                            .replace(/[\W_]+/g, "-");
                        }),
                      ))
                  }
                  options={customDriverLabelOptions}
                  noborder
                />
              </div>
              <div
                className="customScroll"
                style={{
                  position: "relative",
                  flexGrow: 1,
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                }}
              >
                <DimensionFilter
                  color={defaultColor}
                  filter={filter}
                  onChange={
                    isDisabled
                      ? undefined
                      : (newFilter) =>
                          onChange(
                            produce((s) => {
                              s[i].filter = newFilter;
                              s[i].dimension = [];
                              s[i].color = getDefaultColor(newFilter.datatypename?.[0] ?? slug);
                            }, {}),
                          )
                  }
                  dimensionValues={[...insertionsInThisGroup, ...ungrouped]}
                  labelDictionary={labelDictionary}
                  allowAnyValidFilter
                  clearDisabled
                  backDisabled
                  squaredBorder={true}
                />
              </div>
              <div
                style={{
                  width: "240px",
                  overflowX: "hidden",
                  whiteSpace: "nowrap",
                  flexShrink: 0,
                  fontSize: 14,
                }}
              >
                <DimensionDropdown
                  key={slug}
                  slug={slug}
                  isDisabled={isDisabled}
                  getLabelForDimension={getLabelForDimension}
                  options={options}
                  data={data}
                  onChange={onChange}
                  dimension={dimension}
                />
              </div>

              <ButtonsFrame>
                {!isDisabled && <DeleteIconButton onClick={() => onChange((prev) => prev.filter((d, j) => i !== j))} />}
              </ButtonsFrame>
            </ItemFrame>

            {expanded === i && (
              <DimensionValuesView values={insertionsInThisGroup} filter={filter} labelDictionary={labelDictionary} />
            )}
          </React.Fragment>
        );
      })}

      {data.length === 0 && <Message>You have not defined any business groupings yet.</Message>}

      <div>
        {!isDisabled && (
          <div
            style={{ padding: "9px 15px", gridColumn: "1/-1", borderBottom: `1px solid ${theme.palette.greyish[20]}` }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                onChange((prev) => {
                  const prevGroupings = prev ? [...prev] : [];
                  return [...prevGroupings, { slug: "", name: "", filter: {} }];
                })
              }
              style={{ height: 32 }}
            >
              + Add
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

BusinessGroupingsEditor.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
      filter: PropTypes.object,
      color: PropTypes.string,
    }),
  ),
  businessDimensionValues: PropTypes.arrayOf(PropTypes.object),
};

export default BusinessGroupingsEditor;

const ButtonsFrame = styled.div`
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
`;
// ref: https://www.styled-components.com/docs/faqs#support-for-nesting
const ItemFrame = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.greyish[20]};
  padding: 7px 0px;
  background-color: ${({ warning }) => (warning ? "#ffecec" : "inherit")};
`;

const Count = styled.div`
  box-sizing: border-box;
  min-width: 24px;
  height: 24px;
  margin: 0 0 0 8px;
  padding: 1px 0px;
  text-align: center;
  color: black;
  border: 1px solid black;
  border-radius: 0px;
  cursor: pointer;
  &:hover {
    color: white;
    background: black;
  }
`;

const Message = styled.div`
  font-size: 14px;
  padding: 8px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.greyish[20]};
`;

function DimensionValuesView({ values, filter = {}, labelDictionary = {}, isError = false }) {
  const headers = _.uniq(
    values.reduce(
      (acc, curr) => [
        ...acc,
        ...Object.keys(curr).filter((dim) => curr[dim]), //only those dimensions which have at least one non null value
      ],
      [],
    ),
  );
  return (
    <div
      style={{
        background: isError ? "hsl(0deg 100% 96%)" : "#f3f3f3",
        borderTop: "1px lightgray solid",
        borderBottom: "1px lightgray solid",
        marginBottom: 5,
        padding: 10,
        display: "grid",
        //some groupings can have many columns so have a min width for each column and enable a vertical scrollbar.
        gridTemplateColumns: `repeat(${headers.length}, minmax(150px, 1fr))`,
        gridGap: 1,
        maxHeight: 250,
        overflow: "auto",
      }}
    >
      {headers.map((header) => (
        <Cell key={header} style={{ opacity: 0.5 }}>
          {formattedLabel(labelDictionary, null, header)}
        </Cell>
      ))}
      {values.map((d) =>
        headers.map((header, i) => (
          <Cell
            key={i}
            style={filter[header]?.includes(d[header]) ? { color: "hsl(269deg 98% 52%)", fontWeight: 500 } : {}}
          >
            {formattedLabel(labelDictionary, d[header], header, { returnNull: true })}
          </Cell>
        )),
      )}
    </div>
  );
}

const Cell = styled.div`
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

function DimensionDropdown({
  dimension = [],
  data = [],
  onChange,
  isDisabled,
  options = [],
  getLabelForDimension,
  slug,
}) {
  let dimensionList = [...dimension, ..._.difference(options, dimension)];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();

  const disabled = isDisabled || !onChange;
  const handleClick = (option) => {
    if (!option) return;
    onChange?.(() =>
      produce(data, (draftData) => {
        const find = draftData.find((d) => d.slug === slug);
        find.dimension ||= [];
        const index = find.dimension.findIndex((dim) => dim === option);
        if (index !== -1) {
          find.dimension.splice(index, 1);
        } else {
          find.dimension.push(option);
        }
        find.dimension.sort((dim1, dim2) => dimensionList.indexOf(dim1) - dimensionList.indexOf(dim2));
      }),
    );
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(dimensionList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onChange(() =>
      produce(data, (draftData) => {
        const find = draftData.find((d) => d.slug === slug);
        find.dimension ||= [];
        find.dimension.sort((dim1, dim2) => items.indexOf(dim1) - items.indexOf(dim2));
      }),
    );
    dimensionList = items;
  };
  return (
    <>
      <Cell
        onClick={(e) => setAnchorEl(e.currentTarget)}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            width: "calc(100% - 20px)",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: dimension.length === 0 ? "#000000" : theme.palette.greyish[50],
          }}
        >
          {dimension.length === 0 ? "no breakdown" : dimension.map((dim) => getLabelForDimension(dim)).join(", ")}
        </span>
        <Icon>
          <ArrowIcon
            style={{ transform: anchorEl ? "rotate(180deg)" : "rotate(0)", transition: "transform 0.2s ease" }}
          />
        </Icon>
      </Cell>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        TransitionComponent={Fade}
        MenuListProps={{ disablePadding: true }}
      >
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="dimensionsItem" index={0}>
            {(provided) => (
              <List
                dense
                disablePadding
                sx={{ "&": { padding: 0, outline: "none" } }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {dimensionList && dimensionList.length > 0 ? (
                  dimensionList.map((option, index) => (
                    <Draggable
                      key={option}
                      draggableId={option}
                      index={index}
                      isDragDisabled={!dimension?.includes(option) || disabled}
                    >
                      {(provided, snapshot) => (
                        <ListItem
                          key={option}
                          sx={{
                            cursor: disabled ? "not-allowed" : "pointer",
                            backgroundColor: dimension?.includes(option)
                              ? `${theme.palette.primary.main}10`
                              : undefined,
                            textTransform: "capitalize",
                            padding: "4px 8px",
                            gap: "8px",
                          }}
                          onClick={() => handleClick(option)}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <ListItemIcon style={{ minWidth: "fit-content" }}>
                            <Checkbox
                              edge="start"
                              disabled={disabled}
                              checked={Boolean(dimension?.includes(option))}
                              tabIndex={-1}
                              disableRipple
                              color="primary"
                              style={{ padding: 0, margin: 0 }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={getLabelForDimension(option)} sx={{ span: { fontSize: "12px" } }} />
                          <DraggableDroppableIcon
                            provided={provided}
                            isDragging={snapshot.isDragging}
                            isChecked={dimension?.includes(option)}
                          />
                        </ListItem>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <ListItem
                    disabled={true}
                    style={{ cursor: "not-allowed", padding: "4px 8px", fontSize: "14px" }}
                    key={null}
                    onClick={() => handleClick(null)}
                  >
                    no breakdown
                  </ListItem>
                )}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Menu>
    </>
  );
}
