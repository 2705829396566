import React from "react";
import ButtonBase from "@mui/material/ButtonBase";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled/macro";
import tinycolor from "tinycolor2";
import _ from "lodash";

const ChipFrame = styled.div`
  display: inline-block;
  vertical-align: middle;
  border-radius: ${({ squaredBorder }) => (squaredBorder ? 0 : "0px")};
  overflow: hidden;
`;

const INITIAL_FLICKER_DELAY = 500;
function flicker(element, from, to, times) {
  for (let i = 0; i < times * 2; i++)
    setTimeout(
      () => {
        const use = i % 2 ? from : to;
        Object.keys(use).forEach((prop) => (element.style[prop] = use[prop]));
      },
      i === 0 ? INITIAL_FLICKER_DELAY : INITIAL_FLICKER_DELAY + i * 100,
    );
}

const flickerDebounced = _.debounce(flicker, 1000);

function MultiChip({
  parts,
  color: baseColor,
  disableUpdateAnimation = false,
  dataTestId,
  squaredBorder,
  ...otherProps
}) {
  const ref = React.useRef();
  const didMountRef = React.useRef(false); // We use it to avoid flicker at initial mount
  const partsString = JSON.stringify(parts);
  React.useEffect(() => {
    if (didMountRef.current && ref && ref.current && !disableUpdateAnimation) {
      // Make sure fromStyles is null so we restore the original styles
      const fromStyles = { opacity: 1 };
      const toStyles = { opacity: 0 };
      flickerDebounced(ref.current, fromStyles, toStyles, 2);
    } else {
      didMountRef.current = true;
    }
    // I do this because otherwise it'll flicker when the popper closes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsString]);

  const derivedColors = {
    light: {
      color: tinycolor(baseColor || "#323232")
        .darken()
        .toString(),
      backgroundColor: tinycolor(baseColor || "#323232")
        .setAlpha(0.07)
        .desaturate()
        .toString(),
      borderColor: tinycolor(baseColor || "#323232")
        .setAlpha(baseColor ? 0.8 : 0.07)
        .toString(),
    },
    dark: {
      color: (tinycolor(baseColor || "#323232").isDark() ? "white" : "black").toString(),
      backgroundColor: baseColor || "#323232",
      borderColor: "black",
    },
  };

  const darkMode = Boolean(useTheme().extraData?.darkHeader);
  const colors = darkMode
    ? { default: derivedColors.dark, active: derivedColors.light }
    : { default: derivedColors.light, active: derivedColors.dark };

  return (
    <ChipFrame
      ref={ref}
      {...otherProps}
      squaredBorder={squaredBorder}
      style={{
        ...(otherProps?.style || {}),
        // border: `1px solid transparent`,
        borderColor: colors.default.borderColor,
      }}
    >
      {parts.map((props, i) => (
        <ChipPart
          key={i}
          colors={colors}
          dataTestId={dataTestId}
          borderOnTheLeft={i !== 0}
          squaredBorder={squaredBorder}
          {...props}
        />
      ))}
    </ChipFrame>
  );
}

export default MultiChip;

function ChipPart({
  colors,
  title,
  labels,
  labelTestId,
  borderOnTheLeft = false,
  disableUpdateAnimation,
  dataTestId,
  style = {},
  squaredBorder,
  ...otherProps
}) {
  return (
    <ButtonBase
      focusRipple
      style={{
        fontFamily: useTheme().typography.fontFamily,
        borderLeft: borderOnTheLeft && `1px solid ${colors.default.color}`, //matches the divider color to text color
      }}
      {...otherProps}
    >
      <ChipPartDiv
        color={colors.default.color}
        backgroundColor={colors.default.backgroundColor}
        style={style}
        squaredBorder={squaredBorder}
      >
        <Title>{title}</Title>
        <Label>
          {labels.map((d, i) => (
            <React.Fragment key={d}>
              {i !== 0 && <span style={{ marginRight: 7 }}>,</span>}
              <span data-test-id={labelTestId ? dataTestId + labelTestId : dataTestId}>{d}</span>
            </React.Fragment>
          ))}
        </Label>
      </ChipPartDiv>
    </ButtonBase>
  );
}

const ChipPartDiv = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 230px;
  height: 37px;
  text-align: left;
  padding: 4px 8px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  &:hover {
    color: ${(props) => (tinycolor(props.color).isDark() ? "white" : "black").toString()};
    background-color: ${(props) => props.color};
  }
`;

const Title = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.7;
`;

const Label = styled.div`
  display: inline-block;
  line-height: 1.2;
  font-size: 12px;
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
