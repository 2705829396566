import moment from "moment";

import {
  BACK_TO_ACTIVITIES_LIST,
  ACTIVITIES_TOGGLE_INVESTMENT_PLAN_SELECTION,
  ACTIVITIES_TOGGLE_MEDIA_MIX_EXPANDED,
  SORT_PREDICTIONS,
  STEP,
} from "./actions";

import {
  REMOVE_INVESTMENT_PLAN,
  REMOVE_INVESTMENT_PLAN_SUCCESS,
  REMOVE_INVESTMENT_PLAN_ERROR,
  GET_ACTIVITY_SUMMARIES,
  GET_ACTIVITY_SUMMARIES_ERROR,
  GET_ACTIVITY_SUMMARIES_SUCCESS,
  GET_PLANS_BY_ACTIVITY,
  GET_PLANS_BY_ACTIVITY_ERROR,
  GET_PLANS_BY_ACTIVITY_SUCCESS,
  SET_SORT_ACTIVITIES_LIST,
} from "./store/actions";

import { ACTIVATE_PLAN_SUCCESS } from "legacy/ActivateInvestmentPlan/actions";

export const initialState = {
  step: STEP.ACTIVITIES_LIST,
  status: "ready",
  sort: {
    column: null,
    type: null,
  },
  error: "",
  selectedActivityId: null,
  selectedActivity: null,
  plans: [],
  allActivities: [],
  activitiesList: [],
  search: "",
  comparingPlans: {
    mediaMixExpanded: false,
    effectAxisRange: undefined,
    selectedPlans: [null, null, null], // No investment plan is selected
  },
};

export default function activitiesReducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_INVESTMENT_PLAN:
    case GET_ACTIVITY_SUMMARIES:
      return {
        ...state,
        status: "loading",
      };

    case SET_SORT_ACTIVITIES_LIST:
      return {
        ...state,
        sortActivitiesList: action.sortActivitiesList,
      };
    case GET_PLANS_BY_ACTIVITY:
      return {
        ...state,
        selectedActivityId: action.activityId,
        selectedActivity: state.activitiesList.find((a) => a.id === action.activityId),
        status: "loading",
        step: "PREDICTION_LIST",
        numberOfInvestmentPlans: state.activitiesList.find((a) => a.id === action.activityId),
      };
    case REMOVE_INVESTMENT_PLAN_SUCCESS:
      return {
        ...state,
        status: "ready",
        plans: state.plans.filter((plan) => plan.id !== action.id),
        step:
          state.plans.filter((plan) => plan.id !== action.id).length === 0
            ? STEP.ACTIVITIES_LIST
            : STEP.PREDICTION_LIST,
        comparingPlans: {
          ...state.comparingPlans,
          // We cannot change the array size. As it is expected to have the length of the supported concurrent plans to compare
          selectedPlans: state.comparingPlans.selectedPlans.map((planId) => (planId !== action.id ? planId : null)),
        },
        selectedActivityId:
          state.plans.filter((plan) => plan.id !== action.id).length === 0
            ? initialState.selectedActivityId
            : state.selectedActivityId,

        selectedActivity:
          state.plans.filter((plan) => plan.id !== action.id).length === 0
            ? initialState.selectedActivity
            : state.selectedActivity,
      };
    case "ACTIVITIES_ALL_ACTIVITIES_LOADED":
      return { ...state, allActivities: action.allActivities };
    case GET_ACTIVITY_SUMMARIES_SUCCESS: {
      const selectedActivity = action.list.find((a) => a.id === state.selectedActivityId);
      return {
        ...state,
        activitiesList: [
          ...action.list
            /* .filter((a) => Object.keys(a.investmentPlans).length > 0) don't filter out empty activities */
            // Calculate earliest start date and latest end date to show in activity summary
            .map((activity) => {
              const plans = Object.values(activity.investmentPlans || {});
              const earliestStartDate = moment.min(plans.map((d) => moment(d.startDate))).format("YYYY-MM-DD");
              const latestEndDate = moment.max(plans.map((d) => moment(d.endDate))).format("YYYY-MM-DD");

              return { ...activity, period: { earliestStartDate, latestEndDate } };
            }),
        ]
          // Sort by latest end date and, in case of equal end date, by name
          .sort((a, b) => {
            if (a.period.latestEndDate < b.period.latestEndDate) return 1;
            else if (a.period.latestEndDate > b.period.latestEndDate) return -1;
            else return a.name > b.name ? 1 : -1;
          }),

        selectedActivity: selectedActivity,
        plans: state.plans.map((d) => ({ ...d, isActivated: d.id === selectedActivity.activePlanId })),
        status: "ready",
      };
    }
    case GET_PLANS_BY_ACTIVITY_SUCCESS:
      return {
        ...state,
        status: "ready",
        step: STEP.PREDICTION_LIST,
        plans: action.plans.map((d) =>
          d.id === state.selectedActivity.activePlanId ? { ...d, isActivated: true } : d,
        ),
      };
    case REMOVE_INVESTMENT_PLAN_ERROR:
    case GET_ACTIVITY_SUMMARIES_ERROR:
    case GET_PLANS_BY_ACTIVITY_ERROR:
      return {
        ...state,
        status: "error",
        error: action.error,
      };

    case "SAVE_INVESTMENT_PLAN_DONE": // It seems saving a plan should trigger this
    case BACK_TO_ACTIVITIES_LIST:
      return {
        ...state,
        selectedActivityId: initialState.selectedActivityId,
        selectedActivity: initialState.selectedActivity,
        sort: initialState.sort,
        step: STEP.ACTIVITIES_LIST,
        plans: [...initialState.plans],
        comparingPlans: {
          ...initialState.comparingPlans,
          selectedPlans: [...initialState.comparingPlans.selectedPlans],
        },
        search: initialState.search,
      };

    case ACTIVITIES_TOGGLE_INVESTMENT_PLAN_SELECTION:
      const selectedPlans = [...state.comparingPlans.selectedPlans];
      const indexOfId = selectedPlans.indexOf(action.id);
      const indexOfNull = selectedPlans.indexOf(null);
      if (indexOfId > -1) selectedPlans[indexOfId] = null;
      else if (indexOfNull > -1) selectedPlans[indexOfNull] = action.id;

      return {
        ...state,
        comparingPlans: {
          ...state.comparingPlans,
          selectedPlans,
        },
      };

    case ACTIVITIES_TOGGLE_MEDIA_MIX_EXPANDED:
      return {
        ...state,
        comparingPlans: {
          ...state.comparingPlans,
          mediaMixExpanded: !state.comparingPlans.mediaMixExpanded,
        },
      };

    case SORT_PREDICTIONS:
      return {
        ...state,
        sort: {
          column: action.column,
          type: action.sortType,
        },
        plans:
          action.sortType === "ascending" && action.column !== "mcpa"
            ? [...state.plans].sort((item1, item2) => {
                if (action.column !== "date_range") {
                  const filtered = {
                    item1: item1.result.kpis.find((item) => item.id === action.column),
                    item2: item2.result.kpis.find((item) => item.id === action.column),
                  };
                  const value1 = filtered.item1 ? filtered.item1.value : undefined;
                  const value2 = filtered.item2 ? filtered.item2.value : undefined;
                  return value1 && value2 && value1 < value2;
                } else {
                  const value1 = moment(item1.request.startDate);
                  const value2 = moment(item2.request.startDate);
                  return value1.isBefore(value2);
                }
              })
            : action.column !== "mcpa"
              ? [...state.plans].sort((item1, item2) => {
                  if (action.column !== "date_range") {
                    const filtered = {
                      item1: item1.result.kpis.find((item) => item.id === action.column),
                      item2: item2.result.kpis.find((item) => item.id === action.column),
                    };
                    const value1 = filtered.item1 ? filtered.item1.value : undefined;
                    const value2 = filtered.item2 ? filtered.item2.value : undefined;
                    return value1 && value2 && value1 > value2;
                  } else {
                    const value1 = moment(item1.request.startDate);
                    const value2 = moment(item2.request.startDate);
                    return value1.isAfter(value2);
                  }
                })
              : null,
      };

    case ACTIVATE_PLAN_SUCCESS:
      return {
        ...state,
        selectedActivity: { ...state.selectedActivity, activePlanId: action.plan.id },
        plans: state.plans.map((d) => ({ ...d, isActivated: d.id === action.plan.id })),
        activitiesList: state.activitiesList.map((d) =>
          d.id === action.activityId ? { ...d, isActivated: true, activePlanId: action.plan.id } : d,
        ),
      };

    case "MODEL_INIT":
      return initialState;

    default:
      return state;
  }
}
