import React from "react";
import useSWR from "swr";
import msalFetcher from "./msalFetcher";
import { paramsSerialiser, useDebounce } from "./useData";

// For making caching dependant on params,
// keep an eye on https://github.com/vercel/swr/pull/145#discussion_r350064725
export default function useMsalData(apiRoute, params = {}, swrOpts) {
  const lastResult = React.useRef(undefined);
  // Sometimes the frontend will change params too quickly due to
  // users clicking buttons very fast. This allows us to apply slower changing params
  const url = useDebounce(apiRoute && apiRoute + paramsSerialiser(params), 1000);
  const token = sessionStorage.getItem("msalToken");
  const {
    data,
    mutate,
    isLoading: loading = true,
  } = useSWR(url, (url) => msalFetcher(url, {}, token), {
    refreshWhenHidden: true,
    revalidateIfStale: true,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
    dedupingInterval: 8 * 1000, // Do not revalidate very often
    ...swrOpts,
  });
  if (data) {
    lastResult.current = { data: data.data, status: data.status };
    return { data: data.data, loading, status: data.status, mutate };
  } else {
    // If data is stale, return the latest cached data
    // The reason for this is to make possible transitions when changing filters
    // (a transition between the previous and newly fetched data)
    return { data: lastResult.current?.data, loading: true, status: lastResult.current?.status, mutate };
  }
}
