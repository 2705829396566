import numeral from "numeral";
import { assert } from "chai";

numeral.register("locale", "us", {
  delimiters: { thousands: ",", decimal: "." },
  abbreviations: {
    thousand: "K",
    million: "M",
    billion: "B",
    trillion: "T",
  },
  ordinal: function (number) {
    var b = number % 10;
    return ~~((number % 100) / 10) === 1 ? "th" : b === 1 ? "st" : b === 2 ? "nd" : b === 3 ? "rd" : "th";
  },
  currency: { symbol: "USD" },
});

let theCurrencyFormat;

export const initializeCurrency = (currency) => ({
  USD: { prefix: `${currency} `, decimalSeparator: ".", thousandSeparator: "," },
});

export const currencyFormat = () => {
  assert.isTrue(theCurrencyFormat !== undefined, "Numeral hasn't been initialized.");
  return theCurrencyFormat;
};

let localeIsSet = false;

export const setLocale = ({ market, currency }) => {
  numeral.locale("us");
  localeIsSet = true;
};

export const formattedCurrencyLabel = (value) => {
  assert.isTrue(localeIsSet, "Numeral hasn't been initialized.");
  return numeral(value).format();
};
