import { Icon } from "@mui/material";
import styled from "@emotion/styled";

//for non clickable icons
//if the icon is clickable use IconButtonWrapper
//don't modify this, for unique cases make a new styled component out of this
//or for one off cases use inline styled.
export const IconWrapper = styled(Icon)`
  display: inline-flex;
  height: fit-content;
  width: fit-content;
`;
