import styled from "@emotion/styled/macro";
import React from "react";
import { useTheme } from "@mui/styles";
import { maxChars } from "shared/helpers/formatters";
import { ReactComponent as ArrowLeftIcon } from "shared/Icons/arrow-left-white.svg";
import { ReactComponent as CrossIcon } from "./assets/cross.svg";
import { Tooltip } from "@mui/material";
import formattedLabel from "shared/helpers/formattedLabel";

const getLabel = (filter, labelDictionary, defaultLabel) => {
  const getSingleDimensionLabel = (dimension, values) =>
    values.map((value, i) => formattedLabel(labelDictionary, value, dimension));

  const getMultiDimensionLabel = () =>
    Object.entries(filter).map(([dimension]) => getDimensionLabel(labelDictionary, filter, dimension));

  if (Object.keys(filter).length === 1) {
    const [dimension, values] = Object.entries(filter)[0];
    return getSingleDimensionLabel(dimension, values).join(", ");
  } else if (Object.keys(filter).length > 0) {
    return getMultiDimensionLabel().join(", ");
  } else {
    return defaultLabel;
  }
};
const HoverLabel = ({ filter, labelDictionary }) => {
  const filterKeys = Object.keys(filter);

  return filterKeys.map((key, i) => {
    const dimensionLabel = getDimensionLabel(labelDictionary, filter, key).toUpperCase();
    const filterValues = filter[key] ?? [];

    return (
      <div key={key}>
        <span>{dimensionLabel}</span>
        <br />
        {filterValues.map((value) => formattedLabel(labelDictionary, value, key)).join(" ,")}
        <br />
        {i + 1 < filterKeys.length && <br />}
      </div>
    );
  });
};

function SingleChip({ labelDictionary, filter, onClick, back, clear, defaultLabel }) {
  const theme = useTheme();
  const { enabled: backEnabled, onClick: backOnClick } = back;
  const { enabled: clearEnabled, onClick: clearOnClick } = clear;

  const label = getLabel(filter, labelDictionary, defaultLabel);

  return (
    <ChipsFrame style={{ "--border-value": `1px solid ${theme.palette.primary.contrastText}` }}>
      {backEnabled && (
        <IconChip onClick={backOnClick} style={{ borderRight: "var(--border-value)" }}>
          <ArrowLeftIcon height={20} width={20} />
        </IconChip>
      )}
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: { p: "8px", fontSize: "12px", maxWidth: "600px", maxHeight: "600px", overflow: "auto" },
          },
        }}
        title={
          Object.keys(filter).length > 0 && (
            <HoverLabel key={filter} filter={filter} labelDictionary={labelDictionary} />
          )
        }
      >
        <Chip onClick={onClick}>
          <Title>Showing</Title>
          <Label>{maxChars(label, 40)}</Label>
        </Chip>
      </Tooltip>
      {clearEnabled && (
        <IconChip onClick={clearOnClick} style={{ borderLeft: "var(--border-value)" }}>
          <CrossIcon height={20} width={20} />
        </IconChip>
      )}
    </ChipsFrame>
  );
}
export { getLabel, HoverLabel };
export default SingleChip;

const getDimensionLabel = (labelDictionary, filter, dimension) =>
  `${formattedLabel(labelDictionary, null, dimension)}${` (${filter[dimension]?.length})`}`;

const Title = styled.div`
  color: ${({ theme }) => theme.palette.greyish[20]};
  font-size: 10px;
  text-transform: uppercase;
`;

const ChipsFrame = styled.div`
  display: flex;
`;

const Chip = styled.div`
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: ${({ theme }) => theme.palette.greyish[80]};
  width: max-content;
  height: 37px;
  cursor: pointer;
`;

const IconChip = styled(Chip)`
  padding: 7px 4px;
  justify-content: center;
`;

const Label = styled.div`
  color: #fff;
  font-size: 12px;
  /* line-height: 24px; */
`;
