import styled from "@emotion/styled/macro";

export default function Tabs({ activeEmail, users, tab, tabChange }) {
  const tabs = [
    {
      active: tab === "internal",
      onClick: () => tabChange("internal"),
      dataTestId: "internal-users",
      value: `${users?.filter((user) => user.email.endsWith("@kantar.com"))?.length || 0} Internal`,
    },
    {
      active: tab === "external",
      onClick: () => tabChange("external"),
      dataTestId: "external-users",
      value: `${users?.filter((user) => !user.email.endsWith("@kantar.com"))?.length || 0} External`,
    },
    {
      active: tab === "all",
      onClick: () => tabChange("all"),
      dataTestId: "all-users",
      value: `${users?.length || 0} All`,
    },
  ];
  return (
    <Panel>
      <Choose>
        {tabs.map(({ active, onClick, dataTestId, value }) => (
          <Option
            key={dataTestId}
            disabled={activeEmail !== ""}
            active={active}
            onClick={onClick}
            data-test-id={dataTestId}
          >
            {value}
          </Option>
        ))}
      </Choose>
    </Panel>
  );
}

const Panel = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 16px 0;
`;

const Choose = styled.div`
  display: flex;

  > * {
    &:first-of-type {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    &:last-of-type {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 8px 10px;
  color: #000;
  background: ${({ theme }) => theme.palette.greyish[20]};
  cursor: pointer;
  text-transform: uppercase;
  &:not(:first-child):not(:last-child) {
    border-left: 1px solid ${({ theme }) => theme.palette.greyish[50]};
    border-right: 1px solid ${({ theme }) => theme.palette.greyish[50]};
  }
  ${(props) =>
    props.active &&
    `
      background: black;
      color: white;
      cursor: default;
    `}

  ${(props) =>
    props.disabled &&
    `
      cursor: not-allowed;
    `}
`;
