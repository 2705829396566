import { useSearchParams, useNavigate } from "react-router-dom";
import { filteredSearchParams } from "shared/helpers/urlSearchParams";

export function useNavigateWithParams() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return ({ path, keep = ["org", "model"] }) => {
    const navigationPath = path + filteredSearchParams(searchParams, keep);
    navigate(navigationPath);
  };
}
