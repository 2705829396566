import backend from "backend";

export default function useLongPollingPrediction(socketSlug) {
  const { data, mutate } = backend.useData(
    `v3/predictions/${socketSlug}`,
    null, // No query params
    { refreshInterval: 10000 }, // Long polling
    true, // Logging disabled
  );

  // We currently only support one prediction (it'll return an array of one)
  return { ...(data?.[0] || {}), invalidate: mutate };
}
