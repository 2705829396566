import { logoutMSAL } from "auth/msalAuth";
import "../auth-form.css";

export default function Login({ error = false }) {
  return (
    <div className="auth-form">
      <div>
        <h1>Hamilton AI</h1>
        {error && (
          <div>
            <span className="error">
              You do not have access to HamiltonAI. Please reach out to your point of contact in Kantar.
            </span>
            <br />
            <span className="log-out" onClick={() => logoutMSAL().then((res) => (error = false))}>
              Logout
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
