import backend from "backend";

export function useOrgData(msalToken, accounts, instance) {
  const {
    data = null,
    loading = true,
    status,
    mutate,
  } = backend.useMsalData(msalToken ? `/auth/v2/user/get-organizations` : null);

  return {
    data,
    loading,
    status,
    mutate,
  };
}
