import React from "react";
import _ from "lodash";
import styled from "@emotion/styled/macro";
import List from "@mui/material/List";
import OptionsForOneDimension from "./OptionsForOneDimension";
import useKeyboard from "./useKeyboard";
import useFuzzySearch from "./useFuzzySearch";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { InputAdornment } from "@mui/material";
import { ReactComponent as CrossIcon } from "shared/Icons/cross.svg";
import { ReactComponent as SearchIcon } from "shared/Icons/Magnifier-black.svg";

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: 14,
    padding: "6px 8px",
    backgroundColor: theme.palette.greyish[10],
  },
}));

export default function Options({
  options,
  inputEl,
  hoveredDimension,
  nearestDimension,
  filter,
  disabled,
  onFilterToggle,
  onRemoveDimension,
  onClose,
  expandedByDefault,
  hiddenDimensions,
  labelDictionary,
  dimensionOptions = [],
}) {
  const searchRef = React.useRef();
  const [text, setText] = React.useState("");
  const { searchResults, indexWithBestScore, flattenedList } = useFuzzySearch({ text, options });
  const classes = useStyles();
  const availableDimensionsInFilterList = (dimension) => {
    const desiredObject = dimensionOptions.find((item) => item.value === dimension);
    if (desiredObject === undefined || desiredObject?.length === 0) return true;
    return desiredObject?.selected;
  };
  // If there's no search results, show full list
  const filteredList = searchResults.length > 0 ? searchResults : flattenedList;
  var sortOrder = [
    "driver-custom",
    "datatypename",
    "effect_type",
    ..._.uniq(filteredList.map((d) => d.dimension)).filter((d) => d !== "effect_type" && d !== "datatypename"),
  ];
  filteredList.sort((a, b) => {
    // first have a look at dimension
    // get the indices of the given sortOrder
    // take the difference of the indices
    var delta = sortOrder.indexOf(a.dimension) - sortOrder.indexOf(b.dimension);
    // test, if the indices are the same
    if (delta === 0) {
      // the comparison should usually return 0,
      // but this will here not work. we need
      // the comparison of the second sort order of dimension.
      // take the variables and make a real comparison,
      // while we expecting strings to be compared
      return a.dimension === b.dimension ? 0 : a.dimension < b.dimension ? -1 : 1;
    }
    // otherwise return the delta
    return delta;
  });

  function onSelect({ value, dimension }) {
    // Select the search text instead of clearing it, to allow multi selects under same search
    if (searchRef.current) searchRef.current.select();
    onFilterToggle({ value, dimension });
  }

  const { bind, selectedItem } = useKeyboard({
    filteredList,
    indexWithBestScore,
    noResults: searchResults.length === 0,
    onSelect,
    onRemoveNearest: () => {
      if (text.length === 0) onRemoveDimension(nearestDimension);
    },
    onEsc: onClose,
  });

  const optionsByDimension = {};
  filteredList.forEach(({ dimension, value }) => {
    return (
      availableDimensionsInFilterList(dimension) &&
      (optionsByDimension[dimension] = [
        ...(optionsByDimension[dimension] || []),
        {
          value,
          dimension,
          selected: selectedItem && dimension === selectedItem.dimension && value === selectedItem.value,
          chosen: filter[dimension] && filter[dimension].includes(value),
        },
      ])
    );
  });

  return (
    <>
      {!disabled && (
        <SearchTextField
          {...bind}
          inputRef={searchRef}
          autoFocus
          autoComplete="off"
          fullWidth
          variant="filled"
          id="search"
          value={text}
          placeholder="Search"
          onChange={(e) => setText(e.target.value)}
          InputProps={{
            classes: { input: classes.input },
            endAdornment: (
              <InputAdornment style={{ cursor: "pointer" }} position="end">
                {text ? <CrossIcon height={20} onClick={() => setText("")} /> : <SearchIcon height={20} />}
              </InputAdornment>
            ),
            autoComplete: "off",
          }}
          data-test-id="dimensionsFilterSearch"
        />
      )}

      <div>
        <List dense disablePadding style={{ padding: 0 }}>
          {Object.keys(optionsByDimension)
            .filter((d) => !hiddenDimensions.includes(d) || Object.keys(filter).includes(d))
            .map((d) => (
              <OptionsForOneDimension
                key={d}
                dimension={d}
                options={optionsByDimension[d]}
                expandedOverride={(() => {
                  // When an item is selected normal behaviour (dimensions will
                  // expand if they contain the selection or we click on their expan button)
                  if (selectedItem !== null) return undefined;

                  // But if any dimension chip is hovered we go into an inspection
                  // mode where only the dimension hovered is expanded
                  if (hoveredDimension) return Boolean(hoveredDimension === d);

                  // By default, normal behaviour
                  return d === expandedByDefault;
                })()}
                onSelect={onSelect}
                labelDictionary={labelDictionary}
              />
            ))}
        </List>
      </div>
    </>
  );
}

const SearchTextField = styled(TextField)`
  z-index: 10000;
  position: sticky;
  top: 0;
  & > .MuiInputBase-root {
    --background-color: ${({ theme }) => theme.palette.greyish[10]};
    padding-right: 8px;
    border-radius: 0;

    &::before {
      border-bottom-color: #000;
    }

    &,
    &:hover,
    & > input {
      background-color: var(--background-color);
    }

    &.Mui-focused {
      background-color: var(--background-color);
      --background-color: ${({ theme }) => theme.palette.whiteish.main};
    }
  }
`;
