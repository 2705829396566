//use this to sort an array according to order mentioned in another array.

/*
 opts = {
   sortingConstraints: when sorting constraints for backward compatibility 
                       we have to take care of "media-group" in addition to "media-grouping" 
 }
*/
export function sortArrayBasedOnAnother(arr = [], sortOrder = [], opts = {}) {
  const indexMap = new Map(sortOrder.map((val, index) => [val, index]));
  return arr.sort((a, b) => {
    const { sortingConstraints } = opts;
    if (sortingConstraints) {
      if (a === "media-group") a = "media-grouping";
      if (b === "media-group") b = "media-grouping";
    }
    if (indexMap.has(a) && indexMap.has(b)) {
      return indexMap.get(a) - indexMap.get(b);
    } else if (indexMap.has(a)) {
      return -1;
    } else if (indexMap.has(b)) {
      return 1;
    } else {
      return 0;
    }
  });
}
