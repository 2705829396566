import styled from "@emotion/styled";
import { Checkbox, FormControl, FormControlLabel, Icon, Menu, Tooltip } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import { ButtonText, DropDownButton, MenuTitle } from ".";

const CheckBoxMenu = React.forwardRef(
  (
    {
      options = [],
      showSelectAll = true,
      selected,
      onChange,
      fixedWidth = 240,
      title,
      style = { container: {}, button: {}, buttonText: {}, helpText: {}, menuText: {} },
      clickDisabled = false,
      onChangeAll,
    },
    ref,
  ) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const [selectAll, setSelectAll] = React.useState(false);

    const handleClick = (event) => {
      if (!clickDisabled) {
        setAnchorEl(event.currentTarget);
      }
    };

    const itemSelected = (option) => {
      onChange(option);
    };

    React.useEffect(() => {
      const allChecked = options.every((option) => option.checked);
      const allIntermidiate = options.every((option) => option.checked === null);
      setSelectAll(allChecked ? true : allIntermidiate ? null : false);
    }, [options]);

    const checkedItemsSize = options.filter((option) => option.checked).length;
    const intermidiateItemsSize = options.filter((option) => option.checked === null).length;
    return (
      <FormControl style={{ width: fixedWidth === "100%" ? "100%" : "initial" }}>
        {title && (
          <MenuTitle ref={ref} variant="subtitle1">
            {title.toUpperCase()}
          </MenuTitle>
        )}
        {/* calc dynamic logic calculatedWidth + 10px buffer + 24px for arrow down/up icon */}
        <div
          style={{
            width: fixedWidth,
            fontSize: "14px", // this font size must be here to useDynamicWidth based on ch unit.
            ...style.container,
          }}
        >
          <Tooltip
            title={
              checkedItemsSize !== 0 || intermidiateItemsSize !== 0 ? (
                <>
                  <div style={{ marginBottom: 8 }}>{`${title.toUpperCase()}`}</div>
                  {checkedItemsSize !== 0 && (
                    <div style={{ paddingTop: "5px" }}>
                      {`Grant (${checkedItemsSize}/${options.length})`}
                      <br />
                      {options
                        .filter((option) => option.checked)
                        .map((option, i) => (
                          <>
                            <span style={{ color: theme.palette.greyish[20] }}>{option.label}</span>
                            {i !== options.length && <br />}
                          </>
                        ))}
                    </div>
                  )}
                  {intermidiateItemsSize !== 0 && (
                    <div style={{ paddingTop: "5px" }}>
                      {`Revoke (${intermidiateItemsSize}/${options.length})`}
                      <br />
                      {options
                        .filter((option) => option.checked === null)
                        .map((option, i) => (
                          <>
                            <span style={{ color: theme.palette.greyish[20] }}>{option.label}</span>
                            {i !== options.length && <br />}
                          </>
                        ))}
                    </div>
                  )}
                </>
              ) : null
            }
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  padding: "10px 12px",
                },
              },
            }}
          >
            <DropDownButton
              aria-haspopup="true"
              aria-expanded={open ? "true" : null}
              variant="outlined"
              disableElevation
              onClick={handleClick}
              endIcon={<DropdownIcon />}
              disableFocusRipple={true}
              disableRipple={true}
              disableTouchRipple={true}
              open={open}
              style={{ ...style.button, cursor: clickDisabled && "not-allowed" }}
            >
              <ButtonText style={{ ...style.buttonText }}>
                {options.every((option) => option.checked === false)
                  ? `Select ${title}`
                  : `${checkedItemsSize ? `Grant ${checkedItemsSize}/${options.length}` : ""}${intermidiateItemsSize ? ` Revoke ${intermidiateItemsSize}/${options.length}` : ""}`}
              </ButtonText>
            </DropDownButton>
          </Tooltip>
          <Menu
            MenuListProps={{
              disablePadding: true,
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              style: {
                width: fixedWidth,
                borderRadius: 0,
              },
            }}
          >
            <Label theme={theme}>{title.toUpperCase()}</Label>
            <FormControlLabel
              sx={{
                margin: 0,
                width: "100%",
                padding: "4px 8px",
                ".MuiTypography-root": { fontSize: 12, padding: "8px", lineHeight: 1.2 },
                borderBottom: `1px solid ${theme.palette.greyish[25]}`,
                background: theme.palette.whiteish.main,
              }}
              key="all"
              control={
                <StyledIcon
                  onClick={() => {
                    const newState = selectAll === null ? false : selectAll === false ? true : null;
                    setSelectAll(newState);
                    onChangeAll(newState);
                  }}
                >
                  <Checkbox
                    size="medium"
                    checked={Boolean(selectAll)}
                    indeterminate={selectAll === null}
                    name="checkedF"
                    color="primary"
                    style={{ ...style.menuText, textAlign: "right", width: "auto" }}
                  />
                </StyledIcon>
              }
              label={`All ${title.toLowerCase()}`}
            />
            {options?.map((option) => (
              <FormControlLabel
                sx={{
                  margin: 0,
                  width: "100%",
                  padding: "4px 8px",
                  ".MuiTypography-root": { fontSize: 12, padding: "8px", lineHeight: 1.2 },
                }}
                key={option.id}
                control={
                  <StyledIcon>
                    <Checkbox
                      size="medium"
                      disabled={option.disabled}
                      checked={Boolean(option.checked)}
                      indeterminate={option.checked === null}
                      onClick={() => itemSelected(option.id)}
                      name="checkedF"
                      color="primary"
                      style={{ ...style.menuText, textAlign: "right", width: "auto" }}
                    />
                  </StyledIcon>
                }
                label={option.label}
              />
            ))}
          </Menu>
        </div>
      </FormControl>
    );
  },
);

export default CheckBoxMenu;

const DropdownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M5.06226 7.5L10.0415 12.5L15.0208 7.5"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Label = styled.div`
  color: ${({ theme }) => theme.palette.greyish[50]};
  font-size: 12px;
  padding: 8px;
  display: flex;
  gap: 2px;
  height: 30px;
`;

const StyledIcon = styled(Icon)`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
