/*
To keep the global Redux state to a minimum we cache hydrations.
This works very well, but it has a side effect: after mutations, components don't know
that any update has happened, and they don't refetch the data. And even if they did,
they would hit the cache and miss the updated data.

To avoid this, we use sequence numbers in hydration parametres.

Thus, if we mutate some data we increase the sequence number of that data and as the
hydrations that depend on it will include it, a re-rerender would result in a refetch
with an updated seqNum and we'll avoid hitting the cache and get the fresh data.

This reducer keeps track of those sequence numbers. */

export default function seqNumReducer(state = initialState, action) {
  switch (action.type) {
    case "SEQNUM_ACTIVITIES_MUTATION":
      return { ...state, activities: state.activities + 1 };

    default:
      return state;
  }
}

// There's one key per seqNum
const initialState = {
  activities: 0,
};
