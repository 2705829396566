import tinycolor from "tinycolor2";

/* 
❗IMPORTANT❗
👀🔴
This is sorted manually as color perceived by human eye 👀, if there is a need to add new colors 
  then manually insert them at right place.
*/
const MANUAL_SORTED = [
  "rgb(0, 0, 90)",
  "rgb(40, 53, 147)",
  "rgb(0, 106, 201)",
  "rgb(9, 132, 227)",
  "rgb(41, 121, 255)",
  "rgb(33, 150, 243)",
  "rgb(0, 184, 255)",
  "rgb(28, 228, 255)",
  "rgb(85, 195, 225)",
  "rgb(116, 185, 255)",
  "rgb(158, 228, 255)",
  "rgb(128, 28, 255)",
  "rgb(108, 92, 231)",
  "rgb(126, 87, 194)",
  "rgb(162, 155, 254)",
  "rgb(204, 128, 207)",
  "rgb(213, 0, 249)",
  "rgb(228, 28, 229)",
  "rgb(128, 28, 69)",
  "rgb(255, 64, 129)",
  "rgb(232, 67, 167)",
  "rgb(253, 121, 168)",
  "rgb(250, 177, 160)",
  "rgb(255, 234, 167)",
  "rgb(214, 48, 49)",
  "rgb(255, 112, 67)",
  "rgb(228, 128, 69)",
  "rgb(225, 112, 85)",
  "rgb(255, 118, 117)",
  "rgb(253, 187, 84)",
  "rgb(255, 235, 59)",
  "rgb(255, 228, 69)",
  "rgb(253, 203, 110)",
  "rgb(28, 78, 69)",
  "rgb(48, 118, 19)",
  "rgb(0, 200, 83)",
  "rgb(128, 228, 29)",
  "rgb(38, 166, 154)",
  "rgb(39, 207, 192)",
  "rgb(85, 239, 196)",
  "rgb(129, 236, 236)",
  "rgb(200, 220, 200)",
  "rgb(45, 22, 4)",
  "rgb(121, 85, 72)",
  "rgb(45, 52, 54)",
  "rgb(99, 110, 114)",
  "rgb(178, 190, 195)",
  "rgb(223, 230, 233)",
];

export default function getColorPickerOptions({ opts = {} }) {
  if (opts.manualSort) {
    return MANUAL_SORTED;
  } else if (opts.sortByHue) {
    return MANUAL_SORTED.sort((a, b) => tinycolor(a).toHsl().h - tinycolor(b).toHsl().h);
  } else if (opts.sortByClusteringAndBrightness) {
    return finalList;
  }
  return MANUAL_SORTED;
}

//READ -> https://tomekdev.com/posts/sorting-colors-in-js
//The closer to black, grey, white color a color is the harder it is to spot "the color itself".
//A separate list for grey, black, white shades sorted according to human perception -> GREY_BLACK_SHADES
const MODIFIED_COLOR_SET = [
  "rgb(39, 207, 192)",
  "rgb(158, 228, 269)",
  "rgb(258, 228, 69)",
  "rgb(250, 177, 160)",
  "rgb(255, 118, 117)",
  "rgb(253, 121, 168)",
  "rgb(129, 236, 236)",
  "rgb(225, 112, 85)",
  "rgb(214, 48, 49)",
  "rgb(228, 128, 69)",
  "rgb(28, 228, 269)",
  "rgb(228, 28, 229)",
  "rgb(128, 28, 269)",
  "rgb(116, 185, 255)",
  "rgb(255, 234, 167)",
  "rgb(108, 92, 231)",
  "rgb(9, 132, 227)",
  "rgb(0, 106, 201)",
  "rgb(0, 184, 258)",
  "rgb(162, 155, 254)",
  "rgb(232, 67, 167)",
  "rgb(253, 203, 110)",
  "rgb(85, 239, 196)",
  "rgb(128, 228, 29)",
  // "rgb(200, 220, 200)",
  "rgb(48, 118, 19)",
  "rgb(28, 78, 69)",
  "rgb(128, 28, 69)",
  // "rgb(178, 190, 195)",
  // "rgb(99, 110, 114)",
  // "rgb(45, 52, 54)",
  // "rgb(45, 22, 4)",
  // "rgb(223, 230, 233)",
  "rgb(0, 0, 90)",
  "rgb(213,0,249)",
  "rgb(40,53,147)",
  "rgb(121,85,72)",
  "rgb(33,150,243)",
  "rgb(255,235,59)",
  "rgb(126,87,194)",
  "rgb(85,195,225)",
  "rgb(255,64,129)",
  "rgb(0,200,83)",
  "rgb(255,112,67)",
  "rgb(41,121,255)",
  "rgb(204,128,207)",
  "rgb(38,166,154)",
  "rgb(253,187,84)",
];

const GREY_BLACK_SHADES = [
  "rgb(200, 220, 200)",
  "rgb(223, 230, 233)",
  "rgb(178, 190, 195)",
  "rgb(99, 110, 114)",
  "rgb(45, 52, 54)",
  "rgb(45, 22, 4)",
];

function colorDistance(color1, color2) {
  const x =
    Math.pow(color1[0] - color2[0], 2) + Math.pow(color1[1] - color2[1], 2) + Math.pow(color1[2] - color2[2], 2);
  return Math.sqrt(x);
}

const clusters = [
  { name: "red", leadColor: [255, 0, 0], colors: [] },
  { name: "orange", leadColor: [255, 128, 0], colors: [] },
  { name: "yellow", leadColor: [255, 255, 0], colors: [] },
  { name: "chartreuse", leadColor: [128, 255, 0], colors: [] },
  { name: "green", leadColor: [0, 255, 0], colors: [] },
  { name: "spring green", leadColor: [0, 255, 128], colors: [] },
  { name: "cyan", leadColor: [0, 255, 255], colors: [] },
  { name: "azure", leadColor: [0, 127, 255], colors: [] },
  { name: "blue", leadColor: [0, 0, 255], colors: [] },
  { name: "violet", leadColor: [127, 0, 255], colors: [] },
  { name: "magenta", leadColor: [255, 0, 255], colors: [] },
  { name: "rose", leadColor: [255, 0, 128], colors: [] },
  { name: "black", leadColor: [0, 0, 0], colors: [] },
  { name: "grey", leadColor: [235, 235, 235], colors: [] },
  { name: "white", leadColor: [255, 255, 255], colors: [] },
];

MODIFIED_COLOR_SET.forEach((color) => {
  const rgb = color.match(/\d+/g);
  let minDistance = 1000;
  let minDistanceClusterIndex = -1;
  clusters.forEach((cluster, index) => {
    if (colorDistance(rgb, cluster.leadColor) < minDistance) {
      minDistance = colorDistance(rgb, cluster.leadColor);
      minDistanceClusterIndex = index;
    }
  });
  clusters[minDistanceClusterIndex].colors.push(color);
});

clusters.forEach((cluster) => {
  cluster.colors.sort((a, b) => {
    return tinycolor(a).getBrightness() - tinycolor(b).getBrightness();
  });
});

const listOfColors = clusters.flatMap((item) => item.colors.map((color) => color));

const finalList = [...listOfColors, ...GREY_BLACK_SHADES];
