// For context, see conversations:
// - https://blackwoodseven.slack.com/archives/CJW82FTK6/p1638964550009500?thread_ts=1638960421.001900&cid=CJW82FTK6
// - https://blackwoodseven.slack.com/archives/CJW82FTK6/p1639648661003300?thread_ts=1639599177.002700&cid=CJW82FTK6
function getWhatSocketCallsModelslug({ modelSlug, socketSlug }) {
  const isOldModel = modelSlug !== socketSlug || LIST_OF_OLD_MODELS_WITH_SAME_MODEL_AND_SOCKET_SLUG.includes(modelSlug);
  return isOldModel ? "default" : socketSlug;
}

// To know where this hard coded list comes from, see https://blackwoodseven.slack.com/archives/CJW82FTK6/p1638965011010100?thread_ts=1638960421.001900&cid=CJW82FTK6
const LIST_OF_OLD_MODELS_WITH_SAME_MODEL_AND_SOCKET_SLUG = [
  "tsb_pca_daily",
  "tsb_loans_daily",
  "tsb_brand",
  "memo_demo",
  "churn_new_pr",
  "digi_brand",
];

module.exports = getWhatSocketCallsModelslug;
