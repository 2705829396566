import styled from "@emotion/styled/macro";
import Tooltip from "@mui/material/Tooltip";
import { Option } from "admin";
import { useAccount } from "auth";
import React from "react";
import ReactDOM from "react-dom";
import { useSessionStorageSync } from "shared/helpers/sessionStorageSync";
import ConfigurationEditor from "./ConfigurationEditor";
import UsersEditor from "./UsersEditor";

export default function OrgEditor({
  activeTab,
  setActiveTab,
  organization,
  name,
  changesPending,
  changesPendingCb,
  setDisableScroll,
}) {
  const { isClaim } = useAccount();
  React.useEffect(() => {
    if (activeTab === null) {
      //set initial tab based on claims
      setActiveTab(isClaim("OrgConfig.Read") || isClaim("ModelConfig.Read") ? 0 : isClaim("OrgARM.Read") ? 1 : null);
    }
  }, [isClaim, setActiveTab, activeTab]);
  const [selectedModel] = useSessionStorageSync("selected-model");
  return (
    <>
      <AdminHeaderForOrgEditor>
        <div style={{ marginRight: 15, fontSize: "18px", color: "#000" }}>{name}</div>
        <Choose>
          {(isClaim("OrgConfig.Read") || isClaim("ModelConfig.Read")) && (
            <Option
              style={{ fontSize: "14px", padding: "7.5px 10px", height: "fit-content" }}
              active={activeTab === 0}
              onClick={() => {
                setActiveTab(0);
                setDisableScroll(false);
              }}
              data-test-id="admin-configuration-tab"
            >
              Configuration
            </Option>
          )}

          {isClaim("OrgARM.Read") && (
            <Tooltip title={changesPending ? "You need to save or discard changes first!" : null} arrow>
              <Option
                style={{ fontSize: "14px", padding: "7.5px 10px" }}
                active={activeTab === 1}
                onClick={() => {
                  if (!changesPending) setActiveTab(1);
                }}
                disabled={changesPending}
                data-test-id="admin-users-tab"
              >
                users
              </Option>
            </Tooltip>
          )}
        </Choose>
      </AdminHeaderForOrgEditor>
      {activeTab === 0 && (isClaim("OrgConfig.Read") || isClaim("ModelConfig.Read")) && (
        <ConfigurationEditor key={selectedModel} organization={organization} changesPendingCb={changesPendingCb} />
      )}
      {activeTab === 1 && isClaim("OrgARM.Read") && (
        <UsersEditor organization={organization} setDisableScroll={setDisableScroll} />
      )}
    </>
  );
}

function AdminHeaderForOrgEditor({ children }) {
  const adminHeaderTitleEl = document.getElementById("admin-header");
  return adminHeaderTitleEl && ReactDOM.createPortal(children, adminHeaderTitleEl);
}

const Choose = styled.div`
  display: flex;

  > * {
    &:first-of-type {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    &:last-of-type {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
`;
