import backend from "backend";
import _ from "lodash";

export default function useDimensionValues(socketSlug, activeVersion, opts = {}) {
  const { data: dimensionValues = [] } = backend.useData(
    `v4/dimensions/${socketSlug}/${activeVersion}`,
    {},
    {
      dedupingInterval: 60 * 1000, // Do not revalidate very often
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return opts.onlyMedia
    ? dimensionValues.filter((d) => d.driver_type === "paid_media").map((d) => _.omit(d, "driver_type"))
    : dimensionValues;
}
