import { useAccount } from "auth";
import { jwtDecode } from "jwt-decode";
import store from "store";
import { notifyNew } from "store/action-creators";
import { LinkButton } from "../LittleToolsForCoworkers";

export default function LittleToolsForDevelopers() {
  const { conf } = useAccount();
  const copyToClipboard = (data, successMessage, failureMessage) => {
    navigator.clipboard
      .writeText(JSON.stringify(data, null, 2))
      .then(() => store.dispatch(notifyNew({ message: successMessage })))
      .catch(() => store.dispatch(notifyNew({ message: failureMessage, isError: true })));
  };

  const msalTokenDetails = {
    token: sessionStorage.getItem("msalToken"),
    decodedToken: jwtDecode(sessionStorage.getItem("msalToken")),
  };

  const orgTokenDetails = {
    token: sessionStorage.getItem("current-token-v2"),
    decodedToken: jwtDecode(sessionStorage.getItem("current-token-v2")),
  };

  return (
    <>
      <div style={{ margin: "1em 0 0.5em 0", color: "#C7C7C7" }}>
        <span role="img" aria-label="dev">
          👨‍💻
        </span>{" "}
        Little tools for developers:
      </div>
      <LinkButton
        onClick={() =>
          copyToClipboard(msalTokenDetails, "✨ MSAL token copied to clipboard!", "Failed to copy MSAL token")
        }
      >
        Copy MSAL token
      </LinkButton>
      <LinkButton
        onClick={() =>
          copyToClipboard(orgTokenDetails, "✨ Org JWT token copied to clipboard!", "Failed to copy Org JWT token")
        }
      >
        Copy Org JWT token
      </LinkButton>
      <LinkButton onClick={() => copyToClipboard(conf, "✨ Org conf copied to clipboard!", "Failed to copy Org conf")}>
        Copy Org conf
      </LinkButton>
    </>
  );
}
