import backend from "backend";
import { call, put, takeLatest, delay } from "redux-saga/effects";

import { seqNumActivitiesMutation, mediaInsightsSelectPeriod, notifyNew, notifyClose } from "store/action-creators";

import { ACTIVATE_PLAN_SUCCESS, ACTIVATE_PLAN_ERROR, ACTIVATE_PLAN } from "../actions";
import { CircularProgress } from "@mui/material";

const difference = require("lodash/fp/difference");

/**
 * Activate a plan within a given activity, eventually after de-activating a list of plans (given by correlation ID)
 * If the newly activated plan overlaps any other plan, already active and not scheduled for de-activation, fails without doing anything
 */
function* activatePlan({
  plan,
  plansToDeactivate = [],
  activity,
  socketSlug,
  newFileName = "",
  labelDictionary = {},
  showDirectIndirectSplit,
  metrics = [],
  dispatch,
}) {
  // Only the activity and plan's ID are needed. We may want to simplify code.
  const activityId = activity.id;
  const correlationId = plan.id;
  const { startDate, endDate } = activity.investmentPlans[correlationId];

  try {
    yield call(() =>
      // fetching overlapping plans and checking that they are scheduled for de-activation.
      backend
        .get("v1/activities", { params: { active: true, startDate, endDate, modelSlug: socketSlug } })
        .then((overlappingActivities) => {
          const overlappingPlans = overlappingActivities.map((a) => a.activePlanId);

          if (difference(overlappingPlans, plansToDeactivate).length > 0) {
            throw new Error("Plan overlaps with other plans");
          }

          // De-activating plans
          Promise.all(
            // Assuming that plansToDeactivate doesn't contain *more* things.
            overlappingActivities.map((a) =>
              backend.patch(`v1/activities/${socketSlug}/${a.id}`, { activePlanId: null }),
            ),
          )
            // Finally activating the requested plan
            .then(() => backend.patch(`v1/activities/${socketSlug}/${activityId}`, { activePlanId: correlationId }))
            .then(() => seqNumActivitiesMutation());
        }),
    );
    const url = `v3/export/investment-plans/${socketSlug}/${plan.id}?isBrandHealthDataAvailable=${showDirectIndirectSplit}`;
    const overrideLabels = metrics.reduce((result, item) => {
      result[item.slug] = item.name;
      return result;
    }, {});
    dispatch(
      notifyNew({
        message: (
          <>
            <CircularProgress size={20} sx={{ color: "#fff" }} /> Preparing to download
          </>
        ),
        isPersistent: true,
        closeButton: false,
      }),
    );
    backend
      .downloadBinary(url, newFileName, {
        method: "POST",
        body: JSON.stringify({ ...labelDictionary, ...overrideLabels }),
        headers: { "Content-Type": "application/json" },
      })
      .then(() => dispatch(notifyClose()));

    yield put({ type: ACTIVATE_PLAN_SUCCESS, plan, activityId: activity.id });
    // This is for the new overview to focus on plan period and rerender after an activation
    yield delay(1000);
    yield put(mediaInsightsSelectPeriod({ section: "media", period: [plan.request.startDate, plan.request.endDate] }));
    yield put(seqNumActivitiesMutation());
  } catch (err) {
    console.error("Activate plan failed", err);

    yield put(
      notifyNew({
        message: "Failed activating plan. Please try again.",
        isError: true,
      }),
    );

    yield put({ type: ACTIVATE_PLAN_ERROR });
  }
}

export function* ActivateInvestmentPlanSaga() {
  yield takeLatest(ACTIVATE_PLAN, activatePlan);
}
