export default async function msalFetcher(url, opts = {}, token = "") {
  const urlToShow = url.split("?")[0];

  return fetch("/backend-api" + url, {
    ...opts,
    headers: { ...(opts.headers || {}), msal_user_auth: `${token}` },
  }).then((res) => {
    if (!res.ok) {
      return res.text().then((errorResponse) => {
        const errorInfo = `${res.status} ${urlToShow}\n${errorResponse || ""}`;
        // logoutMSAL();
        return { data: null, status: res.status, errorInfo: errorInfo, loading: false };
      });
    } else {
      // No error, behave differently depending on content type
      const contentType = res.headers.get("content-type");
      return (contentType?.includes("application/json") ? res.json() : res.text())
        .then((data) => {
          return { data: data, status: 200 };
        })
        .catch(() => null); // In case the return is empty, don't propagate error
    }
  });
}
