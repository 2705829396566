import FEATURES from "features";

const prodFeatures = Object.entries(FEATURES).filter(([, value]) => value.in === "PROD");
const AVAILABLE_FEATURES = prodFeatures.map(([key, _]) => key);
const AVAILABLE_FEATURES_LABELS = Object.fromEntries(prodFeatures.map(([key, value]) => [key, value.label]));

export default function schemaFeatures({ data = [], allModelSlugs, modelsLabels }) {
  return {
    title: "Features",
    type: "array",
    default: [],
    items: {
      type: "object",
      ui__uniqueKey: "name",
      default: { name: undefined, blacklistedModels: [] },
      properties: {
        name: {
          title: "Name",
          type: "string",
          // Exclude already added features
          enum: AVAILABLE_FEATURES.filter((d) => !data?.map((d) => d?.name).includes(d)),
          ui__labels: AVAILABLE_FEATURES_LABELS,
        },
        blacklistedModels: {
          title: "Excluded models",
          description: "Mark here the models you don't want to show in this feature",
          type: "array",
          default: [],
          items: {
            type: "string",
            enum: allModelSlugs,
            ui__labels: modelsLabels,
          },
        },
      },
    },

    ui__isError: (data = []) => {
      if (data === undefined) return "You need to add at least one feature";
      for (const item of data) {
        if (!item.name) return "Choose a feature";
        if (!AVAILABLE_FEATURES.includes(item.name))
          return `${item.name} is no longer a valid feature. Please, remove it.`;
      }
    },
  };
}
