import React from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import styled from "@emotion/styled/macro";
import _ from "lodash";

import validateRequest from "../helpers/validateRequest";
import getWhatSocketCallsModelslug from "shared/helpers/getWhatSocketCallsModelslug";

import {
  cancelEditor,
  startEditorValidation,
  successEditorValidation,
  errorEditorValidation,
  saveConstraint,
} from "store/reducers/activityDialog";

import { useAccount } from "auth";

const TableFooter = ({
  index,
  constraint,
  validating,
  cancelEditor,
  startEditorValidation,
  successEditorValidation,
  errorEditorValidation,
  saveConstraint,
  activityV2StateData,
  activityV2StateInlineEditor,
}) => {
  const { selectedModel, selectedModelConf: modelConf, isFeatureAvailable } = useAccount();
  const { socketSlug, objective } = modelConf;

  const handleValidateRequest = ({
    socketSlug,
    whatSocketCallsModelslug,
    objective,
    isAdding,
    addedOrEditedConstraint,
    useExperimentalOptimizer,
  }) => {
    startEditorValidation();
    validateRequest({
      socketSlug,
      whatSocketCallsModelslug,
      objective,
      isAdding,
      addedOrEditedConstraint,
      useExperimentalOptimizer,
      activityV2StateData,
      activityV2StateInlineEditor,
    })
      .then((res) => {
        console.log("Contraints validated:", res);
        if (res.constraintsValid) {
          successEditorValidation();
          saveConstraint({ index: activityV2StateInlineEditor.index });
        } else {
          errorEditorValidation({
            errorMessage: "Not compatible with previous inputs or available publisher inventory",
          });
        }
      })
      .catch((err) => {
        console.error("Error while verifying constraints", err);
        // Try to find out what's going on with these errors
        errorEditorValidation({ errorMessage: "Internal system error, please try again" });
      });
  };

  return (
    <EditorFooterList>
      <EditorFooterListItem>
        <Button sx={{ color: "#000", fontSize: "14px" }} onClick={() => cancelEditor()}>
          Cancel
        </Button>
        <Button
          sx={{ fontSize: "14px" }}
          variant="contained"
          disabled={
            [constraint.type, constraint.value, constraint.startDate, constraint.endDate].some(_.isNil) ||
            !constraint.dimensions.some((d) => d.selection && !_.isEmpty(d.selection)) ||
            validating
          }
          onClick={() =>
            handleValidateRequest({
              socketSlug,
              whatSocketCallsModelslug: getWhatSocketCallsModelslug({ modelSlug: selectedModel, socketSlug }),
              objective,
              isAdding: index === null,
              addedOrEditedConstraint: constraint,
              useExperimentalOptimizer: isFeatureAvailable("Use experimental optimizer"),
            })
          }
        >
          Add constraint
        </Button>
      </EditorFooterListItem>
    </EditorFooterList>
  );
};

const EditorFooterList = styled.ul`
  list-style-type: none;
  margin-top: 15px;
  padding: 0;
`;

const EditorFooterListItem = styled.li`
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  height: 32px;
`;

export default connect(
  (state) => ({
    activityV2StateData: state.activityDialog.data,
    activityV2StateInlineEditor: state.activityDialog.inlineEditor,
    ...state.activityDialog.inlineEditor,
  }),
  {
    cancelEditor,
    startEditorValidation,
    successEditorValidation,
    errorEditorValidation,
    saveConstraint,
  },
)(TableFooter);
