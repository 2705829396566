import styled from "@emotion/styled";
import { logout } from "auth";
import { logoutMSAL } from "auth/msalAuth";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Alert } from "./Assets/alert.svg";

function ErrorPage({ scenario = "PageNotFound" }) {
  const navigate = useNavigate();
  const messages = {
    PageNotFound: {
      title: "404: Page not found",
      desc: "Sorry, we could not find this page.",
      action: (
        <>
          You can either <span onClick={() => navigate("/")}>go back</span> or{" "}
          <span onClick={() => logoutMSAL()}>log out.</span>
        </>
      ),
    },
    ModelNotFound: {
      title: "Access Denied",
      desc: "Sorry, you do not have access to this model!",
      action: (
        <>
          You can either <span>go to the model selection</span> or <span>log out.</span>
        </>
      ),
    },
    AccessDenied: {
      title: "Access Denied",
      desc: "Sorry, you do not have access to this page!",
      action: (
        <>
          <span onClick={() => logout()}>Select a different organizatino</span>
        </>
      ),
    },
  };

  return (
    <MainContainer>
      <Content>
        <Title>{messages[scenario]?.title}</Title>
        <Desc>{messages[scenario]?.desc}</Desc>
        <Action>{messages[scenario]?.action}</Action>
        <NeedHelp>Click the ‘Need help?’ button located on the left side of the screen to get support.</NeedHelp>
      </Content>

      <AlertIcon>
        <Alert />
      </AlertIcon>
    </MainContainer>
  );
}

const MainContainer = styled("div")`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 150px;
  background: rgba(250, 250, 250, 1);
`;

const Content = styled("div")`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 5px 0px;
`;

const AlertIcon = styled("div")`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0px 20px;
  padding-top: 40px;
`;

const Title = styled("div")`
  color: #000;
  font-size: 24px;
  font-weight: 700;
`;

const Desc = styled("div")`
  color: #000;
  font-size: 80px;
  font-weight: 250;
`;

const Action = styled("div")`
  color: #131321;
  font-size: 22px;
  margin-top: 30px;
  span {
    text-decoration-line: underline;
    color: rgba(112, 35, 168, 1);
    cursor: pointer;
  }
`;

const NeedHelp = styled("div")`
  color: #000;
  font-size: 22px;
  margin-top: 30px;
`;

export default ErrorPage;
