import React from "react";
import styled from "@emotion/styled";
import { grey } from "@mui/material/colors";
import dayjs from "dayjs";

const BroadcastItem = ({ item }) => {
  const formatedDate = dayjs(item.date).format("MMM DD, YYYY");
  const formatedTime = dayjs(item.date).format("HH:mm");

  const isActive = item.status !== "inactive";
  return (
    <ListItem isActive={isActive}>
      <time dateTime={item.date}>
        {formatedDate} &nbsp;{formatedTime}
      </time>
      <p style={{ whiteSpace: "pre-wrap" }}>{item.broadcastmessage}</p>
    </ListItem>
  );
};

const ListItem = styled.li`
  border-left: 3px solid ${(props) => (props.isActive ? "#00b600" : props.theme.palette.greyish[20])};
  padding: 4px 8px 0;

  time {
    color: ${grey[500]};
    font-size: 0.9rem;
  }

  p {
    padding: 10px 0;
    margin: 0;
    color: ${(props) => (props.isActive ? "#000000" : props.theme.palette.greyish[25])};
    font-size: 1rem;
  }
`;
export default BroadcastItem;
