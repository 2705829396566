import styled from "@emotion/styled";
import { Modal } from "@mui/material";
import { TextButton } from "./TextButton";

export function ConfirmationModal({
  open,
  title,
  content,
  onCancel,
  cancelText,
  onConfirm,
  confirmText,
  variant = "confirm", //"confirm" or "warning"
  isConfirmDisabled = false,
  ...props
}) {
  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Brown, Helvetica, sans-serif",
      }}
    >
      <ModalContent {...props}>
        <h1>{title}</h1>
        <p>{content}</p>
        <ModalActions>
          <TextButton
            color={variant === "warning" ? "alertRed" : "primary"}
            variant="contained"
            disabled={isConfirmDisabled}
            onClick={onConfirm}
          >
            {confirmText}
          </TextButton>
          <TextButton color="blackish" onClick={onCancel}>
            {cancelText}
          </TextButton>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
  --gap: 20px;
  display: flex;
  flex-direction: column;
  padding: var(--gap);
  gap: var(--gap);
  background-color: white;
  width: fit-content;
  color: black;
  max-width: var(--max-width, 390px);

  h1 {
    font-size: 16px;

    //resets
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  p {
    font-size: 14px;

    //resets
    margin: 0;
    padding: 0;
  }
`;

const ModalActions = styled.div`
  --gap: 15px;
  display: flex;
  flex-direction: row-reverse;
  gap: var(--gap);
`;
