import styled from "@emotion/styled";
import { connect } from "react-redux";
import { updateFeatureStatus } from "store/action-creators";

import "./feature-toggler.css";

const FeatureToggler = ({ featureOverrides, onToggle }) => {
  const FeatureList = (features, categoryEmoji, categoryName) => (
    <>
      <div style={{ margin: "1em 0 0.5em 0", color: "#C7C7C7" }}>
        <span role="img" aria-label={categoryName}>
          {categoryEmoji}
        </span>{" "}
        {categoryName}
      </div>
      <ul>
        {features.map(([key, value]) => (
          <li
            key={key}
            title={`'${value.label}${value.ahaId !== undefined ? ` (${value.ahaId})` : ``}' is ${
              value.state === undefined
                ? "not overridden (read from configuration)"
                : value.state
                  ? "overridden to ACTIVE"
                  : "overridden to DEACTIVATED"
            }`}
            style={{ color: "#fff" }}
            onClick={() => onToggle(key)}
            data-ref={"Feature " + key}
          >
            <FeatureLabel>
              {value.label}
              {value.ahaId !== undefined && ` (${value.ahaId})`}
            </FeatureLabel>
            <span style={{ float: "right", color: value.state ? "#3eb080" : "white" }}>
              {value.state === undefined ? "–" : value.state ? "✔" : "✖"}
            </span>
          </li>
        ))}
      </ul>
    </>
  );

  const prodFeatures = Object.entries(featureOverrides).filter(([, value]) => value.in === "PROD");
  const reviewFeatures = Object.entries(featureOverrides).filter(([, value]) => value.in === "REVIEW");
  const devFeatures = Object.entries(featureOverrides).filter(([, value]) => value.in === "DEV");

  return (
    <div className="feature-toggler">
      {FeatureList(prodFeatures, "🏭", "Features in Production:")}
      {FeatureList(reviewFeatures, "📝", "Features for Review:")}
      {FeatureList(devFeatures, "🌱", "Features in Development:")}
    </div>
  );
};

export default connect(
  (state) => ({ featureOverrides: state.featureOverrides }),
  (dispatch) => ({ onToggle: (feature) => dispatch(updateFeatureStatus(feature)) }),
)(FeatureToggler);

const FeatureLabel = styled.span`
  width: 90%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: top;
`;
