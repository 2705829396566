import React from "react";
import _ from "lodash";
import fromKeyValueListToKeyValuesObject from "shared/helpers/fromKeyValueListToKeyValuesObject";
import filterOutUselessDimensionValues from "shared/helpers/filterOutUselessDimensionValues";
import Options from "./Options";
import getToggledFilter from "./getToggledFilter";
import styled from "@emotion/styled/macro";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function DimensionFilter({
  filter,
  onChange,
  dimensionValues,
  groupingToFilter = {},
  defaultLabel = "Pick from the list",
  hiddenDimensions = [],
  labelDictionary = {},
  squaredBorder = false,
  selectedDimension = {},
  onDeleteDimension,
  isNewDimensionRow,
  buttonHeight,
  width = "300px",
}) {
  const [text, setText] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);
  const inputEl = React.useRef();
  const [hoveredDimension, setHoveredDimension] = React.useState(null);

  // Discard unique dimensions that are incompatible with this filter
  const valuesGroupedByDim = fromKeyValueListToKeyValuesObject(dimensionValues);
  let reasonableOptions = _.mapValues(valuesGroupedByDim, (values, dimension) =>
    filterOutUselessDimensionValues({ filter, uniqueDimensions: dimensionValues })(values, dimension)
      .slice()
      .filter((d) => d !== null)
      .sort((a, b) =>
        // Sort by label
        (labelDictionary[dimension + "." + a] || a) > (labelDictionary[dimension + "." + b] || String(b)) ? 1 : -1,
      ),
  );

  const filteredDimensions = Object.keys(filter);
  function toggleOpen(e) {
    setAnchorEl(anchorEl === null ? e.currentTarget : null);
  }

  const open = Boolean(anchorEl);
  const id = open ? "constraintsDimensionFilter" : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        onClick={toggleOpen}
        disableRipple={true}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        sx={{
          border: "none",
          color: _.isEmpty(selectedDimension) ? "#858574" : "#000",
          padding: "0px 3px",
          width: "100%",
          justifyContent: "space-between",
          textTransform: "none",
          textAlign: "left",
          borderBottom: `2px solid ${open ? "#7023A8" : "#000"} `,
          "&:hover": {
            borderBottom: "2px solid black",
          },
          height: buttonHeight,
        }}
      >
        <LabelWrapper>{defaultLabel}</LabelWrapper>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Menu
          id={isOpen ? "dimension-picker" : undefined}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={() => {
            setText("");
            setAnchorEl(null);
          }}
          TransitionComponent={Fade}
        >
          <div style={{ position: "relative" }} data-test-id="dimensionsFilterMenu">
            <OptionsFrame
              width={width}
              isNewDimensionRow={isNewDimensionRow}
              data-test-id="dimensionsFilterMenuOptions"
            >
              <Options
                text={text}
                setText={setText}
                onDeleteDimension={() => {
                  onDeleteDimension();
                  setText("");
                  setAnchorEl(null);
                }}
                inputEl={inputEl}
                hoveredDimension={hoveredDimension}
                options={
                  !_.isEmpty(filter)
                    ? reasonableOptions
                    : {
                        grouping: Object.keys(groupingToFilter).sort((a, b) => (a > b ? +1 : -1)),
                        ...reasonableOptions,
                      }
                }
                nearestDimension={filteredDimensions[filteredDimensions.length - 1]}
                filter={filter}
                selectedDimension={selectedDimension}
                onRemoveDimension={(dimension) => {
                  if (hoveredDimension === dimension) setHoveredDimension(null);
                  if (onChange) onChange(_.omit(filter, [dimension]));
                }}
                onFilterToggle={({ dimension, value }) => {
                  if (!onChange) return;
                  if (dimension === "grouping") onChange(groupingToFilter[value]);
                  else onChange(getToggledFilter({ previousFilter: {}, value, inDimension: dimension }));
                  setText("");
                  setAnchorEl(null);
                }}
                onClose={() => {
                  setText("");
                  setAnchorEl(null);
                }}
                disabled={onChange === undefined}
                hiddenDimensions={hiddenDimensions}
                labelDictionary={labelDictionary}
              />
            </OptionsFrame>
            {text.length > 0 ? (
              <CloseIconFrame onClick={() => setText("")} data-test-id="dimensionsFilterClose">
                <CloseIcon style={{ width: "20px", height: "20px", color: "#000", backgroundColor: "transparent" }} />
              </CloseIconFrame>
            ) : (
              <CloseIconFrame data-test-id="dimensionsFilterSearch">
                <SearchIcon style={{ width: "20px", height: "20px", color: "#000", backgroundColor: "transparent" }} />
              </CloseIconFrame>
            )}
          </div>
        </Menu>
      </Popover>
    </>
  );
}

const LabelWrapper = styled.span`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OptionsFrame = styled.div`
  width: ${({ width }) => width};
  max-height: ${(props) => (props.isNewDimensionRow ? "21rem" : "17rem")};
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0;
  margin: -8px 0 -8px 0;

  /* Scrollbars are tricky to style, for now I'm disabling it */
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

const CloseIconFrame = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 15px 12px 8px;
  z-index: 1;
  cursor: pointer;
`;
