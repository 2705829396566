import styled from "@emotion/styled/macro";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTheme } from "@mui/styles";

export function Title({ slug, title, description, large, error, isUppercase, extraStylesDiv, extraStylesIcon }) {
  const theme = useTheme();
  return title && large ? (
    <BigHeader theme={theme}>
      <BigTitle>
        {title} {error && <ErrorMessage data-test-id="adminErrorMessage">{error}</ErrorMessage>}
      </BigTitle>
      <div style={{ lineHeight: "140%" }}>{description}</div>
    </BigHeader>
  ) : (
    <div
      style={{
        color: theme.palette.greyish[50],
        padding: "0px 15px",
        fontSize: 14,
        height: "35px",
        display: "flex",
        alignItems: "center",
        textTransform: isUppercase ? "uppercase" : "default",
        ...extraStylesDiv,
      }}
    >
      {(title || slug) && <span>{title || slug}</span>}
      {(description || error) && (
        <Tooltip
          title={
            <>
              {
                <div
                  style={{
                    whiteSpace: "pre-wrap", //to break the line \n in description
                  }}
                >
                  {description}
                </div>
              }
              {error && (
                <div
                  style={{
                    margin: "4px 0",
                    backgroundColor: "white",
                    color: "red",
                    padding: "4px 6px",
                    borderRadius: 0,
                  }}
                >
                  {error}
                </div>
              )}
            </>
          }
          PopperProps={{ style: { maxWidth: 220 } }}
          arrow
        >
          <InfoOutlinedIcon
            fontSize="small"
            style={{
              verticalAlign: "middle",
              marginLeft: 8,
              cursor: "pointer",
              color: error ? "red" : "inherit",
              height: 18,
              width: 18,
              ...extraStylesIcon,
            }}
          />
        </Tooltip>
      )}
    </div>
  );
}

export const Cell = styled.div`
  width: 100%;
`;

export const BigTitle = styled.div`
  text-transform: uppercase;
  margin-bottom: 4px;
  line-height: 140%;
`;

export const BigHeader = styled.div`
  padding: 15px 15px 10px 15px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.greyish[50]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.greyish[20]};
`;

export const TableLayout = styled.div`
  display: grid;
  grid-template-columns:
    repeat(${({ columns }) => columns || 1}, minmax(100px, 1fr))
    ${({ extra }) => (extra !== undefined ? `${extra || 0}px` : "")};

  > * {
    border-bottom: 1px solid ${({ theme }) => theme.palette.greyish[20]} !important;
  }
`;

const ErrorMessage = styled.span`
  &::before {
    content: "●";
    line-height: 1em;
    font-size: 18px;
    margin: 0 6px;
  }
  text-transform: none;
  letter-spacing: 0;
  color: red;
`;
