import backend from "backend";

export default function useLongPollingSimulator(modelSlug) {
  const { data, mutate, loading } = backend.useData(
    modelSlug ? `v1/simulation/${modelSlug}` : null,
    null, // No query params
    { refreshInterval: 10000 }, // Long polling
    true, // Logging disabled
  );

  return { data: data || {}, invalidate: mutate, loading };
}
