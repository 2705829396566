import useData from "./useData";
import useMsalData from "./useMsalData";
import fetcher from "./fetcher";
import downloadBinary from "./downloadBinary";

const headers = { "content-type": "application/json" };
const backend = {
  get: (url, { params } = {}) => fetcher(params ? url + "?" + new URLSearchParams(params) : url),
  put: (url, body) => fetcher(url, { method: "PUT", body: JSON.stringify(body), headers }),
  post: (url, body) => fetcher(url, { method: "POST", body: JSON.stringify(body), headers }),
  patch: (url, body) => fetcher(url, { method: "PATCH", body: JSON.stringify(body), headers }),
  delete: (url) => fetcher(url, { method: "DELETE" }),
  useData,
  fetcher,
  downloadBinary,
  useMsalData,
};

export default backend;
