import React, { useImperativeHandle } from "react";
import { Badge, Icon, Popover } from "@mui/material";
import { styled } from "@mui/system";
import { grey } from "@mui/material/colors";

import backend from "backend";
import { ReactComponent as BroadcastIconWhite } from "./IconAssets/broadcast-icon-white.svg";
import { ReactComponent as BroadcastIconBlack } from "./IconAssets/broadcast-icon-black.svg";
import { ReactComponent as BroadCastNotificationBlackIcon } from "./IconAssets/Broadcast-Notification-Black.svg";
import { ReactComponent as BroadCastNotificationWhiteIcon } from "./IconAssets/Broadcast-Notification-White.svg";
import BroadcastItem from "./BroadcastItem";
import useBroadcastMessages from "shared/hooks/useBroadcastMessages";
import { useAccount } from "auth";

const BroadcastPanel = React.forwardRef((props, ref) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const broadcastIconRef = React.useRef();

  const isBroadcastPanelOpen = Boolean(anchorEl);

  const { broadcastItems, loading, mutate, isNewBroadcastAvailable, latestBroadcast } = useBroadcastMessages();

  const { isFeatureAvailable } = useAccount();
  const IconSrc = isFeatureAvailable("BW7 theme") ? (
    isNewBroadcastAvailable ? (
      <BroadCastNotificationBlackIcon />
    ) : (
      <BroadcastIconBlack />
    )
  ) : isNewBroadcastAvailable ? (
    <BroadCastNotificationWhiteIcon />
  ) : (
    <BroadcastIconWhite />
  );

  useImperativeHandle(ref, () => ({
    //exposing this functionalities to the navigation component.
    openBroadcastPanel() {
      handleClick();
    },
    closeNewBroadcastBar() {
      markBroadcastRead();
    },
  }));
  const handleClick = async (event) => {
    setAnchorEl((prevAnchor) => broadcastIconRef.current);

    await markBroadcastRead();
  };

  const markBroadcastRead = async () => {
    if (latestBroadcast === undefined) {
      return; //don't call the patch api if there is no unread items.
    }
    //modify the local cache first
    const seenUpdate = broadcastItems.map((item) => ({ ...item, hasBeenSeen: true }));
    await mutate(seenUpdate, false);

    await backend.patch(`v1/broadcasts`, { hasBeenSeen: true, hasKey: Boolean(latestBroadcast?.hasKey) });
  };
  const handleClose = (event) => {
    setAnchorEl((prevAnchor) => null);
  };
  return (
    <>
      <StyledBadge
        invisible={!isNewBroadcastAvailable}
        variant="dot"
        aria-haspopup="true"
        onClick={handleClick}
        ref={broadcastIconRef}
      >
        <Icon aria-label="broadcast icon">{IconSrc}</Icon>
      </StyledBadge>

      <Popover
        id="broadcasts-panel"
        open={isBroadcastPanelOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: "right",
        }}
      >
        <BroadcastsContainer>
          {loading ? (
            <NotAvailableMessage>
              <p>Loading...</p>
            </NotAvailableMessage>
          ) : broadcastItems?.length > 0 ? (
            <BroadcastList>
              {broadcastItems.map((broadcastItem) => (
                <BroadcastItem item={broadcastItem} key={broadcastItem.id} />
              ))}
            </BroadcastList>
          ) : (
            <NotAvailableMessage>
              <p>No information to report at this point in time.</p>
            </NotAvailableMessage>
          )}
        </BroadcastsContainer>
      </Popover>
    </>
  );
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& img": {
    cursor: "pointer",
    paddingBottom: 6,
  },
}));

const BroadcastsContainer = styled("div")`
  max-width: 495px;
  max-height: 500px;
`;

const NotAvailableMessage = styled("div")`
  font-size: 1rem;
  color: ${grey[700]};
  padding: 1rem;

  p {
    margin: 0;
  }
`;

const BroadcastList = styled("ol")`
  list-style: none;
  padding: 16px;
  margin: 0;

  & > li:not(:last-child) {
    margin-bottom: 24px;
  }
`;
export default BroadcastPanel;
