import React from "react";
import JsonURL from "@jsonurl/jsonurl";
import _ from "lodash";

const URL_UPDATE_EVENT = new CustomEvent("__urlupdate");

// With these we can customize behaviour, for example we can optimise performance not returning changes when we don't care (by specifying out) or updating the value differently depending on the value (by specifying in)
const DEFAULT_XFORM = {
  out: (valueStr) => valueStr, // Applied to the read parameter value
  in: (newValue) => newValue, // Applied to the new contents to be updated
};

// Allows to store state in a URL parameter
// A component can use this state independently of other search paramters
// It optionally accepts in/out transformations (see above)
export function useUrlSearchParam(paramName, xform = DEFAULT_XFORM) {
  const [paramValueEncoded, setParamValueEncoded] = React.useState(xform.out(getUrlParamValueEncoded(paramName)));

  // This is to make sure to update the value when navigating back-and-forth
  React.useEffect(() => {
    const listener = () =>
      // If it results in the same string no rerender will happen https://stackoverflow.com/a/67518058
      setParamValueEncoded(xform.out(getUrlParamValueEncoded(paramName)));
    window.addEventListener("popstate", listener);
    window.addEventListener("__urlupdate", listener);
    return () => {
      window.removeEventListener("popstate", listener);
      window.removeEventListener("__urlupdate", listener);
    };
  }, [paramName, xform]);

  return [
    paramValueEncoded && JsonURL.parse(paramValueEncoded, { AQF: true }),
    (newValue) => {
      const xformed = xform.in(cleanUp(newValue));
      setUrlParamValueEncoded(
        paramName,
        _.isUndefined(xformed) ? undefined : JsonURL.stringify(xformed, { AQF: true }),
      );
    },
  ];
}

// This is used to only keep some of the search params when we change route
// Example: You may want to keep startDate and endDate when moving to BI from another tab
export function filteredSearchParams(params, allowed) {
  if (allowed)
    // Ingnore filtering if not defined
    [...params.keys()].forEach((p) => {
      if (!allowed.includes(p)) params.delete(p);
    });
  const paramsStr = customSearchParamsStringify(params);
  return paramsStr.length === 0 ? "" : "?" + customSearchParamsStringify(params);
}

// In case of an object, remove keys with undefined values
function cleanUp(val) {
  if (val !== null && typeof val === "object") return _.pickBy(val, (v) => v !== undefined);
  else return val;
}

function getUrlParamValueEncoded(paramName) {
  // I use encodeURI because searchParams.get() decodes '+' as whitespace
  // and I need to re-encode it so that JsonURL can later on parse it
  return encodeURI(new URLSearchParams(window.location.search).get(paramName)) || undefined;
}

function setUrlParamValueEncoded(paramName, newParamValueEncoded) {
  if (newParamValueEncoded === getUrlParamValueEncoded(paramName)) return; // Avoid unnecessary update

  const params = new URLSearchParams(window.location.search);
  if (_.isUndefined(newParamValueEncoded)) params.delete(paramName);
  else params.set(paramName, newParamValueEncoded);
  const newSearchParamsEncoded = customSearchParamsStringify(params);
  window.history.pushState(
    {},
    "",
    `${window.location.pathname}${newSearchParamsEncoded.length > 0 ? "?" + newSearchParamsEncoded : ""}`,
  );

  window.dispatchEvent(URL_UPDATE_EVENT);
}

// I manually stringify to avoid encoding parens, which make the URL unreadable
function customSearchParamsStringify(params) {
  return [...params.entries()].map(([k, v]) => `${k}=${v}`).join("&");
}
