import React from "react";
import _ from "lodash";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import OptionsForOneDimension from "./OptionsForOneDimension";
import useKeyboard from "./useKeyboard";
import useFuzzySearch from "./useFuzzySearch";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    paddingTop: "15px",
    backgroundColor: theme.palette.greyish[10],
  },
  underline: {
    "&:after": {
      borderBottomColor: "#000", // Change to your desired color
    },
  },
}));

export default function Options({
  options,
  inputEl,
  hoveredDimension,
  nearestDimension,
  filter,
  disabled,
  onFilterToggle,
  onRemoveDimension,
  onClose,
  hiddenDimensions,
  labelDictionary,
  selectedDimension,
  onDeleteDimension,
  setText,
  text,
}) {
  const searchRef = React.useRef();
  const { searchResults, indexWithBestScore, flattenedList } = useFuzzySearch({ text, options });
  const classes = useStyles();
  // If there's no search results, show full list
  const filteredList = searchResults.length > 0 ? searchResults : flattenedList;
  var sortOrder = [
    "driver-custom",
    "datatypename",
    "effect_type",
    ..._.uniq(filteredList.map((d) => d.dimension)).filter((d) => d !== "effect_type" && d !== "datatypename"),
  ];
  filteredList.sort((a, b) => {
    // first have a look at dimension
    // get the indices of the given sortOrder
    // take the difference of the indices
    var delta = sortOrder.indexOf(a.dimension) - sortOrder.indexOf(b.dimension);
    // test, if the indices are the same
    if (delta === 0) {
      // the comparison should usually return 0,
      // but this will here not work. we need
      // the comparison of the second sort order of dimension.
      // take the variables and make a real comparison,
      // while we expecting strings to be compared
      return a.dimension === b.dimension ? 0 : a.dimension < b.dimension ? -1 : 1;
    }
    // otherwise return the delta
    return delta;
  });

  function onSelect({ value, dimension }) {
    // Select the search text instead of clearing it, to allow multi selects under same search
    if (searchRef.current) searchRef.current.select();
    onFilterToggle({ value, dimension });
  }

  const { bind, selectedItem } = useKeyboard({
    filteredList,
    indexWithBestScore,
    noResults: searchResults.length === 0,
    onSelect,
    onRemoveNearest: () => {
      if (text.length === 0) onRemoveDimension(nearestDimension);
    },
    onEsc: onClose,
  });

  const optionsByDimension = {};
  filteredList.forEach(
    ({ dimension, value }) =>
      (optionsByDimension[dimension] = [
        ...(optionsByDimension[dimension] || []),
        {
          value,
          dimension,
          selected: selectedItem && dimension === selectedItem.dimension && value === selectedItem.value,
          chosen: selectedDimension[dimension] && selectedDimension[dimension].includes(value),
        },
      ]),
  );

  return (
    <>
      {!disabled && (
        <TextField
          {...bind}
          inputRef={searchRef}
          autoFocus
          autoComplete="off"
          fullWidth
          style={{
            backgroundColor: "#FAFAFA",
            position: "sticky",
            top: "0px",
            zIndex: 1,
          }}
          variant="filled"
          id="search"
          value={text}
          placeholder="Search"
          onChange={(e) => setText(e.target.value)}
          InputProps={{
            classes: { input: classes.input, underline: classes.underline },
          }}
          data-test-id="dimensionsFilterSearch"
        />
      )}

      <div>
        <List dense disablePadding style={{ margin: "10px 0 0" }}>
          {Object.keys(optionsByDimension)
            .filter((d) => !hiddenDimensions.includes(d) || !Object.keys(filter).includes(d))
            .map((d) => (
              <OptionsForOneDimension
                key={d}
                dimension={d}
                options={optionsByDimension[d]}
                expandedOverride={(() => {
                  // When an item is selected normal behaviour (dimensions will
                  // expand if they contain the selection or we click on their expan button)
                  if (selectedItem !== null) return undefined;

                  // But if any dimension chip is hovered we go into an inspection
                  // mode where only the dimension hovered is expanded
                  if (hoveredDimension) return Boolean(hoveredDimension === d);

                  // By default, normal behaviour
                  // return d === expandedByDefault;
                })()}
                onSelect={onSelect}
                labelDictionary={labelDictionary}
              />
            ))}

          {selectedDimension && !_.isEmpty(selectedDimension) && (
            <ListItem sx={{ padding: "4px 0px 0px" }}>
              <Button
                disableRipple
                onClick={() => onDeleteDimension()}
                sx={{
                  fontSize: "14px",
                  width: "100%",
                  ":hover": { backgroundColor: "#F2F1EE;" },
                  ":active": { backgroundColor: "#E10000", color: "#fff" },
                  backgroundColor: "#fff",
                  color: "#E10000",
                }}
                variant="text"
                color="error"
              >
                DELETE DIMENSION
              </Button>
            </ListItem>
          )}
        </List>
      </div>
    </>
  );
}
