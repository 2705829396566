import styled from "@emotion/styled/macro";
import { InputAdornment, MenuItem, TableSortLabel, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Fade from "@mui/material/Fade";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import { useTheme } from "@mui/styles";
import React from "react";
import { getRoleLabel } from "shared/helpers/formatters";
import { Cell } from "./layoutComponents";

// Icons
import { ReactComponent as SearchIcon } from "shared/Icons/Magnifier.svg";
import { ReactComponent as CrossIcon } from "shared/Icons/crossGrey.svg";

export default function EditorItemForOrgRoles({
  schema,
  data = [],
  onChange,
  isDisabled,
  showLabel = true,
  defaultRole = "",
  extraStyles = {},
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sort, setSort] = React.useState("asc");
  const options = schema.items.enum || [];
  const accessibleOrgs = data.map((a) => a.orgSlug);
  const dataNotInOptions = accessibleOrgs.filter((d) => !options.includes(d));

  //used to subtract this height from scrollable items maxHeight to maintain container height
  const [searchContainerHeight, setSearchContainerHeight] = React.useState(0);
  const searchContainerRef = React.useCallback((containerNode) => {
    //this will run whenever the ref node changes with the node as argument.
    setSearchContainerHeight(containerNode?.clientHeight ?? 0);
  }, []);

  const getLabel = (slug) => schema.items.ui__labels?.[slug] || slug;
  const getOrgRole = (org) => data.find((a) => a.orgSlug === org)?.orgRole || defaultRole;
  const disabled = isDisabled || schema.ui__readOnly || !onChange;
  const [searchText, setSearchText] = React.useState("");
  const itemsToShow = [...options, ...dataNotInOptions]
    .filter((a) => a !== undefined)
    .filter((a) => (isDisabled ? accessibleOrgs.includes(a) : a))
    .filter((a) => getLabel(a).toLowerCase().includes(searchText));

  const sortArray = (array) => {
    const sortedArray = array.slice().sort((a, b) => {
      if (sort === "asc") {
        return getLabel(a).localeCompare(getLabel(b), undefined, { sensitivity: "base" });
      } else if (sort === "desc") {
        return getLabel(b).localeCompare(getLabel(a), undefined, { sensitivity: "base" });
      } else {
        return array;
      }
    });
    return sortedArray;
  };

  const theme = useTheme();
  return (
    <>
      <Cell
        onClick={(e) => setAnchorEl(e.currentTarget)}
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          padding: "10px 14px",
          color: anchorEl !== null && theme.palette.primary.main,
          fontWeight: anchorEl !== null && 700,
          fontSize: "18px",
        }}
      >
        <span
          style={{
            width: "calc(100% - 20px)",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            opacity: data.length > 0 ? 1 : 0.5,
            ...extraStyles,
          }}
        >
          {`${data.length} Organizations`}
        </span>
      </Cell>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setSearchText("");
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{ style: { marginTop: -6 } }}
        TransitionComponent={Fade}
        MenuListProps={{ disablePadding: true }}
      >
        <div
          ref={searchContainerRef}
          key="Search"
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 2,
            padding: 0,
            flexDirection: "column",
          }}
        >
          <MenuItem
            key="help-text"
            style={{
              cursor: disabled ? "not-allowed" : "pointer",
              borderBottom: "1px solid #cacaca",
              color: "gray",
              background: "#fafafa",
            }}
          >
            <ListItemText primary={`Multi organization access`} />
          </MenuItem>
          <TextField
            key={"search"}
            sx={{ width: "100%" }}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchText === "" ? (
                    <SearchIcon />
                  ) : (
                    <CrossIcon onClick={() => setSearchText("")} style={{ cursor: "pointer" }} />
                  )}
                </InputAdornment>
              ),
              autoComplete: "off",
            }}
            hiddenLabel
            id="user-search"
            variant="filled"
            placeholder="Search by organization name"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value.toLowerCase())}
            onKeyDown={(e) => e.stopPropagation()}
          />
          <MenuItem
            style={{
              width: "100%",
              padding: "8px 13px",
              color: "#858574",
            }}
          >
            <ListItemText
              sx={{
                width: isDisabled ? 150 : 182,
                flexGrow: 0,
              }}
            >
              <TableSortLabelStyled
                active={sort === "asc" || sort === "desc"}
                direction={sort}
                onClick={(e) => {
                  setSort((sort) => (sort === "asc" ? "desc" : "asc"));
                }}
                style={{ flexDirection: "row", fontSize: 14 }}
              >
                <span>organization</span>
              </TableSortLabelStyled>
            </ListItemText>
            <div style={{ padding: "0px 24px", visibility: "hidden" }}>→</div>
            <ListItemText primary="ORG.ROLE"></ListItemText>
          </MenuItem>
        </div>

        {itemsToShow.length === 0 && (
          <MenuItem sx={{ padding: "8px 13px" }}>There are no Organizations available.</MenuItem>
        )}
        <div style={{ maxHeight: 400 - searchContainerHeight, overflow: "auto" }} className="customScroll">
          {sortArray(itemsToShow)
            .sort((a, b) => accessibleOrgs?.includes(b) - accessibleOrgs?.includes(a))
            .map((option) => (
              <MenuItem
                key={option || "unknown"}
                style={{
                  cursor: disabled ? "not-allowed" : "pointer",
                  color: "black",
                  padding: "8px 13px",
                  fontSize: "16px",
                }}
                disabled={disabled}
                onClick={
                  !isDisabled && onChange
                    ? () =>
                        onChange(
                          // Keep the same order in the enum
                          () =>
                            sortArray(
                              [...options, ...dataNotInOptions]
                                .filter((a) => a !== undefined)
                                .filter((d) =>
                                  d === option ? !accessibleOrgs.includes(d) : accessibleOrgs.includes(d),
                                ),
                            ).map((org) => {
                              return { orgSlug: org, orgName: getLabel(org), orgRole: getOrgRole(org) };
                            }),
                        )
                    : undefined
                }
              >
                {!isDisabled && (
                  <ListItemIcon style={{ minWidth: "32px" }}>
                    <Checkbox
                      edge="start"
                      disabled={disabled}
                      checked={accessibleOrgs?.includes(option)}
                      tabIndex={-1}
                      disableRipple
                      color="primary"
                      style={{ padding: 0, marginLeft: 0 }}
                    />
                  </ListItemIcon>
                )}
                <span
                  style={{
                    width: 150,
                    maxWidth: 150,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {getLabel(option)}
                </span>

                <span style={{ padding: "0px 24px" }}>→</span>
                <span>{getRoleLabel(getOrgRole(option))}</span>
              </MenuItem>
            ))}
        </div>
      </Menu>
    </>
  );
}

const TableSortLabelStyled = styled(TableSortLabel)`
  text-transform: uppercase;
  cursor: pointer;
  color: #858574 !important;
  font-size: 14px;
  font-family: "Brown", "Helvetica", sans-serif;
`;
